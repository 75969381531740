import {
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Eye, EyeSlash } from 'iconsax-react';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useSession } from '../../api';
import { Button, PageBody, PageContainer, PageHeader } from '../../components';
import { MIN_PASSWORD_LENGTH, passwordContainsNumber, passwordContainsSpecialCharacter } from '../../utils/password-requirements';
import { useFormState } from '../../utils/useFormState';

const OtterBackgroundStack = styled(Stack)`
  position: relative;

  &::before {
    content: '';
    background-image: url('/otter-write.png');
    background-size: 360px;
    background-repeat: no-repeat;
    background-position: top right;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.5;
  }
`;

export function AcceptInvitePage({ ...props }) {
  const theme = useTheme();
  const { checkInviteToken, registerAccount } = useSession();
  const location = useLocation();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [loading, setLoading] = useState(false);
  const token = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);

    return searchParams.get('invite-token');
  }, [location]);

  const [inviteTokenValid, setInviteTokenValid] = useState<boolean | null>(null);
  useEffect(() => {
    if (!token) {
      return;
    }

    checkInviteToken(token)
      .then((isValid) => {
        setInviteTokenValid(isValid);
      })
      .catch((e) => {
        throw e;
      });
  }, [token, checkInviteToken]);

  const [firstName, setFirstName, firstNameTouched, setFirstNameTouched] = useFormState('');
  const [lastName, setLastName, lastNameTouched, setLastNameTouched] = useFormState('');
  const [password, setPassword, passwordTouched, setPasswordTouched] = useFormState('');
  const [revealPassword, setRevealPassword] = useState(false);
  const [confirmPassword, setConfirmPassword, confirmPasswordTouched, setConfirmPasswordTouched] = useFormState('');
  const [revealConfirmPassword, setRevealConfirmPassword] = useState(false);

  const passwordError = useMemo(() => {
    if (!password) {
      return 'Password is required.';
    } else if (password.length < MIN_PASSWORD_LENGTH) {
      return `Password must have at least ${MIN_PASSWORD_LENGTH} characters.`;
    } else if (!passwordContainsNumber(password)) {
      return 'Password must contain a number.';
    } else if (!passwordContainsSpecialCharacter(password)) {
      return 'Password must contain a special character.';
    } else {
      return null;
    }
  }, [password]);

  const confirmPasswordError = useMemo(() => {
    if (!confirmPassword) {
      return 'Please repeat your password.';
    } else if (confirmPassword !== password) {
      return 'Passwords do not match.';
    } else {
      return null;
    }
  }, [confirmPassword, password]);

  const error = useMemo(() => {
    return !!passwordError || !!confirmPasswordError || !firstName || !lastName || !token;
  }, [passwordError, confirmPasswordError, firstName, lastName, token]);

  const register = async () => {
    if (error) {
      return;
    }

    setLoading(true);

    try {
      await registerAccount(token!, firstName, lastName, password);

      //For aesthetic purposes
      await new Promise((resolve) => {
        setTimeout(resolve, 1000);
      });

      navigate('/');
    } finally {
      setLoading(false);
    }
  };

  const submit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await register();
  };

  return (
    <PageContainer {...props}>
      <PageHeader title='Accept Invitation' />
      <PageBody gutter='thin'>
        {inviteTokenValid === null && (
          <Stack justifyContent='center' alignItems='center' flex={1}>
            <CircularProgress />
          </Stack>
        )}

        {inviteTokenValid === false && (
          <Stack justifyContent='center' alignItems='center' flex={1}>
            <Typography>This invitation is invalid. Please ask your company admin to resend the invitation.</Typography>
          </Stack>
        )}

        {inviteTokenValid === true && (
          <form onSubmit={submit}>
            <OtterBackgroundStack
              spacing={10}
              sx={{
                width: isSmallScreen ? '100%' : '70%',
                margin: '0 auto',
                paddingTop: '196px',
                paddingBottom: '32px',
                paddingLeft: isSmallScreen ? 0 : '64px',
                paddingRight: isSmallScreen ? 0 : '64px',
              }}
            >
              <Stack>
                <Stack direction='row'>
                  <FormControl required disabled={loading} error={firstNameTouched && !firstName} fullWidth>
                    <TextField
                      label='First Name'
                      placeholder='Enter name...'
                      value={firstName}
                      onBlur={() => setFirstNameTouched(true)}
                      onChange={(event) => setFirstName(event.target.value)}
                    />
                    <FormHelperText>{!firstName && firstNameTouched && 'Required'}</FormHelperText>
                  </FormControl>

                  <FormControl required disabled={loading} error={lastNameTouched && !lastName} fullWidth>
                    <TextField
                      label='Last Name'
                      placeholder='Enter name...'
                      value={lastName}
                      onBlur={() => setLastNameTouched(true)}
                      onChange={(event) => setLastName(event.target.value)}
                    />
                    <FormHelperText>{!lastName && lastNameTouched && 'Required'}</FormHelperText>
                  </FormControl>
                </Stack>

                <FormControl required error={!!passwordError && passwordTouched}>
                  <TextField
                    type={revealPassword ? 'text' : 'password'}
                    label='Password'
                    placeholder='Enter your password'
                    value={password}
                    disabled={loading}
                    onBlur={() => setPasswordTouched(true)}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton tabIndex={-1} onClick={() => setRevealPassword((revealPassword) => !revealPassword)}>
                            {revealPassword ? <EyeSlash /> : <Eye />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {!!passwordError && passwordTouched && <FormHelperText>{passwordError}</FormHelperText>}
                </FormControl>
                <FormControl required error={!!confirmPasswordError && confirmPasswordTouched}>
                  <TextField
                    type={revealConfirmPassword ? 'text' : 'password'}
                    label='Repeat Password'
                    placeholder='Repeat your password'
                    value={confirmPassword}
                    disabled={loading}
                    onBlur={() => setConfirmPasswordTouched(true)}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton tabIndex={-1} onClick={() => setRevealConfirmPassword((revealPassword) => !revealPassword)}>
                            {revealConfirmPassword ? <EyeSlash /> : <Eye />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {!!confirmPasswordError && confirmPasswordTouched && <FormHelperText>{confirmPasswordError}</FormHelperText>}
                </FormControl>
              </Stack>

              <Button type='submit' variant='contained' color='primary' disabled={error} onClick={register}>
                Let's Go!
              </Button>
            </OtterBackgroundStack>
          </form>
        )}
      </PageBody>
    </PageContainer>
  );
}
