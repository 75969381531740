import { Box, CircularProgress, IconButton, Stack, Typography, alpha, useTheme } from '@mui/material';
import { Add, CloseCircle } from 'iconsax-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { PlaidLinkError, PlaidLinkOnExitMetadata, usePlaidLink } from 'react-plaid-link';
import styled from 'styled-components';
import { InstitutionStatus, appEnv, useTransaction } from '../../api';
import { Button, ConfirmDialog, PageBody, PageContainer, PageHeader } from '../../components';
import { MED_VERTICAL_SPACING } from '../../theme';

const TileGrid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 50%;

  > * {
    margin: ${({ theme }) => theme.spacing(MED_VERTICAL_SPACING)};
  }
`;

const InstitutionTile = styled.div<{ status: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 128px;
  height: 128px;
  border-radius: ${({ theme }) => theme.roundedCorners(5)};
  border: ${({ theme, status }) => {
    if (status === InstitutionStatus.CONNECTED) {
      return `1px solid ${theme.palette.primary.main}`;
    } else if (status === InstitutionStatus.CONNECTION_STALE) {
      return `1px solid ${theme.palette.warning[100]}`;
    } else {
      return `1px solid ${theme.palette.error[100]}`;
    }
  }};

  background: ${({ theme, status }) => {
    if (status === InstitutionStatus.CONNECTED) {
      return alpha(theme.palette.primary.main, 0.25);
    } else if (status === InstitutionStatus.CONNECTION_STALE) {
      return alpha(theme.palette.warning.main, 0.25);
    } else {
      return alpha(theme.palette.error.main, 0.25);
    }
  }};

  &:hover {
    cursor: pointer;
    background: ${({ theme, status }) => {
      if (status === InstitutionStatus.CONNECTED) {
        return alpha(theme.palette.primary.main, 0.5);
      } else if (status === InstitutionStatus.CONNECTION_STALE) {
        return alpha(theme.palette.warning.main, 0.5);
      } else {
        return alpha(theme.palette.error.main, 0.5);
      }
    }};
  }
`;

const NewInstitutionTile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 128px;
  height: 128px;
  border-radius: ${({ theme }) => theme.roundedCorners(5)};
  border: ${({ theme }) => `1px dashed ${theme.palette.primary.main}`};

  &:hover {
    cursor: pointer;
  }
`;

const useToken = () => {
  const { institutions, fetchInstitutions, createLinkToken, createUpdateModeLinkToken, authenticate, setLinkItemLoginRequired, removeInstitution } =
    useTransaction();

  useEffect(() => {
    fetchInstitutions().catch((e) => {
      throw e;
    });
  }, [fetchInstitutions]);

  return {
    institutions,
    fetchInstitutions,
    createLinkToken,
    createUpdateModeLinkToken,
    setLinkItemLoginRequired,
    authenticate,
    removeInstitution,
  };
};

export function PlaidUpdatePage() {
  const theme = useTheme();
  const [loading, setLoading] = useState<string | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const { institutions, fetchInstitutions, createLinkToken, createUpdateModeLinkToken, authenticate, setLinkItemLoginRequired, removeInstitution } =
    useToken();
  const { recordPlaidLinkSessionFailure } = useTransaction();

  const onLinkSuccess = useCallback(
    async (token: string) => {
      try {
        await authenticate(token);
        await fetchInstitutions();
      } finally {
        setToken(null);
      }
    },
    [authenticate, fetchInstitutions]
  );

  const onLinkExit = useCallback(
    async (error: null | PlaidLinkError, metadata: PlaidLinkOnExitMetadata) => {
      if (error) {
        await recordPlaidLinkSessionFailure(metadata.link_session_id);
      }
      setToken(null);
    },
    [recordPlaidLinkSessionFailure]
  );

  const { open, ready } = usePlaidLink({
    token,
    onSuccess: onLinkSuccess,
    onExit: onLinkExit,
  });

  const createInstitutionLink = useCallback(async () => {
    setLoading('new');

    try {
      const token = await createLinkToken();
      setToken(token);
    } finally {
      setLoading(null);
    }
  }, [createLinkToken]);

  const updateInstitutionLink = useCallback(
    async (institutionId: string) => {
      setLoading(institutionId);

      try {
        const token = await createUpdateModeLinkToken(institutionId);
        setToken(token);
      } finally {
        setLoading(null);
      }
    },
    [createUpdateModeLinkToken]
  );

  const setLoginRequired = useCallback(
    async (institutionId: string) => {
      await setLinkItemLoginRequired(institutionId);
      await fetchInstitutions();
    },
    [setLinkItemLoginRequired, fetchInstitutions]
  );

  useEffect(() => {
    if (token && open && ready) {
      open();
    }
  }, [token, open, ready]);

  const problemExists = useMemo(() => {
    if (!institutions) {
      return false;
    }

    return !!institutions.find((i) => i.status === InstitutionStatus.CONNECTION_FAILED || i.status === InstitutionStatus.CONNECTION_STALE);
  }, [institutions]);

  const [showRemoveInstitutionConfirmDialogFor, setShowRemoveInstitutionConfirmDialogFor] = useState<string | null>(null);

  return (
    <PageContainer>
      <PageHeader title='Plaid Update' />
      <PageBody gutter='thick'>
        <Stack alignItems='center' justifyContent='center' flex={1}>
          <Box display='flex' flexDirection='column' alignItems='center'>
            {problemExists ? <img src='/shocked-otter.png' width='200px' /> : <img src='/content-otter.png' width='200px' />}
            <Typography align='center'>These are your currently connected institutions.</Typography>
            {problemExists && (
              <Typography align='center'>Connection problems are highlighted in red. Click an institution to update its connection.</Typography>
            )}
            {!problemExists && (
              <Typography align='center'>
                All connections are <em>otter-ly</em> flawless!
              </Typography>
            )}
          </Box>
          <TileGrid>
            {institutions &&
              institutions.map((i) => (
                <InstitutionTile key={i.id} status={i.status} onClick={() => updateInstitutionLink(i.id)}>
                  <IconButton
                    style={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                    }}
                    onClick={(e) => {
                      setShowRemoveInstitutionConfirmDialogFor(i.id);
                      e.stopPropagation();
                    }}
                  >
                    <CloseCircle size='1rem' />
                  </IconButton>
                  {loading === i.id ? <CircularProgress /> : i.name}
                  {appEnv === 'local' && (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={async (e) => {
                        e.stopPropagation();
                        await setLoginRequired(i.id);
                      }}
                    >
                      Lock
                    </Button>
                  )}
                </InstitutionTile>
              ))}
            <NewInstitutionTile onClick={() => createInstitutionLink()}>
              <Typography color={theme.palette.primary.main}>New Institution</Typography>
              <Add color={theme.palette.primary.main} />
            </NewInstitutionTile>
          </TileGrid>
          <ConfirmDialog
            open={!!showRemoveInstitutionConfirmDialogFor}
            onClose={() => setShowRemoveInstitutionConfirmDialogFor(null)}
            onConfirm={async () => {
              setShowRemoveInstitutionConfirmDialogFor(null);
              await removeInstitution(showRemoveInstitutionConfirmDialogFor!);
            }}
            message='Are you sure you want to remove this connected institution?'
          />
        </Stack>
      </PageBody>
    </PageContainer>
  );
}
