import { FormControl, InputLabel, MenuItem, Select, useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Organization } from '../api';
import { storage } from '../utils/storage';

const LOCAL_STORAGE_KEY = 'admin.organizationId';

export const useSelectedOrganization = (initialValue: Organization | null) => {
  const previousSelectedOrganization = useRef<Organization | null | undefined>(undefined);
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(initialValue);

  useEffect(() => {
    previousSelectedOrganization.current = selectedOrganization;
  }, [selectedOrganization]);

  return {
    selectedOrganization,
    setSelectedOrganization,
    previousSelectedOrganization: previousSelectedOrganization.current,
  };
};

export interface AdminOrganizationSelectProps {
  onOrganizationChange: (organization: Organization) => void;
  organizations: Organization[];
}
export function AdminOrganizationSelect({ onOrganizationChange, organizations }: AdminOrganizationSelectProps) {
  const theme = useTheme();

  const [organization, setOrganization] = useState<Organization | null>(null);

  const orgMap = useMemo(() => {
    return organizations.reduce(
      (map, current) => {
        map[current.id!] = current;
        return map;
      },
      {} as { [organizationId: string]: Organization }
    );
  }, [organizations]);

  useEffect(() => {
    const handleOrgChange = async () => {
      const recentOrganizationId = await storage.getItem(LOCAL_STORAGE_KEY);
      const recentOrganization = organizations.find((o) => o.id === recentOrganizationId);
      if (recentOrganization) {
        setOrganization(recentOrganization);
        onOrganizationChange(recentOrganization);
      }
    };

    handleOrgChange().catch((e) => {
      throw e;
    });
  }, [onOrganizationChange, organizations]);

  const changeOrganization = useCallback(
    async (organization: Organization) => {
      setOrganization(organization);
      if (organization) {
        await storage.setItem(LOCAL_STORAGE_KEY, organization.id!);
      } else {
        await storage.removeItem(LOCAL_STORAGE_KEY);
      }
      onOrganizationChange(organization);
    },
    [onOrganizationChange]
  );

  return (
    <FormControl size='small'>
      <InputLabel id='organization-select-label'>Organization</InputLabel>
      <Select
        label='Organization'
        labelId='organization-select-label'
        autoWidth
        value={organization?.id || ''}
        onChange={(event) => changeOrganization(orgMap[event.target.value])}
        style={{
          minWidth: theme.spacing(36),
        }}
      >
        {organizations
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((o) => (
            <MenuItem key={o.id} value={o.id}>
              {o.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
