import { Typography, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import { CloseCircle, TickCircle } from 'iconsax-react';

export interface IWidgetProps {
  isSuccess: true | false | null;
  requiresInput?: boolean;
}

export function SuccessIcon() {
  const theme = useTheme();
  return (
    <Stack alignItems='center'>
      <TickCircle variant='Bold' color={theme.palette.primary.main} size={theme.spacing(10)} />
    </Stack>
  );
}

export function FailureIcon() {
  const theme = useTheme();
  return (
    <Stack alignItems='center'>
      <CloseCircle variant='Bold' color={theme.palette.error.main} size={theme.spacing(10)} />
      <Typography>An error occurred. Please try again.</Typography>
    </Stack>
  );
}
