import { Close } from '@mui/icons-material';
import { Alert, AlertTitle, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, useTheme } from '@mui/material';
import { DocumentText } from 'iconsax-react';
import React, { KeyboardEvent, MouseEvent, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import { Button } from './button';
import { FileThumbnail } from './file-thumbnail';

const thumbsContainer: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb: React.CSSProperties = {
  position: 'relative',
  display: 'inline-flex',
  borderRadius: '1rem',
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
  alignItems: 'center',
  justifyContent: 'center',
};

const thumbInner: React.CSSProperties = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img: React.CSSProperties = {
  display: 'block',
  width: '72px',
  height: '72px',
};

const DragAndDropContainer = styled.div`
  flex-shrink: 0;
  border-radius: 12px;
  border: ${({ theme }) => `1px dashed ${theme.palette.neutral.main}`};
  text-align: center;
  padding: ${({ theme }) => theme.spacing(5)};
  min-width: 300px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    min-width: 150px;
  }
`;

export interface FileObject extends File {
  preview: string;
}

export interface DocumentUploadDialogProps {
  open: boolean;
  onClose: () => void;
  onUpload: (docs: FileObject[]) => void;
}
export function DocumentUploadDialog({ open, onClose, onUpload }: DocumentUploadDialogProps) {
  const theme = useTheme();
  const [files, setFiles] = useState<FileObject[]>([]);
  const [fileError, setFileError] = useState('');

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/*': ['.gif', '.jpeg', '.png', '.pdf', '.heic'] },
    maxSize: 5242880, // 5MB
    maxFiles: 50,
    onDrop: (acceptedFiles, fileRejections) => {
      setFileError('');
      fileRejections.forEach((file) => {
        file.errors.forEach((err) => {
          if (err.code === 'file-too-large') {
            setFileError(`${file.file.name} is greater than 5 MB.`);
          } else if (err.code === 'file-invalid-type') {
            setFileError(err.message);
          } else if (err.code === 'too-many-files') {
            setFileError(`Too many files added. A maximum of 50 files can be uploaded at a time.`);
          } else {
            setFileError(`An error occurred while uploading this file.`);
          }
        });
      });
      setFiles((existing) => [
        ...existing,
        ...acceptedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          });
        }),
      ]);
    },
  });

  const upload = useCallback(() => {
    onUpload(files);
    onClose();
  }, [onUpload, onClose, files]);

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        upload();
      }
    },
    [upload]
  );

  const removeFile = (e: MouseEvent, file: FileObject) => {
    e.stopPropagation();
    setFiles((existing) => [...existing.filter((f) => f !== file)]);
  };

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <FileThumbnail fileType={file.type} filePreviewUrl={file.preview} style={img} />
      </div>
      <IconButton
        onClick={(e) => removeFile(e, file)}
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          transform: 'translate(50%, -50%)',
          width: 18,
          height: 18,
          background: theme.palette.neutral.main,
        }}
      >
        <Close style={{ height: '16px', color: theme.palette.common.black }} />
      </IconButton>
    </div>
  ));

  return (
    <Dialog open={open} onClose={onClose} onKeyDown={onKeyDown}>
      <DialogTitle>Add Receipts/Invoices</DialogTitle>
      <DialogContent>
        <Stack>
          <DragAndDropContainer>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              {files.length > 1 && <h4>{files.length} files added</h4>}
              {files.length === 1 && <h4>1 file added</h4>}
              {files.length === 0 && (
                <>
                  <DocumentText style={{ paddingTop: theme.spacing(3), color: theme.palette.neutral.main }} variant='Bold' size={theme.spacing(15)} />
                  <h4>Drag and drop receipt/invoice files here</h4>
                  <p style={{ fontSize: theme.spacing(3) }}>Supported file types: PDF, JPG, GIF, PNG up to 5MB</p>
                  <Button variant='outlined' color='primary'>
                    Browse File(s)
                  </Button>
                </>
              )}
              {files.length > 0 && <aside style={thumbsContainer}>{thumbs}</aside>}
            </div>
          </DragAndDropContainer>
          {fileError && (
            <Alert severity='error'>
              <AlertTitle>Error</AlertTitle>
              {fileError}
            </Alert>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' disabled={files.length === 0} onClick={upload}>
          Upload Receipt(s)
        </Button>
      </DialogActions>
    </Dialog>
  );
}
