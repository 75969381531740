import { createContext, useCallback, useContext } from 'react';
import { axios } from './axios-base';
import { DocumentUnmatchedReason, PaidForPersonallyHandling } from './shared-interfaces';

interface IDocumentContext {
  uploadDocuments: (docs: FileDetails[] | File[]) => Promise<string[]>;
  clarify: (args: {
    documentId: string;
    description?: string;
    unmatchedReason?: DocumentUnmatchedReason;
    paymentAccount?: string;
    reimburseToUserId?: string;
    paidForPersonallyHandling?: PaidForPersonallyHandling;
    savedForLater?: boolean;
  }) => Promise<void>;
  reimbursementApprovalResult: (args: { documentId: string; approved: boolean }) => Promise<void>;
  refreshSignedUrls: (documentId: string) => Promise<{
    signedUrl: string;
    signedUrlExp: number;
    thumbnailSignedUrl: string;
    thumbnailSignedUrlExp: number;
  }>;
}

export interface FileObject {
  name: string;
  size: number;
  type: string;
  preview: string;
}

export function fileToBase64(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = function () {
      const base64String = (reader.result as string).split(',')[1];
      resolve(base64String);
    };

    reader.onerror = function (error) {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

export interface FileDetails {
  base64String: string;
  fileName: string;
  mimeType: string;
}

export async function convertFilesToFileDetails(files: File[]) {
  const fileDataPromises = [] as Array<Promise<FileDetails>>;
  for (const file of files) {
    fileDataPromises.push(
      (async () => {
        const base64String = await fileToBase64(file);

        return {
          fileName: file.name,
          mimeType: file.type,
          base64String,
        };
      })()
    );
  }
  return await Promise.all(fileDataPromises);
}

function useDocumentData() {
  const uploadDocuments = useCallback(async (files: FileDetails[] | File[]) => {
    if (!files.length) {
      throw new Error('No riles provided for upload');
    }

    if (!(files[0] as FileDetails).base64String) {
      files = await convertFilesToFileDetails(files as File[]);
    }

    const uploadResponse: { data: string[] } = await axios.post('/documents/upload-multiple', {
      files,
    });

    return uploadResponse.data; // Assuming the response will contain an array of ids
  }, []);

  const clarify = useCallback(
    async ({
      documentId,
      description,
      unmatchedReason,
      paymentAccount,
      reimburseToUserId,
      paidForPersonallyHandling,
      savedForLater,
    }: {
      documentId: string;
      description?: string;
      unmatchedReason?: DocumentUnmatchedReason | undefined;
      paymentAccount?: string;
      reimburseToUserId?: string;
      paidForPersonallyHandling?: PaidForPersonallyHandling;
      savedForLater?: boolean;
    }) => {
      await axios.post(`/documents/${documentId}/clarify`, {
        description,
        unmatchedReason,
        paymentAccount,
        reimburseToUserId,
        paidForPersonallyHandling,
        savedForLater,
      });
    },
    []
  );

  const reimbursementApprovalResult = useCallback(async ({ documentId, approved }: { documentId: string; approved: boolean }) => {
    await axios.post(`/documents/${documentId}/reimbursement-approval-result`, {
      approved,
    });
  }, []);

  const refreshSignedUrls = useCallback(async (documentId: string) => {
    const response = await axios.post(`/documents/${documentId}/refresh-signed-urls`, {});

    const refreshData = response.data as {
      signedUrl: string;
      signedUrlExp: number;
      thumbnailSignedUrl: string;
      thumbnailSignedUrlExp: number;
    };

    return refreshData;
  }, []);

  const documentContext: IDocumentContext = {
    clarify,
    reimbursementApprovalResult,
    uploadDocuments,
    refreshSignedUrls,
  };
  return documentContext;
}

export const DocumentContext = createContext<IDocumentContext>({} as IDocumentContext);

export const useDocument = () => useContext(DocumentContext);

export const DocumentContextProvider = ({ children }: { children: React.ReactNode }) => {
  const document = useDocumentData();

  return <DocumentContext.Provider value={document}>{children}</DocumentContext.Provider>;
};
