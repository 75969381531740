import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { useEffect, useMemo, useState } from 'react';
import { Account, Journal, JournalEntry, LogicalType, StandardAccounts, StatementType, SyncType } from '../api';
import { Button } from './button';
import { StatementTypeSelect } from './statement-type-select';
import { TreeSelect } from './tree-select';

const SUBACCOUNT = 'Subaccount';

export interface CreateAccountParams {
  name: string;
  type: string;
  cardType?: string | null;
  accountNumber?: string;
  parentId?: string;
  description: string;
  currency: string;
  syncType: SyncType | null;
  statementAvailabilityDayOfMonth: number | null;
  statementType: string | null;
}

export interface EditAccountDialogProps {
  open: boolean;
  journalEntries: JournalEntry[];
  journal: Journal;
  accounts: Account[];
  existingAccount: Account | null;
  onClose: () => void;
  createAccount: (account: CreateAccountParams) => Promise<void>;
  updateAccount: (account: Partial<Account> & { id: string; cardType?: string | null }) => Promise<void>;
}
export function EditAccountDialog({
  createAccount,
  updateAccount,
  existingAccount,
  accounts,
  journalEntries,
  journal,
  open,
  onClose,
}: EditAccountDialogProps) {
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState('');
  const [nameTouched, setNameTouched] = useState(false);

  const [description, setDescription] = useState('');
  const [descriptionTouched, setDescriptionTouched] = useState(false);

  const [type, setType] = useState<string>(StandardAccounts.CASH);

  const [cardType, setCardType] = useState<string | null>(null);

  const [accountNumber, setAccountNumber] = useState('');
  const [accountNumberTouched, setAccountNumberTouched] = useState(false);

  const [currency, setCurrency] = useState(journal.currency);

  const [syncType, setSyncType] = useState<SyncType | 'none'>('none');

  const [statementType, setStatementType] = useState(StatementType.RBC_CANADA);

  const [statementAvailabilityDayOfMonth, setStatementAvailabilityDayOfMonth] = useState('');
  const [statementAvailabilityDayOfMonthTouched, setStatementAvailabilityDayOfMonthTouched] = useState(false);
  const statementAvailabilityDayOfMonthError = useMemo(() => {
    const parsed = parseInt(statementAvailabilityDayOfMonth);
    return isNaN(parsed) || parsed === 0 || parsed > 31;
  }, [statementAvailabilityDayOfMonth]);

  useEffect(() => {
    setLoading(false);
    setName(existingAccount?.name || '');
    setNameTouched(false);
    setDescription(existingAccount?.description || '');
    setDescriptionTouched(false);
    setType(existingAccount?.standardAccount || StandardAccounts.CASH);
    setCardType(existingAccount?.externalType || null);
    setAccountNumber(existingAccount?.externalId || '');
    setAccountNumberTouched(false);
    setCurrency(existingAccount?.externalCurrency || journal.currency);
    setSyncType(existingAccount?.syncType || 'none');
    setStatementAvailabilityDayOfMonth(
      existingAccount && existingAccount.statementAvailabilityDayOfMonth ? String(existingAccount.statementAvailabilityDayOfMonth) : ''
    );
    setStatementAvailabilityDayOfMonthTouched(false);
    setStatementType(StatementType.RBC_CANADA);
  }, [open, existingAccount, journal]);

  const validParentAccounts = useMemo(() => {
    return accounts
      .filter((a) => {
        if (
          a.standardAccount === StandardAccounts.GST_HST_RECEIVABLE ||
          a.standardAccount === StandardAccounts.GST_HST_PAYABLE ||
          a.standardAccount === StandardAccounts.GST_HST_SUSPENSE ||
          a.standardAccount === StandardAccounts.PST_PAYABLE ||
          a.standardAccount === StandardAccounts.PST_SUSPENSE ||
          a.standardAccount === StandardAccounts.RETAINED_EARNINGS_DEFICIT ||
          a.standardAccount === StandardAccounts.REWARDS_AND_REFUNDS ||
          a.standardAccount === StandardAccounts.LOSS_ON_FOREIGN_EXCHANGE ||
          a.standardAccount === StandardAccounts.BANK_CHARGES ||
          a.standardAccount === StandardAccounts.UNCATEGORIZED_EXPENSES ||
          a.standardAccount === StandardAccounts.UNCATEGORIZED_ASSETS ||
          a.standardAccount === StandardAccounts.UNCATEGORIZED_INCOME ||
          a.standardAccount === StandardAccounts.CASH ||
          a.standardAccount === StandardAccounts.PERSONAL_AND_CREDIT_CARD_LOANS ||
          a.standardAccount === StandardAccounts.LINE_OF_CREDIT ||
          a.standardAccount === StandardAccounts.EXPENSE_REIMBURSEMENT ||
          a.standardAccount === StandardAccounts.UNDEPOSITED_FUNDS
        ) {
          return false;
        }

        return a.logicalType !== LogicalType.GENERAL_ACCOUNT;
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [accounts]);
  const [parentAccountId, setParentAccountId] = useState('');
  const [parentAccountTouched, setParentAccountTouched] = useState(false);

  const valid = useMemo(() => {
    return (
      name &&
      description &&
      type &&
      ((accountNumber && !parentAccountId) || (parentAccountId && !accountNumber)) &&
      syncType &&
      (syncType !== SyncType.STATEMENT_UPLOAD || !statementAvailabilityDayOfMonthError) &&
      (!cardType ||
        (cardType &&
          (type === StandardAccounts.CASH || type === StandardAccounts.PERSONAL_AND_CREDIT_CARD_LOANS || type === StandardAccounts.LINE_OF_CREDIT)))
    );
  }, [name, description, type, cardType, accountNumber, parentAccountId, syncType, statementAvailabilityDayOfMonthError]);

  const changeType = (type: string) => {
    setType(type);
    setParentAccountId('');
    setAccountNumber('');
  };

  const changeCardType = (cardType: string) => {
    if (cardType === 'unknown') {
      return;
    } else if (cardType === 'none') {
      setCardType(null);
    } else {
      setCardType(cardType);
    }
  };

  const accountInUse = useMemo(() => {
    if (!existingAccount) {
      return false;
    }

    const entryAccounts = new Set<string>();
    for (const entry of journalEntries) {
      for (const debit of entry.debits) {
        entryAccounts.add(debit.accountId);
      }

      for (const credit of entry.credits) {
        entryAccounts.add(credit.accountId);
      }
    }
    return entryAccounts.has(existingAccount.id);
  }, [journalEntries, existingAccount]);

  const create = async () => {
    const requiresSyncType =
      type === StandardAccounts.CASH || type === StandardAccounts.LINE_OF_CREDIT || type === StandardAccounts.PERSONAL_AND_CREDIT_CARD_LOANS;

    try {
      setLoading(true);
      await createAccount({
        name,
        description,
        type,
        cardType,
        accountNumber: accountNumber || undefined,
        parentId: parentAccountId || undefined,
        currency,
        syncType: requiresSyncType && syncType && syncType !== 'none' ? syncType : null,
        statementAvailabilityDayOfMonth: syncType !== SyncType.AUTOMATIC ? Number(statementAvailabilityDayOfMonth) : null,
        statementType: syncType !== SyncType.AUTOMATIC ? statementType : null,
      });
    } finally {
      setLoading(false);
    }
  };

  const update = async () => {
    if (!existingAccount) {
      return;
    }

    const requiresSyncType =
      existingAccount.standardAccount === StandardAccounts.CASH ||
      existingAccount.standardAccount === StandardAccounts.LINE_OF_CREDIT ||
      existingAccount.standardAccount === StandardAccounts.PERSONAL_AND_CREDIT_CARD_LOANS;

    try {
      setLoading(true);
      await updateAccount({
        id: existingAccount.id,
        name,
        cardType,
        description,
        externalId: accountNumber || null,
        syncType: requiresSyncType && syncType && syncType !== 'none' ? syncType : null,
        statementAvailabilityDayOfMonth: syncType !== SyncType.AUTOMATIC ? Number(statementAvailabilityDayOfMonth) : null,
        statementType: syncType !== SyncType.AUTOMATIC ? statementType : null,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>{existingAccount ? 'Edit Journal Account' : 'Create Journal Account'}</DialogTitle>
      <DialogContent>
        <LocalizationProvider>
          <form>
            <Stack>
              {!existingAccount && (
                <FormControl>
                  <InputLabel id='account-type-select-label'>Type</InputLabel>
                  <Select
                    label='Type'
                    labelId='account-type-select-label'
                    value={type}
                    onChange={(event) => changeType(event.target.value)}
                    disabled={loading || !!existingAccount}
                  >
                    <MenuItem value={StandardAccounts.CASH}>{StandardAccounts.CASH}</MenuItem>
                    <MenuItem value={StandardAccounts.PERSONAL_AND_CREDIT_CARD_LOANS}>{StandardAccounts.PERSONAL_AND_CREDIT_CARD_LOANS}</MenuItem>
                    <MenuItem value={StandardAccounts.LINE_OF_CREDIT}>{StandardAccounts.LINE_OF_CREDIT}</MenuItem>
                    <MenuItem value={SUBACCOUNT}>{SUBACCOUNT}</MenuItem>
                  </Select>
                </FormControl>
              )}
              {(type === StandardAccounts.PERSONAL_AND_CREDIT_CARD_LOANS || type === StandardAccounts.LINE_OF_CREDIT) && (
                <FormControl>
                  <InputLabel id='create-account-card-type'>Card Type</InputLabel>
                  <Select
                    label='Card Type'
                    labelId='create-account-card-type'
                    value={cardType || 'none'}
                    onChange={(event) => changeCardType(event.target.value)}
                    disabled={loading}
                  >
                    <MenuItem value='none'>None</MenuItem>
                    <MenuItem value='VISA'>Visa</MenuItem>
                    <MenuItem value='MASTERCARD'>Mastercard</MenuItem>
                    <MenuItem value='AMEX'>Amex</MenuItem>
                    <MenuItem value='unknown'>{cardType}</MenuItem>
                  </Select>
                </FormControl>
              )}
              {(type === StandardAccounts.CASH ||
                type === StandardAccounts.PERSONAL_AND_CREDIT_CARD_LOANS ||
                type === StandardAccounts.LINE_OF_CREDIT) && (
                <FormControl>
                  <InputLabel id='create-account-currency'>Currency</InputLabel>
                  <Select
                    label='Currency'
                    labelId='create-account-currency'
                    value={currency}
                    onChange={(event) => setCurrency(event.target.value)}
                    disabled={!!existingAccount}
                  >
                    <MenuItem value='CAD'>CAD</MenuItem>
                    <MenuItem value='USD'>USD</MenuItem>
                  </Select>
                </FormControl>
              )}
              {(type === StandardAccounts.CASH ||
                type === StandardAccounts.PERSONAL_AND_CREDIT_CARD_LOANS ||
                type === StandardAccounts.LINE_OF_CREDIT) && (
                <FormControl required error={!accountNumber && accountNumberTouched}>
                  <TextField
                    fullWidth
                    label='Account Number'
                    placeholder='Account number...'
                    value={accountNumber}
                    onBlur={() => setAccountNumberTouched(true)}
                    onChange={(e) => setAccountNumber(e.target.value)}
                    disabled={syncType === SyncType.AUTOMATIC || loading || accountInUse}
                  />
                  <FormHelperText>{!accountNumber && accountNumberTouched && 'Account number invalid'}</FormHelperText>
                </FormControl>
              )}
              {type === SUBACCOUNT && (
                <FormControl required error={!parentAccountId && parentAccountTouched}>
                  <TreeSelect
                    label='Parent Account'
                    items={validParentAccounts}
                    itemComparator={(a, b) => a.name.localeCompare(b.name)}
                    value={parentAccountId}
                    onChange={(event) => {
                      setParentAccountId(event.target.value as string);
                      setParentAccountTouched(true);
                    }}
                    disabled={!!existingAccount || loading}
                    allowParentSelection={true}
                  />
                </FormControl>
              )}
              <FormControl required error={!name && nameTouched}>
                <TextField
                  fullWidth
                  label='Name'
                  placeholder='Account name...'
                  value={name}
                  onBlur={() => setNameTouched(true)}
                  onChange={(e) => setName(e.target.value)}
                  disabled={
                    loading ||
                    (type !== StandardAccounts.CASH &&
                      type !== StandardAccounts.PERSONAL_AND_CREDIT_CARD_LOANS &&
                      type !== StandardAccounts.LINE_OF_CREDIT &&
                      type !== 'Subaccount')
                  }
                />
                <FormHelperText>{!name && nameTouched && 'Invalid name'}</FormHelperText>
              </FormControl>

              <FormControl required error={!description && descriptionTouched}>
                <TextField
                  fullWidth
                  multiline
                  label='Description'
                  placeholder='Description...'
                  value={description}
                  onBlur={() => setDescriptionTouched(true)}
                  onChange={(e) => setDescription(e.target.value)}
                  disabled={
                    loading ||
                    (type !== StandardAccounts.CASH &&
                      type !== StandardAccounts.PERSONAL_AND_CREDIT_CARD_LOANS &&
                      type !== StandardAccounts.LINE_OF_CREDIT &&
                      type !== 'Subaccount')
                  }
                />
                <FormHelperText>{!description && descriptionTouched && 'Invalid description'}</FormHelperText>
              </FormControl>

              {(type === StandardAccounts.CASH ||
                type === StandardAccounts.PERSONAL_AND_CREDIT_CARD_LOANS ||
                type === StandardAccounts.LINE_OF_CREDIT) && (
                <FormControl>
                  <InputLabel id='account-sync-type-label'>Sync Type</InputLabel>
                  <Select
                    label='Sync Type'
                    labelId='account-sync-type-label'
                    value={syncType || 'none'}
                    onChange={(event) => setSyncType(event.target.value as SyncType)}
                  >
                    <MenuItem value={'none'}>None</MenuItem>
                    <MenuItem value={SyncType.STATEMENT_UPLOAD}>Statement Upload</MenuItem>
                    <MenuItem value={SyncType.AUTOMATIC}>Automatic</MenuItem>
                  </Select>
                </FormControl>
              )}

              {(type === StandardAccounts.CASH ||
                type === StandardAccounts.PERSONAL_AND_CREDIT_CARD_LOANS ||
                type === StandardAccounts.LINE_OF_CREDIT) &&
                syncType !== SyncType.AUTOMATIC && (
                  <FormControl required error={statementAvailabilityDayOfMonthError && statementAvailabilityDayOfMonthTouched}>
                    <TextField
                      fullWidth
                      label='Statement Availability Day of Month'
                      placeholder='Day...'
                      value={statementAvailabilityDayOfMonth}
                      onBlur={() => setStatementAvailabilityDayOfMonthTouched(true)}
                      onChange={(e) => setStatementAvailabilityDayOfMonth(e.target.value)}
                      disabled={loading}
                    />
                    <FormHelperText>{statementAvailabilityDayOfMonthError && statementAvailabilityDayOfMonthTouched && 'Invalid Day'}</FormHelperText>
                  </FormControl>
                )}

              {(type === StandardAccounts.CASH ||
                type === StandardAccounts.PERSONAL_AND_CREDIT_CARD_LOANS ||
                type === StandardAccounts.LINE_OF_CREDIT) &&
                syncType === SyncType.STATEMENT_UPLOAD && (
                  <FormControl>
                    <InputLabel id='statement-type-label'>Statement Type</InputLabel>
                    <StatementTypeSelect
                      label='Type'
                      labelId='statementTypeSelect'
                      statementType={statementType}
                      onStatementTypeChange={(statementType) => setStatementType(statementType)}
                    />
                  </FormControl>
                )}
            </Stack>
          </form>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        {loading && (
          <Box display='flex' justifyContent='center' sx={{ width: '100%' }}>
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <>
            <Button variant='contained' color='neutral' onClick={onClose}>
              Cancel
            </Button>
            <Button variant='contained' color='primary' disabled={!valid} onClick={existingAccount ? update : create}>
              {existingAccount ? 'Update' : 'Create'}
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
