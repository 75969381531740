import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { CloseCircle } from 'iconsax-react';
import { ReactNode, useCallback, useState } from 'react';
import { Button } from './button';
import { ThreeColumn } from './three-column';

export interface ConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void | Promise<void>;
  title?: string;
  onConfirmLabel?: string;
  message?: string;
  content?: ReactNode;
}

export function ConfirmDialog({ open, onClose, onConfirm, title, onConfirmLabel, message, content }: ConfirmDialogProps) {
  const [loading, setLoading] = useState(false);

  const confirm = useCallback(async () => {
    setLoading(true);
    try {
      const result = onConfirm();
      if (result && typeof result.then === 'function') {
        await result;
      }
    } finally {
      setLoading(false);
    }
  }, [onConfirm]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <ThreeColumn $mainColumn align='center'>
          <span></span>
          <span>{title || 'Are you sure?'}</span>
          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </ThreeColumn>
      </DialogTitle>
      <DialogContent>
        {message}
        {content}
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='neutral'
          onClick={() => {
            onClose();
          }}
          disabled={loading}
        >
          Close
        </Button>
        <Button variant='contained' color='primary' onClick={confirm} disabled={loading}>
          {loading ? <CircularProgress /> : onConfirmLabel || 'Continue'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
