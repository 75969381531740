import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { axios } from '../../api/axios-base';
import { PageBody, PageContainer, PageHeader } from '../../components';

const thumbsContainer: React.CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16,
};

const thumb: React.CSSProperties = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

const thumbInner: React.CSSProperties = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

const img: React.CSSProperties = {
  display: 'block',
  width: 'auto',
  height: '100%',
};

interface FileObject {
  name: string;
  size: number;
  type: string;
  preview: string;
}

export function ReceiptTestPage({ ...props }: any) {
  const [files, setFiles] = useState<FileObject[]>([]);
  const [docs, setDocs] = useState<{ id: string }[]>([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': [],
      'application/pdf': [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
          alt='file'
        />
      </div>
    </div>
  ));

  useEffect(() => {
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, [files]);

  const receiptUpload = async () => {
    try {
      const newDocs = await Promise.all(
        files.map(async (file) => {
          if (!file) return null;

          const formData = new FormData();
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          formData.append('file', file);
          formData.append('file_name', file.name);

          const uploadResponse: { data: { id: string } } = await axios.post('/documents', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

          const docId = uploadResponse.data.id;
          const docResponse: { data: { id: string } } = await axios.get(`/documents/${docId}`);
          const newDoc = { id: docResponse.data.id };
          return newDoc;
        })
      );

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setDocs([...docs, ...newDocs.filter((doc) => doc !== null)]);
    } catch (error) {
      console.error('Error uploading receipts:', error);
    }
  };

  const getLinks = (docs: { id: string }[]) => {
    return docs.map((doc) => (
      <li key={doc.id}>
        <a target='_blank' href={`/receipt/${doc.id}`}>
          Receipt {doc.id}
        </a>
      </li>
    ));
  };

  return (
    <>
      <PageContainer {...props}>
        <PageHeader title='Add Receipt' />
        <PageBody>
          <section className='container'>
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              {files.length > 1 && <h4>{files.length} files added</h4>}
              {files.length === 1 && <h4>1 file added</h4>}
              {files.length === 0 && <h4>Drag and drop some files here</h4>}
              <p>Supported file: PDF, JPG, PNG</p>
            </div>
            {files.length > 0 && <aside style={thumbsContainer}>{thumbs}</aside>}
          </section>
          <Button variant='contained' color='primary' disabled={files.length === 0} onClick={() => receiptUpload()}>
            Upload Receipt(s)
          </Button>
          {docs.length > 0 && (
            <>
              <ul>{getLinks(docs)}</ul>
            </>
          )}
        </PageBody>
      </PageContainer>
    </>
  );
}
