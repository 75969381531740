import { PushNotifications } from '@capacitor/push-notifications';
import { useEffect, useRef } from 'react';
import { platform, useSession } from '../api';

export const usePushNotifications = () => {
  const { sessionDiff, recordDeviceToken, removeDeviceToken } = useSession();

  const deviceToken = useRef<string | null>(null);

  useEffect(() => {
    if (platform === 'web') {
      return;
    }

    if (!sessionDiff.old && sessionDiff.new) {
      PushNotifications.requestPermissions()
        .then((result) => {
          if (result.receive === 'granted') {
            return PushNotifications.register();
          }
        })
        .catch((e) => {
          throw e;
        });

      PushNotifications.addListener('registration', (token) => {
        deviceToken.current = token.value;

        recordDeviceToken(token.value).catch((e) => {
          throw e;
        });
      }).catch((e) => {
        throw e;
      });
    }

    if (sessionDiff.old && !sessionDiff.new && deviceToken.current) {
      removeDeviceToken(deviceToken.current).catch((e) => {
        throw e;
      });
    }

    return () => {
      PushNotifications.removeAllListeners().catch((e) => {
        throw e;
      });
    };
  }, [sessionDiff, recordDeviceToken, removeDeviceToken]);
};
