import { CSSProperties } from 'react';

export function FileThumbnail({ filePreviewUrl, fileType, style }: { filePreviewUrl?: string; fileType: string; style?: CSSProperties }) {
  if (fileType === 'application/pdf') {
    return <img src='/pdf-file-icon.svg' alt='404 - Not Found' style={style} />;
  } else if (fileType === 'image/heic') {
    return <img src='/heic-file-icon.svg' alt='404 - Not Found' style={style} />;
  }

  return (
    <img
      src={filePreviewUrl}
      style={style}
      onLoad={() => {
        URL.revokeObjectURL(filePreviewUrl!);
      }}
      alt='file'
    />
  );
}
