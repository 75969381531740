import { Stack, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components';

export function NotFoundPage() {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Stack alignItems='center' justifyContent='center' width='100vw' height='100dvh' style={{ background: theme.palette.common.black }} spacing={5}>
      <img style={{ height: theme.spacing(10) }} alt='Otter' src={theme.palette.mode === 'dark' ? '/otter-logo.svg' : '/otter-logo-white.svg'} />
      <img
        src='/404.png'
        alt='404 - Not Found'
        style={{
          width: '80%',
          maxWidth: '600px',
        }}
      />
      <Typography variant='h3' color='white' align='center'>
        Whoops - we otter have that page, but we can't seem to find it!
      </Typography>
      <Button variant='contained' color='primary' onClick={() => navigate('/')}>
        Back to familiar waters
      </Button>
    </Stack>
  );
}
