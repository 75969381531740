import {
  Alert,
  AlertTitle,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { AddCircle, Clock, CloseCircle, CloseSquare, Copy, CopySuccess, TickCircle } from 'iconsax-react';
import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Account, AsyncJobStatus, StatementType, Transaction, TransactionImport, useAdmin } from '../../../api';
import { Button, ConfirmDialog, Search, StatementTypeSelect } from '../../../components';

const useAdminData = (organizationId?: string) => {
  const { fetchTransactionImports, transactionImports } = useAdmin();

  useEffect(() => {
    if (!organizationId) {
      return;
    }

    fetchTransactionImports(organizationId).catch((e) => {
      throw e;
    });

    const refreshInterval = setInterval(() => {
      fetchTransactionImports(organizationId).catch((e) => {
        throw e;
      });
    }, 5000);

    return () => {
      clearInterval(refreshInterval);
    };
  }, [fetchTransactionImports, organizationId]);

  const imports = useMemo(() => {
    if (!organizationId) {
      return [];
    }

    return transactionImports[organizationId] || [];
  }, [transactionImports, organizationId]);

  return {
    transactionImports: imports,
  };
};

interface StatementUploadSelectProps {
  onUpload: () => void;
  account: string;
  onAccountChange: (accountId: string) => void;
  file: File | null;
  onFileChange: (file: File | null) => void;
  statementType: StatementType;
  onStatementTypeChange: (type: string) => void;
  accounts: Account[];
}

function StatementUploadSelect({
  file,
  onFileChange,
  statementType,
  onStatementTypeChange,
  onUpload,
  onAccountChange,
  account,
  accounts,
}: StatementUploadSelectProps) {
  const inputRef = useRef<HTMLInputElement | null>(null);

  return (
    <Stack direction='row'>
      {file && (
        <Stack>
          <Typography>{file.name}</Typography>
          <Stack direction='row'>
            <Button variant='contained' color='neutral' onClick={() => onFileChange(null)}>
              Clear
            </Button>
            <Button variant='contained' color='primary' onClick={() => onUpload()}>
              Generate Preview
            </Button>
          </Stack>
        </Stack>
      )}

      {!file && (
        <Button
          variant='contained'
          color='primary'
          onClick={() => {
            inputRef.current?.click();
          }}
        >
          Select File
          <input type='file' hidden ref={inputRef} onChange={(event) => onFileChange(event.target.files![0])} />
        </Button>
      )}

      <FormControl style={{ minWidth: 200 }}>
        <InputLabel id='statementTypeSelect'>Type</InputLabel>
        <StatementTypeSelect
          label='Type'
          labelId='statementTypeSelect'
          statementType={statementType}
          onStatementTypeChange={(statementType) => onStatementTypeChange(statementType)}
        />
      </FormControl>

      <FormControl style={{ minWidth: 200 }}>
        <InputLabel id='statementAccountSelect'>Account</InputLabel>
        <Select label='Account' labelId='statementAccountSelect' value={account} onChange={(event) => onAccountChange(event.target.value)}>
          <MenuItem value={''}>None</MenuItem>
          {accounts.map((a) => {
            return (
              <MenuItem key={a.externalId} value={a.externalId!}>
                {a.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Stack>
  );
}

function TransactionImportPreviewText({
  params,
  duplicateMap,
  importOverrides,
}: {
  params: GridRenderCellParams;
  duplicateMap: { [transactionId: string]: Transaction };
  importOverrides: { [transactionId: string]: boolean };
}) {
  const theme = useTheme();
  const transaction = params.row as Transaction;
  const duplicate = duplicateMap[transaction.id];

  return (
    <span
      style={{
        color:
          (duplicate && importOverrides[transaction.id] !== true) || importOverrides[transaction.id] === false ? '#888' : theme.palette.text.primary,
        fontStyle: (duplicate && importOverrides[transaction.id] !== true) || importOverrides[transaction.id] === false ? 'italic' : 'normal',
      }}
    >
      {params.colDef.field === 'date' || params.colDef.field === 'duplicateDate'
        ? params.value
          ? formatInTimeZone(params.value as Date, 'UTC', 'MMM d, yyyy')
          : params.value
        : params.value}
    </span>
  );
}

interface TransactionImportPreviewTableProps {
  transactions: Transaction[];
  duplicateMap: { [transactionId: string]: Transaction };
  importOverrides: { [transactionId: string]: boolean };
  onMarkDuplicate: (transactionId: string) => void;
  onMarkForImport: (transactionId: string) => void;
  style?: CSSProperties;
}

function TransactionImportPreviewTable({
  transactions,
  duplicateMap,
  importOverrides,
  onMarkDuplicate,
  onMarkForImport,
  style,
}: TransactionImportPreviewTableProps) {
  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      renderCell: (params) => <TransactionImportPreviewText params={params} duplicateMap={duplicateMap} importOverrides={importOverrides} />,
    },
    {
      field: 'type',
      headerName: 'Type',
      valueGetter: (params) => (parseFloat((params.row as Transaction).amount) < 0 ? 'Income' : 'Expense'),
      renderCell: (params) => <TransactionImportPreviewText params={params} duplicateMap={duplicateMap} importOverrides={importOverrides} />,
    },
    {
      field: 'date',
      headerName: 'Date (UTC)',
      renderCell: (params) => <TransactionImportPreviewText params={params} duplicateMap={duplicateMap} importOverrides={importOverrides} />,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      valueGetter: (params) => {
        const transaction = params.row as Transaction;
        const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: transaction.isoCurrencyCode });

        const floatAmount = parseFloat(transaction.amount);
        return amountFormatter.format(Math.abs(floatAmount));
      },
      renderCell: (params) => <TransactionImportPreviewText params={params} duplicateMap={duplicateMap} importOverrides={importOverrides} />,
    },
    {
      field: 'duplicateName',
      headerName: 'Name',
      valueGetter: (params) => {
        const transaction = params.row as Transaction;
        const duplicate = duplicateMap[transaction.id];

        if (!duplicate) {
          return null;
        }

        return duplicate.name;
      },
      renderCell: (params) => <TransactionImportPreviewText params={params} duplicateMap={duplicateMap} importOverrides={importOverrides} />,
    },
    {
      field: 'duplicateDate',
      headerName: 'Date (UTC)',
      valueGetter: (params) => {
        const transaction = params.row as Transaction;
        const duplicate = duplicateMap[transaction.id];

        if (!duplicate) {
          return null;
        }

        return duplicate.date;
      },
      renderCell: (params) => <TransactionImportPreviewText params={params} duplicateMap={duplicateMap} importOverrides={importOverrides} />,
    },
    {
      field: 'duplicateType',
      headerName: 'Type',
      valueGetter: (params) => {
        const transaction = params.row as Transaction;
        const duplicate = duplicateMap[transaction.id];

        if (!duplicate) {
          return null;
        }

        return parseFloat(duplicate.amount) < 0 ? 'Income' : 'Expense';
      },
      renderCell: (params) => <TransactionImportPreviewText params={params} duplicateMap={duplicateMap} importOverrides={importOverrides} />,
    },
    {
      field: 'duplicateAmount',
      headerName: 'Amount',
      valueGetter: (params) => {
        const transaction = params.row as Transaction;
        const duplicate = duplicateMap[transaction.id];

        if (!duplicate) {
          return null;
        }

        const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: duplicate.isoCurrencyCode });

        const floatAmount = parseFloat(duplicate.amount);
        return amountFormatter.format(Math.abs(floatAmount));
      },
      renderCell: (params) => <TransactionImportPreviewText params={params} duplicateMap={duplicateMap} importOverrides={importOverrides} />,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => {
        const transaction = params.row as Transaction;
        const duplicate = duplicateMap[transaction.id];

        if ((duplicate && importOverrides[transaction.id] !== true) || (!duplicate && importOverrides[transaction.id] === false)) {
          return (
            <Button variant='contained' color='primary' onClick={() => onMarkForImport(transaction.id)}>
              Mark for Import
            </Button>
          );
        } else {
          return (
            <Button variant='contained' color='primary' onClick={() => onMarkDuplicate(transaction.id)}>
              Do not import
            </Button>
          );
        }
      },
    },
  ];

  return (
    <DataGrid
      experimentalFeatures={{ columnGrouping: true }}
      columnGroupingModel={[
        {
          groupId: 'Parsed Transaction',
          children: [{ field: 'name' }, { field: 'date' }, { field: 'type' }, { field: 'amount' }],
        },
        {
          groupId: 'Potential Existing Duplicate',
          children: [{ field: 'duplicateName' }, { field: 'duplicateDate' }, { field: 'duplicateType' }, { field: 'duplicateAmount' }],
        },
      ]}
      style={style}
      rows={transactions}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 50,
          },
        },
        sorting: {
          sortModel: [{ field: 'date', sort: 'desc' }],
        },
      }}
      pageSizeOptions={[50]}
    />
  );
}

interface ImportTransactionsDialogProps {
  open: boolean;
  onClose: () => void;
  style?: CSSProperties;
  organizationId: string;
  accounts: Account[];
}

export function ImportTransactionsDialog({ open, onClose, organizationId, accounts, style }: ImportTransactionsDialogProps) {
  const theme = useTheme();
  const { createTransactionImportPreview, importTransactions } = useAdmin();

  const accountsWithExternalIds = accounts.filter((a) => a.externalId);

  const [statementFile, setStatementFile] = useState<File | null>(null);
  const [statementType, setStatementType] = useState(StatementType.RBC_CANADA);
  const [accountExternalId, setAccountExternalId] = useState<string>('');
  const [parsedTransactions, setParsedTransactions] = useState<Transaction[]>([]);
  const [duplicateMap, setDuplicateMap] = useState<{ [transactionId: string]: Transaction }>({});
  const [closingBalance, setClosingBalance] = useState<{ date: Date; amount: string; currency: string } | null>(null);
  const [importOverrides, setImportOverrides] = useState<{ [transactionId: string]: boolean }>({});
  const [loading, setLoading] = useState(false);
  const [showWarningDialogFor, setShowWarningDialogFor] = useState<{ duplicateStatement?: boolean } | null>(null);

  useEffect(() => {
    setStatementFile(null);
    setStatementType(StatementType.RBC_CANADA);
    setAccountExternalId('');
    setParsedTransactions([]);
    setDuplicateMap({});
    setImportOverrides({});
    setLoading(false);
    setShowWarningDialogFor(null);
  }, [open]);

  const createPreview = async () => {
    if (!statementFile || !accountExternalId) {
      return;
    }

    try {
      setLoading(true);
      const transactionImport = await createTransactionImportPreview(organizationId, statementFile, statementType, accountExternalId);

      setParsedTransactions(transactionImport.transactions);

      setDuplicateMap(transactionImport.duplicateMap);

      setClosingBalance(transactionImport.closingBalance || null);
    } finally {
      setLoading(false);
    }
  };

  const performImport = async (ignoreWarnings?: boolean) => {
    if (!statementFile || !accountExternalId) {
      return;
    }

    try {
      setLoading(true);
      const transactionsToImport = parsedTransactions.filter((t) => {
        return (!duplicateMap[t.id] || importOverrides[t.id]) && importOverrides[t.id] !== false;
      });

      const warnings = await importTransactions({
        organizationId,
        transactions: transactionsToImport,
        accountId: accountExternalId,
        statementFile,
        ignoreWarnings: ignoreWarnings || false,
        closingBalance: closingBalance || undefined,
      });
      if (warnings && !ignoreWarnings) {
        setShowWarningDialogFor(warnings);
      } else {
        setShowWarningDialogFor(null);
        setParsedTransactions([]);
        setDuplicateMap({});
        setImportOverrides({});
        setStatementFile(null);
        onClose();
      }
    } finally {
      setLoading(false);
    }
  };

  const markDuplicate = (transactionId: string) => {
    setImportOverrides((existing) => {
      return {
        ...existing,
        [transactionId]: false,
      };
    });
  };

  const markForImport = (transactionId: string) => {
    setImportOverrides((existing) => {
      return {
        ...existing,
        [transactionId]: true,
      };
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle>
        <Stack direction='row' justifyContent='space-between'>
          <span>Import Transactions</span>

          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
        }}
      >
        <Stack style={style} paddingTop={theme.spacing(5)} overflow='hidden'>
          <StatementUploadSelect
            account={accountExternalId}
            onAccountChange={(id) => setAccountExternalId(id)}
            statementType={statementType}
            onStatementTypeChange={(type) => setStatementType(type as StatementType)}
            file={statementFile}
            onFileChange={(file) => setStatementFile(file)}
            onUpload={createPreview}
            accounts={accountsWithExternalIds}
          />
          <Typography variant='h4'>Preview</Typography>
          <TransactionImportPreviewTable
            transactions={parsedTransactions}
            duplicateMap={duplicateMap}
            importOverrides={importOverrides}
            onMarkDuplicate={markDuplicate}
            onMarkForImport={markForImport}
          />
          <ConfirmDialog
            open={!!showWarningDialogFor}
            onClose={() => setShowWarningDialogFor(null)}
            content={
              <Stack>
                {showWarningDialogFor &&
                  Object.keys(showWarningDialogFor).map((warning) => (
                    <Alert severity='warning' key={warning}>
                      <AlertTitle>Warning</AlertTitle>
                      <Typography>
                        {warning === 'duplicateStatement' &&
                          'It looks like a similar statement is already in the system. Are you sure you want to import these transactions?'}
                      </Typography>
                    </Alert>
                  ))}
              </Stack>
            }
            onConfirm={() => performImport(true)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='neutral'
          onClick={() => {
            onClose();
          }}
          disabled={loading}
        >
          Close
        </Button>
        <Button variant='contained' color='primary' onClick={() => performImport()} disabled={!parsedTransactions.length || loading}>
          {loading ? <CircularProgress /> : 'Import'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const getJobLabel = (transactionImport: TransactionImport) => {
  switch (transactionImport.status) {
    case AsyncJobStatus.NOT_STARTED:
      return 'Not Started';
    case AsyncJobStatus.IN_PROGRESS:
      return 'In Progress';
    case AsyncJobStatus.COMPLETED:
      return 'Complete';
    case AsyncJobStatus.FAILED:
      return 'Failed';
    default:
      return 'Unknown';
  }
};

interface TransactionImportsTableProps {
  transactionImports: TransactionImport[];
  style?: CSSProperties;
}

function TransactionImportsTable({ transactionImports, style }: TransactionImportsTableProps) {
  const theme = useTheme();
  const [idCopied, setIdCopied] = useState<string | null>(null);
  const copy = useCallback(async (id: string) => {
    setIdCopied(id);
    await navigator.clipboard.writeText(id);
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 50,
      renderCell: (params) => {
        const transactionImport = params.row as TransactionImport;

        return (
          <Tooltip title={transactionImport.id}>
            <span>
              <IconButton onClick={() => copy(transactionImport.id)}>
                {idCopied === transactionImport.id ? (
                  <CopySuccess size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                ) : (
                  <Copy size='1.1rem' variant='Outline' color={theme.palette.primary.main} />
                )}
              </IconButton>
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => {
        const transactionImport = params.row as TransactionImport;

        const getJobIcon = () => {
          switch (transactionImport.status) {
            case AsyncJobStatus.NOT_STARTED:
              return <Clock size='1rem' />;
            case AsyncJobStatus.IN_PROGRESS:
              return <CircularProgress size='1rem' />;
            case AsyncJobStatus.COMPLETED:
              return <TickCircle color={theme.palette.primary.main} size='1rem' />;
            case AsyncJobStatus.FAILED:
              return <CloseSquare color={theme.palette.error.main} size='1rem' />;
            default:
              return 'Unknown';
          }
        };

        return (
          <Stack direction='row' spacing={2} alignItems='center'>
            {getJobIcon()}
            <span>{getJobLabel(transactionImport)}</span>
          </Stack>
        );
      },
    },
    {
      field: 'fileName',
      headerName: 'File Name',
      flex: 1,
      renderCell: (params) => {
        const transactionImport = params.row as TransactionImport;

        if (transactionImport.statement) {
          return transactionImport.statement.fileName;
        } else {
          return 'Unknown';
        }
      },
    },
    {
      field: 'statement',
      headerName: 'Statement',
      renderCell: (params) => {
        const transactionImport = params.row as TransactionImport;

        let iconSrc: string;
        if (transactionImport.statement) {
          iconSrc = transactionImport.statement.type === 'application/pdf' ? '/pdf-file-icon.svg' : '/csv.svg';
        } else {
          return null;
        }

        return (
          <Box padding={theme.spacing(2)}>
            <Link href={transactionImport.statement.url} target='_blank'>
              <img src={iconSrc} alt={transactionImport.statement.fileName} width='32px' height='32px' />
            </Link>
          </Box>
        );
      },
    },
    {
      field: 'created',
      headerName: 'Import Date',
      width: 150,
      renderCell: (params) => {
        const transactionImport = params.row as TransactionImport;

        return (
          <Tooltip title={transactionImport.created.toString()}>
            <span>{format(transactionImport.created, 'MMM d, yyyy')}</span>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <DataGrid
      experimentalFeatures={{ columnGrouping: true }}
      columnGroupingModel={[
        {
          groupId: 'Parsed Transaction',
          children: [{ field: 'name' }, { field: 'date' }, { field: 'type' }, { field: 'amount' }],
        },
        {
          groupId: 'Potential Existing Duplicate',
          children: [{ field: 'duplicateName' }, { field: 'duplicateDate' }, { field: 'duplicateType' }, { field: 'duplicateAmount' }],
        },
      ]}
      style={style}
      rows={transactionImports}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 50,
          },
        },
        sorting: {
          sortModel: [{ field: 'created', sort: 'desc' }],
        },
      }}
      pageSizeOptions={[50]}
    />
  );
}

export interface TransactionImportsProps {
  organizationId: string;
  accounts: Account[];
  style?: CSSProperties;
}
export function TransactionImports({ organizationId, accounts, style }: TransactionImportsProps) {
  const { transactionImports } = useAdminData(organizationId);
  const [showImportDialog, setShowImportDialog] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState<string[]>([]);

  const filteredImports = useMemo(() => {
    if (!searchCriteria.length) {
      return transactionImports;
    }

    return transactionImports.filter((t) => {
      for (const searchCriterion of searchCriteria) {
        if (`id::${t.id}` === searchCriterion) {
          return true;
        }

        if (t.id.includes(searchCriterion)) {
          return true;
        }

        if (t.statement && t.statement.fileName.toLowerCase().includes(searchCriterion.toLowerCase())) {
          return true;
        }

        const status = getJobLabel(t);
        if (status.toLowerCase().includes(searchCriterion.toLowerCase())) {
          return true;
        }

        const formattedDate = format(t.created, 'MMM d, yyyy');
        if (formattedDate.toLowerCase().includes(searchCriterion.toLowerCase())) {
          return true;
        }
      }

      return false;
    });
  }, [searchCriteria, transactionImports]);

  return (
    <Stack style={style}>
      <Stack direction='row' justifyContent='space-between'>
        <Search searchCriteria={searchCriteria} onSearchCriteriaUpdate={(searchCriteria) => setSearchCriteria(searchCriteria)} style={{ flex: 1 }} />

        <Button variant='contained' color='primary' onClick={() => setShowImportDialog(true)}>
          <Stack direction='row' alignItems='center' spacing={2}>
            <AddCircle variant='Bold' size='1.1rem' />
            <span>Import Transactions</span>
          </Stack>
        </Button>
      </Stack>

      <TransactionImportsTable
        transactionImports={filteredImports}
        style={{
          flex: 1,
        }}
      />

      <ImportTransactionsDialog
        open={showImportDialog}
        onClose={() => setShowImportDialog(false)}
        organizationId={organizationId}
        accounts={accounts}
        style={{
          flex: 1,
        }}
      />
    </Stack>
  );
}
