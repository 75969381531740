import { Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useConversation } from '../../api';
import { Button } from '../button';
import { IWidgetProps } from './IWidgetProps';

interface ContineButtonWidgetProps extends IWidgetProps {
  text?: string;
}

export const ContinueButtonWidget = ({ text, isSuccess }: ContineButtonWidgetProps) => {
  const theme = useTheme();
  const { addConversationMessage, conversationId } = useConversation();
  const [disabled, setDisabled] = useState(isSuccess === true || isSuccess === false);

  const clickContinue = async () => {
    setDisabled(true);
    await addConversationMessage({ conversationId: conversationId as string, message: `Let's continue` });
  };

  return (
    <>
      {text && <Typography>{text}</Typography>}
      <Button disabled={disabled} variant='contained' color='primary' onClick={() => clickContinue()} sx={{ mt: theme.spacing(2), width: '100%' }}>
        Let's Continue
      </Button>
    </>
  );
};
