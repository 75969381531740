import { GridCellModes, GridCellModesModel, GridCellParams } from '@mui/x-data-grid';
import { useCallback, useMemo, useState } from 'react';

export function useOneClickDataGridEditing() {
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});
  const editing = useMemo(() => {
    return Object.values(cellModesModel).some((columnModes) => Object.values(columnModes).find((field) => field.mode === GridCellModes.Edit));
  }, [cellModesModel]);

  const handleCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  const handleCellClick = useCallback((params: GridCellParams, event: React.MouseEvent) => {
    if (!params.isEditable) {
      return;
    }

    // Ignore portal
    if (
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (event.target as any).nodeType === 1 &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
      !(event.currentTarget as any).contains(event.target as Element)
    ) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {}
            ),
          }),
          {}
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce((acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }), {}),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  return {
    cellModesModel,
    handleCellClick,
    handleCellModesModelChange,
    editing,
  };
}
