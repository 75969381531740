import { Box, CircularProgress } from '@mui/material';
import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const VerificationInputs = styled.div`
  display: flex;
  flex-direction: row;

  > input {
    width: 1rem;
    height: 2rem;
    text-align: center;
    background: ${({ theme }) => theme.palette.background2.default};
    color: ${({ theme }) => theme.palette.text.primary};
    border: 1px solid ${({ theme }) => theme.palette.border.main};
    border-radius: ${({ theme }) => theme.roundedCorners(2)};
    margin-right: ${({ theme }) => theme.spacing(2)};
    padding: ${({ theme }) => theme.spacing(2)};
    font-size: 1.5rem;
  }

  > input:last-child {
    margin-right: 0;
  }
`;

export interface VerificationCodeInputProps {
  loading: boolean;
  length: number;
  onChange: (code: string) => void;
  onComplete: (verificationCode: string) => void;
}
export function VerificationCodeInput({ length, loading, onChange, onComplete }: VerificationCodeInputProps) {
  const [code, setCode] = useState([...Array<string>(length)].map(() => ''));
  const [completed, setCompleted] = useState(false);
  const inputs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    inputs.current[0]?.focus();
  }, [inputs]);

  useEffect(() => {
    const codeStr = code.join('');
    onChange(codeStr);
    if (code.every((num) => num !== '' && !completed)) {
      setCompleted(true);
      onComplete(codeStr);
    }
  }, [code, completed, onChange, onComplete]);

  const processInput = (e: ChangeEvent<HTMLInputElement>, slot: number) => {
    const num = e.target.value;

    if (!num || /[^0-9a-zA-Z]/.test(num)) {
      return;
    }

    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);

    if (slot !== length - 1) {
      inputs.current[slot + 1]!.focus();
    }
  };

  const onKeyUp = (e: KeyboardEvent<HTMLInputElement>, slot: number) => {
    if (e.key === 'Backspace' && code[slot]) {
      const newCode = [...code];
      newCode[slot] = '';
      setCode(newCode);
    }

    if (e.key === 'Backspace' && slot !== 0) {
      inputs.current[slot - 1]!.focus();
    }
  };

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>, slot: number) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text/plain').replace(/\s/g, '');

    // Check if the pasted data contains any non-alphanumeric characters
    if (/[^0-9a-zA-Z]/.test(pastedData)) {
      return;
    }

    const newCode = [...code];
    for (let i = slot; i < length && i - slot < pastedData.length; i++) {
      newCode[i] = pastedData[i - slot];
    }
    setCode(newCode);
  };

  return (
    <Box alignItems='center'>
      {loading ? (
        <CircularProgress />
      ) : (
        <VerificationInputs>
          {code.map((num, idx) => {
            return (
              <input
                key={idx}
                type='text'
                inputMode='numeric'
                maxLength={1}
                value={num}
                autoFocus={!code[0].length && idx === 0}
                readOnly={loading}
                onChange={(e) => processInput(e, idx)}
                onKeyUp={(e) => onKeyUp(e, idx)}
                onPaste={(e) => onPaste(e, idx)}
                ref={(ref) => (inputs.current[idx] = ref)}
              />
            );
          })}
        </VerificationInputs>
      )}
    </Box>
  );
}
