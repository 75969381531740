import { FolderZip } from '@mui/icons-material';
import { CircularProgress, Link, Stack, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFileArchive } from '../../api';

export function DownloadFileArchivePage() {
  const theme = useTheme();
  const { id } = useParams();
  const { fetchFileArchives, fileArchives } = useFileArchive();
  const [error, setError] = useState(false);

  const fileArchive = useMemo(() => {
    return fileArchives.find((a) => a.id === id);
  }, [fileArchives, id]);

  useEffect(() => {
    fetchFileArchives().catch((e) => {
      throw e;
    });
  }, [fetchFileArchives]);

  useEffect(() => {
    if (!id || !fileArchive) {
      return;
    }

    try {
      if (fileArchive.signedUrl) {
        const anchor = document.createElement('a');

        anchor.href = fileArchive.signedUrl;
        anchor.download = fileArchive.name;

        document.body.appendChild(anchor);

        anchor.click();

        document.body.removeChild(anchor);
      } else {
        throw new Error(`File archive has no signed url`);
      }
    } catch (e) {
      setError(true);
    }
  }, [fileArchive, id]);

  return (
    <Stack width='100%' height='100%' alignItems='center' justifyContent='center'>
      {error && (
        <span>
          Download link is invalid. Please contact <Link href='mailto:support@otter.ca'>support@otter.ca</Link> for assistance.
        </span>
      )}
      {fileArchive && (
        <Stack alignItems='center'>
          <FolderZip style={{ fontSize: theme.spacing(32) }} />
          <span>
            Your download should begin shortly. If not, please <Link href={fileArchive.signedUrl!}>click here</Link> to begin the download.
          </span>
        </Stack>
      )}
      {!fileArchive && <CircularProgress />}
    </Stack>
  );
}
