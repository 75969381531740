import { DatePicker, DatePickerProps, DateValidationError, PickerChangeHandlerContext } from '@mui/x-date-pickers';
import { utcToZonedTime, zonedTimeToUtc } from '../utils/date-utils';

export function UTCDatePicker({ value, onChange, ...props }: DatePickerProps<Date>) {
  const handleDateChange = (newValue: Date | null, context: PickerChangeHandlerContext<DateValidationError>) => {
    if (!newValue && onChange) {
      onChange(newValue, context);
    }

    const dateInUTC = newValue ? utcToZonedTime(newValue) : null;

    if (dateInUTC) {
      dateInUTC.setUTCHours(0);
      dateInUTC.setUTCMinutes(0);
      dateInUTC.setUTCSeconds(0);
      dateInUTC.setUTCMilliseconds(0);
    }

    if (onChange) {
      onChange(dateInUTC, context);
    }
  };

  return <DatePicker format='yyyy-MM-dd' value={value ? zonedTimeToUtc(value) : null} onChange={handleDateChange} {...props} />;
}
