import {
  Alert,
  Avatar,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { CloseCircle, Eye, EyeSlash } from 'iconsax-react';
import { FormEvent, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Link } from './link';

const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(5)};
`;

interface SignInStepProps {
  loading: boolean;
  signInError?: string;
  onClose: () => void;
  onSignIn: (email: string, password: string) => void;
  onOpenSignUp: () => void;
  onForgotPassword: () => void;
}
function SignInStep({ loading, signInError, onClose, onSignIn, onOpenSignUp, onForgotPassword }: SignInStepProps) {
  const theme = useTheme();

  const [emailTouched, setEmailTouched] = useState(false);
  const [email, setEmail] = useState('');
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [password, setPassword] = useState('');

  const emailError = useMemo(() => {
    const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}/;
    if (!email) {
      return 'Email is required.';
    } else if (!emailRegex.test(email)) {
      return 'Email address is invalid.';
    } else {
      return null;
    }
  }, [email]);

  const passwordError = useMemo(() => {
    if (!password) {
      return 'Password is required.';
    }
    return null;
  }, [password]);

  const [revealPassword, setRevealPassword] = useState(false);

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (emailError || passwordError) {
      return;
    }

    onSignIn(email, password);
  };

  return (
    <Stack width={theme.spacing(70)} margin={theme.spacing(5)}>
      <Header>
        <Avatar
          alt='Otter'
          sx={{
            background: theme.palette.secondary.main,
          }}
          src='/otter-o-white.svg'
        />

        <Typography variant='h2'>Welcome Back</Typography>
        <IconButton onClick={onClose}>
          <CloseCircle />
        </IconButton>
      </Header>
      <form onSubmit={submit}>
        <Stack>
          <FormControl required error={!!emailError && emailTouched}>
            <TextField
              label='Email'
              placeholder='Enter your email'
              value={email}
              onBlur={() => setEmailTouched(true)}
              onChange={(e) => setEmail(e.target.value)}
            />
            {!!emailError && emailTouched && <FormHelperText>{emailError}</FormHelperText>}
          </FormControl>
          <FormControl required error={!!passwordError && passwordTouched}>
            <TextField
              type={revealPassword ? 'text' : 'password'}
              label='Password'
              placeholder='Enter your password'
              value={password}
              onBlur={() => setPasswordTouched(true)}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton tabIndex={-1} onClick={() => setRevealPassword((revealPassword) => !revealPassword)}>
                      {revealPassword ? <EyeSlash /> : <Eye />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {!!passwordError && passwordTouched && <FormHelperText>{passwordError}</FormHelperText>}
          </FormControl>
          {signInError && (
            <FormControl error={true}>
              <FormHelperText>{signInError}</FormHelperText>
            </FormControl>
          )}
          <Typography alignSelf='center'>
            <Link to={window.location.pathname} onClick={onForgotPassword}>
              Forgot your password?
            </Link>
          </Typography>

          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={loading || !!(emailError || passwordError)}
            onClick={() => onSignIn(email, password)}
          >
            {loading ? <CircularProgress style={{ alignSelf: 'center' }} /> : 'Sign In'}
          </Button>

          <Typography alignSelf='center'>
            Don't have an account yet?{' '}
            <Link to={window.location.pathname} onClick={onOpenSignUp}>
              Sign up
            </Link>
          </Typography>
        </Stack>
      </form>
    </Stack>
  );
}

interface ResetPasswordProps {
  loading: boolean;
  onResetPassword: (email: string) => void;
  onClose: () => void;
}
function ResetPassword({ loading, onResetPassword, onClose }: ResetPasswordProps) {
  const theme = useTheme();

  const [email, setEmail] = useState('');
  const [emailTouched, setEmailTouched] = useState(false);
  const [open, setOpen] = useState(false);
  const emailError = useMemo(() => {
    const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}/;
    if (!email) {
      return 'Email is required.';
    } else if (!emailRegex.test(email)) {
      return 'Email address is invalid.';
    } else {
      return null;
    }
  }, [email]);

  const submit = () => {
    if (emailError) {
      return;
    }
    setOpen(true);

    onResetPassword(email);
  };
  const handleClose = () => setOpen(false);

  return (
    <Stack margin={theme.spacing(5)} width={theme.spacing(70)}>
      <Header>
        <Avatar
          alt='Otter'
          sx={{
            background: theme.palette.secondary.main,
          }}
          src='/otter-o-white.svg'
        />

        <Typography variant='h2' textAlign='center'>
          Reset Your Password
        </Typography>
        <IconButton onClick={onClose}>
          <CloseCircle />
        </IconButton>
      </Header>
      <form onSubmit={submit}>
        <Stack>
          <FormControl required error={!!emailError && emailTouched}>
            <TextField
              label='Email'
              placeholder='Enter your email'
              value={email}
              onBlur={() => setEmailTouched(true)}
              onChange={(e) => setEmail(e.target.value)}
            />
            {!!emailError && emailTouched && <FormHelperText>{emailError}</FormHelperText>}
          </FormControl>
          <Button type='submit' variant='contained' color='primary' disabled={loading || !!emailError} onClick={() => submit()}>
            {loading ? <CircularProgress style={{ alignSelf: 'center' }} /> : 'Reset Password'}
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} message='Verification Email Sent'>
              <Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>
                An email has been sent to {email}
              </Alert>
            </Snackbar>
          </Button>
        </Stack>
      </form>
    </Stack>
  );
}

type SignInProps = Omit<SignInStepProps & ResetPasswordProps, 'onForgotPassword' | 'onOpenSignIn'>;
export function SignIn({ loading, signInError, onClose, onSignIn, onOpenSignUp, onResetPassword }: SignInProps) {
  const [step, setStep] = useState<'sign-in' | 'reset-password'>('sign-in');

  if (step === 'reset-password') {
    return (
      <ResetPassword
        loading={loading}
        onResetPassword={(email) => {
          onResetPassword(email);
        }}
        onClose={onClose}
      />
    );
  }

  return (
    <SignInStep
      loading={loading}
      signInError={signInError}
      onClose={onClose}
      onSignIn={onSignIn}
      onOpenSignUp={onOpenSignUp}
      onForgotPassword={() => setStep('reset-password')}
    />
  );
}
