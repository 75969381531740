import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { CSSProperties } from 'react';
import styled from 'styled-components';
import { useSession } from '../api';

const Header = styled.header<{ title?: string }>`
  flex: 0;

  display: grid;
  grid-template-columns: ${({ title }) => (title ? '1fr 1fr 1fr' : '1fr 1fr')};

  align-items: center;

  padding: ${({ theme }) => theme.spacing(5)};

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: ${({ theme }) => theme.spacing(5)};
  }
`;

export function PageHeader({ title, style }: { title?: string; style?: CSSProperties }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { session } = useSession();

  if (isSmallScreen) {
    return (
      title && (
        <Box display='flex' alignItems='center' justifyContent='center' padding={theme.spacing(10)}>
          <Typography variant='h3' textAlign='center'>
            {title}
          </Typography>
        </Box>
      )
    );
  }

  return (
    <Header title={title} style={style}>
      <Stack direction='row' justifyContent='center' alignItems='center' justifySelf='start'>
        <Typography variant='h2'>👋</Typography>
        <Typography variant='h3'>Hi {session?.firstName ? session.firstName : 'there'}</Typography>
      </Stack>
      {title && (
        <Box display='flex' alignItems='center' justifySelf='center'>
          <Typography variant='h3' textAlign='center'>
            {title}
          </Typography>
        </Box>
      )}
      <Box></Box>
    </Header>
  );
}
