import { createContext, useCallback, useContext, useState } from 'react';
import { FileArchive } from '.';
import { axios } from './axios-base';

interface IFileArchiveContext {
  fileArchives: FileArchive[];
  fetchFileArchives: () => Promise<void>;
}

function useFileArchiveData() {
  const [fileArchives, setFileArchives] = useState<FileArchive[]>([]);

  const fetchFileArchives = useCallback(async () => {
    const response = await axios.get(`/file-archives`);

    const body = response.data as { archives: FileArchive[] };

    const converted = body.archives.map((a) => ({
      ...a,
      created: new Date(a.created),
      updated: new Date(a.updated),
    }));

    setFileArchives(converted);
  }, []);

  const fileArchiveContext: IFileArchiveContext = {
    fileArchives,
    fetchFileArchives,
  };
  return fileArchiveContext;
}

export const FileArchiveContext = createContext<IFileArchiveContext>({} as IFileArchiveContext);

export const useFileArchive = () => useContext(FileArchiveContext);

export const FileArchiveContextProvider = ({ children }: { children: React.ReactNode }) => {
  const fileArchive = useFileArchiveData();

  return <FileArchiveContext.Provider value={fileArchive}>{children}</FileArchiveContext.Provider>;
};
