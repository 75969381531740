import { Box, ButtonProps, emphasize, Fade, IconButton, PaletteColor, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { ArrowCircleDown, ArrowRight, FlashCircle } from 'iconsax-react';
import { createContext, createRef, forwardRef, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createSearchParams, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { AuthorType, Conversation, ConversationMessage, useConversation as useConversationApi, useSession } from '../../api';
import { MessageType } from '../../api/conversation-stream';
import { Button, ConversationInput, ConversationMessageContainer, ConversationMessages, PageContainer, PageHeader } from '../../components';
import { fetchWidgetProps } from '../../components/widgets';
import { IWidgetProps } from '../../components/widgets/IWidgetProps';
import { ScreenSize, useScreenSize } from '../../utils/use-screen-size';
import { CurrentConversationContext } from './current-conversation-context';

function ColoredIcon({ icon, color }: { icon: 'flashCircle'; color: 'primary' | 'secondary' | 'accent1' | 'accent2' }) {
  const icons = {
    flashCircle: FlashCircle,
  };

  const theme = useTheme();

  const IconComponent = icons[icon];

  return (
    <Box
      borderRadius={theme.roundedCorners(2)}
      padding={theme.spacing(2)}
      bgcolor={(theme.palette[color] as unknown as PaletteColor)[100]}
      alignItems='center'
      justifyContent='center'
    >
      <IconComponent
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        variant='Bold'
        color={theme.palette[color].main}
      />
    </Box>
  );
}

interface SuggestionButtonProps {
  text: string;
  icon: 'flashCircle';
  iconColor: 'primary' | 'secondary' | 'accent1' | 'accent2';
  className?: string;
  onClick: () => void;
}
const SuggestionButton = styled(
  forwardRef<HTMLButtonElement, SuggestionButtonProps>(({ text, icon, iconColor, className, onClick, ...props }, ref) => {
    const theme = useTheme();

    return (
      <Button
        ref={ref} // Pass the ref here
        variant='outlined'
        color='secondary'
        shade={100}
        disableElevation={false}
        elevation={8}
        className={className}
        onClick={onClick}
        {...props}
      >
        <Box display='flex' width='100%' flexDirection='row' justifyContent='space-between' alignItems='center'>
          <Stack flex={1} direction='row' alignItems='center' marginRight={theme.spacing(3)}>
            <ColoredIcon icon={icon} color={iconColor} />
            <Typography textAlign='left' color={theme.palette.common.black}>
              {text}
            </Typography>
          </Stack>
          <ArrowRight color={theme.palette.common.black} />
        </Box>
      </Button>
    );
  })
)`
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  padding-left: ${({ theme }) => theme.spacing(4)};
  padding-right: ${({ theme }) => theme.spacing(4)};
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: ${({ theme }) => theme.roundedCorners(4)};

  &:hover {
    background-color: ${emphasize('#fff')};
    border: 1px solid ${({ theme }) => theme.palette.border.main};
  }
`;

interface AskMeAnythingBoxProps {
  onSuggestionUsed: (suggestion: string) => void;
  onStrategy: (strategy: string) => void;
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function AskMeAnythingBox({ onSuggestionUsed, onStrategy }: AskMeAnythingBoxProps) {
  const { session } = useSession();
  const isAuthenticated = session?.isRegistered && session?.isVerified;
  const theme = useTheme();

  const suggestions = [
    'What expenses are deductible?',
    'Can I charge a portion of my home/vehicle expenses to my business?',
    'Should I pay myself through dividends or a salary',
  ];

  return (
    <Stack spacing={10}>
      <Stack alignItems='center' spacing={1}>
        <Typography variant='h1' textAlign='center'>
          Ask me anything
        </Typography>
        <Typography textAlign='center'>
          Meet Ollie the Otter, your personal Chief Financial Otter - the AI chatbot that streamlines your business finances, from bookkeeping to
          expert advice, all in one place!
        </Typography>
      </Stack>
      <Stack alignItems='stretch'>
        <Typography variant='small' textAlign='center' color={theme.palette.secondary[200]}>
          Not sure where to start? You can try:
        </Typography>
        <Fade in={true} timeout={{ enter: 1000 }} mountOnEnter unmountOnExit>
          <SuggestionButton icon='flashCircle' iconColor='primary' text={suggestions[0]} onClick={() => onSuggestionUsed(suggestions[0])} />
        </Fade>
        <Fade in={true} timeout={{ enter: 2000 }} mountOnEnter unmountOnExit>
          <SuggestionButton icon='flashCircle' iconColor='accent2' text={suggestions[1]} onClick={() => onSuggestionUsed(suggestions[1])} />
        </Fade>
        <Fade in={true} timeout={{ enter: 3000 }} mountOnEnter unmountOnExit>
          <SuggestionButton icon='flashCircle' iconColor='accent1' text={suggestions[2]} onClick={() => onSuggestionUsed(suggestions[2])} />
        </Fade>
        {isAuthenticated && (
          <>
            <Fade in={true} timeout={{ enter: 3000 }} mountOnEnter unmountOnExit>
              <SuggestionButton icon='flashCircle' iconColor='primary' text='Onboarding to Otter' onClick={() => onStrategy('ONBOARDING')} />
            </Fade>
            <Fade in={true} timeout={{ enter: 3000 }} mountOnEnter unmountOnExit>
              <SuggestionButton icon='flashCircle' iconColor='secondary' text='Add Receipts to Otter' onClick={() => onStrategy('RECEIPT_UPLOAD')} />
            </Fade>
          </>
        )}
      </Stack>
    </Stack>
  );
}

export const ConversationScrollContext = createContext({} as { scrollLocked: boolean; setScrollLocked: (locked: boolean) => void });

const useConversation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { session, createUnregisteredUser, startUnregisteredSession } = useSession();
  const {
    conversations,
    conversationData: conversationsData,
    conversationStreaming,
    fetchConversation,
    fetchConversationData,
    addConversationMessage,
    markConversationViewed,
    setConversationId,
    interruptConversationStreaming,
    scrollLock,
    scrollLockMutable,
  } = useConversationApi();

  useEffect(() => {
    return () => {
      if (id) {
        interruptConversationStreaming();
        Promise.all([fetchConversation(id, true), fetchConversationData(id)]).catch((e) => {
          throw e;
        });
      }
    };
  }, [id, interruptConversationStreaming, fetchConversation, fetchConversationData]);

  useEffect(() => {
    if (!id) {
      return;
    }

    const fetch = async () => {
      await Promise.all([fetchConversation(id), fetchConversationData(id)]);
    };

    fetch().catch((e: { response: { status: number } }) => {
      if (e.response.status !== 404) {
        throw e;
      }
    });
  }, [fetchConversation, fetchConversationData, id]);

  useEffect(() => {
    if (!id) {
      const queryParams = location.search;
      navigate({
        pathname: `/conversations/${uuid()}`,
        search: queryParams,
      });
      return;
    }
    setConversationId(id);
  }, [id, setConversationId, location, navigate]);

  const conversation = useMemo<Conversation>(() => {
    if (!id || !conversations || !conversations[id]) {
      return {
        id,
        title: '',
        messages: [],
        created: new Date(),
        updated: new Date(),
        lastViewed: new Date(),
        ended: false,
      } as Conversation;
    }

    return conversations[id];
  }, [conversations, id]);

  useEffect(() => {
    // If user logs out while viewing conversation,
    // direct them to a different conversation so
    // that they don't attempt to modify
    // a conversation they no longer have access to
    if (!session && conversation.messages.length) {
      navigate('/');
    }
  }, [session, conversation, navigate]);

  const addMessage = useCallback(
    async (message: string, strategy?: string) => {
      if (!id) {
        return;
      }

      if (!session) {
        const user = await createUnregisteredUser();
        await startUnregisteredSession(user.id);
        await addConversationMessage({
          conversationId: id,
          message,
          strategy,
          includeMessageInConversation: !strategy,
        });
      } else {
        await addConversationMessage({
          conversationId: id,
          message,
          strategy,
          includeMessageInConversation: !strategy,
        });
      }

      conversationExists.current = true;
    },
    [createUnregisteredUser, startUnregisteredSession, addConversationMessage, id, session]
  );

  const conversationExists = useRef(false);
  useEffect(() => {
    if (id && conversations[id]) {
      conversationExists.current = true;
    } else {
      conversationExists.current = false;
    }
  }, [id, conversations]);

  useEffect(() => {
    if (!id || !conversationExists.current || conversationStreaming) {
      return;
    }

    markConversationViewed(id).catch((e) => {
      throw e;
    });
  }, [id, markConversationViewed, conversationStreaming]);

  const conversationData = useMemo(() => {
    if (!id) {
      return null;
    }

    return conversationsData[id] || null;
  }, [conversationsData, id]);

  return {
    conversation,
    messages: conversation.messages,
    conversationData,
    conversationStreaming,
    addMessage,
    scrollLock,
    scrollLockMutable,
  };
};

const useStrategyRedirect = () => {
  const { addMessage } = useConversation();
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queuedStrategy = useRef<string | null>(null);
  const initialized = useRef(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const strategy = params.get('strategy');
    if (strategy && id && !initialized.current) {
      initialized.current = true;
      queuedStrategy.current = strategy;
      navigate({
        search: '',
      });
    }
  }, [location, navigate, id]);

  useEffect(() => {
    const strategy = queuedStrategy.current;
    if (strategy) {
      queuedStrategy.current = null;
      addMessage('', strategy).catch((e) => {
        throw e;
      });
    }
  }, [location, queuedStrategy, addMessage]);
};

const useRequiresSessionRedirect = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { session, sessionLoaded } = useSession();

  useEffect(() => {
    const params = new URLSearchParams(location.search);

    const requiresSession = params.get('requiresSession');

    if (requiresSession && sessionLoaded && !session) {
      const searchParams = createSearchParams({
        modal: 'sign-in',
        returnTo: location.pathname,
      });

      navigate({
        pathname: '/',
        search: `?${searchParams.toString()}`,
      });
    }
  }, [sessionLoaded, session, navigate, location]);
};

const useWidgetRequiresInput = <T extends IWidgetProps>(messages: ConversationMessage[]) => {
  return useMemo(() => {
    if (!messages.length) {
      return false;
    }

    let lastSystemMessage: ConversationMessage | null = null;
    for (let i = messages.length - 1; i >= 0; i--) {
      const message = messages[i];

      if (message.authorType === AuthorType.SYSTEM) {
        lastSystemMessage = message;
        break;
      }
    }

    if (!lastSystemMessage) {
      return false;
    }

    if (lastSystemMessage.messageType === MessageType.WIDGET) {
      const content = JSON.parse(lastSystemMessage.content) as fetchWidgetProps<T>;

      return !!content.props.requiresInput && content.props.isSuccess !== true && content.props.isSuccess !== false;
    }
  }, [messages]);
};

interface ScrollToBottomButtonProps extends ButtonProps {
  messageContainerRef: HTMLDivElement | null;
}
const ScrollToBottomButton = forwardRef<HTMLButtonElement, ScrollToBottomButtonProps>(({ messageContainerRef, ...props }, ref) => {
  const theme = useTheme();
  const [isIn, setIsIn] = useState(false);
  const screenSize = useScreenSize();

  useEffect(() => {
    const listener = () => {
      setIsIn(!!messageContainerRef && messageContainerRef.scrollHeight - (messageContainerRef.scrollTop + messageContainerRef.clientHeight) > 5);
    };

    if (messageContainerRef) {
      messageContainerRef.addEventListener('scroll', listener);
    }

    return () => {
      if (messageContainerRef) {
        messageContainerRef.removeEventListener('scroll', listener);
      }
    };
  }, [messageContainerRef]);

  let bottom: string;
  if (screenSize === ScreenSize.LARGE) {
    bottom = isIn ? theme.spacing(40) : theme.spacing(20);
  } else {
    bottom = isIn ? theme.spacing(20) : theme.spacing(2);
  }

  return (
    <IconButton
      disabled={!isIn}
      {...props}
      ref={ref}
      style={{
        opacity: isIn ? 100 : 0,
        background: theme.palette.background.default,
        color: theme.palette.text.primary,
        position: 'absolute',
        bottom: bottom,
        left: '50%',
        transform: `translateX(-50%)`,
        transition: '250ms ease',
      }}
    >
      <ArrowCircleDown />
    </IconButton>
  );
});

export function ConversationPage({ ...props }) {
  const { conversation, messages, conversationStreaming, addMessage, conversationData, scrollLock, scrollLockMutable } = useConversation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const inputRef = useRef<HTMLInputElement | null>(null);

  useRequiresSessionRedirect();
  useStrategyRedirect();

  const [messageContainerRef, setMessageContainerRef] = useState<HTMLDivElement | null>(null);
  const [messagesWrapperRef, setMessagesWrapperRef] = useState<HTMLDivElement | null>(null);
  const messageRefs = useRef<{ [messageId: string]: RefObject<HTMLDivElement> }>({});

  for (const message of messages) {
    messageRefs.current[message.id] = messageRefs.current[message.id] || createRef<HTMLDivElement | null>();
  }

  useEffect(() => {
    if (!conversationStreaming && inputRef.current) {
      inputRef.current.focus();
    }
  }, [conversationStreaming, inputRef]);

  const disableInput = useWidgetRequiresInput(messages);

  return (
    <CurrentConversationContext.Provider
      value={{
        conversation,
        messages,
        data: conversationData,
      }}
    >
      <PageContainer {...props} style={{ position: 'relative', overflow: 'hidden' }}>
        <PageHeader />

        <ConversationMessageContainer
          conversation={conversation}
          messages={messages}
          messageRefs={messageRefs.current}
          messagesWrapperRef={messagesWrapperRef}
          scrollLock={scrollLock}
          scrollLockMutable={scrollLockMutable}
          streaming={conversationStreaming}
          messageContainerRef={messageContainerRef}
          ref={setMessageContainerRef}
        >
          {conversation && !!messages.length && (
            <ConversationMessages conversation={conversation} messages={messages} messageRefs={messageRefs.current} ref={setMessagesWrapperRef} />
          )}
        </ConversationMessageContainer>

        <ConversationInput
          ref={inputRef}
          style={{
            zIndex: 1,
            borderRadius: theme.roundedCorners(4),
            marginLeft: isSmallScreen ? theme.spacing(2) : theme.spacing(50),
            marginRight: isSmallScreen ? theme.spacing(2) : theme.spacing(50),
            marginBottom: isSmallScreen ? theme.spacing(2) : theme.spacing(20),
          }}
          disabled={disableInput}
          conversationStarted={!!messages.length}
          conversationEnded={!!conversation.ended}
          conversationStreaming={conversationStreaming}
          onSubmit={(message) => addMessage(message)}
        />

        <ScrollToBottomButton
          messageContainerRef={messageContainerRef}
          onClick={() => {
            if (messageContainerRef) {
              messageContainerRef.scrollTo({
                top: messageContainerRef.scrollHeight,
                behavior: 'smooth',
              });
            }
          }}
        />
      </PageContainer>
    </CurrentConversationContext.Provider>
  );
}
