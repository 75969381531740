import { forwardRef } from 'react';
import styled from 'styled-components';

const PageBodyBase = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>((props, ref) => {
  return <div ref={ref} {...props} />;
});

type PageBodyProps = {
  gutter?: 'none' | 'thin' | 'medium' | 'thick';
};

export const PageBody = styled(PageBodyBase)<PageBodyProps>`
  flex: 1;

  display: flex;
  flex-direction: column;

  padding-left: ${({ theme, gutter }) => {
    if (gutter === 'thick') {
      return theme.spacing(50);
    } else if (gutter === 'medium') {
      return theme.spacing(15);
    } else if (gutter === 'thin') {
      return theme.spacing(5);
    } else {
      return 0;
    }
  }};
  padding-right: ${({ theme, gutter }) => {
    if (gutter === 'thick') {
      return theme.spacing(50);
    } else if (gutter === 'medium') {
      return theme.spacing(15);
    } else if (gutter === 'thin') {
      return theme.spacing(5);
    } else {
      return 0;
    }
  }};

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: ${({ theme, gutter }) => {
      if (gutter === 'thin' || gutter === 'medium' || gutter === 'thick') {
        return theme.spacing(5);
      } else {
        return 0;
      }
    }};
  }

  padding-bottom: ${({ theme }) => theme.spacing(5)};

  overflow: auto;
  min-height: 0;
`;
