import React from 'react';
import { AdminContextProvider } from './admin';
import { ConversationContextProvider } from './conversation';
import { DocumentContextProvider } from './document';
import { FileArchiveContextProvider } from './file-archive';
import { LedgerContextProvider } from './ledger';
import { OrganizationContextProvider } from './organization';
import { SessionContextProvider } from './session';
import { TransactionContextProvider } from './transaction';

export * from './admin';
export * from './app-env';
export * from './conversation';
export * from './document';
export * from './file-archive';
export * from './ledger';
export * from './organization';
export * from './session';
export * from './shared-interfaces';
export * from './statement-type';
export * from './transaction';

export const ApiContextProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <SessionContextProvider>
      <OrganizationContextProvider>
        <LedgerContextProvider>
          <FileArchiveContextProvider>
            <ConversationContextProvider>
              <DocumentContextProvider>
                <TransactionContextProvider>
                  <AdminContextProvider>{children}</AdminContextProvider>
                </TransactionContextProvider>
              </DocumentContextProvider>
            </ConversationContextProvider>
          </FileArchiveContextProvider>
        </LedgerContextProvider>
      </OrganizationContextProvider>
    </SessionContextProvider>
  );
};
