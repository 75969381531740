import { createContext, useContext } from 'react';
import { Conversation, ConversationMessage } from '../../api';

interface ICurrentConversationContext {
  conversation: Conversation;
  messages: ConversationMessage[];
  data: object | null;
}

export const CurrentConversationContext = createContext<ICurrentConversationContext>({} as ICurrentConversationContext);

export const useCurrentConversation = () => useContext(CurrentConversationContext);
