import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { CloseCircle, TickCircle } from 'iconsax-react';
import { useState } from 'react';

import { useConversation } from '../../api';
import { Button } from '../button';

export enum AccountType {
  CONSULTING_INCOME = 'Consulting income',
  SALES = 'Sales',
}

const useOnboardingCallbacks = () => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const { addConversationMessage, conversationId } = useConversation();

  const choose = async (accountType: AccountType) => {
    setLoading(true);
    try {
      await addConversationMessage({ conversationId: conversationId as string, message: accountType, includeMessageInConversation: false });
      setFailure(false);
      setSuccess(true);
    } catch (e) {
      setFailure(true);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    success,
    failure,
    choose,
  };
};

export const IncomeDescriptionWidget = () => {
  const { loading, success, failure, choose } = useOnboardingCallbacks();
  const theme = useTheme();

  let content;
  if (loading) {
    content = (
      <Stack alignItems='center'>
        <CircularProgress />
      </Stack>
    );
  } else if (success) {
    content = (
      <Stack alignItems='center'>
        <TickCircle variant='Bold' color={theme.palette.primary.main} size={theme.spacing(10)} />
      </Stack>
    );
  } else {
    content = (
      <Stack>
        <Button variant='contained' color='primary' onClick={() => choose(AccountType.CONSULTING_INCOME)}>
          My business makes revenue through consulting.
        </Button>
        <Button variant='contained' color='primary' onClick={() => choose(AccountType.SALES)}>
          My business makes revenue through the sale of a product.
        </Button>
        {failure && (
          <Stack alignItems='center' spacing={2}>
            <CloseCircle variant='Bold' color={theme.palette.error.main} />
            <Typography>An error occurred. Please try again.</Typography>
          </Stack>
        )}
      </Stack>
    );
  }

  return (
    <Stack>
      <Typography sx={{ whiteSpace: 'pre-line' }}>Which of the following best describes your business?</Typography>
      {content}
    </Stack>
  );
};
