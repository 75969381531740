import { Download } from '@mui/icons-material';
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Select,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGrid, GridAlignment, GridColDef, GridPaginationModel, GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid';
import { formatInTimeZone } from 'date-fns-tz';
import {
  ArrowRotateRight,
  BackwardItem,
  Book,
  CalendarCircle,
  Category2,
  Clock,
  CloseCircle,
  Copy,
  CopySuccess,
  DocumentText1,
  Eye,
  EyeSlash,
  InfoCircle,
  Slash,
  TickCircle,
  Warning2,
} from 'iconsax-react';
import { CSSProperties, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Account,
  CheckInStatus,
  Journal,
  JournalEntry,
  Organization,
  Refund,
  SpecialStatus,
  Transaction,
  TransactionDocumentMatch,
  TransactionDownloadOptions,
  Transfer,
  UpdateTransactionErrorResolutions,
  UpdateTransactionErrors,
  useAdmin,
} from '../../../api/index.tsx';
import {
  AdminJournalSelect,
  AdminOrganizationSelect,
  Button,
  CategorizationDialog,
  ConfirmDialog,
  PageBody,
  PageContainer,
  PageHeader,
  Search,
  TreeSelectDataGridCellEdit,
  TreeSelectDataGridCellView,
  useSelectedOrganization,
} from '../../../components/index.ts';
import { useOneClickDataGridEditing } from '../../../utils/datagrid.ts';
import { dateIsWithinFY, getFiscalYear } from '../../../utils/date-utils.ts';
import { CheckInResultsDialog } from './check-in-results-dialog.tsx';
import { DuplicateTransactions } from './duplicate-transactions.tsx';
import { transactionFilter } from './filters.ts';
import { TransactionImports } from './import-transactions.tsx';

const useAdminData = (selectedOrg: Organization | null, selectedJournal: Journal | null) => {
  const {
    fetchOrganizations,
    fetchJournals,
    fetchJournalAccounts,
    fetchJournalEntries,
    fetchTransactions,
    fetchTransactionDocumentMatches,
    fetchUnreviewedTransactionCounts,
    fetchRefunds,
    fetchTransfers,
    fetchTransactionsMissingDocuments,
    fetchDuplicateTransactions,
    updateTransaction: updateTransactionApi,
    createTransactionImportPreview,
    journals,
    journalAccounts,
    journalEntries,
    organizations,
    transactions,
    transactionDocumentMatches,
    transactionsMissingDocuments: transactionsMissingDocumentsSets,
    createRefund: createRefundApi,
    createTransfer: createTransferApi,
    refunds: refundsByOrg,
    transfers: transfersByOrg,
    reprocessTransaction: reprocessTransactionApi,
    downloadTransactions: downloadTransactionsApi,
    unreviewedTransactionCounts,
    duplicateTransactions,
  } = useAdmin();

  const initialized = useRef(false);
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      fetchOrganizations().catch((e) => {
        throw e;
      });
    }
  }, [fetchOrganizations]);

  useEffect(() => {
    if (!selectedOrg) {
      return;
    }

    fetchJournals(selectedOrg.id!).catch((e) => {
      throw e;
    });

    fetchUnreviewedTransactionCounts(selectedOrg.id!).catch((e) => {
      throw e;
    });

    fetchTransactions(selectedOrg.id!).catch((e) => {
      throw e;
    });

    fetchTransactionDocumentMatches(selectedOrg.id!).catch((e) => {
      throw e;
    });

    fetchTransactionsMissingDocuments(selectedOrg.id!).catch((e) => {
      throw e;
    });

    fetchRefunds(selectedOrg.id!).catch((e) => {
      throw e;
    });

    fetchTransfers(selectedOrg.id!).catch((e) => {
      throw e;
    });

    fetchDuplicateTransactions(selectedOrg.id!).catch((e) => {
      throw e;
    });
  }, [
    fetchJournals,
    fetchTransactions,
    fetchTransactionDocumentMatches,
    fetchTransactionsMissingDocuments,
    fetchUnreviewedTransactionCounts,
    fetchDuplicateTransactions,
    fetchRefunds,
    fetchTransfers,
    selectedOrg,
  ]);

  useEffect(() => {
    if (!selectedJournal || !selectedOrg) {
      return;
    }

    fetchJournalAccounts(selectedJournal.id).catch((e) => {
      throw e;
    });
  }, [selectedJournal, fetchJournalAccounts, selectedOrg]);

  useEffect(() => {
    if (!selectedJournal) {
      return;
    }

    fetchJournalEntries(selectedJournal.id).catch((e) => {
      throw e;
    });
  }, [selectedJournal, fetchJournalEntries]);

  const journalAccountsById = useMemo(() => {
    const accountsById = {} as { [accountId: string]: Account };
    if (!journals || !selectedOrg || !selectedJournal || !journals[selectedOrg.id!]) {
      return accountsById;
    }

    const accounts = journalAccounts[selectedJournal.id];
    if (!accounts) {
      return accountsById;
    }

    for (const account of accounts) {
      accountsById[account.id] = account;
    }

    return accountsById;
  }, [journalAccounts, journals, selectedOrg, selectedJournal]);

  const orgJournals = useMemo(() => {
    if (!selectedOrg) {
      return [];
    }

    return journals[selectedOrg.id!] || [];
  }, [selectedOrg, journals]);

  const transactionsForJournal = useMemo(() => {
    if (!selectedOrg || !selectedJournal) {
      return [];
    }

    const orgTransactions = transactions[selectedOrg.id!] || [];

    return orgTransactions.filter((t) => dateIsWithinFY(t.date, selectedJournal.fy, selectedOrg.fyEndMonth));
  }, [selectedJournal, selectedOrg, transactions]);

  const journalEntriesForJournal = useMemo(() => {
    if (!selectedJournal) {
      return null;
    }

    return journalEntries[selectedJournal.id];
  }, [selectedJournal, journalEntries]);

  const matchesByTransactionId = useMemo(() => {
    if (!selectedOrg || !transactionDocumentMatches[selectedOrg.id!]) {
      return {};
    }

    return transactionDocumentMatches[selectedOrg.id!]
      .flatMap((m) => m.matches)
      .reduce(
        (map, current) => {
          if (!map[current.transactionId]) {
            map[current.transactionId] = [];
          }

          map[current.transactionId].push(current);

          return map;
        },
        {} as { [transactionId: string]: TransactionDocumentMatch[] }
      );
  }, [transactionDocumentMatches, selectedOrg]);

  const transactionsMissingDocuments = useMemo(() => {
    if (!selectedOrg) {
      return new Set<string>();
    }

    return transactionsMissingDocumentsSets[selectedOrg.id!] || new Set();
  }, [transactionsMissingDocumentsSets, selectedOrg]);

  const updateTransaction = useCallback(
    async (original: Transaction, changes: Partial<Transaction>, errorResolutions?: UpdateTransactionErrorResolutions) => {
      if (!selectedOrg || !selectedJournal) {
        return;
      }

      await updateTransactionApi(selectedOrg.id!, original.id, changes, errorResolutions);

      const fetchPromises = [];
      fetchPromises.push(fetchJournalEntries(selectedJournal.id));
      fetchPromises.push(fetchTransactionsMissingDocuments(selectedOrg.id!));

      if (matchesByTransactionId[original.id]) {
        fetchPromises.push(fetchTransactionDocumentMatches(selectedOrg.id!));
      }

      if (original.approved !== changes.approved) {
        fetchPromises.push(fetchUnreviewedTransactionCounts(selectedOrg.id!));
      }

      await Promise.all(fetchPromises);
    },
    [
      selectedOrg,
      selectedJournal,
      updateTransactionApi,
      matchesByTransactionId,
      fetchJournalEntries,
      fetchTransactionDocumentMatches,
      fetchTransactionsMissingDocuments,
      fetchUnreviewedTransactionCounts,
    ]
  );

  const createRefund = useCallback(
    async (paymentTransactionId: string, refundTransactionId: string) => {
      if (!selectedOrg || !selectedJournal) {
        return;
      }

      await createRefundApi(selectedOrg.id!, selectedJournal.id, paymentTransactionId, refundTransactionId);

      await fetchRefunds(selectedOrg.id!);
    },
    [createRefundApi, selectedOrg, selectedJournal, fetchRefunds]
  );

  const createTransfer = useCallback(
    async (fromTransactionId: string, toTransactionId: string) => {
      if (!selectedOrg || !selectedJournal) {
        return;
      }

      await createTransferApi(selectedOrg.id!, selectedJournal.id, fromTransactionId, toTransactionId);

      await fetchTransfers(selectedOrg.id!);
    },
    [createTransferApi, selectedOrg, selectedJournal, fetchTransfers]
  );

  const refunds = useMemo(() => {
    if (!selectedOrg) {
      return {};
    }

    const orgRefunds = refundsByOrg[selectedOrg.id!] || [];

    return orgRefunds.reduce(
      (map, current) => {
        map[current.paymentTransactionId] = current;
        map[current.refundTransactionId] = current;
        return map;
      },
      {} as { [transactionId: string]: Refund }
    );
  }, [refundsByOrg, selectedOrg]);

  const transfers = useMemo(() => {
    if (!selectedOrg) {
      return {};
    }

    const orgTransfers = transfersByOrg[selectedOrg.id!] || [];

    return orgTransfers.reduce(
      (map, current) => {
        map[current.fromTransactionId] = current;
        map[current.toTransactionId] = current;
        return map;
      },
      {} as { [transactionId: string]: Transfer }
    );
  }, [transfersByOrg, selectedOrg]);

  const reprocessTransaction = useCallback(
    async (transactionId: string) => {
      if (!selectedOrg) {
        return;
      }

      await reprocessTransactionApi(selectedOrg.id!, transactionId);
    },
    [selectedOrg, reprocessTransactionApi]
  );

  const downloadTransactions = useCallback(
    async (options?: TransactionDownloadOptions) => {
      if (!selectedOrg || !selectedJournal) {
        return;
      }

      await downloadTransactionsApi(selectedOrg.id!, selectedJournal.id, options);
    },
    [downloadTransactionsApi, selectedJournal, selectedOrg]
  );

  const orgUnreviewedTransactionCounts = useMemo(() => {
    if (!selectedOrg || !unreviewedTransactionCounts) {
      return null;
    }

    return unreviewedTransactionCounts[selectedOrg.id!];
  }, [unreviewedTransactionCounts, selectedOrg]);

  const duplicateCountByFy = useMemo(() => {
    if (!selectedOrg || !duplicateTransactions) {
      return null;
    }

    const duplicates = duplicateTransactions[selectedOrg.id!]?.transactions || [];

    const byFy = {} as { [fy: string]: number };

    for (const duplicate of duplicates) {
      if (!byFy[getFiscalYear(duplicate.date, selectedOrg.fyEndMonth)]) {
        byFy[getFiscalYear(duplicate.date, selectedOrg.fyEndMonth)] = 0;
      }

      byFy[getFiscalYear(duplicate.date, selectedOrg.fyEndMonth)] = byFy[getFiscalYear(duplicate.date, selectedOrg.fyEndMonth)] + 1;
    }

    return byFy;
  }, [duplicateTransactions, selectedOrg]);

  const needClarificationCountByFy = useMemo(() => {
    if (!selectedOrg || !transactions) {
      return null;
    }

    const orgTransactions = transactions[selectedOrg.id!] || [];

    const byFy = {} as { [fy: string]: number };

    for (const transaction of orgTransactions) {
      if (
        (transaction.approved && !transaction.ignored && Object.keys(transaction.issues).length > 0) ||
        transaction.checkInStatus === CheckInStatus.SCHEDULED ||
        transaction.checkInStatus === CheckInStatus.SAVED_FOR_LATER ||
        transaction.checkInStatus === CheckInStatus.CLARIFIED ||
        transaction.checkInStatus === CheckInStatus.CONFLICT
      ) {
        if (!byFy[getFiscalYear(transaction.date, selectedOrg.fyEndMonth)]) {
          byFy[getFiscalYear(transaction.date, selectedOrg.fyEndMonth)] = 0;
        }

        byFy[getFiscalYear(transaction.date, selectedOrg.fyEndMonth)] = byFy[getFiscalYear(transaction.date, selectedOrg.fyEndMonth)] + 1;
      }
    }

    return byFy;
  }, [transactions, selectedOrg]);

  return {
    fetchJournalEntries,
    fetchTransactionDocumentMatches,
    organizations,
    transactions: transactionsForJournal,
    journalAccountsById,
    journals: orgJournals,
    journalEntries: journalEntriesForJournal,
    updateTransaction,
    createTransactionImportPreview,
    matchesByTransactionId,
    transactionsMissingDocuments,
    refunds,
    transfers,
    createRefund,
    createTransfer,
    reprocessTransaction,
    downloadTransactions,
    unreviewiedTransactionCounts: orgUnreviewedTransactionCounts,
    duplicateCountByFy,
    needClarificationCountByFy,
  };
};

interface CreateTransferDialogProps {
  transaction: Transaction | null;
  transactions: Transaction[];
  transfers: { [transactionId: string]: Transfer };
  journalAccountsById: { [accountId: string]: Account };
  journalAccountsByExternalId: { [externalId: string]: Account };
  onClose: () => void;
  onCreateTransfer: (fromTransactionId: string, toTransactionId: string) => Promise<void>;
}

function CreateTransferDialog({
  transaction,
  transactions,
  transfers,
  journalAccountsById,
  journalAccountsByExternalId,
  onClose,
  onCreateTransfer,
}: CreateTransferDialogProps) {
  const PAGE_SIZE = 50;

  const [searchCriteria, setSearchCriteria] = useState<string[]>([]);
  const [selectedMatch, setSelectedMatch] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const isExpense = useMemo(() => {
    return transaction ? parseFloat(transaction.amount) > 0 : false;
  }, [transaction]);

  useEffect(() => {
    setSearchCriteria([]);
    setSelectedMatch(null);
  }, [transaction]);

  const existingTransfer = useMemo(() => {
    if (!transaction) {
      return null;
    }

    return transfers[transaction.id];
  }, [transfers, transaction]);

  const baseColumns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
      },
      {
        field: 'type',
        headerName: 'Type',
        valueGetter: (params) => (parseFloat((params.row as Transaction).amount) < 0 ? 'Income' : 'Expense'),
      },
      {
        field: 'date',
        headerName: 'Date (UTC)',
        renderCell: (params) => {
          const transaction = params.row as Transaction;

          return formatInTimeZone(transaction.date, 'UTC', 'MMM d, yyyy');
        },
      },
      {
        field: 'amount',
        headerName: 'Amount',
        valueGetter: (params) => {
          const transaction = params.row as Transaction;
          const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: transaction.isoCurrencyCode });

          const floatAmount = parseFloat(transaction.amount);
          return amountFormatter.format(Math.abs(floatAmount));
        },
      },
      {
        field: 'accountId',
        headerName: 'Originating Account',
        width: 200,
        valueGetter: (params) => {
          const transaction = params.row as Transaction;
          const account = journalAccountsByExternalId[transaction.accountId];
          const accoundIdLabel = account?.externalMaskedAccountId ? `***${account.externalMaskedAccountId}` : 'Unknown ID';
          return `${account?.name} (${accoundIdLabel})` || `Unknown (${transaction.accountId})`;
        },
      },
    ];
  }, [journalAccountsByExternalId]);

  const transactionColumns: GridColDef[] = useMemo(() => {
    return [
      ...baseColumns,
      {
        field: 'action',
        headerName: '',
      },
    ];
  }, [baseColumns]);

  const matchColumns: GridColDef[] = useMemo(() => {
    return [
      ...baseColumns,
      {
        field: 'action',
        headerName: 'Select',
        renderCell: (params) => {
          const transaction = params.row as Transaction;

          return (
            <Checkbox
              disabled={!!existingTransfer}
              checked={
                existingTransfer?.fromTransactionId === transaction.id ||
                existingTransfer?.toTransactionId === transaction.id ||
                selectedMatch === transaction.id
              }
              onChange={() => setSelectedMatch((current) => (current === transaction.id ? null : transaction.id))}
            />
          );
        },
      },
    ];
  }, [baseColumns, selectedMatch, existingTransfer]);

  const filteredMatchOptions = useMemo(() => {
    if (existingTransfer) {
      if (isExpense) {
        return transactions.filter((t) => t.id === existingTransfer.toTransactionId);
      } else {
        return transactions.filter((t) => t.id === existingTransfer.fromTransactionId);
      }
    }

    return transactions
      .filter((t) => {
        const matchIsExpense = parseFloat(t.amount) > 0;
        return (
          t.id !== transaction?.id && !t.specialStatus && (isExpense ? !matchIsExpense : matchIsExpense) && t.accountId !== transaction?.accountId
        );
      })
      .filter((t) => {
        if (!searchCriteria.length) {
          return true;
        }

        for (const searchCriterion of searchCriteria) {
          if (!searchCriterion) {
            continue;
          }

          return transactionFilter({
            transaction: t,
            searchCriterion,
            journalAccountsById,
            accountsByExternalId: journalAccountsByExternalId,
          });
        }

        return false;
      });
  }, [transactions, searchCriteria, journalAccountsByExternalId, journalAccountsById, isExpense, transaction, existingTransfer]);

  const save = useCallback(async () => {
    if (!selectedMatch || !transaction) {
      return;
    }

    try {
      setLoading(true);
      if (isExpense) {
        await onCreateTransfer(transaction.id, selectedMatch);
      } else {
        await onCreateTransfer(selectedMatch, transaction.id);
      }
    } finally {
      setLoading(false);
    }
    onClose();
  }, [onClose, isExpense, transaction, selectedMatch, onCreateTransfer]);

  if (!transaction) {
    return null;
  }

  return (
    <Dialog open={!!transaction} onClose={onClose} fullScreen>
      <DialogTitle>
        <Stack direction='row' justifyContent='space-between'>
          Create Transfer
          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack>
          <Typography>
            A transfer consists of 2 transactions - an expense (outflow) from one account and an income (inflow) to another. Select both transactions
            to continue.
          </Typography>
          <form style={{ height: '100%' }}>
            <Stack style={{ height: '100%' }}>
              <Typography variant='h3'>{isExpense ? 'From' : 'To'}</Typography>
              <DataGrid
                style={{ flex: '0 0 fit-content' }}
                rows={[transaction]}
                columns={transactionColumns}
                pageSizeOptions={[PAGE_SIZE]}
                hideFooter={true}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: PAGE_SIZE,
                    },
                  },
                  sorting: {
                    sortModel: [{ field: 'date', sort: 'desc' }],
                  },
                }}
              />

              <Typography variant='h3'>{isExpense ? 'To' : 'From'}</Typography>
              <Search searchCriteria={searchCriteria} onSearchCriteriaUpdate={(criteria) => setSearchCriteria(criteria)} />
              <DataGrid
                style={{ flex: 1 }}
                rows={filteredMatchOptions}
                columns={matchColumns}
                pageSizeOptions={[PAGE_SIZE]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: PAGE_SIZE,
                    },
                  },
                  sorting: {
                    sortModel: [{ field: 'date', sort: 'desc' }],
                  },
                }}
              />
            </Stack>
          </form>
        </Stack>
      </DialogContent>
      <DialogActions>
        {loading && (
          <Stack alignItems='center' width='100%'>
            <CircularProgress />
          </Stack>
        )}
        {!loading && (
          <>
            <Button variant='contained' color='neutral' onClick={onClose}>
              Close
            </Button>

            {!existingTransfer && (
              <Button variant='contained' color='primary' disabled={!selectedMatch} onClick={save}>
                Save
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

interface CreateRefundDialogProps {
  transaction: Transaction | null;
  transactions: Transaction[];
  refunds: { [transactionId: string]: Refund };
  journalAccountsById: { [accountId: string]: Account };
  journalAccountsByExternalId: { [externalId: string]: Account };
  onClose: () => void;
  onCreateRefund: (paymentTransactionId: string, refundTransactionId: string) => Promise<void>;
}

function CreateRefundDialog({
  transaction,
  transactions,
  refunds,
  journalAccountsById,
  journalAccountsByExternalId,
  onClose,
  onCreateRefund,
}: CreateRefundDialogProps) {
  const PAGE_SIZE = 50;

  const [searchCriteria, setSearchCriteria] = useState<string[]>([]);
  const [selectedMatch, setSelectedMatch] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const isExpense = useMemo(() => {
    return transaction ? parseFloat(transaction.amount) > 0 : false;
  }, [transaction]);

  useEffect(() => {
    setSearchCriteria([]);
    setSelectedMatch(null);
  }, [transaction]);

  const existingRefund = useMemo(() => {
    if (!transaction) {
      return null;
    }

    return refunds[transaction.id];
  }, [refunds, transaction]);

  const baseColumns: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
      },
      {
        field: 'type',
        headerName: 'Type',
        valueGetter: (params) => (parseFloat((params.row as Transaction).amount) < 0 ? 'Income' : 'Expense'),
      },
      {
        field: 'date',
        headerName: 'Date (UTC)',
        renderCell: (params) => {
          const transaction = params.row as Transaction;

          return formatInTimeZone(transaction.date, 'UTC', 'MMM d, yyyy');
        },
      },
      {
        field: 'amount',
        headerName: 'Amount',
        valueGetter: (params) => {
          const transaction = params.row as Transaction;
          const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: transaction.isoCurrencyCode });

          const floatAmount = parseFloat(transaction.amount);
          return amountFormatter.format(Math.abs(floatAmount));
        },
      },
      {
        field: 'accountId',
        headerName: 'Originating Account',
        width: 200,
        valueGetter: (params) => {
          const transaction = params.row as Transaction;
          const account = journalAccountsByExternalId[transaction.accountId];
          const accoundIdLabel = account?.externalMaskedAccountId ? `***${account.externalMaskedAccountId}` : 'Unknown ID';
          return `${account?.name} (${accoundIdLabel})` || `Unknown (${transaction.accountId})`;
        },
      },
    ];
  }, [journalAccountsByExternalId]);

  const transactionColumns: GridColDef[] = useMemo(() => {
    return [
      ...baseColumns,
      {
        field: 'action',
        headerName: '',
      },
    ];
  }, [baseColumns]);

  const matchColumns: GridColDef[] = useMemo(() => {
    return [
      ...baseColumns,
      {
        field: 'action',
        headerName: 'Select',
        renderCell: (params) => {
          const transaction = params.row as Transaction;

          return (
            <Checkbox
              disabled={!!existingRefund}
              checked={
                existingRefund?.paymentTransactionId === transaction.id ||
                existingRefund?.refundTransactionId === transaction.id ||
                selectedMatch === transaction.id
              }
              onChange={() => setSelectedMatch((current) => (current === transaction.id ? null : transaction.id))}
            />
          );
        },
      },
    ];
  }, [baseColumns, selectedMatch, existingRefund]);

  const filteredMatchOptions = useMemo(() => {
    if (existingRefund) {
      if (isExpense) {
        return transactions.filter((t) => t.id === existingRefund.refundTransactionId);
      } else {
        return transactions.filter((t) => t.id === existingRefund.paymentTransactionId);
      }
    }

    return transactions
      .filter((t) => {
        const matchIsExpense = parseFloat(t.amount) > 0;
        return (
          t.id !== transaction?.id && !t.specialStatus && (isExpense ? !matchIsExpense : matchIsExpense) && t.accountId === transaction?.accountId
        );
      })
      .filter((t) => {
        if (!searchCriteria.length) {
          return true;
        }

        for (const searchCriterion of searchCriteria) {
          if (!searchCriterion) {
            continue;
          }

          return transactionFilter({
            transaction: t,
            searchCriterion,
            journalAccountsById,
            accountsByExternalId: journalAccountsByExternalId,
          });
        }

        return false;
      });
  }, [transactions, searchCriteria, journalAccountsByExternalId, journalAccountsById, isExpense, transaction, existingRefund]);

  const save = useCallback(async () => {
    if (!selectedMatch || !transaction) {
      return;
    }

    try {
      setLoading(true);
      if (isExpense) {
        await onCreateRefund(transaction.id, selectedMatch);
      } else {
        await onCreateRefund(selectedMatch, transaction.id);
      }
    } finally {
      setLoading(false);
    }
    onClose();
  }, [onClose, isExpense, transaction, selectedMatch, onCreateRefund]);

  if (!transaction) {
    return null;
  }

  return (
    <Dialog open={!!transaction} onClose={onClose} fullScreen>
      <DialogTitle>
        <Stack direction='row' justifyContent='space-between'>
          Create Refund
          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack>
          <Typography>
            A refund consists of 2 transactions - an initial payment and a transaction that reverses that payment, refunding the money. Select both
            transactions to continue.
          </Typography>
          <form style={{ height: '100%' }}>
            <Stack style={{ height: '100%' }}>
              <Typography variant='h3'>{isExpense ? 'Payment Transaction' : 'Refund Transaction'}</Typography>
              <DataGrid
                style={{ flex: '0 0 fit-content' }}
                rows={[transaction]}
                columns={transactionColumns}
                pageSizeOptions={[PAGE_SIZE]}
                hideFooter={true}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: PAGE_SIZE,
                    },
                  },
                  sorting: {
                    sortModel: [{ field: 'date', sort: 'desc' }],
                  },
                }}
              />

              <Typography variant='h3'>{isExpense ? 'Refund Transaction' : 'Payment Transaction'}</Typography>
              <Search searchCriteria={searchCriteria} onSearchCriteriaUpdate={(criteria) => setSearchCriteria(criteria)} />
              <DataGrid
                style={{ flex: 1 }}
                rows={filteredMatchOptions}
                columns={matchColumns}
                pageSizeOptions={[PAGE_SIZE]}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: PAGE_SIZE,
                    },
                  },
                  sorting: {
                    sortModel: [{ field: 'date', sort: 'desc' }],
                  },
                }}
              />
            </Stack>
          </form>
        </Stack>
      </DialogContent>
      <DialogActions>
        {loading && (
          <Stack alignItems='center' width='100%'>
            <CircularProgress />
          </Stack>
        )}
        {!loading && (
          <>
            <Button variant='contained' color='neutral' onClick={onClose}>
              Close
            </Button>

            {!existingRefund && (
              <Button variant='contained' color='primary' disabled={!selectedMatch} onClick={save}>
                Save
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

interface TransactionTableProps {
  tab: AdminTransactionsPageTab;
  page?: number;
  transfers: { [transactionId: string]: Transfer };
  refunds: { [transactionId: string]: Refund };
  transactions: Transaction[];
  loadingTransactions: Set<string>;
  matchesByTransactionId: { [transactionId: string]: TransactionDocumentMatch[] };
  transactionsMissingDocuments: Set<string>;
  journalAccountsById: { [accountId: string]: Account };
  journalAccountsByExternalId: { [externalId: string]: Account };
  onPageChange?: (page: number) => void;
  onOpenCategorizationDetails: (transaction: Transaction) => void;
  onViewJournalEntryForTransaction: (transaction: Transaction) => void;
  onUpdateTransactionAssignedCategory: (transaction: Transaction, assignedCategory: string) => void;
  onRemoveTransactionSpecialStatus: (transaction: Transaction) => void;
  onCreateTransfer: (transaction: Transaction) => void;
  onCreateRefund: (transaction: Transaction) => void;
  onViewTransfer: (transaction: Transaction) => void;
  onViewRefund: (transaction: Transaction) => void;
  onReprocessTransaction: (transaction: Transaction) => void;
  onIgnoreTransaction: (transaction: Transaction) => void;
  onUnignoreTransaction: (transaction: Transaction) => void;
  onApproveTransaction: (transaction: Transaction) => void;
  onCheckInSignOff: (transaction: Transaction) => void;
  onMarkTransactionAsUnreviewed: (transaction: Transaction) => void;
  onIgnoreDocumentRequirement: (transaction: Transaction) => void;
  onUnignoreMatchRequirement: (transaction: Transaction) => void;
  style?: CSSProperties;
}
function TransactionTable({
  tab,
  page,
  transfers,
  refunds,
  transactions,
  loadingTransactions,
  matchesByTransactionId,
  transactionsMissingDocuments,
  journalAccountsById,
  journalAccountsByExternalId,
  onPageChange,
  onViewJournalEntryForTransaction,
  onOpenCategorizationDetails,
  onUpdateTransactionAssignedCategory,
  onRemoveTransactionSpecialStatus,
  onCreateRefund,
  onViewRefund,
  onCreateTransfer,
  onViewTransfer,
  onReprocessTransaction,
  onIgnoreTransaction,
  onUnignoreTransaction,
  onApproveTransaction,
  onCheckInSignOff,
  onMarkTransactionAsUnreviewed,
  onIgnoreDocumentRequirement,
  onUnignoreMatchRequirement,
  style,
}: TransactionTableProps) {
  const PAGE_SIZE = 50;
  const [paginationModel, setPaginationModel] = useState({
    page: page ? page : 0,
    pageSize: PAGE_SIZE,
  });

  useEffect(() => {
    setPaginationModel({
      page: page ? page : 0,
      pageSize: PAGE_SIZE,
    });
  }, [page, transactions]);

  const updatePaginationModel = (paginationModel: GridPaginationModel) => {
    setPaginationModel(paginationModel);

    if (onPageChange) {
      onPageChange(paginationModel.page);
    }
  };

  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

  const accounts = useMemo(() => {
    return Object.values(journalAccountsById).sort((a, b) => a.name.localeCompare(b.name));
  }, [journalAccountsById]);

  const [idCopied, setIdCopied] = useState<string | null>(null);
  const copy = useCallback(async (transactionId: string) => {
    setIdCopied(transactionId);
    await navigator.clipboard.writeText(transactionId);
  }, []);

  const theme = useTheme();

  const transfer = useCallback(
    (transaction: Transaction) => {
      const transferExists = transfers[transaction.id];
      if (transferExists) {
        onViewTransfer(transaction);
      } else {
        onCreateTransfer(transaction);
      }
    },
    [onCreateTransfer, onViewTransfer, transfers]
  );

  const refund = useCallback(
    (transaction: Transaction) => {
      const refundExists = refunds[transaction.id];
      if (refundExists) {
        onViewRefund(transaction);
      } else {
        onCreateRefund(transaction);
      }
    },
    [onCreateRefund, onViewRefund, refunds]
  );

  const [checkInDialogOpenFor, setCheckInDialogOpenFor] = useState<null | Transaction>(null);

  const columns: GridColDef[] = useMemo(() => {
    const columnsArray: GridColDef[] = [
      {
        field: 'id',
        headerName: 'ID',
        width: 50,
        renderCell: (params) => {
          const transaction = params.row as Transaction;
          const loading = loadingTransactions.has(transaction.id);

          return (
            <Tooltip title={loading ? 'Loading' : transaction.id}>
              <span>
                {loading ? (
                  <CircularProgress size='1rem' />
                ) : (
                  <IconButton onClick={() => copy(transaction.id)}>
                    {idCopied === transaction.id ? (
                      <CopySuccess size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                    ) : (
                      <Copy size='1.1rem' variant='Outline' color={theme.palette.primary.main} />
                    )}
                  </IconButton>
                )}
              </span>
            </Tooltip>
          );
        },
      },
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
      },
      {
        field: 'type',
        headerName: 'Type',
        valueGetter: (params) => (parseFloat((params.row as Transaction).amount) < 0 ? 'Income' : 'Expense'),
      },
      {
        field: 'date',
        headerName: 'Date (UTC)',
        renderCell: (params) => {
          const transaction = params.row as Transaction;

          return formatInTimeZone(transaction.date, 'UTC', 'MMM d, yyyy');
        },
      },
      {
        field: 'amount',
        headerName: 'Amount',
        valueGetter: (params) => {
          const transaction = params.row as Transaction;
          const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: transaction.isoCurrencyCode });

          const floatAmount = parseFloat(transaction.amount);
          return amountFormatter.format(Math.abs(floatAmount));
        },
      },
      {
        field: 'accountId',
        headerName: 'Originating Account',
        valueGetter: (params) => {
          const transaction = params.row as Transaction;
          const account = journalAccountsByExternalId[transaction.accountId];
          const accoundIdLabel = account?.externalMaskedAccountId ? `***${account.externalMaskedAccountId}` : 'Unknown ID';
          return `${account?.name} (${accoundIdLabel})` || `Unknown (${transaction.accountId})`;
        },
      },
      {
        field: 'assignedCategory',
        headerName: 'Assigned Account',
        width: 150,
        editable: true,
        valueGetter: (params) => {
          const accountId = params.value as string;
          return journalAccountsById[accountId];
        },
        valueSetter: (params) => {
          const account = params.value as Account | null;
          return { ...params.row, assignedCategory: account?.id || null } as Transaction;
        },
        renderCell: (params) => {
          const transaction = params.row as Transaction;

          return (
            <Tooltip title={transaction.assignedCategory ? journalAccountsById[transaction.assignedCategory]?.name : 'Unknown'} placement='left'>
              <TreeSelectDataGridCellView
                params={params}
                items={accounts}
                disabled={loadingTransactions.has(transaction.id) || !!matchesByTransactionId[transaction.id]}
              />
            </Tooltip>
          );
        },
        renderEditCell: (params) => {
          return (
            <TreeSelectDataGridCellEdit
              params={params}
              items={accounts}
              itemComparator={(a, b) => a.name.localeCompare(b.name)}
              noSelectionValue='none'
            />
          );
        },
      },
      {
        field: 'specialStatus',
        headerName: 'Special Status',
        width: 150,
        renderCell: (params) => {
          const transaction = params.row as Transaction;

          return (
            <Tooltip title={transaction.specialStatus} placement='top'>
              <Select
                value={transaction.specialStatus || 'none'}
                displayEmpty
                onChange={(event) => {
                  if (event.target.value === 'none') {
                    onRemoveTransactionSpecialStatus(transaction);
                  }
                }}
                disabled={loadingTransactions.has(transaction.id)}
                sx={{ width: '100%' }}
              >
                <MenuItem value={'none'}>None</MenuItem>
                {(!transaction.specialStatus || transaction.specialStatus === SpecialStatus.PART_OF_TRANSFER) && (
                  <MenuItem
                    value={SpecialStatus.PART_OF_TRANSFER}
                    onClick={() => {
                      transfer(transaction);
                    }}
                  >
                    Part of Transfer
                  </MenuItem>
                )}
                {(!transaction.specialStatus || transaction.specialStatus === SpecialStatus.REFUNDED) && (
                  <MenuItem
                    value={SpecialStatus.REFUNDED}
                    onClick={() => {
                      refund(transaction);
                    }}
                  >
                    Refunded
                  </MenuItem>
                )}
              </Select>
            </Tooltip>
          );
        },
      },
      {
        field: 'actions',
        headerName: 'Actions',
        width: tab === AdminTransactionsPageTab.NEED_CLARIFICATION ? 296 : 232,
        align: 'center',
        renderCell: (params) => {
          const transaction = params.row as Transaction;
          const transactionLoading = loadingTransactions.has(transaction.id);

          return (
            <Stack direction='row' spacing={0}>
              <Tooltip title='Reprocess Transaction'>
                <span>
                  <IconButton disabled={loadingTransactions.has(transaction.id)} onClick={() => onReprocessTransaction(transaction)}>
                    <ArrowRotateRight
                      size='1rem'
                      variant='Bold'
                      color={transactionLoading ? theme.palette.text.disabled : theme.palette.warning.main}
                    />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title='View Categorization'>
                <span>
                  <IconButton
                    disabled={loadingTransactions.has(transaction.id)}
                    onClick={() => {
                      onOpenCategorizationDetails(transaction);
                    }}
                  >
                    <Category2 size='1rem' variant='Bold' color={transactionLoading ? theme.palette.text.disabled : theme.palette.primary.main} />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title='View Document Matches'>
                <span>
                  <IconButton
                    disabled={transactionLoading || !matchesByTransactionId[transaction.id]}
                    onClick={() => {
                      setTimeout(() => {
                        window.open(`/admin/matches?transactionId=${transaction.id}`, '_blank');
                      }, 100);
                    }}
                  >
                    <BackwardItem
                      size='1rem'
                      variant='Bold'
                      color={transactionLoading || !matchesByTransactionId[transaction.id] ? theme.palette.text.disabled : theme.palette.primary.main}
                    />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title='View Journal Entries'>
                <span>
                  <IconButton
                    disabled={transactionLoading}
                    onClick={() => {
                      setTimeout(() => {
                        onViewJournalEntryForTransaction(transaction);
                      }, 100);
                    }}
                  >
                    <Book size='1rem' variant='Bold' color={transactionLoading ? theme.palette.text.disabled : theme.palette.primary.main} />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title={transaction.ignoreMatchRequirement ? 'Unignore Document Requirement' : 'Ignore Document Requirement'}>
                <span>
                  <IconButton
                    disabled={transactionLoading || (!transactionsMissingDocuments.has(transaction.id) && !transaction.ignoreMatchRequirement)}
                    onClick={() => {
                      if (transaction.ignoreMatchRequirement) {
                        onUnignoreMatchRequirement(transaction);
                      } else {
                        onIgnoreDocumentRequirement(transaction);
                      }
                    }}
                  >
                    {!transaction.ignoreMatchRequirement && (
                      <svg
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          width: '1rem',
                          height: '1rem',
                        }}
                        viewBox='0 0 100 100'
                      >
                        <line
                          x1='100'
                          y1='10'
                          x2='10'
                          y2='100'
                          strokeWidth='1px'
                          stroke={theme.palette.background.default}
                          vectorEffect='non-scaling-stroke'
                        />
                        <line
                          x1='100'
                          y1='0'
                          x2='0'
                          y2='100'
                          strokeWidth='1px'
                          stroke={
                            transactionLoading || (!transactionsMissingDocuments.has(transaction.id) && !transaction.ignoreMatchRequirement)
                              ? theme.palette.text.disabled
                              : theme.palette.primary.main
                          }
                          vectorEffect='non-scaling-stroke'
                        />
                      </svg>
                    )}
                    <DocumentText1
                      size='1rem'
                      variant='Bold'
                      color={
                        transactionLoading || (!transactionsMissingDocuments.has(transaction.id) && !transaction.ignoreMatchRequirement)
                          ? theme.palette.text.disabled
                          : theme.palette.primary.main
                      }
                    />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title={transaction.ignored ? 'Make Active' : 'Ignore'}>
                <span>
                  <IconButton
                    onClick={() => {
                      if (transaction.ignored) {
                        onUnignoreTransaction(transaction);
                      } else {
                        onIgnoreTransaction(transaction);
                      }
                    }}
                    disabled={!!matchesByTransactionId[transaction.id] || transactionLoading}
                  >
                    {transaction.ignored ? (
                      <Eye
                        variant='Bold'
                        size='1rem'
                        color={
                          matchesByTransactionId[transaction.id] || transactionLoading ? theme.palette.text.disabled : theme.palette.primary.main
                        }
                      />
                    ) : (
                      <EyeSlash
                        variant='Bold'
                        size='1rem'
                        color={
                          matchesByTransactionId[transaction.id] || transactionLoading ? theme.palette.text.disabled : theme.palette.primary.main
                        }
                      />
                    )}
                  </IconButton>
                </span>
              </Tooltip>

              {transaction.approved && !transaction.ignored && (
                <Tooltip title='Mark as unreviewed'>
                  <span>
                    <IconButton
                      disabled={
                        transactionLoading ||
                        (!transaction.assignedCategory && !transfers[transaction.id] && !refunds[transaction.id]) ||
                        !!matchesByTransactionId[transaction.id]
                      }
                      onClick={() => onMarkTransactionAsUnreviewed(transaction)}
                    >
                      <InfoCircle
                        variant='Bold'
                        size='1rem'
                        color={
                          transactionLoading ||
                          (!transaction.assignedCategory && !transfers[transaction.id] && !refunds[transaction.id]) ||
                          matchesByTransactionId[transaction.id]
                            ? theme.palette.text.disabled
                            : theme.palette.primary.main
                        }
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {tab === AdminTransactionsPageTab.NEED_CLARIFICATION && (
                <Tooltip title='Approve'>
                  <span>
                    <IconButton
                      onClick={() => onCheckInSignOff(transaction)}
                      disabled={
                        transactionLoading ||
                        Object.keys(transaction.issues).length > 0 ||
                        !transaction.approved ||
                        transaction.checkInStatus === CheckInStatus.SCHEDULED ||
                        transaction.checkInStatus === CheckInStatus.SAVED_FOR_LATER
                      }
                    >
                      <TickCircle
                        variant='Bold'
                        size='1rem'
                        color={
                          transactionLoading ||
                          Object.keys(transaction.issues).length > 0 ||
                          !transaction.approved ||
                          transaction.checkInStatus === CheckInStatus.SCHEDULED ||
                          transaction.checkInStatus === CheckInStatus.SAVED_FOR_LATER
                            ? theme.palette.text.disabled
                            : theme.palette.primary.main
                        }
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
              {!transaction.approved && !transaction.ignored && (
                <Tooltip title='Approve'>
                  <span>
                    <IconButton
                      onClick={() => onApproveTransaction(transaction)}
                      disabled={transactionLoading || (!transaction.assignedCategory && !transfers[transaction.id] && !refunds[transaction.id])}
                    >
                      <TickCircle
                        variant='Bold'
                        size='1rem'
                        color={
                          transactionLoading || (!transaction.assignedCategory && !transfers[transaction.id] && !refunds[transaction.id])
                            ? theme.palette.text.disabled
                            : theme.palette.primary.main
                        }
                      />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </Stack>
          );
        },
      },
    ];

    if (tab === AdminTransactionsPageTab.NEED_CLARIFICATION) {
      const clarificaitonColumns: GridColDef[] = [
        {
          field: 'issues',
          headerName: 'Issues',
          width: 64,
          align: 'center' as GridAlignment,
          type: 'string',
          renderCell: (params: GridRenderCellParams) => {
            const transaction = params.row as Transaction;
            const hasIssues = Object.keys(transaction.issues).length > 0;

            if (!hasIssues) {
              return null;
            }

            const issues = [];
            if (transaction.issues.uncategorized) {
              issues.push('Uncategorized');
            }
            if (transaction.issues.unmatched) {
              issues.push('Unmatched');
            }

            return (
              <Tooltip
                title={
                  <>
                    {issues.map((i) => (
                      <span
                        key={i}
                        style={{
                          display: 'block',
                        }}
                      >
                        {i}
                      </span>
                    ))}
                  </>
                }
                placement='left'
              >
                <Warning2 color={theme.palette.warning.main} />
              </Tooltip>
            );
          },
        },
        {
          field: 'checkIn',
          headerName: 'Check-In',
          width: 64,
          align: 'center' as GridAlignment,
          type: 'string',
          renderCell: (params: GridRenderCellParams) => {
            const transaction = params.row as Transaction;

            if (transaction.checkInStatus === CheckInStatus.SCHEDULED) {
              return (
                <Tooltip title='Scheduled for Check-In'>
                  <CalendarCircle color={theme.palette.primary.main} />
                </Tooltip>
              );
            }

            if (transaction.checkInStatus === CheckInStatus.SAVED_FOR_LATER) {
              return (
                <Tooltip title='Saved for Later'>
                  <Clock color={theme.palette.primary.main} />
                </Tooltip>
              );
            }

            if (transaction.checkInStatus === CheckInStatus.CONFLICT) {
              return (
                <Tooltip title='Conflict Exists'>
                  <IconButton onClick={() => setCheckInDialogOpenFor(transaction)}>
                    <Slash color={theme.palette.warning.main} />
                  </IconButton>
                </Tooltip>
              );
            }

            if (transaction.checkInStatus === CheckInStatus.CLARIFIED) {
              return (
                <Tooltip title='Clarified'>
                  <IconButton onClick={() => setCheckInDialogOpenFor(transaction)}>
                    <TickCircle color={theme.palette.primary.main} />
                  </IconButton>
                </Tooltip>
              );
            }

            return null;
          },
        },
      ];

      columnsArray.splice(columnsArray.length - 1, 0, ...clarificaitonColumns);
    }

    return columnsArray;
  }, [
    idCopied,
    copy,
    theme,
    journalAccountsByExternalId,
    onOpenCategorizationDetails,
    onViewJournalEntryForTransaction,
    accounts,
    matchesByTransactionId,
    journalAccountsById,
    onRemoveTransactionSpecialStatus,
    loadingTransactions,
    onReprocessTransaction,
    refund,
    transfer,
    onIgnoreTransaction,
    onUnignoreTransaction,
    onApproveTransaction,
    onMarkTransactionAsUnreviewed,
    onIgnoreDocumentRequirement,
    onUnignoreMatchRequirement,
    onCheckInSignOff,
    transactionsMissingDocuments,
    refunds,
    transfers,
    tab,
  ]);

  const { handleCellClick, cellModesModel, handleCellModesModelChange } = useOneClickDataGridEditing();

  const [queuedCategoryChange, setQueuedCategoryChange] = useState<{ original: Transaction; assignedCategory: string } | null>(null);

  const changeAssignedCategory = useCallback(
    (original: Transaction, newAssignedCategory: string) => {
      if (matchesByTransactionId[original.id]) {
        setQueuedCategoryChange({
          original,
          assignedCategory: newAssignedCategory,
        });
      } else {
        onUpdateTransactionAssignedCategory(original, newAssignedCategory);
      }
    },
    [onUpdateTransactionAssignedCategory, matchesByTransactionId]
  );

  const confirmTransactionCategoryChange = useCallback(() => {
    if (!queuedCategoryChange) {
      return;
    }

    onUpdateTransactionAssignedCategory(queuedCategoryChange.original, queuedCategoryChange.assignedCategory);
    setQueuedCategoryChange(null);
  }, [onUpdateTransactionAssignedCategory, queuedCategoryChange]);

  if (!accounts.length) {
    return (
      <Stack alignItems='center' justifyContent='center' flex={1}>
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <>
      <DataGrid
        style={style}
        rows={transactions}
        columns={columns}
        paginationModel={paginationModel}
        onPaginationModelChange={updatePaginationModel}
        rowSelectionModel={selectedRows}
        onRowSelectionModelChange={(rows) => setSelectedRows(rows)}
        pageSizeOptions={[PAGE_SIZE]}
        initialState={{
          sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }],
          },
        }}
        onCellClick={handleCellClick}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        isCellEditable={(params) => {
          const transaction = params.row as Transaction;
          return !!params.colDef.editable && !loadingTransactions.has(transaction.id) && !matchesByTransactionId[transaction.id];
        }}
        processRowUpdate={(newRow, oldRow) => {
          const old = oldRow as Transaction;
          const updated = newRow as Transaction;

          if (updated.assignedCategory && updated.assignedCategory !== old.assignedCategory) {
            changeAssignedCategory(old, updated.assignedCategory);
            return old;
          }

          return updated;
        }}
      />
      <ConfirmDialog
        open={!!queuedCategoryChange}
        onClose={() => setQueuedCategoryChange(null)}
        onConfirm={confirmTransactionCategoryChange}
        message='This transaction is matched to one or more documents. Editing the transaction will unmatch the transaction. Do you want to proceed?'
      />
      <CheckInResultsDialog openFor={checkInDialogOpenFor} onClose={() => setCheckInDialogOpenFor(null)} />
    </>
  );
}

interface TransactionCategorizationDialogProps {
  transaction: Transaction | null;
  open: boolean;
  onClose: () => void;
}
function TransactionCategorizationDialog({ transaction, open, onClose }: TransactionCategorizationDialogProps) {
  const { fetchTransactionCategorizations, transactionCategorizations } = useAdmin();

  useEffect(() => {
    if (!transaction) {
      return;
    }

    fetchTransactionCategorizations(transaction.id).catch((e) => {
      throw e;
    });
  }, [fetchTransactionCategorizations, transaction]);

  return (
    <CategorizationDialog
      title='Transaction Categorization'
      confidenceRating={transaction?.categorizationConfidence}
      open={open}
      onClose={onClose}
      categorizationConversations={transaction ? transactionCategorizations[transaction.id] || null : null}
    />
  );
}

interface TransactionsProps {
  tab: AdminTransactionsPageTab;
  journal: Journal;
  organization: Organization;
  journalAccountsById: { [accountId: string]: Account };
  tabTransactions: Transaction[];
  allTransactions: Transaction[];
  matchesByTransactionId: { [transactionId: string]: TransactionDocumentMatch[] };
  journalEntries: JournalEntry[] | null;
  style?: CSSProperties;
}

export function Transactions({
  tab,
  journal,
  organization,
  journalAccountsById,
  tabTransactions,
  allTransactions,
  matchesByTransactionId,
  journalEntries,
  style,
}: TransactionsProps) {
  const [searchCriteria, setSearchCriteria] = useState<string[]>([]);
  const [categorizationDialogOpenFor, setCategorizationDialogOpenFor] = useState<Transaction | null>(null);
  const {
    updateTransaction: updateTransactionApi,
    downloadTransactions,
    createRefund: createRefundApi,
    createTransfer: createTransferApi,
    reprocessTransaction: reprocessTransactionApi,
    refunds,
    transfers,
    transactionsMissingDocuments,
  } = useAdminData(organization, journal);
  const { transactionIds, updateTransactionIds } = useContext(AdminTransactionsPageUrlContext);
  const [downloadMenuOpen, setDownloadMenuOpen] = useState(false);
  const downloadButtonRef = useRef<HTMLButtonElement | null>(null);
  const [downloadIncludesAssignedCategory, setDownloadIncludesAssignedCategory] = useState(false);
  const [loadingTransactions, setLoadingTransactions] = useState(() => new Set<string>());

  const [createTransferDialogOpenFor, setCreateTransferDialogOpenFor] = useState<Transaction | null>(null);
  const [createRefundDialogOpenFor, setCreateRefundDialogOpenFor] = useState<Transaction | null>(null);
  const [confirmReprocessDialogOpenFor, setConfirmReprocessDialogOpenFor] = useState<Transaction | null>(null);
  const [confirmTransferApprovalFor, setConfirmTransferApprovalFor] = useState<Transaction | null>(null);
  const [confirmRefundApprovalFor, setConfirmRefundApprovalFor] = useState<Transaction | null>(null);

  const previousSearchCriteria = useRef<string[]>([]);
  const [tablePageBookmark, setTablePageBookmark] = useState<number | undefined>(undefined);

  useEffect(() => {
    previousSearchCriteria.current = searchCriteria;
  }, [searchCriteria]);

  const updateTablePageBookmark = useCallback(
    (page: number) => {
      if (!searchCriteria.length && !previousSearchCriteria.current.length) {
        setTablePageBookmark(page);
      }
    },
    [searchCriteria]
  );

  useEffect(() => {
    if (transactionIds.length) {
      setSearchCriteria(transactionIds.map((tid) => `id::${tid}`));
    }
  }, [transactionIds]);

  const changeSearchCriteria = useCallback(
    (criteria: string[]) => {
      updateTransactionIds([]);
      setSearchCriteria(criteria);
    },
    [updateTransactionIds]
  );

  const viewJournalEntry = useCallback(
    (transaction: Transaction) => {
      if (!journalEntries) {
        return;
      }

      const journalEntriesForTransaction = journalEntries.filter((je) => je.tags.find((t) => t === `transaction::${transaction.id}`));
      if (journalEntriesForTransaction.length >= 1) {
        const journalEntryIdsParam = journalEntriesForTransaction.map((e) => `journalEntryIds=${e.id}`).join('&');
        window.open(`/admin/journals?${journalEntryIdsParam}`, '_blank');
      }
    },
    [journalEntries]
  );

  const [queuedTransactionUpdate, setQueuedTransactionUpdate] = useState<{
    original: Transaction;
    changes: Partial<Transaction>;
    errors: UpdateTransactionErrors;
  } | null>(null);

  const updateTransaction = useCallback(
    async (original: Transaction, changes: Partial<Transaction>, errorResolutions?: UpdateTransactionErrorResolutions) => {
      const refund = refunds[original.id];
      const transfer = transfers[original.id];

      try {
        setLoadingTransactions((existing) => {
          if (refund) {
            const newSet = new Set(existing);
            newSet.add(refund.paymentTransactionId);
            newSet.add(refund.refundTransactionId);
            return newSet;
          }
          if (transfer) {
            const newSet = new Set(existing);
            newSet.add(transfer.fromTransactionId);
            newSet.add(transfer.toTransactionId);
            return newSet;
          }

          const newSet = new Set(existing);
          newSet.add(original.id);
          return newSet;
        });

        try {
          await updateTransactionApi(original, changes, errorResolutions);
        } catch (e) {
          if ((e as UpdateTransactionErrors).updateTransactionError) {
            setQueuedTransactionUpdate({
              errors: e as UpdateTransactionErrors,
              original,
              changes,
            });
          } else {
            throw e;
          }
        }
      } finally {
        setLoadingTransactions((existing) => {
          if (refund) {
            const newSet = new Set(existing);
            newSet.delete(refund.paymentTransactionId);
            newSet.delete(refund.refundTransactionId);
            return newSet;
          }
          if (transfer) {
            const newSet = new Set(existing);
            newSet.delete(transfer.fromTransactionId);
            newSet.delete(transfer.toTransactionId);
            return newSet;
          }

          const newSet = new Set(existing);
          newSet.delete(original.id);
          return newSet;
        });
      }
    },
    [updateTransactionApi, refunds, transfers]
  );

  const createRefund = useCallback(
    async (paymentTransactionId: string, refundTransactionId: string) => {
      try {
        setLoadingTransactions((existing) => {
          const newSet = new Set(existing);
          newSet.add(paymentTransactionId);
          newSet.add(refundTransactionId);
          return newSet;
        });

        await createRefundApi(paymentTransactionId, refundTransactionId);
      } finally {
        setLoadingTransactions((existing) => {
          const newSet = new Set(existing);
          newSet.delete(paymentTransactionId);
          newSet.delete(refundTransactionId);
          return newSet;
        });
      }
    },
    [createRefundApi]
  );

  const createTransfer = useCallback(
    async (fromTransactionId: string, toTransactionId: string) => {
      try {
        setLoadingTransactions((existing) => {
          const newSet = new Set(existing);
          newSet.add(fromTransactionId);
          newSet.add(toTransactionId);
          return newSet;
        });

        await createTransferApi(fromTransactionId, toTransactionId);
      } finally {
        setLoadingTransactions((existing) => {
          const newSet = new Set(existing);
          newSet.delete(fromTransactionId);
          newSet.delete(toTransactionId);
          return newSet;
        });
      }
    },
    [createTransferApi]
  );

  const reprocessTransaction = useCallback(
    async (transactionId: string) => {
      try {
        setLoadingTransactions((existing) => {
          const newSet = new Set(existing);
          newSet.add(transactionId);
          return newSet;
        });

        await reprocessTransactionApi(transactionId);
      } finally {
        setLoadingTransactions((existing) => {
          const newSet = new Set(existing);
          newSet.delete(transactionId);
          return newSet;
        });
      }
    },
    [reprocessTransactionApi]
  );

  const accountsByExternalId = useMemo(() => {
    return Object.values(journalAccountsById).reduce(
      (map, current) => {
        if (current.externalId) {
          map[current.externalId] = current;
        }
        return map;
      },
      {} as { [externalId: string]: Account }
    );
  }, [journalAccountsById]);

  const filteredTransactions = !tabTransactions
    ? []
    : tabTransactions.filter((t) => {
        if (!searchCriteria.length) {
          return true;
        }

        for (const searchCriterion of searchCriteria) {
          if (!searchCriterion) {
            continue;
          }

          return transactionFilter({
            transaction: t,
            searchCriterion,
            journalAccountsById,
            accountsByExternalId,
          });
        }

        return false;
      });

  return (
    <Stack style={style}>
      <Stack direction='row' spacing={5}>
        <Search style={{ flex: 1 }} searchCriteria={searchCriteria} onSearchCriteriaUpdate={(criteria) => changeSearchCriteria(criteria)} />
        <Button variant='outlined' color='neutral' style={{ background: 'none' }} onClick={() => setDownloadMenuOpen(true)} ref={downloadButtonRef}>
          <Download />
        </Button>
        <Popover
          open={downloadMenuOpen}
          onClose={() => setDownloadMenuOpen(false)}
          anchorEl={downloadButtonRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        >
          <MenuList>
            <MenuItem
              onClick={() => {
                setDownloadIncludesAssignedCategory((current) => !current);
              }}
            >
              <FormControlLabel
                onClick={(e) => {
                  e.preventDefault();
                }}
                control={
                  <Checkbox checked={downloadIncludesAssignedCategory} onChange={(e) => setDownloadIncludesAssignedCategory(e.target.checked)} />
                }
                label='Include Assigned Category'
              />
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={async () => {
                await downloadTransactions({
                  includeAssignedCategory: downloadIncludesAssignedCategory,
                });
              }}
            >
              <ListItemText>Download All</ListItemText>
            </MenuItem>
            <MenuItem
              onClick={async () => {
                await downloadTransactions({
                  ids: filteredTransactions.map((t) => t.id),
                  includeAssignedCategory: downloadIncludesAssignedCategory,
                });
              }}
            >
              <ListItemText>Download Filtered</ListItemText>
            </MenuItem>
          </MenuList>
        </Popover>
      </Stack>

      <TransactionTable
        tab={tab}
        style={{ flex: 1 }}
        page={tablePageBookmark}
        onPageChange={updateTablePageBookmark}
        transactions={filteredTransactions}
        transfers={transfers}
        refunds={refunds}
        loadingTransactions={loadingTransactions}
        matchesByTransactionId={matchesByTransactionId}
        transactionsMissingDocuments={transactionsMissingDocuments}
        journalAccountsById={journalAccountsById}
        journalAccountsByExternalId={accountsByExternalId}
        onOpenCategorizationDetails={(transaction: Transaction) => setCategorizationDialogOpenFor(transaction)}
        onViewJournalEntryForTransaction={viewJournalEntry}
        onUpdateTransactionAssignedCategory={(transaction, category) => updateTransaction(transaction, { assignedCategory: category })}
        onRemoveTransactionSpecialStatus={(transaction) => updateTransaction(transaction, { specialStatus: null })}
        onCreateTransfer={(transaction) => setCreateTransferDialogOpenFor(transaction)}
        onViewTransfer={(transaction) => setCreateTransferDialogOpenFor(transaction)}
        onCreateRefund={(transaction) => setCreateRefundDialogOpenFor(transaction)}
        onViewRefund={(transaction) => setCreateRefundDialogOpenFor(transaction)}
        onReprocessTransaction={(transaction) => setConfirmReprocessDialogOpenFor(transaction)}
        onIgnoreTransaction={(transaction) => updateTransaction(transaction, { ignored: true })}
        onUnignoreTransaction={(transaction) => updateTransaction(transaction, { ignored: false })}
        onApproveTransaction={async (transaction) => {
          if (transaction?.specialStatus === SpecialStatus.PART_OF_TRANSFER) {
            setConfirmTransferApprovalFor(transaction);
          } else if (transaction?.specialStatus === SpecialStatus.REFUNDED) {
            setConfirmRefundApprovalFor(transaction);
          } else {
            await updateTransaction(transaction, { approved: true });
          }
        }}
        onCheckInSignOff={async (transaction) => {
          await updateTransaction(transaction, { checkInStatus: CheckInStatus.SIGNED_OFF });
        }}
        onMarkTransactionAsUnreviewed={(transaction) => updateTransaction(transaction, { approved: false })}
        onIgnoreDocumentRequirement={(transaction) => updateTransaction(transaction, { ignoreMatchRequirement: true })}
        onUnignoreMatchRequirement={(transaction) => updateTransaction(transaction, { ignoreMatchRequirement: false })}
      />
      <TransactionCategorizationDialog
        open={!!categorizationDialogOpenFor}
        onClose={() => setCategorizationDialogOpenFor(null)}
        transaction={categorizationDialogOpenFor}
      />
      <CreateTransferDialog
        transaction={createTransferDialogOpenFor}
        transactions={allTransactions}
        transfers={transfers}
        journalAccountsByExternalId={accountsByExternalId}
        journalAccountsById={journalAccountsById}
        onClose={() => setCreateTransferDialogOpenFor(null)}
        onCreateTransfer={createTransfer}
      />
      <CreateRefundDialog
        transaction={createRefundDialogOpenFor}
        transactions={allTransactions}
        refunds={refunds}
        journalAccountsByExternalId={accountsByExternalId}
        journalAccountsById={journalAccountsById}
        onClose={() => setCreateRefundDialogOpenFor(null)}
        onCreateRefund={createRefund}
      />
      <ConfirmDialog
        open={!!confirmReprocessDialogOpenFor}
        onClose={() => setConfirmReprocessDialogOpenFor(null)}
        onConfirm={async () => {
          setConfirmReprocessDialogOpenFor(null);
          await reprocessTransaction(confirmReprocessDialogOpenFor!.id);
        }}
        content={
          <>
            <Stack>
              <Typography>
                Are you sure you want to reprocess this transaction? This will reverse all journal entries associated with the transaction and
                reprocess from scratch. Note that if this transaction has a special status, this may leave the journal in an inconsistent state.
              </Typography>
              <Typography fontWeight={700}>THIS SHOULD BE CONSIDERED A LAST RESORT, AND MAY WARRANT A DISCUSSION.</Typography>
            </Stack>
          </>
        }
      />
      <ConfirmDialog
        open={!!confirmTransferApprovalFor}
        onClose={() => setConfirmTransferApprovalFor(null)}
        onConfirm={async () => {
          await updateTransaction(confirmTransferApprovalFor!, { approved: true });
          setConfirmTransferApprovalFor(null);
        }}
        message='This transaction is part of a transfer. Approving will approve both transactions involved in the transfer.'
      />
      <ConfirmDialog
        open={!!confirmRefundApprovalFor}
        onClose={() => setConfirmRefundApprovalFor(null)}
        onConfirm={async () => {
          await updateTransaction(confirmRefundApprovalFor!, { approved: true });
          setConfirmRefundApprovalFor(null);
        }}
        message='This transaction is part of a refund. Approving will approve both transactions involved in the refund.'
      />
      <ConfirmDialog
        open={!!queuedTransactionUpdate}
        onClose={() => setQueuedTransactionUpdate(null)}
        content={
          <Stack>
            {queuedTransactionUpdate?.errors.matchExists && (
              <Typography>
                This transaction is matched to one or more documents. Updating the transaction will unmatch it. Are you sure you want to continue?
              </Typography>
            )}
          </Stack>
        }
        onConfirm={async () => {
          if (!queuedTransactionUpdate) {
            return;
          }

          const errorResolutions = {} as UpdateTransactionErrorResolutions;
          if (queuedTransactionUpdate.errors.matchExists) {
            errorResolutions.matchExists = 'unmatch';
          }

          await updateTransaction(queuedTransactionUpdate.original, queuedTransactionUpdate.changes, errorResolutions);

          setQueuedTransactionUpdate(null);
        }}
      />
    </Stack>
  );
}

const AdminTransactionsPageUrlContext = createContext(
  {} as {
    transactionIds: string[];
    updateTransactionIds: (transactionIds: string[]) => void;
    tab: AdminTransactionsPageTab;
    updateTab: (tab: AdminTransactionsPageTab) => void;
  }
);

const AdminTransactionsPageUrlContextProvider = ({ children }: { children: React.ReactNode }) => {
  const TRANSACTION_IDS_PARAM = 'transactionIds';
  const TAB_PARAM = 'tab';

  const [tab, setTab] = useState(AdminTransactionsPageTab.UNREVIEWED);
  const [transactionIds, setTransactionIds] = useState<string[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [pendingSearchParams, setPendingSearchParams] = useState<URLSearchParams | null>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const transactionIdsParam = queryParams.getAll(TRANSACTION_IDS_PARAM);
    if (transactionIdsParam) {
      setTransactionIds(transactionIdsParam);
    }

    const tabParam = queryParams.get(TAB_PARAM);
    if (tabParam) {
      setTab(tabParam as AdminTransactionsPageTab);
    }
  }, [location]);

  useEffect(() => {
    if (pendingSearchParams) {
      const searchParams = new URLSearchParams(location.search);

      for (const [key, value] of pendingSearchParams.entries()) {
        searchParams.set(key, value);
      }
      navigate({ search: searchParams.toString() });

      setPendingSearchParams(null);
    }
  }, [pendingSearchParams, navigate, location.search]);

  const clearSearchParam = useCallback(
    (param: string) => {
      setPendingSearchParams((previous) => {
        const searchParams = new URLSearchParams(location.search);

        if (previous) {
          for (const [key, value] of previous.entries()) {
            searchParams.set(key, value);
          }
        }

        searchParams.delete(param);

        return searchParams;
      });
    },
    [location]
  );

  const updateTransactionIds = useCallback(
    (transactionIds: string[]) => {
      if (!transactionIds.length) {
        clearSearchParam(TRANSACTION_IDS_PARAM);
      } else {
        setPendingSearchParams((previous) => {
          const searchParams = new URLSearchParams(location.search);

          if (previous) {
            for (const [key, value] of previous.entries()) {
              searchParams.set(key, value);
            }
          }

          searchParams.delete(TRANSACTION_IDS_PARAM);
          for (const id of transactionIds) {
            searchParams.append(TRANSACTION_IDS_PARAM, id);
          }

          return searchParams;
        });
      }

      setTransactionIds(transactionIds);
    },
    [location, clearSearchParam]
  );

  const updateTab = useCallback(
    (tab: AdminTransactionsPageTab | null) => {
      if (!tab) {
        clearSearchParam(TAB_PARAM);
      } else {
        setPendingSearchParams((previous) => {
          const searchParams = new URLSearchParams(location.search);

          if (previous) {
            for (const [key, value] of previous.entries()) {
              searchParams.set(key, value);
            }
          }

          searchParams.set(TAB_PARAM, tab);

          return searchParams;
        });
      }
    },
    [location, clearSearchParam]
  );

  return (
    <AdminTransactionsPageUrlContext.Provider value={{ transactionIds, updateTransactionIds, tab, updateTab }}>
      {children}
    </AdminTransactionsPageUrlContext.Provider>
  );
};

export enum AdminTransactionsPageTab {
  UNREVIEWED = 'UNREVIEWED',
  NEED_CLARIFICATION = 'NEED_CLARIFICATION',
  REVIEWED = 'REVIEWED',
  ALL_ACTIVE = 'ALL_ACTIVE',
  DUPLICATES = 'DUPLICATES',
  IGNORED = 'IGNORED',
  IMPORTS = 'IMPORTS',
  ALL = 'ALL',
}

function AdminTransactionsPageContent({ ...props }) {
  const { selectedOrganization: selectedOrg, setSelectedOrganization: setSelectedOrg, previousSelectedOrganization } = useSelectedOrganization(null);
  const [selectedJournal, setSelectedJournal] = useState<Journal | null>(null);
  const {
    organizations,
    journalAccountsById,
    transactions,
    journals,
    journalEntries,
    matchesByTransactionId,
    unreviewiedTransactionCounts,
    duplicateCountByFy,
    needClarificationCountByFy,
  } = useAdminData(selectedOrg, selectedJournal);
  const theme = useTheme();
  const { tab: currentTab, updateTab: setCurrentTab } = useContext(AdminTransactionsPageUrlContext);

  const requiresAttnCounts = useMemo(() => {
    if (!duplicateCountByFy || !unreviewiedTransactionCounts || !needClarificationCountByFy) {
      return null;
    }

    const counts = {} as { [fy: string]: number };

    for (const [fy, count] of Object.entries(unreviewiedTransactionCounts)) {
      counts[fy] = count;
    }

    for (const [fy, count] of Object.entries(duplicateCountByFy)) {
      if (!counts[fy]) {
        counts[fy] = count;
      } else {
        counts[fy] = counts[fy] + count;
      }
    }

    for (const [fy, count] of Object.entries(needClarificationCountByFy)) {
      if (!counts[fy]) {
        counts[fy] = count;
      } else {
        counts[fy] = counts[fy] + count;
      }
    }

    return counts;
  }, [duplicateCountByFy, unreviewiedTransactionCounts, needClarificationCountByFy]);

  const tabFilteredTransactions = transactions.filter((t) => {
    if (currentTab === AdminTransactionsPageTab.IGNORED) {
      return t.ignored;
    } else if (currentTab === AdminTransactionsPageTab.UNREVIEWED) {
      return !t.approved && !t.ignored;
    } else if (currentTab === AdminTransactionsPageTab.NEED_CLARIFICATION) {
      return (
        (t.approved && !t.ignored && Object.keys(t.issues).length > 0) ||
        t.checkInStatus === CheckInStatus.SCHEDULED ||
        t.checkInStatus === CheckInStatus.SAVED_FOR_LATER ||
        t.checkInStatus === CheckInStatus.CLARIFIED ||
        t.checkInStatus === CheckInStatus.CONFLICT
      );
    } else if (currentTab === AdminTransactionsPageTab.REVIEWED) {
      return t.approved && !t.ignored && Object.keys(t.issues).length === 0;
    } else if (currentTab === AdminTransactionsPageTab.ALL_ACTIVE) {
      return !t.ignored;
    }

    return true;
  });

  if (!organizations) {
    return (
      <PageContainer {...props}>
        <PageHeader title='Admin - Transactions' />
        <PageBody gutter='thin' style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
          <CircularProgress />
        </PageBody>
      </PageContainer>
    );
  }

  return (
    <PageContainer {...props}>
      <PageHeader title='Admin - Transactions' />
      <PageBody gutter='thin'>
        <Stack height='100%'>
          <Stack direction='row' paddingTop={theme.spacing(2)}>
            <AdminOrganizationSelect
              organizations={organizations}
              onOrganizationChange={(org) => {
                setSelectedOrg(org);
                if (selectedOrg?.id !== previousSelectedOrganization?.id) {
                  setSelectedJournal(null);
                }
              }}
            />

            <AdminJournalSelect
              requiresAttnCounts={requiresAttnCounts}
              journals={journals}
              organization={selectedOrg}
              selectedJournal={selectedJournal}
              onJournalChange={(j) => setSelectedJournal(j)}
            />
          </Stack>

          <>
            <Tabs
              value={currentTab}
              onChange={(_e, newValue: AdminTransactionsPageTab) => {
                setCurrentTab(newValue);
              }}
              style={{
                width: '100%',
              }}
            >
              <Tab
                label={
                  selectedJournal && unreviewiedTransactionCounts && unreviewiedTransactionCounts[selectedJournal.fy]
                    ? `Unreviewed (${unreviewiedTransactionCounts[selectedJournal.fy]})`
                    : 'Unreviewed'
                }
                value={AdminTransactionsPageTab.UNREVIEWED}
              />
              <Tab
                label={
                  selectedJournal && needClarificationCountByFy && needClarificationCountByFy[selectedJournal.fy]
                    ? `Need Clarification (${needClarificationCountByFy[selectedJournal.fy]})`
                    : 'Need Clarification'
                }
                value={AdminTransactionsPageTab.NEED_CLARIFICATION}
              />
              <Tab label='Reviewed' value={AdminTransactionsPageTab.REVIEWED} />
              <Tab label='All Active' value={AdminTransactionsPageTab.ALL_ACTIVE} />
              <Tab
                label={
                  selectedJournal && duplicateCountByFy && duplicateCountByFy[selectedJournal.fy]
                    ? `Duplicates (${duplicateCountByFy[selectedJournal.fy]})`
                    : 'Duplicates'
                }
                value={AdminTransactionsPageTab.DUPLICATES}
              />
              <Tab label='Ignored' value={AdminTransactionsPageTab.IGNORED} />
              <Tab label='All' value={AdminTransactionsPageTab.ALL} />
              <Box style={{ flexGrow: 1 }}></Box>
              <Tab label='Imports' value={AdminTransactionsPageTab.IMPORTS} />
            </Tabs>

            {(!selectedOrg || !selectedJournal) && (
              <Stack justifyContent='center' alignItems='center' flex={1}>
                <Typography>Please select Organization and FY</Typography>
              </Stack>
            )}

            {currentTab !== AdminTransactionsPageTab.IMPORTS &&
              currentTab !== AdminTransactionsPageTab.DUPLICATES &&
              selectedOrg &&
              selectedJournal && (
                <Transactions
                  tab={currentTab}
                  journal={selectedJournal}
                  organization={selectedOrg}
                  journalAccountsById={journalAccountsById}
                  tabTransactions={tabFilteredTransactions}
                  allTransactions={transactions}
                  matchesByTransactionId={matchesByTransactionId}
                  journalEntries={journalEntries}
                  style={{ flex: 1, overflow: 'auto' }}
                />
              )}

            {currentTab === AdminTransactionsPageTab.DUPLICATES && selectedOrg && selectedJournal && (
              <DuplicateTransactions journal={selectedJournal} organization={selectedOrg} style={{ flex: 1, overflow: 'auto' }} />
            )}

            {currentTab === AdminTransactionsPageTab.IMPORTS && selectedOrg && selectedJournal && (
              <TransactionImports
                organizationId={selectedOrg.id!}
                accounts={Object.values(journalAccountsById)}
                style={{ flex: 1, overflow: 'auto' }}
              />
            )}
          </>
        </Stack>
      </PageBody>
    </PageContainer>
  );
}

export function AdminTransactionsPage({ ...props }) {
  return (
    <AdminTransactionsPageUrlContextProvider>
      <AdminTransactionsPageContent {...props} />
    </AdminTransactionsPageUrlContextProvider>
  );
}
