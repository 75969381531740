import { Box, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, TextField, Typography, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { CloseCircle } from 'iconsax-react';
import { Account, Document, documentTotal, DocumentType } from '../../api';
import { SMALL_HORIZONTAL_SPACING } from '../../theme';
import { DocumentViewer } from '../document-img';
import { ThreeColumn } from '../three-column';

export function restrictLength(str: string, length: number, delimiter = ' ') {
  const tokenized = str.split(delimiter);

  let sum = 0;
  const approvedFrontParts = [] as string[];
  const approvedBackParts = [] as string[];

  if (tokenized.length === 1) {
    return str;
  }

  for (let i = 0; i < Math.ceil(tokenized.length / 2); i++) {
    if (i !== tokenized.length - 1 - i) {
      const frontPart = tokenized[i];
      if (sum + frontPart.length + 1 < length) {
        sum += frontPart.length;
        approvedFrontParts.push(frontPart);
      }
    }

    const backPart = tokenized[tokenized.length - 1 - i];
    if (sum + backPart.length + 1 < length) {
      sum += backPart.length;
      approvedBackParts.push(backPart);
    }
  }

  const frontJoined = approvedFrontParts.join(delimiter);
  const backJoined = approvedBackParts.join(delimiter);

  if (frontJoined + delimiter + backJoined === str) {
    return str;
  }

  return frontJoined + ' ... ' + backJoined;
}

export function AccountDetailsDialog({ open, onClose, account }: { open: boolean; onClose: () => void; account: Account | null }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth='md'>
      <DialogTitle>
        <ThreeColumn align='center'>
          <span></span>
          <span>Account Details</span>
          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </ThreeColumn>
      </DialogTitle>
      <DialogContent>
        {account && (
          <Stack>
            <TextField InputProps={{ readOnly: true, disableUnderline: true }} fullWidth label='Account Name' value={account.name} />

            <TextField InputProps={{ readOnly: true, disableUnderline: true }} fullWidth label='Account Type' value={account.standardAccount} />

            {account.name !== account.description && (
              <TextField InputProps={{ readOnly: true, disableUnderline: true }} fullWidth label='Account Description' value={account.description} />
            )}

            {account.externalMaskedAccountId && (
              <TextField
                InputProps={{ readOnly: true, disableUnderline: true }}
                fullWidth
                label='Account Number'
                value={`****${account.externalMaskedAccountId}`}
              />
            )}

            {account.externalCurrency && (
              <TextField
                InputProps={{ readOnly: true, disableUnderline: true }}
                fullWidth
                label='Account Currency'
                value={account.externalCurrency}
              />
            )}
          </Stack>
        )}
      </DialogContent>
    </Dialog>
  );
}

export function DocumentImageDialog({ openFor, onClose }: { openFor: Document | null; onClose: () => void }) {
  const theme = useTheme();

  const date = openFor?.paymentDate || openFor?.date;
  const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: openFor?.currency || 'CAD' });

  let amount: string;
  if (!openFor?.afterTax) {
    amount = 'Unknown';
  } else if (openFor.discount) {
    amount = `${amountFormatter.format(documentTotal(openFor))} (discounted from ${amountFormatter.format(parseFloat(openFor.afterTax))})`;
  } else {
    amount = amountFormatter.format(parseFloat(openFor.afterTax));
  }

  return (
    <Dialog open={!!openFor} onClose={onClose} style={{ margin: theme.spacing(SMALL_HORIZONTAL_SPACING) }} fullScreen>
      <DialogTitle>
        <ThreeColumn $mainColumn align='center'>
          <span></span>
          <span>
            {openFor?.merchantName
              ? `Document - ${openFor.merchantName}`
              : `Document - Unknown ${openFor?.type === DocumentType.INVOICE ? 'Invoice' : 'Receipt'}`}
          </span>
          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </ThreeColumn>
      </DialogTitle>
      <DialogContent>
        {openFor && (
          <Stack direction='row' height='100%'>
            <Stack minWidth={theme.spacing(64)}>
              <Typography variant='h3'>Document Information</Typography>

              <Box>
                <Typography variant='h5'>Type</Typography>
                <Chip
                  size='small'
                  sx={{
                    fontSize: '0.666rem',
                  }}
                  color={openFor.isIncome ? 'primary' : 'error'}
                  label={openFor.isIncome ? 'INCOME' : 'EXPENSE'}
                />
              </Box>

              <Box>
                <Typography variant='h5'>Received by Otter</Typography>
                <Typography>{format(openFor.created, 'MMM d, yyyy') || 'Unknown'}</Typography>
              </Box>

              <Box>
                <Typography variant='h5'>Merchant</Typography>
                <Typography>{openFor.merchantName || 'Unknown'}</Typography>
              </Box>

              <Box>
                <Typography variant='h5'>Document Date</Typography>
                <Typography>{date ? format(date, 'MMM d, yyyy') : 'Unknown'}</Typography>
              </Box>

              <Box>
                <Typography variant='h5'>Amount (after tax)</Typography>
                <Typography>{amount}</Typography>
              </Box>

              {openFor.GST && (
                <Box>
                  <Typography variant='h5'>GST</Typography>
                  <Typography>{amountFormatter.format(parseFloat(openFor.GST))}</Typography>
                </Box>
              )}

              {openFor.HST && (
                <Box>
                  <Typography variant='h5'>HST</Typography>
                  <Typography>{amountFormatter.format(parseFloat(openFor.HST))}</Typography>
                </Box>
              )}

              {openFor.PST && (
                <Box>
                  <Typography variant='h5'>PST</Typography>
                  <Typography>{amountFormatter.format(parseFloat(openFor.PST))}</Typography>
                </Box>
              )}
            </Stack>

            <Box overflow='auto' style={{ flex: 1 }}>
              <DocumentViewer document={openFor} style={{ width: '100%', height: '100%' }} />
            </Box>
          </Stack>
        )}
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}
