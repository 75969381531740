import { Preferences } from '@capacitor/preferences';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { isMobile } from '../api';

export const storage = {
  setItem: async (key: string, value: string) => {
    if (isMobile) {
      await Preferences.set({
        key,
        value,
      });
    } else {
      localStorage.setItem(key, value);
    }
  },
  setSecureItem: async (key: string, value: string) => {
    if (isMobile) {
      await SecureStoragePlugin.set({ key, value });
    } else {
      throw new Error('No secure storage on web');
    }
  },
  getItem: async (key: string) => {
    if (isMobile) {
      try {
        const result = await Preferences.get({ key });
        return result.value;
      } catch (e) {
        return null;
      }
    } else {
      return localStorage.getItem(key);
    }
  },
  getSecureItem: async (key: string) => {
    if (isMobile) {
      try {
        const result = await SecureStoragePlugin.get({ key });
        return result.value;
      } catch (e) {
        return null;
      }
    }

    throw new Error('No secure storage on web');
  },
  removeItem: async (key: string) => {
    if (isMobile) {
      try {
        await Preferences.remove({ key });
      } catch (e) {
        //Noop
      }
    } else {
      localStorage.removeItem(key);
    }
  },
  removeSecureItem: async (key: string) => {
    if (isMobile) {
      try {
        await SecureStoragePlugin.remove({ key });
      } catch (e) {
        //Noop
      }
    } else {
      throw new Error('No secure storage on web');
    }
  },
};
