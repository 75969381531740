import { Chip, MenuItem, MenuList, Popover, Stack, useTheme } from '@mui/material';
import { More } from 'iconsax-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Journal, Organization } from '../api';
import { SMALL_HORIZONTAL_SPACING } from '../theme';
import { getFiscalYear } from '../utils/date-utils';
import { storage } from '../utils/storage';
import { Button } from './button';

const LOCAL_STORAGE_JOURNAL_KEY = 'admin.journal';

export interface AdminJournalSelectProps {
  showNone?: boolean;
  requiresAttnCounts?: { [fy: string]: number } | null;
  organization: Organization | null;
  journals: Journal[];
  selectedJournal: Journal | null;
  onJournalChange: (journal: Journal | null) => void;
}
export function AdminJournalSelect({
  showNone,
  requiresAttnCounts,
  journals,
  organization,
  selectedJournal,
  onJournalChange,
}: AdminJournalSelectProps) {
  const theme = useTheme();

  const changeJournal = useCallback(
    async (journalId: string) => {
      const journal = journals.find((j) => j.id === journalId);
      if (!journal && journalId !== 'none') {
        return;
      }

      if (journalId === 'none') {
        await storage.removeItem(LOCAL_STORAGE_JOURNAL_KEY);
      } else {
        await storage.setItem(LOCAL_STORAGE_JOURNAL_KEY, journalId);
      }

      onJournalChange(journal || null);
    },
    [onJournalChange, journals]
  );

  const journalsRef = useRef<Journal[] | null>(null);
  const organizationRef = useRef<Organization | null>(null);
  useEffect(() => {
    if (journalsRef.current === journals && organizationRef.current === organization) {
      return;
    }

    journalsRef.current = journals;
    organizationRef.current = organization;

    const handleNewJournals = async () => {
      const savedJournalId = await storage.getItem(LOCAL_STORAGE_JOURNAL_KEY);
      const savedJournal = journals.find((j) => j.id === savedJournalId);
      if (savedJournalId === 'none') {
        await changeJournal('none');
      } else if (savedJournal) {
        await changeJournal(savedJournal.id);
      } else if (journals.length > 0 && organization && journals[0].organizationId === organization.id) {
        const currentFY = getFiscalYear(new Date(), organization.fyEndMonth);
        const fyJournal = journals.find((j) => j.fy === currentFY)!;
        await changeJournal(fyJournal.id);
      }
    };

    handleNewJournals().catch((e) => {
      throw e;
    });
  }, [journals, changeJournal, organization]);

  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const [moreMenuOpen, setMoreMenuOpen] = useState(false);

  const sortedJournals = journals.sort((a, b) => Number(b.fy) - Number(a.fy));
  const numToShow = 3;
  const shownJournals = sortedJournals.slice(0, numToShow);
  const shownJournalFys = new Set(shownJournals.map((j) => j.fy));
  if (showNone && sortedJournals.length < numToShow) {
    shownJournalFys.add('none');
  }

  let otherJournalUnreviewedCount: number | null = null;
  if (requiresAttnCounts) {
    otherJournalUnreviewedCount = Object.entries(requiresAttnCounts)
      .filter(([fy, _]) => !shownJournalFys.has(fy))
      .reduce((sum, [_, count]) => {
        return sum + count;
      }, 0);
  }

  return (
    <Stack direction='row' alignItems='stretch' spacing={SMALL_HORIZONTAL_SPACING}>
      {journals
        .sort((a, b) => Number(b.fy) - Number(a.fy))
        .slice(0, 3)
        .map((journal) => (
          <Button
            key={journal.id}
            variant={selectedJournal === journal ? 'contained' : 'outlined'}
            color='primary'
            onClick={() => changeJournal(journal.id)}
          >
            {journal.fy}
            {requiresAttnCounts && !!requiresAttnCounts[journal.fy] && (
              <Chip
                size='small'
                sx={{
                  maxHeight: '1rem',
                  fontSize: (theme.typography.small as { fontSize: string }).fontSize,
                  marginLeft: theme.spacing(SMALL_HORIZONTAL_SPACING),
                }}
                label={requiresAttnCounts[journal.fy]}
                color='warning'
              />
            )}
          </Button>
        ))}

      {showNone && sortedJournals.length < numToShow && (
        <Button variant={selectedJournal === null ? 'contained' : 'outlined'} color='primary' onClick={() => changeJournal('none')}>
          None
          {requiresAttnCounts && !!requiresAttnCounts['none'] && (
            <Chip
              size='small'
              sx={{
                maxHeight: '1rem',
                fontSize: (theme.typography.small as { fontSize: string }).fontSize,
                marginLeft: theme.spacing(SMALL_HORIZONTAL_SPACING),
              }}
              label={requiresAttnCounts['none']}
              color='warning'
            />
          )}
        </Button>
      )}

      {shownJournals.length !== sortedJournals.length && (
        <>
          <Button variant='outlined' color='primary' ref={anchorEl} onClick={() => setMoreMenuOpen(true)}>
            <More size='1rem' />
            {requiresAttnCounts && !!otherJournalUnreviewedCount && (
              <Chip
                size='small'
                sx={{
                  maxHeight: '1rem',
                  fontSize: (theme.typography.small as { fontSize: string }).fontSize,
                  marginLeft: theme.spacing(SMALL_HORIZONTAL_SPACING),
                }}
                label={otherJournalUnreviewedCount}
                color='warning'
              />
            )}
          </Button>

          <Popover
            open={moreMenuOpen}
            onClose={() => setMoreMenuOpen(false)}
            anchorEl={anchorEl.current}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              horizontal: 'center',
              vertical: 'top',
            }}
          >
            <MenuList>
              {sortedJournals.slice(numToShow).map((j) => (
                <MenuItem key={j.fy}>
                  {j.fy}

                  {requiresAttnCounts && !!requiresAttnCounts[j.fy] && (
                    <Chip
                      size='small'
                      sx={{
                        maxHeight: '1rem',
                        fontSize: (theme.typography.small as { fontSize: string }).fontSize,
                        marginLeft: theme.spacing(SMALL_HORIZONTAL_SPACING),
                      }}
                      label={requiresAttnCounts[j.fy]}
                      color='warning'
                    />
                  )}
                </MenuItem>
              ))}

              {showNone && !shownJournalFys.has('none') && (
                <MenuItem>
                  None
                  {requiresAttnCounts && !!requiresAttnCounts['none'] && (
                    <Chip
                      size='small'
                      sx={{
                        maxHeight: '1rem',
                        fontSize: (theme.typography.small as { fontSize: string }).fontSize,
                        marginLeft: theme.spacing(SMALL_HORIZONTAL_SPACING),
                      }}
                      label={requiresAttnCounts['none']}
                      color='warning'
                    />
                  )}
                </MenuItem>
              )}
            </MenuList>
          </Popover>
        </>
      )}
    </Stack>
  );
}
