import { Stack } from '@mui/material';
import { useState } from 'react';
import { useConversation } from '../../api';
import { Button } from '../button';
import { IWidgetProps } from './IWidgetProps';
import { PlaidLinkWidget } from './plaid-link';

export function PlaidCreateConnectionWidget(props: IWidgetProps) {
  const { addConversationMessage, conversationId } = useConversation();
  const [complete, setComplete] = useState(props.isSuccess === true || props.isSuccess === false);
  const [loading, setLoading] = useState(false);

  const maybeLater = async () => {
    setComplete(true);
    await addConversationMessage({ conversationId: conversationId as string, message: `Maybe later...` });
  };

  return (
    <Stack direction='row'>
      <PlaidLinkWidget
        buttonShade={400}
        disable={complete}
        buttonColor='primary'
        buttonVariant='contained'
        buttonLabel='Connect Accounts'
        onSuccess={() => setComplete(true)}
        onExit={() => setComplete(true)}
        onLoadingChange={(isLoading) => setLoading(isLoading)}
        {...props}
      />

      {!complete && !loading && (
        <Button variant='contained' color='neutral' onClick={maybeLater}>
          Maybe later...
        </Button>
      )}
    </Stack>
  );
}
