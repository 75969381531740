import { CircularProgress, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { formatInTimeZone } from 'date-fns-tz';
import { CloseCircle, Copy, CopySuccess, Eye, TickCircle } from 'iconsax-react';
import { CSSProperties, useCallback, useEffect, useMemo, useState } from 'react';
import { Journal, Organization, Transaction, UpdateTransactionErrorResolutions, UpdateTransactionErrors, useAdmin } from '../../../api';
import { ConfirmDialog, Search } from '../../../components';
import { dateIsWithinFY } from '../../../utils/date-utils';
import { AdminTransactionsPageTab } from './admin-transactions-page';
import { transactionFilter } from './filters';

const useAdminData = (organization: Organization, journal: Journal) => {
  const { duplicateTransactions, fetchDuplicateTransactions, updateTransaction: updateTransactionApi } = useAdmin();

  useEffect(() => {
    fetchDuplicateTransactions(organization.id!).catch((e) => {
      throw e;
    });
  }, [organization, fetchDuplicateTransactions]);

  const duplicatesForJournal = useMemo(() => {
    if (!organization || !journal) {
      return {
        transactions: null,
        duplicateMap: null,
      } as {
        transactions: Transaction[] | null;
        duplicateMap: { [transactionId: string]: Transaction } | null;
      };
    }

    const orgDuplicates =
      duplicateTransactions[organization.id!] ||
      ({
        transactions: null,
        duplicateMap: null,
      } as {
        transactions: Transaction[] | null;
        duplicateMap: { [transactionId: string]: Transaction } | null;
      });

    return {
      transactions: orgDuplicates.transactions
        ? orgDuplicates.transactions.filter((t) => dateIsWithinFY(t.date, journal.fy, organization.fyEndMonth))
        : null,
      duplicateMap: orgDuplicates.duplicateMap,
    };
  }, [organization, journal, duplicateTransactions]);

  const acceptDuplicate = useCallback(
    async (transactionId: string, errorResolutions?: UpdateTransactionErrorResolutions) => {
      if (!organization) {
        return;
      }

      await updateTransactionApi(organization.id!, transactionId, { ignored: true }, errorResolutions);

      await fetchDuplicateTransactions(organization.id!);
    },
    [updateTransactionApi, organization, fetchDuplicateTransactions]
  );

  const rejectDuplicate = useCallback(
    async (transactionId: string, duplicateId: string) => {
      if (!organization) {
        return;
      }

      const first = updateTransactionApi(organization.id!, transactionId, { overrideDuplicateDetection: true });
      const second = updateTransactionApi(organization.id!, duplicateId, { overrideDuplicateDetection: true });

      await Promise.all([first, second]);

      await fetchDuplicateTransactions(organization.id!);
    },
    [updateTransactionApi, organization, fetchDuplicateTransactions]
  );

  return {
    transactions: duplicatesForJournal.transactions,
    duplicateMap: duplicatesForJournal.duplicateMap,
    acceptDuplicate,
    rejectDuplicate,
  };
};

interface DuplicateRow {
  id: string;
  isoCurrencyCode: string;
  name: string;
  date: Date;
  type: string;
  amount: string;
  duplicateId: string;
  duplicateName: string;
  duplicateDate: Date;
  duplicateType: string;
  duplicateAmount: string;
}

export interface DuplicateTransactionsProps {
  organization: Organization;
  journal: Journal;
  style?: CSSProperties;
}

export function DuplicateTransactions({ organization, journal, style }: DuplicateTransactionsProps) {
  const theme = useTheme();
  const [searchCriteria, setSearchCriteria] = useState<string[]>([]);
  const [loadingTransactions, setLoadingTransactions] = useState<Set<string>>(new Set());
  const [confirmAcceptDialogOpenFor, setConfirmAcceptDialogOpenFor] = useState<string | null>(null);
  const [confirmRejectDialogOpenFor, setConfirmRejectDialogOpenFor] = useState<{ transactionId: string; duplicateId: string } | null>(null);
  const [queuedTransactionAccept, setQueuedTransactionAccept] = useState<{
    transactionId: string;
    transaction: Partial<Transaction>;
    errors: UpdateTransactionErrors;
  } | null>(null);
  const { transactions, duplicateMap, acceptDuplicate, rejectDuplicate } = useAdminData(organization, journal);

  const transactionsById = useMemo(() => {
    const map = {} as { [tid: string]: Transaction };

    if (!transactions || !duplicateMap) {
      return map;
    }

    for (const transaction of transactions) {
      map[transaction.id] = transaction;
    }

    for (const duplicate of Object.values(duplicateMap)) {
      map[duplicate.id] = duplicate;
    }

    return map;
  }, [transactions, duplicateMap]);

  const [idCopied, setIdCopied] = useState<string | null>(null);
  const copy = useCallback(async (transactionId: string) => {
    setIdCopied(transactionId);
    await navigator.clipboard.writeText(transactionId);
  }, []);

  const accept = async (transactionId: string, errorResolutions?: UpdateTransactionErrorResolutions) => {
    try {
      const newSet = new Set(loadingTransactions);
      newSet.add(transactionId);
      setLoadingTransactions(newSet);

      await acceptDuplicate(transactionId, errorResolutions);
    } catch (e) {
      if ((e as UpdateTransactionErrors).updateTransactionError) {
        setQueuedTransactionAccept({
          transactionId,
          transaction: { ignored: true },
          errors: e as UpdateTransactionErrors,
        });
      } else {
        throw e;
      }
    } finally {
      const newSet = new Set(loadingTransactions);
      newSet.delete(transactionId);
      setLoadingTransactions(newSet);
    }
  };

  const reject = async (transactionId: string, duplicateId: string) => {
    try {
      const newSet = new Set(loadingTransactions);
      newSet.add(transactionId);
      setLoadingTransactions(newSet);

      await rejectDuplicate(transactionId, duplicateId);
    } finally {
      const newSet = new Set(loadingTransactions);
      newSet.delete(transactionId);
      setLoadingTransactions(newSet);
    }
  };

  const viewTransaction = (transactionId: string) => {
    const transaction = transactionsById[transactionId];
    let tab;
    if (transaction.approved) {
      tab = AdminTransactionsPageTab.REVIEWED;
    } else {
      tab = AdminTransactionsPageTab.UNREVIEWED;
    }
    window.open(`/admin/transactions?tab=${tab}&transactionIds=${transactionId}`, '_blank');
  };

  const rows: DuplicateRow[] | null =
    transactions
      ?.map((t) => {
        const duplicate = duplicateMap![t.id];

        return {
          id: t.id,
          isoCurrencyCode: t.isoCurrencyCode,
          name: t.name,
          date: t.date,
          type: parseFloat(t.amount) < 0 ? 'Income' : 'Expense',
          amount: t.amount,
          duplicateId: duplicate.id,
          duplicateName: duplicate.name,
          duplicateDate: duplicate.date,
          duplicateType: parseFloat(duplicate.amount) < 0 ? 'Income' : 'Expense',
          duplicateAmount: duplicate.amount,
        };
      })
      .filter((t) => {
        if (!searchCriteria.length) {
          return true;
        }

        for (const searchCriterion of searchCriteria) {
          if (!searchCriterion) {
            continue;
          }

          const transactionFound = transactionFilter({
            transaction: t,
            searchCriterion,
          });

          if (transactionFound) {
            return true;
          }

          const duplicateFound = transactionFilter({
            transaction: {
              id: t.duplicateId,
              name: t.duplicateName,
              date: t.duplicateDate,
              isoCurrencyCode: t.isoCurrencyCode,
              amount: t.duplicateAmount,
            },
            searchCriterion,
          });

          if (duplicateFound) {
            return true;
          }
        }

        return false;
      }) || null;

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 50,
      renderCell: (params) => {
        const row = params.row as DuplicateRow;
        const loading = loadingTransactions.has(row.id);

        return (
          <Tooltip title={loading ? 'Loading' : row.id}>
            <span>
              {loading ? (
                <CircularProgress size='1rem' />
              ) : (
                <IconButton onClick={() => copy(row.id)}>
                  {idCopied === row.id ? (
                    <CopySuccess size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                  ) : (
                    <Copy size='1.1rem' variant='Outline' color={theme.palette.primary.main} />
                  )}
                </IconButton>
              )}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'type',
      headerName: 'Type',
    },
    {
      field: 'date',
      headerName: 'Date (UTC)',
      renderCell: (params) => formatInTimeZone(params.value as Date, 'UTC', 'MMM d, yyyy'),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      renderCell: (params) => {
        const row = params.row as DuplicateRow;
        const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: row.isoCurrencyCode });

        const floatAmount = parseFloat(row.amount);
        return amountFormatter.format(Math.abs(floatAmount));
      },
    },
    {
      field: 'duplicateId',
      headerName: 'ID',
      width: 50,
      renderCell: (params) => {
        const row = params.row as DuplicateRow;

        return (
          <Tooltip title={row.duplicateId}>
            <span>
              <IconButton onClick={() => copy(row.duplicateId)}>
                {idCopied === row.duplicateId ? (
                  <CopySuccess size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                ) : (
                  <Copy size='1.1rem' variant='Outline' color={theme.palette.primary.main} />
                )}
              </IconButton>
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'duplicateName',
      headerName: 'Name',
      flex: 1,
      valueGetter: (params) => {
        const row = params.row as DuplicateRow;

        return row.duplicateName;
      },
    },
    {
      field: 'duplicateDate',
      headerName: 'Date (UTC)',
      valueGetter: (params) => formatInTimeZone(params.value as Date, 'UTC', 'MMM d, yyyy'),
    },
    {
      field: 'duplicateType',
      headerName: 'Type',
      valueGetter: (params) => {
        const row = params.row as DuplicateRow;

        return row.duplicateType;
      },
    },
    {
      field: 'duplicateAmount',
      headerName: 'Amount',
      renderCell: (params) => {
        const row = params.row as DuplicateRow;

        const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: row.isoCurrencyCode });

        const floatAmount = parseFloat(row.duplicateAmount);
        return amountFormatter.format(Math.abs(floatAmount));
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => {
        const row = params.row as DuplicateRow;
        const loading = loadingTransactions.has(row.id) || loadingTransactions.has(row.duplicateId);

        return (
          <Stack direction='row' spacing={0}>
            <Tooltip title='Correct - these are duplicates'>
              <span>
                <IconButton onClick={() => setConfirmAcceptDialogOpenFor(row.id)} disabled={loading}>
                  <TickCircle variant='Bold' size='1rem' color={loading ? theme.palette.text.disabled : theme.palette.primary.main} />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title='Incorrect - these are both valid transactions'>
              <span>
                <IconButton onClick={() => setConfirmRejectDialogOpenFor({ transactionId: row.id, duplicateId: row.duplicateId })} disabled={loading}>
                  <CloseCircle variant='Bold' size='1rem' color={loading ? theme.palette.text.disabled : theme.palette.primary.main} />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title='View Transaction'>
              <span>
                <IconButton onClick={() => viewTransaction(row.id)} disabled={loading}>
                  <Eye variant='Bold' size='1rem' color={loading ? theme.palette.text.disabled : theme.palette.primary.main} />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title='View Duplicate Transaction'>
              <span>
                <IconButton onClick={() => viewTransaction(row.duplicateId)} disabled={loading}>
                  <Copy size='1rem' color={loading ? theme.palette.text.disabled : theme.palette.primary.main} />
                  <Eye
                    variant='Bold'
                    size='0.75rem'
                    color={loading ? theme.palette.text.disabled : theme.palette.primary.main}
                    style={{ position: 'absolute' }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  return (
    <Stack style={style}>
      <Stack direction='row' spacing={5}>
        <Search style={{ flex: 1 }} searchCriteria={searchCriteria} onSearchCriteriaUpdate={(criteria) => setSearchCriteria(criteria)} />
      </Stack>

      <DataGrid
        loading={!rows}
        experimentalFeatures={{ columnGrouping: true }}
        columnGroupingModel={[
          {
            groupId: 'Transaction',
            children: [{ field: 'name' }, { field: 'date' }, { field: 'type' }, { field: 'amount' }],
          },
          {
            groupId: 'Potential Existing Duplicate',
            children: [{ field: 'duplicateName' }, { field: 'duplicateDate' }, { field: 'duplicateType' }, { field: 'duplicateAmount' }],
          },
        ]}
        rows={rows || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
          sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }],
          },
        }}
        pageSizeOptions={[50]}
      />

      <ConfirmDialog
        open={!!confirmAcceptDialogOpenFor}
        onClose={() => setConfirmAcceptDialogOpenFor(null)}
        message='Are you sure you want to confirm that this transaction is a duplicate? This will result in the transaction being ignored.'
        onConfirm={async () => {
          await accept(confirmAcceptDialogOpenFor!);
          setConfirmAcceptDialogOpenFor(null);
        }}
      />

      <ConfirmDialog
        open={!!confirmRejectDialogOpenFor}
        onClose={() => setConfirmRejectDialogOpenFor(null)}
        message='Are you sure that this transaction is not a duplicate?'
        onConfirm={async () => {
          await reject(confirmRejectDialogOpenFor!.transactionId, confirmRejectDialogOpenFor!.duplicateId);
          setConfirmRejectDialogOpenFor(null);
        }}
      />

      <ConfirmDialog
        open={!!queuedTransactionAccept}
        onClose={() => setQueuedTransactionAccept(null)}
        content={
          <Stack>
            {queuedTransactionAccept?.errors.matchExists && (
              <Typography>
                This transaction is matched to one or more documents. Updating the transaction will unmatch it. Are you sure you want to continue?
              </Typography>
            )}
          </Stack>
        }
        onConfirm={async () => {
          const errorResolutions = {} as UpdateTransactionErrorResolutions;
          if (queuedTransactionAccept!.errors.matchExists) {
            errorResolutions.matchExists = 'unmatch';
          }

          await accept(queuedTransactionAccept!.transactionId, errorResolutions);

          setQueuedTransactionAccept(null);
        }}
      />
    </Stack>
  );
}
