import { CSSProperties } from 'react';
import { PlaidLinkError, PlaidLinkOnExitMetadata, PlaidLinkOnSuccessMetadata, usePlaidLink } from 'react-plaid-link';
import { Button } from './button.tsx';

export interface PlaidProps {
  token: string;
  buttonLabel: string;
  buttonVariant: 'text' | 'contained' | 'outlined';
  buttonColor: 'primary' | 'secondary' | 'neutral';
  buttonShade?: number;
  disabled?: boolean;
  onSuccess: (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => void;
  onExit: (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => void;
  style?: CSSProperties;
}

export function Plaid({ token, buttonLabel, buttonVariant, buttonColor, buttonShade, disabled, onSuccess, onExit, style }: PlaidProps) {
  const { open, ready } = usePlaidLink({
    token,
    onSuccess,
    onExit,
  });

  return (
    <>
      <Button
        variant={buttonVariant}
        color={buttonColor}
        shade={buttonShade || 500}
        onClick={() => (open as () => void)()}
        disabled={!ready || disabled}
        sx={style}
      >
        {buttonLabel}
      </Button>
    </>
  );
}
