import { Link as MuiLink } from '@mui/material';
import { forwardRef } from 'react';
import { LinkProps, Link as RouterLink } from 'react-router-dom';

export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
  const { children, ...rest } = props;
  return (
    <MuiLink component={RouterLink} ref={ref} {...rest}>
      {children}
    </MuiLink>
  );
});
