import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import { appEnv, initAppEnv } from './api';
import { initializeAxios } from './api/axios-base.ts';
import './index.css';

// eslint-disable-next-line @typescript-eslint/no-floating-promises
(async () => {
  await initAppEnv();

  initializeAxios();

  Sentry.init({
    dsn: appEnv === 'local' ? undefined : 'https://c779a6fb63744f4f9d1d175e15ea92d7@o4505387889590272.ingest.sentry.io/4505563525283840',
    environment: appEnv,
  });

  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
})();
