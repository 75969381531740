import { Close } from '@mui/icons-material';
import { alpha, Box, IconButton, useMediaQuery } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { forwardRef, MutableRefObject, Ref, useRef } from 'react';
import { LinkProps } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import { CSSTransition } from 'react-transition-group';
import styled, { keyframes } from 'styled-components';
import {
  LARGE_HORIZONTAL_SPACING,
  LARGE_VERTICAL_SPACING,
  MED_HORIZONTAL_SPACING,
  MED_VERTICAL_SPACING,
  SMALL_HORIZONTAL_SPACING,
  SMALL_VERTICAL_SPACING,
} from '../theme';
import { useThemeMode } from '../theme-mode-context';
import { ScreenSize, useScreenSize } from '../utils/use-screen-size';
import { AppShellHeader } from './app-shell-header';
import { LightDarkToggle } from './light-dark-toggle';
import { Link } from './link';
import { Logo } from './logo';

const PageWrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100dvh;

  display: flex;
  flex-direction: column;

  min-height: 0;
  min-width: 0;

  background: ${({ theme }) => theme.palette.background.default};

  padding-top: var(--safe-area-inset-top);
  padding-bottom: var(--safe-area-inset-bottom);
`;

const Grid = styled.article`
  flex: 1;
  min-height: 0;
  box-sizing: border-box;

  padding-bottom: ${({ theme }) => theme.spacing(LARGE_VERTICAL_SPACING)};
  padding-right: ${({ theme }) => theme.spacing(LARGE_HORIZONTAL_SPACING)};

  display: grid;
  grid-template-columns: repeat(12, 1fr);

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: repeat(4, 1fr);

    padding-bottom: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
    padding-left: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
    padding-right: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};

    grid-gap: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-template-columns: repeat(8, 1fr);

    padding-bottom: ${({ theme }) => theme.spacing(MED_VERTICAL_SPACING)};
    padding-left: ${({ theme }) => theme.spacing(MED_HORIZONTAL_SPACING)};
    padding-right: ${({ theme }) => theme.spacing(MED_HORIZONTAL_SPACING)};

    grid-gap: ${({ theme }) => theme.spacing(MED_HORIZONTAL_SPACING)};
  }
`;

const LeftNav = styled.aside`
  grid-column: span 2;

  display: flex;
  flex-direction: column;

  justify-content: center;
  min-height: 0;
  margin-bottom: ${({ theme }) => theme.spacing(LARGE_VERTICAL_SPACING)};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-bottom: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
    paddding-right: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    margin-bottom: ${({ theme }) => theme.spacing(MED_VERTICAL_SPACING)};
    padding-right: ${({ theme }) => theme.spacing(MED_HORIZONTAL_SPACING)};
  }
`;

const slideInAnimation = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
`;

const fadeInAnimation = keyframes`
  from {
    opacity: 0%;
  }
  to {
    opacity: 100%;
  }
`;

const fadeOutAnimation = keyframes`
  from {
    opacity: 100%;
  }
  to {
    opacity: 0%;
  }
`;

const FlyoutWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 999;

  &.slide-enter {
    overflow: hidden;
    animation: ${fadeInAnimation} 250ms forwards;

    > * {
      animation: ${slideInAnimation} 250ms forwards;
    }
  }

  &.slide-exit {
    overflow: hidden;
    animation: ${fadeOutAnimation} 100ms forwards;
  }
`;

const LeftNavFlyout = styled.aside`
  box-sizing: border-box;
  width: 80%;
  height: 100%;

  padding-top: calc(var(--safe-area-inset-top) + ${({ theme }) => theme.spacing(LARGE_VERTICAL_SPACING)});
  padding-bottom: calc(var(--safe-area-inset-bottom) + ${({ theme }) => theme.spacing(LARGE_VERTICAL_SPACING)});

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-top: calc(var(--safe-area-inset-top) + ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)});
    padding-bottom: calc(var(--safe-area-inset-bottom) + ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)});
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-top: calc(var(--safe-area-inset-top) + ${({ theme }) => theme.spacing(MED_VERTICAL_SPACING)});
    padding-bottom: calc(var(--safe-area-inset-bottom) + ${({ theme }) => theme.spacing(MED_VERTICAL_SPACING)});
  }

  display: flex;
  flex-direction: column;
  box-shadow: ${({ theme }) => theme.shadows[12]};

  background: ${({ theme }) => theme.palette.background.default};

  > *:not(:last-child) {
    margin-bottom: ${({ theme }) => theme.spacing(LARGE_VERTICAL_SPACING)};

    ${({ theme }) => theme.breakpoints.down('sm')} {
      margin-bottom: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
    }

    ${({ theme }) => theme.breakpoints.down('md')} {
      margin-bottom: ${({ theme }) => theme.spacing(MED_VERTICAL_SPACING)};
    }
  }
`;

const FlyoutHeader = ({ onClose }: { onClose: () => void }) => {
  const theme = useTheme();
  const screenSize = useScreenSize();

  let paddingX;
  if (screenSize === ScreenSize.SMALL) {
    paddingX = theme.spacing(SMALL_HORIZONTAL_SPACING);
  } else if (screenSize === ScreenSize.MEDIUM) {
    paddingX = theme.spacing(MED_HORIZONTAL_SPACING);
  } else {
    paddingX = theme.spacing(LARGE_HORIZONTAL_SPACING);
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: paddingX,
        paddingRight: paddingX,
      }}
    >
      <Logo style={{ height: theme.spacing(10), marginTop: '5px' }} />
      <IconButton onClick={onClose} style={{ padding: 0 }}>
        <Close style={{ color: theme.palette.text.primary }} />
      </IconButton>
    </Box>
  );
};

const Main = styled.main`
  grid-column: span 10;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-column: span 4;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-column: span 8;
  }

  display: flex;
  min-height: 0;
  flex: 1;
`;

const Content = styled.div`
  min-width: 0;
  min-height: 0;
  flex: 1;

  background: ${({ theme }) => theme.palette.background2.default};
  border-radius: ${({ theme }) => theme.roundedCorners(5)};
  border: ${({ theme }) => (theme.palette.mode === 'dark' ? 'none' : `1px solid ${theme.palette.border.main}`)};

  display: flex;
  flex-direction: column;
`;

export interface NavLinkProps extends LinkProps {
  active: boolean;
}

export const NavLink = styled(
  forwardRef((props: NavLinkProps, ref: Ref<HTMLAnchorElement>) => {
    const { active, ...rest } = props;
    return <Link ref={ref} {...rest} />;
  })
)`
  display: flex;
  align-items: center;
  font-weight: 500;
  text-decoration: none;

  padding-top: ${({ theme }) => theme.spacing(LARGE_VERTICAL_SPACING)};
  padding-bottom: ${({ theme }) => theme.spacing(LARGE_VERTICAL_SPACING)};
  padding-left: ${({ theme }) => theme.spacing(LARGE_HORIZONTAL_SPACING)};
  padding-right: ${({ theme }) => theme.spacing(LARGE_HORIZONTAL_SPACING)};

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-left: ${({ theme }) => theme.spacing(MED_HORIZONTAL_SPACING)};
    padding-right: ${({ theme }) => theme.spacing(MED_HORIZONTAL_SPACING)};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-left: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
    padding-right: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
  }

  background: ${({ theme, active }) => {
    return active ? alpha(theme.palette.primary.main, 0.08) : null;
  }};

  &:hover {
    background: ${({ theme, active }) => {
      return active ? alpha(theme.palette.primary.main, 0.12) : alpha(theme.palette.primary.main, 0.04);
    }};
  }

  color: ${({ theme, active }) => {
    return active ? theme.palette.primary.main : theme.palette.text.primary;
  }};
`;

const useSwipeClose = ({ flyoutRef, onClose }: { flyoutRef: MutableRefObject<HTMLDivElement | null>; onClose: () => void }) => {
  return useSwipeable({
    onSwiping: (e) => {
      if (!flyoutRef.current) {
        return;
      }

      const width = flyoutRef.current.clientWidth;
      const swipePercent = (100 * e.deltaX) / width;

      if (swipePercent < 0) {
        flyoutRef.current.style.transform = `translateX(${swipePercent}%)`;
      }
    },
    onSwipedLeft: (e) => {
      if (e.deltaX < -30) {
        onClose();
      } else if (flyoutRef.current) {
        flyoutRef.current.style.transform = `translateX(${0}%)`;
      }
    },
  });
};

export interface AppShellProps {
  pageTitle?: string;
  flyoutOpen: boolean;
  onFlyoutChange: (open: boolean) => void;
  leftNavContent: React.ReactNode;
  mainContent: React.ReactNode;
  authenticatedHeaderContent?: React.ReactNode;
}

export default function AppShell({ flyoutOpen, onFlyoutChange, leftNavContent, mainContent, authenticatedHeaderContent }: AppShellProps) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const nodeRef = useRef<HTMLDivElement | null>(null);
  const flyoutRef = useRef<HTMLDivElement | null>(null);
  const swipeHandlers = useSwipeClose({
    flyoutRef,
    onClose: () => onFlyoutChange(false),
  });

  const flyoutWrapperPassthroughRef = (el: HTMLDivElement | null) => {
    swipeHandlers.ref(el);

    nodeRef.current = el;
  };
  const { mode, setMode } = useThemeMode();

  return (
    <PageWrapper>
      <AppShellHeader authenticatedHeaderContent={authenticatedHeaderContent} onOpenFlyout={() => onFlyoutChange(true)} />
      <Grid>
        {!isSmallScreen && (
          <LeftNav theme={theme}>
            {leftNavContent}{' '}
            <LightDarkToggle
              mode={mode}
              onModeChange={(mode) => setMode(mode)}
              style={{
                alignSelf: 'center',
                marginTop: theme.spacing(5),
              }}
            />{' '}
          </LeftNav>
        )}
        {isSmallScreen && (
          <CSSTransition nodeRef={nodeRef} unmountOnExit timeout={250} classNames='slide' in={flyoutOpen}>
            <FlyoutWrapper onClick={() => onFlyoutChange(false)} {...swipeHandlers} ref={flyoutWrapperPassthroughRef}>
              <LeftNavFlyout onClick={(e) => e.stopPropagation()} ref={flyoutRef}>
                <FlyoutHeader onClose={() => onFlyoutChange(false)} />
                {leftNavContent}
                <LightDarkToggle
                  mode={mode}
                  onModeChange={(mode) => setMode(mode)}
                  style={{
                    alignSelf: 'center',
                    marginTop: theme.spacing(5),
                  }}
                />
              </LeftNavFlyout>
            </FlyoutWrapper>
          </CSSTransition>
        )}
        <Main>
          <Content>{mainContent}</Content>
        </Main>
      </Grid>
    </PageWrapper>
  );
}
