import { InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps, Stack, alpha, useTheme } from '@mui/material';
import { GridRenderCellParams, GridRenderEditCellParams, GridValidRowModel, useGridApiContext } from '@mui/x-data-grid';
import { ArrowDown2 } from 'iconsax-react';
import { ForwardedRef, forwardRef, useCallback, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { SortIntoTreeOrder, TreeInput } from '../utils/tree';

export interface TreeSelectItem extends TreeInput {
  name: string;
}

export type TreeSelectProps<T = unknown> = SelectProps<T> & {
  label?: string;
  noSelectionValue?: string;
  items: TreeSelectItem[];
  itemComparator: (v1: TreeSelectItem, v2: TreeSelectItem) => number;
  allowParentSelection?: boolean;
};

export function TreeSelect({ label, noSelectionValue, items, itemComparator, allowParentSelection, ...rest }: TreeSelectProps) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const labelId = useMemo(() => {
    return uuid();
  }, []);

  const treeData = useMemo(() => SortIntoTreeOrder(items, itemComparator), [items, itemComparator]);

  const parents = useMemo(() => {
    return items.reduce((set, current) => {
      if (current.parentId) {
        set.add(current.parentId);
      }
      return set;
    }, new Set<string>());
  }, [items]);

  return (
    <>
      {label && <InputLabel id={labelId}>{label}</InputLabel>}
      <Select label={label} labelId={label ? labelId : undefined} onOpen={() => setIsOpen(true)} onClose={() => setIsOpen(false)} {...rest}>
        {noSelectionValue && <MenuItem value={noSelectionValue}>None</MenuItem>}
        {treeData.map((i) => (
          <MenuItem key={i.id} value={i.id} disabled={!allowParentSelection && parents.has(i.id)}>
            <span style={{ paddingLeft: isOpen ? `calc(${theme.spacing(4)} * ${i.depth})` : undefined }}>{i.name}</span>
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

export interface TreeSelectDataGridCellViewProps {
  items: TreeSelectItem[];
  params: GridRenderCellParams<GridValidRowModel, TreeSelectItem | null>;
  valid?: boolean;
  disabled?: boolean;
}

export const TreeSelectDataGridCellView = forwardRef(
  ({ items, params, valid, disabled, ...rest }: TreeSelectDataGridCellViewProps, ref: ForwardedRef<HTMLDivElement>) => {
    const value = params.value;
    const theme = useTheme();

    const itemNameMap = useMemo(() => {
      return items.reduce((map, current) => {
        map.set(current.id, current.name);
        return map;
      }, new Map<string, string>());
    }, [items]);

    return (
      <Stack
        ref={ref}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        overflow='hidden'
        width='100%'
        height='100%'
        bgcolor={valid || valid === undefined ? undefined : alpha(theme.palette.error.main, 0.5)}
        padding={theme.spacing(2)}
        style={{
          cursor: disabled ? 'auto' : 'pointer',
          color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
        }}
        {...rest}
      >
        <span
          style={{
            flex: '1 1 0%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {value ? itemNameMap.get(value.id) : 'None'}
        </span>
        <ArrowDown2 variant='Bold' size='1rem' />
      </Stack>
    );
  }
);

export type TreeSelectDataGridCellEditProps<T = TreeSelectItem> = SelectProps<T> & {
  label?: string;
  noSelectionValue?: string;
  items: T[];
  itemComparator: (v1: T, v2: T) => number;
  onItemChange?: (item: T) => void;
  params: GridRenderEditCellParams<GridValidRowModel, T>;
};

export function TreeSelectDataGridCellEdit({
  noSelectionValue,
  label,
  items,
  itemComparator,
  onItemChange,
  params,
}: TreeSelectDataGridCellEditProps) {
  const { id, value, field } = params;
  const theme = useTheme();

  const labelId = useMemo(() => {
    return uuid();
  }, []);

  const treeData = useMemo(() => SortIntoTreeOrder(items, itemComparator), [items, itemComparator]);

  const parents = useMemo(() => {
    return items.reduce((set, current) => {
      if (current.parentId) {
        set.add(current.parentId);
      }
      return set;
    }, new Set<string>());
  }, [items]);

  const itemsById = useMemo(() => {
    return items.reduce(
      (map, current) => {
        map[current.id] = current;
        return map;
      },
      {} as { [itemId: string]: TreeSelectItem }
    );
  }, [items]);

  const apiRef = useGridApiContext();

  const change = useCallback(
    async (event: SelectChangeEvent) => {
      const newValue = event.target.value;
      const item = itemsById[newValue] || null;
      await apiRef.current.setEditCellValue({ id, field, value: item });
      apiRef.current.stopCellEditMode({ id, field });

      if (onItemChange) {
        onItemChange(item);
      }
    },
    [apiRef, field, id, itemsById, onItemChange]
  );

  return (
    <>
      {label && <InputLabel id={labelId}>{label}</InputLabel>}
      <Select
        open={true}
        value={value?.id || noSelectionValue}
        onChange={change}
        onClick={(e) => {
          const isBackdropClick = (e.target as HTMLElement).classList.contains('MuiModal-backdrop');
          if (isBackdropClick) {
            apiRef.current.stopCellEditMode({ id, field });
          }
        }}
      >
        {noSelectionValue && <MenuItem value={noSelectionValue}>None</MenuItem>}
        {treeData.map((i) => (
          <MenuItem key={i.id} value={i.id} disabled={parents.has(i.id)}>
            <span style={{ paddingLeft: `calc(${theme.spacing(4)} * ${i.depth})` }}>{i.name}</span>
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
