import { Avatar, IconButton, MenuList, Popover, Stack, useMediaQuery, useTheme } from '@mui/material';
import { HambergerMenu } from 'iconsax-react';
import { CSSProperties, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Role, Session, useSession } from '../api';
import {
  LARGE_HORIZONTAL_SPACING,
  LARGE_VERTICAL_SPACING,
  MED_HORIZONTAL_SPACING,
  MED_VERTICAL_SPACING,
  SMALL_HORIZONTAL_SPACING,
  SMALL_VERTICAL_SPACING,
} from '../theme';
import { storage } from '../utils/storage';
import { Button } from './button';
import { Link } from './link';
import { Logo } from './logo';
import { SignIn as SignInComponent } from './sign-in';
import { SignUp } from './sign-up';

interface CreateAccountProps {
  loading: boolean;
  signUpError?: string;
  verificationError?: string;
  open: boolean;
  verificationForEmail?: string;
  onOpen: () => void;
  onClose: () => void;
  onCreateAccount: (firstName: string, lastName: string, email: string, password: string) => void;
  onCreateDifferentAccount: () => void;
  onOpenSignIn: () => void;
  onOpenVerify: () => void;
  onResendVerification: () => void;
  onVerify: (email: string, verificationCode: string) => void;
  awaitingVerification: boolean;
}
const CreateAccount = ({
  loading,
  signUpError,
  verificationError,
  open,
  verificationForEmail,
  onCreateAccount,
  onCreateDifferentAccount,
  onOpen,
  onClose,
  onOpenSignIn,
  onOpenVerify,
  onResendVerification,
  awaitingVerification,
  onVerify,
}: CreateAccountProps) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const openSignIn = () => {
    onOpenSignIn();
    onClose();
  };

  return (
    <>
      <Button variant='contained' color='primary' corners='round' ref={buttonRef} onClick={onOpen}>
        {awaitingVerification ? 'Verify account' : 'Create account'}
      </Button>
      <Popover
        open={open}
        onClose={onClose}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <SignUp
          loading={loading}
          signUpError={signUpError}
          verificationError={verificationError}
          forEmail={verificationForEmail}
          onClose={onClose}
          onSignUp={(signUpData) => {
            const { firstName, lastName, email, password } = signUpData;

            onCreateAccount(firstName, lastName, email, password);
          }}
          onOpenSignIn={openSignIn}
          onOpenVerify={onOpenVerify}
          onResendVerificationCode={onResendVerification}
          onCreateDifferentAccount={onCreateDifferentAccount}
          awaitingVerification={awaitingVerification}
          onVerify={onVerify}
        />
      </Popover>
    </>
  );
};

interface SignInProps {
  loading: boolean;
  signInError?: string;
  open: boolean;
  onOpen: () => void;
  onClose: () => void;
  onSignIn: (email: string, password: string) => void;
  onOpenSignUp: () => void;
  onResetPassword: (email: string) => void;
}
const SignIn = ({ loading, signInError, open, onOpen, onClose, onSignIn, onOpenSignUp, onResetPassword }: SignInProps) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const openSignUp = () => {
    onClose();
    onOpenSignUp();
  };

  return (
    <>
      <Button variant='contained' color='neutral' shade={300} corners='round' ref={buttonRef} onClick={onOpen}>
        Sign in
      </Button>
      <Popover
        open={open}
        onClose={onClose}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <SignInComponent
          loading={loading}
          signInError={signInError}
          onClose={onClose}
          onSignIn={onSignIn}
          onOpenSignUp={openSignUp}
          onResetPassword={onResetPassword}
        />
      </Popover>
    </>
  );
};

export interface AvatarMenuProps {
  session: Session;
  menuItems: (closeMenu: () => void) => React.ReactNode[];
}
export const AvatarMenu = ({ session, menuItems }: AvatarMenuProps) => {
  const theme = useTheme();
  const avatarRef = useRef<HTMLDivElement | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Avatar
        ref={avatarRef}
        alt={`${session.firstName!} ${session.lastName!}`}
        style={{
          cursor: 'pointer',
          background: theme.palette.accent1.main,
          fontSize: '1rem',
          width: isSmallScreen ? '2rem' : '2.125rem',
          height: isSmallScreen ? '2rem' : '2.125rem',
          outline: `1px solid ${theme.palette.accent1.main}`,
          outlineOffset: '3px',
          margin: '4px',
        }}
        onClick={() => setMenuOpen(true)}
      >
        {`${session.firstName!.charAt(0).toUpperCase()}${session.lastName!.charAt(0).toUpperCase()}`}
      </Avatar>
      <Popover
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        anchorEl={avatarRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
      >
        <MenuList>{menuItems(() => setMenuOpen(false))}</MenuList>
      </Popover>
    </>
  );
};

const Header = styled.header<{ session: Session | null | undefined }>`
  display: grid;
  grid-template-columns: 1fr 1fr;

  > *:first-child {
    justify-self: start;
  }

  > *:last-child {
    justify-self: end;
  }

  padding-left: ${({ theme }) => theme.spacing(LARGE_HORIZONTAL_SPACING)};
  padding-right: ${({ theme }) => theme.spacing(LARGE_HORIZONTAL_SPACING)};
  padding-top: ${({ theme }) => theme.spacing(LARGE_VERTICAL_SPACING)};
  padding-bottom: ${({ theme }) => theme.spacing(LARGE_VERTICAL_SPACING)};
  align-items: center;

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-template-columns: ${({ session }) => (session ? '1fr 1fr 1fr' : '1fr 2fr')};
    padding-left: ${({ theme }) => theme.spacing(MED_HORIZONTAL_SPACING)};
    padding-right: ${({ theme }) => theme.spacing(MED_HORIZONTAL_SPACING)};
    padding-top: ${({ theme }) => theme.spacing(MED_VERTICAL_SPACING)};
    padding-bottom: ${({ theme }) => theme.spacing(MED_VERTICAL_SPACING)};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: ${({ session }) => (session ? '1fr 1fr 1fr' : '1fr 2fr')};
    padding-left: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
    padding-right: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
    padding-top: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
    padding-bottom: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
  }
`;

const OtterLogoLink = () => {
  const theme = useTheme();

  return (
    <Link to='/'>
      <Logo style={{ height: theme.spacing(10), marginTop: '5px', width: '100%' }} />
    </Link>
  );
};

export function AppShellHeader({
  onOpenFlyout,
  authenticatedHeaderContent,
}: {
  onOpenFlyout: () => void;
  authenticatedHeaderContent: React.ReactNode;
  style?: CSSProperties;
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { session, createRegisteredUser, startRegisteredSession, verifyAccount, resendVerificationCode, sendResetPasswordRequest } = useSession();

  const [signUpOpen, setSignUpOpen] = useState(false);
  const [signInOpen, setSignInOpen] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('modal')) {
      if (searchParams.get('modal') === 'sign-in') {
        setSignInOpen(true);
      }

      if (searchParams.get('modal') === 'create-account') {
        setSignUpOpen(true);
      }
    }
  }, [location]);

  const [awaitingVerification, setAwaitingVerification] = useState(false);
  const [emailAwaitingVerification, setEmailAwaitingVerification] = useState<string | null>(null);

  useEffect(() => {
    const checkAwaitingVerification = async () => {
      const emailAwaitingVerification = await storage.getItem('emailAwaitingVerification');

      setAwaitingVerification(!!emailAwaitingVerification);
      setEmailAwaitingVerification(emailAwaitingVerification || null);
    };

    checkAwaitingVerification().catch((e) => {
      throw e;
    });
  }, []);

  useEffect(() => {
    if (emailAwaitingVerification) {
      setSignUpOpen(true);
    }
  }, [emailAwaitingVerification]);

  const removeEmailAwaitingVerification = async () => {
    setAwaitingVerification(false);
    setEmailAwaitingVerification(null);
    await storage.removeItem('emailAwaitingVerification');
  };

  const updateEmailAwaitingVerification = async (email: string) => {
    setEmailAwaitingVerification(email);
    setAwaitingVerification(true);
    await storage.setItem('emailAwaitingVerification', email);
  };

  const [loading, setLoading] = useState(false);
  const [signUpError, setSignUpError] = useState<string | undefined>(undefined);
  const [signInError, setSignInError] = useState<string | undefined>(undefined);
  const [verificationError, setVerificationError] = useState<string | undefined>(undefined);

  const shouldRedirect = useRef<string | null>(null);

  const createAccount = async (firstName: string, lastName: string, email: string, password: string) => {
    setLoading(true);
    try {
      await createRegisteredUser(firstName, lastName, email, password);
      await updateEmailAwaitingVerification(email);
    } catch (e) {
      const error = e as { response: { status: number; data: { reason: string } } };
      if (error.response.status === 400) {
        setSignUpError(error.response.data.reason);
      }
    } finally {
      setLoading(false);
    }
  };

  const verify = async (email: string, verificationCode: string) => {
    setLoading(true);
    try {
      const sendVerification = async () => {
        await verifyAccount(email, verificationCode);
        await removeEmailAwaitingVerification();
        setSignUpOpen(false);
        shouldRedirect.current = '/onboarding';
        setLoading(false);
      };

      // For some reason, UX is better with an arbitrary wait
      await new Promise<void>((resolve, reject) => {
        setTimeout(() => {
          sendVerification()
            .then(() => {
              resolve();
            })
            .catch((e) => {
              reject(e);
            });
        }, 1000);
      });
    } catch (e) {
      const error = e as { response: { status: number; data: { reason: string } } };
      if (error.response.status === 401) {
        setVerificationError(error.response.data.reason);
        shouldRedirect.current = '/?modal=sign-in';
      }
      setLoading(false);
    }
  };

  const signIn = async (email: string, password: string) => {
    setLoading(true);
    try {
      await startRegisteredSession(email, password);
      await removeEmailAwaitingVerification();
      setSignInOpen(false);

      const searchParams = new URLSearchParams(location.search);
      if (searchParams.has('returnTo')) {
        shouldRedirect.current = searchParams.get('returnTo')!;
      }
    } catch (e) {
      setSignInError('Email or password is invalid.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!session) {
      return;
    }

    if (session.role === Role.ADMIN && !location.pathname.startsWith('/admin')) {
      navigate('/admin');
      return;
    }

    if (!shouldRedirect.current) {
      return;
    }

    const redirect = shouldRedirect.current;
    shouldRedirect.current = null;

    navigate(redirect);
  }, [session, shouldRedirect, navigate, location]);

  const resetPassword = async (email: string) => {
    setLoading(true);
    try {
      await sendResetPasswordRequest(email);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Header session={session}>
      {isSmallScreen && (
        <IconButton onClick={onOpenFlyout}>
          <HambergerMenu size='2rem' color={theme.palette.text.primary} />
        </IconButton>
      )}
      {(!isSmallScreen || session) && <OtterLogoLink />}
      <Stack spacing={5} direction='row' justifyContent='end' alignItems='center' style={{ color: '#fff' }}>
        {session && session.isRegistered && session.isVerified && authenticatedHeaderContent}
        {(!session || !session.isRegistered || !session.isVerified) && (
          <>
            <CreateAccount
              loading={loading}
              signUpError={signUpError}
              verificationError={verificationError}
              verificationForEmail={emailAwaitingVerification || undefined}
              awaitingVerification={awaitingVerification}
              open={signUpOpen}
              onOpen={() => setSignUpOpen(true)}
              onClose={() => setSignUpOpen(false)}
              onCreateAccount={createAccount}
              onCreateDifferentAccount={removeEmailAwaitingVerification}
              onOpenSignIn={() => setSignInOpen(true)}
              onOpenVerify={() => setAwaitingVerification(true)}
              onResendVerification={() => resendVerificationCode(emailAwaitingVerification!)}
              onVerify={verify}
            />
            <SignIn
              loading={loading}
              signInError={signInError}
              open={signInOpen}
              onOpen={() => setSignInOpen(true)}
              onClose={() => setSignInOpen(false)}
              onSignIn={signIn}
              onOpenSignUp={() => setSignUpOpen(true)}
              onResetPassword={resetPassword}
            />
          </>
        )}
      </Stack>
    </Header>
  );
}
