import { Chip, Dialog, DialogActions, DialogContent, DialogTitle, Link, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import styled from 'styled-components';
import { OrganizationType, useSession } from '../api';
import { LARGE_VERTICAL_SPACING, MED_VERTICAL_SPACING, SMALL_VERTICAL_SPACING } from '../theme';
import { Button } from './button';
import { DocumentEmailAddress } from './document-email-address';

const TipsTable = styled.table`
  border-collapse: separate;

  border-spacing: 20px; //${({ theme }) => theme.spacing(LARGE_VERTICAL_SPACING)};

  ${({ theme }) => theme.breakpoints.down('sm')} {
    border-spacing: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    border-spacing: ${({ theme }) => theme.spacing(MED_VERTICAL_SPACING)};
  }
`;

export interface DocumentTipsDialogProps {
  open: boolean;
  onClose: () => void;
}
export function DocumentTipsDialog({ open, onClose }: DocumentTipsDialogProps) {
  const { organization } = useSession();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: isSmallScreen ? '' : theme.spacing(150),
        },
      }}
    >
      <DialogTitle>
        <Stack
          textAlign='center'
          alignItems='center'
          borderRadius={theme.roundedCorners(5)}
          bgcolor={theme.palette.common.black}
          color='#fff'
          padding={theme.spacing(5)}
        >
          Helpful Tips for Sending Receipts & Invoices
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack>
          <Typography>
            Sending us your invoices and receipts is effortless, and we've got some tips to make it even smoother. Whether you're sending them via
            email or uploading them from your account, here are some tips to make this a breeze.
          </Typography>
          <TipsTable>
            <tbody>
              <tr>
                {!isSmallScreen && <td></td>}
                <td>
                  <Chip size='small' label='Tip 1' style={{ background: theme.palette.accent2[100], color: theme.palette.common.black }} />
                </td>
              </tr>
              <tr>
                {!isSmallScreen && (
                  <td>
                    <img src='/mail.png' alt='Mail' width='100px' />
                  </td>
                )}
                <td>
                  <Typography variant='h3' color={theme.palette.primary.main}>
                    Your company's invoices and receipts can be sent to Otter in many ways:
                  </Typography>
                  <Typography>Use your phone to snap a picture, scan your documents, or forward emails with attachments.</Typography>

                  <DocumentEmailAddress
                    type='documents'
                    style={{
                      maxWidth: isSmallScreen ? '14rem' : '18rem',
                      marginTop: theme.spacing(2),
                    }}
                  />

                  {organization?.type === OrganizationType.STARTUP && (
                    <DocumentEmailAddress
                      type='reimbursements'
                      style={{
                        maxWidth: isSmallScreen ? '14rem' : '18rem',
                        marginTop: theme.spacing(2),
                      }}
                    />
                  )}
                </td>
              </tr>

              <tr>
                {!isSmallScreen && <td></td>}

                <td>
                  <Chip size='small' label='Tip 2' style={{ background: theme.palette.accent2[100], color: theme.palette.common.black }} />
                </td>
              </tr>
              <tr>
                {!isSmallScreen && (
                  <td>
                    <img src='/receipts.png' alt='Recipts' width='100px' />
                  </td>
                )}
                <td>
                  <Typography variant='h3' color={theme.palette.primary.main}>
                    Separate is great:
                  </Typography>
                  <Typography>
                    If you've got an itemized receipt showing what you bought and another confirming payment, send each as separate pictures please.
                  </Typography>
                </td>
              </tr>

              <tr>
                {!isSmallScreen && <td></td>}
                <td>
                  <Chip size='small' label='Tip 3' style={{ background: theme.palette.accent2[100], color: theme.palette.common.black }} />
                </td>
              </tr>
              <tr>
                {!isSmallScreen && (
                  <td>
                    <img src='/formats.png' alt='Formats' width='100px' />
                  </td>
                )}
                <td>
                  <Typography variant='h3' color={theme.palette.primary.main}>
                    Format options:
                  </Typography>
                  <Typography>Send your docs in JPEG, PNG, HEIC, or PDF format.</Typography>
                </td>
              </tr>

              <tr>
                {!isSmallScreen && <td></td>}
                <td>
                  <Chip size='small' label='Tip 4' style={{ background: theme.palette.accent2[100], color: theme.palette.common.black }} />
                </td>
              </tr>
              <tr>
                {!isSmallScreen && (
                  <td>
                    <img src='/crystal-clear.png' alt='Crystal Clear' width='100px' />
                  </td>
                )}
                <td>
                  <Typography variant='h3' color={theme.palette.primary.main}>
                    Crystal clear:
                  </Typography>
                  <Typography>
                    Make sure the image is clear and shows all the juicy details. We might ask for a resend if things are a bit fuzzy.
                  </Typography>
                </td>
              </tr>
            </tbody>
          </TipsTable>

          <Typography alignSelf='center' textAlign='center' color={theme.palette.neutral.main}>
            If you have any questions or need help, just shoot us a message at <Link href='mailto:support@otter.ca'>support@otter.ca</Link> or message
            us on Slack.
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant='contained'
          color='neutral'
          onClick={() => {
            onClose();
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
