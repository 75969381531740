import axios, { AxiosInstance } from 'axios';
import { appEnv, isMobile, platform } from './app-env';
import { TimeZoneInterceptor } from './axios-time-zone-interceptor';

let axiosBase: AxiosInstance;

function initializeAxios() {
  axiosBase = axios.create();

  axiosBase.defaults.withCredentials = !isMobile;

  if (appEnv === 'local') {
    if (platform === 'android') {
      axiosBase.defaults.baseURL = 'http://10.0.2.2:8080';
    } else {
      axiosBase.defaults.baseURL = 'http://localhost:8080';
    }
  } else if (platform === 'ios' || platform === 'android') {
    axiosBase.defaults.baseURL = `https://api-app.otter.ca`;
  } else {
    axiosBase.defaults.baseURL = `https://api-${window.location.host}`;
  }

  axiosBase.interceptors.request.use(TimeZoneInterceptor, (error) => Promise.reject(error));
}

export { axiosBase as axios, initializeAxios };
