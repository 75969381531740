import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';

let appEnv: string;
let isMobile: boolean;
let platform: string;

async function initAppEnv() {
  const info = await Device.getInfo();

  platform = Capacitor.getPlatform();

  if (platform === 'web') {
    isMobile = false;
  } else {
    isMobile = true;
  }

  if ((platform === 'web' && window.location.host.includes('localhost')) || info.isVirtual) {
    appEnv = 'local';
  } else if (platform !== 'web') {
    appEnv = 'app';
  } else {
    appEnv = `${window.location.host.split('.')[0]}`;
  }
}

export { appEnv, initAppEnv, isMobile, platform };
