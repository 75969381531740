import { Box, Fade, Stack, Typography, useTheme } from '@mui/material';
import { MutableRefObject, RefObject, forwardRef, useEffect, useRef } from 'react';
import { TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { Conversation, ConversationMessage as IConversationMessage } from '../api';
import { LARGE_VERTICAL_SPACING, MED_HORIZONTAL_SPACING, MED_VERTICAL_SPACING, SMALL_HORIZONTAL_SPACING, SMALL_VERTICAL_SPACING } from '../theme';
import { ConversationMessage } from './conversation-message';

export interface ConversationMessageContainerProps {
  loading?: boolean;
  conversation: Conversation;
  messages: IConversationMessage[];
  streaming: boolean;
  messageContainerRef: HTMLDivElement | null;
  messageRefs: { [messageId: string]: RefObject<HTMLDivElement> };
  messagesWrapperRef: HTMLDivElement | null;
  scrollLock: MutableRefObject<boolean>;
  scrollLockMutable: RefObject<boolean>;
  children?: React.ReactNode;
}
const MessageContainerBase = forwardRef<HTMLDivElement, { messages: IConversationMessage[]; children?: React.ReactNode }>(
  ({ messages, ...props }, ref) => {
    return <div ref={ref} {...props} />;
  }
);
const MessageContainer = styled(MessageContainerBase)`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: ${({ messages }) => (messages.length === 0 ? 'center' : 'start')};

  padding-left: ${({ theme }) => theme.spacing(50)};
  padding-right: ${({ theme }) => theme.spacing(50)};
  margin-bottom: ${({ theme }) => theme.spacing(LARGE_VERTICAL_SPACING)};

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-left: ${({ theme }) => theme.spacing(MED_HORIZONTAL_SPACING)};
    padding-right: ${({ theme }) => theme.spacing(MED_HORIZONTAL_SPACING)};
    margin-top: ${({ theme }) => theme.spacing(MED_VERTICAL_SPACING)};
    margin-bottom: ${({ theme }) => theme.spacing(MED_VERTICAL_SPACING)};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-left: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
    padding-right: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
    margin-top: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
    margin-bottom: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
  }

  overflow-y: auto;
  overflow-x: hidden;
  min-height: 0;
`;

const useInitialScrollPosition = (
  conversation: Conversation,
  messages: IConversationMessage[],
  messageContainerRef: HTMLDivElement | null,
  messageRefs: { [messageId: string]: RefObject<HTMLDivElement> }
) => {
  const initialized = useRef<string | null>(null);
  useEffect(() => {
    let messageRefsExist = true;
    for (const message of messages) {
      if (!messageRefs[message.id]) {
        messageRefsExist = false;
      }
    }

    if (initialized.current === conversation.id || !messageContainerRef || !messageRefsExist || !messages.length) {
      return;
    }

    initialized.current = conversation.id;

    if (!conversation.lastViewed) {
      setTimeout(() => {
        if (messageContainerRef) {
          messageContainerRef.scrollTop = 0;
        }
      }, 100);
      return;
    }

    let lastViewedMessage: IConversationMessage | null = null;
    for (let i = messages.length - 1; i >= 0; i--) {
      const message = messages[i];

      if (message.created <= conversation.lastViewed) {
        lastViewedMessage = message;
        break;
      }
    }

    if (lastViewedMessage && lastViewedMessage !== messages[messages.length - 1]) {
      setTimeout(() => {
        messageRefs[lastViewedMessage.id].current?.scrollIntoView({
          behavior: 'auto', // Smooth scrolling
          block: 'start', // Center the item in the view
        });
      }, 100);
    }
  }, [conversation, messages, messageContainerRef, messageRefs]);
};

const useScrollLock = (
  scrollLock: MutableRefObject<boolean>,
  scrollLockMutable: RefObject<boolean>,
  messagesWrapperRef: HTMLDivElement | null,
  messageContainerRef: HTMLDivElement | null,
  messageStreaming: boolean
) => {
  useEffect(() => {
    if (!messageContainerRef || messageStreaming) {
      return;
    }

    const listener = () => {
      const { scrollTop, clientHeight, scrollHeight } = messageContainerRef;

      if (scrollLockMutable.current) {
        scrollLock.current = Math.abs(scrollHeight - (scrollTop + clientHeight)) < 10;
      }
    };

    const ref = messageContainerRef;

    ref.addEventListener('scroll', listener);

    return () => {
      ref.removeEventListener('scroll', listener);
    };
  }, [messageContainerRef, scrollLock, scrollLockMutable, messageStreaming]);

  const prevHeight = useRef(0);
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      if (!messageContainerRef || !messagesWrapperRef) {
        return;
      }

      for (const entry of entries) {
        if (entry.target !== messagesWrapperRef) {
          continue;
        }

        const height = entry.contentRect.height;
        if (height !== prevHeight.current) {
          prevHeight.current = height;

          if (scrollLock.current) {
            messageContainerRef.scrollTop = messageContainerRef.scrollHeight;
          }
        }
      }
    });

    if (messagesWrapperRef) {
      resizeObserver.observe(messagesWrapperRef);
    }

    return () => {
      if (messagesWrapperRef) {
        resizeObserver.unobserve(document.documentElement);
      }
      resizeObserver.disconnect();
    };
  }, [messageContainerRef, messagesWrapperRef, scrollLock]);
};

export const ConversationMessageContainer = forwardRef<HTMLDivElement, ConversationMessageContainerProps>(
  ({ scrollLock, scrollLockMutable, conversation, messages, streaming, messageContainerRef, messagesWrapperRef, messageRefs, children }, ref) => {
    useInitialScrollPosition(conversation, messages, messageContainerRef, messageRefs);

    useScrollLock(scrollLock, scrollLockMutable, messagesWrapperRef, messageContainerRef, streaming);

    return (
      <MessageContainer messages={messages} ref={ref}>
        {children}
      </MessageContainer>
    );
  }
);

const ConversationEndIndicator = forwardRef((_props, ref) => {
  const theme = useTheme();

  return (
    <Box
      ref={ref}
      style={{
        borderRadius: theme.roundedCorners(5),
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        marginTop: theme.spacing(20),
        background: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.neutral[300],
        alignSelf: 'center',
      }}
    >
      <Typography>This conversation has ended.</Typography>
    </Box>
  );
});

export interface ConversationMessagesProps {
  conversation: Conversation;
  messages: IConversationMessage[];
  messageRefs: { [messageId: string]: RefObject<HTMLDivElement> };
  addMessage?: (message: string) => Promise<void>;
}

export const ConversationMessages = forwardRef<HTMLDivElement, ConversationMessagesProps>(({ conversation, messages, messageRefs }, ref) => {
  const conversationMessages = messages
    .filter((message) => !message.isHidden)
    .map((message, idx) => (
      <Fade timeout={{ enter: 1000, exit: 0 }} key={message.id} mountOnEnter unmountOnExit>
        <ConversationMessage message={message} index={idx} ref={messageRefs[message.id]} />
      </Fade>
    ));

  if (conversation.ended) {
    conversationMessages.push(
      <Fade timeout={{ enter: 1000, exit: 0 }} key='end-of-conversation-indicator' mountOnEnter unmountOnExit>
        <ConversationEndIndicator />
      </Fade>
    );
  }

  return (
    <Stack ref={ref}>
      <TransitionGroup component={null}>{conversationMessages}</TransitionGroup>
    </Stack>
  );
  // return <TransitionGroup component={Stack} nodeRef={ref}>{conversationMessages}</TransitionGroup>;
});
