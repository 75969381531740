import { createContext, MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { axios } from './axios-base';
import {
  addToConversation,
  AuthorType,
  Conversation as IConversation,
  ConversationMessage as IConversationMessage,
  MessageType,
  RenderType,
} from './conversation-stream';
import { useSession } from './session';

export { AuthorType, RenderType } from './conversation-stream';

interface SerializedConversationMessage {
  id: string;
  author: string;
  authorType: AuthorType;
  renderType: RenderType;
  content: string;
  messageType: MessageType;
  hidden?: boolean;
  created: string;
}
export interface ConversationMessage extends IConversationMessage {
  created: Date;
}

export interface SerializedConversation {
  id: string;
  title: string;
  created: string;
  updated: string;
  messages: SerializedConversationMessage[];
  lastViewed: string | null;
  latestMessage?: SerializedConversationMessage;
  strategyName?: string;
  strategyState?: string;
  ended: boolean;
}
export interface Conversation extends IConversation<ConversationMessage> {
  title: string;
  created: Date;
  updated: Date;
  messages: ConversationMessage[];
  lastViewed: Date | null;
  latestMessage?: ConversationMessage;
  strategyName?: string;
  strategyState?: string;
  ended: boolean;
}

export interface IConversationContext {
  scrollLockMutable: MutableRefObject<boolean>;
  scrollLock: MutableRefObject<boolean>;
  conversations: Record<string, Conversation>;
  conversationData: Record<string, object>;
  fetchConversation: (id: string, overwriteCache?: boolean) => Promise<void>;
  fetchConversations: () => Promise<void>;
  fetchConversationData: (conversationId: string) => Promise<void>;
  conversationStreaming: boolean;
  interruptConversationStreaming: () => void;
  addConversationMessage: (args: {
    conversationId: string;
    message: string;
    strategy?: string;
    includeMessageInConversation?: boolean;
    beforeStructuredMessageAddition?: () => void;
  }) => Promise<void>;
  markConversationViewed: (conversationId: string) => Promise<void>;
  hasConversationBeenViewed: (conversation: Conversation) => boolean;
  conversationId: string | null;
  setConversationId: (conversationId: string) => void;
}

export function deserializeMessage(message: SerializedConversationMessage) {
  return {
    id: message.id,
    author: message.author,
    authorType: message.authorType,
    renderType: message.renderType,
    content: message.content,
    messageType: message.messageType,
    hidden: message.hidden,
    created: new Date(message.created),
  };
}

export function deserializeConversation(conversation: SerializedConversation) {
  const converted: Conversation = {
    id: conversation.id,
    title: conversation.title,
    created: new Date(conversation.created),
    updated: new Date(conversation.updated),
    messages: conversation.messages.map(deserializeMessage),
    lastViewed: conversation.lastViewed ? new Date(conversation.lastViewed) : null,
    latestMessage: conversation.latestMessage ? deserializeMessage(conversation.latestMessage) : undefined,
    strategyName: conversation.strategyName,
    strategyState: conversation.strategyState,
    ended: conversation.ended,
  };
  return converted;
}

function mergeConversationToConversations(conversations: Record<string, Conversation>, conversation: Conversation) {
  if (conversations[conversation.id]) {
    return {
      ...conversations,
      [conversation.id]: {
        ...conversations[conversation.id],
        title: conversation.title,
        created: conversation.created,
        updated: conversation.updated,
        lastViewed: conversation.lastViewed,
        latestMessage: conversation.latestMessage,
        strategyName: conversation.strategyName,
        ended: conversation.ended,
      },
    };
  }

  return {
    ...conversations,
    [conversation.id]: conversation,
  };
}

export function useConversationData() {
  const { session, sessionDiff, token } = useSession();
  const scrollLockMutable = useRef(true);
  const scrollLock = useRef(true);
  const [conversationStreaming, setConversationStreaming] = useState(false);
  const [conversations, setConversations] = useState({} as Record<string, Conversation>);
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [conversationData, setConversationData] = useState<Record<string, object>>({});
  const conversationStreamingInterrupt = useRef(new AbortController());

  useEffect(() => {
    if (sessionDiff.old && !sessionDiff.new) {
      setConversations({});
      setConversationId(null);
    }
  }, [sessionDiff]);

  const interruptConversationStreaming = useCallback(() => {
    conversationStreamingInterrupt.current.abort();
    conversationStreamingInterrupt.current = new AbortController();
  }, []);

  const fetchConversation = useCallback(
    async (id: string, overwriteCache = false) => {
      if (!session) {
        return;
      }

      try {
        const conversationResponse = await axios.get(`/conversations/${id}`);

        const conversation = conversationResponse.data as SerializedConversation;
        const deserialized = deserializeConversation(conversation);

        setConversations((conversations) => {
          if (overwriteCache) {
            const withoutCached = {
              ...conversations,
            };

            delete withoutCached[id];

            return mergeConversationToConversations(withoutCached, deserialized);
          } else {
            return mergeConversationToConversations(conversations, deserialized);
          }
        });
      } catch (e) {
        const error = e as { response: { status: number; data: { reason: string } } };
        //404s are expected
        if (error.response.status !== 404) {
          throw e;
        }
      }
    },
    [session]
  );

  const fetchConversations = useCallback(async () => {
    if (!session) {
      return;
    }

    const conversationResponse = await axios.get(`/conversations`);

    const conversations = conversationResponse.data as { conversations: SerializedConversation[] };
    const deserializedConversations = conversations.conversations.map(deserializeConversation);

    setConversations((existingConversationsMap) => {
      let newMap = {
        ...existingConversationsMap,
      };

      for (const conversation of deserializedConversations) {
        newMap = mergeConversationToConversations(newMap, conversation);
      }

      return newMap;
    });
  }, [session]);

  const fetchConversationData = useCallback(
    async (conversationId: string) => {
      if (!session) {
        return;
      }

      const dataResponse = await axios.get(`/conversations/${conversationId}/data`);

      const data = dataResponse.data as object;

      setConversationData((existing) => ({
        ...existing,
        [conversationId]: data,
      }));
    },
    [session]
  );

  const addConversationMessage = useCallback(
    async (args: { conversationId: string; message: string; strategy?: string; includeMessageInConversation?: boolean }) => {
      if (!session || !token) {
        return;
      }

      const { conversationId, message, strategy, includeMessageInConversation } = args;
      const url = axios.defaults.baseURL! + `/conversations/${conversationId}`;
      let additionalRequestData = {};
      if (strategy) {
        additionalRequestData = {
          strategyName: strategy,
        };
      }
      await addToConversation({
        url,
        session,
        token: token.token,
        csrf: session.csrf,
        conversation: conversations[conversationId] || {
          id: conversationId,
          title: '',
          strategyName: strategy,
          messages: [],
        },
        message,
        onStreamingStart: () => setConversationStreaming(true),
        onStreamingEnd: () => setConversationStreaming(false),
        onStreamingSuccess: () => {
          Promise.all([fetchConversation(conversationId), fetchConversationData(conversationId)]).catch((e) => {
            throw e;
          });
        },
        onConversationUpdated: (updatedConversation) => {
          setConversations((conversations) => {
            return {
              ...conversations,
              [conversationId]: updatedConversation,
            };
          });
        },
        onConversationDataUpdated: (data: object) => {
          setConversationData((existing) => ({
            ...existing,
            [conversationId]: {
              ...existing[conversationId],
              ...data,
            },
          }));
        },
        additionalRequestData,
        interrupt: conversationStreamingInterrupt.current.signal,
        includeMessageInConversation,
      });
    },
    [token, conversations, session, fetchConversation, fetchConversationData]
  );

  const markConversationViewed = useCallback(
    async (conversationId: string) => {
      await axios.post(`/conversations/${conversationId}/mark-viewed`);
      await fetchConversation(conversationId);
    },
    [fetchConversation]
  );

  const hasConversationBeenViewed = useCallback((conversation: Conversation) => {
    if (!conversation.lastViewed || !conversation.latestMessage) {
      return false;
    }

    if (!conversation.messages || !conversation.messages.length) {
      return true;
    }

    return conversation.lastViewed >= conversation.latestMessage.created;
  }, []);

  const conversationContext: IConversationContext = {
    conversations,
    conversationData,
    fetchConversation,
    fetchConversations,
    fetchConversationData,
    conversationStreaming,
    interruptConversationStreaming,
    addConversationMessage,
    markConversationViewed,
    hasConversationBeenViewed,
    conversationId,
    setConversationId,
    scrollLock,
    scrollLockMutable,
  };

  return conversationContext;
}

export const ConversationContext = createContext<IConversationContext>({} as IConversationContext);

export const useConversation = () => useContext(ConversationContext);

export const ConversationContextProvider = ({ children }: { children: React.ReactNode }) => {
  const conversation = useConversationData();

  return <ConversationContext.Provider value={conversation}>{children}</ConversationContext.Provider>;
};
