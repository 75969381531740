import { Avatar, Box, Card, CardContent, Stack, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { useConversation } from '../../api';
import { Button } from '../button';
import { IWidgetProps } from './IWidgetProps';
import { PlaidLinkWidget } from './plaid-link';

interface Account {
  name: string;
  currentBalance: number;
  id: string;
  currency: string;
  mask: string;
}

interface BankAccountSummaryWidgetProps extends IWidgetProps {
  accounts?: Account[];
}

const getInitials = (name: string) => {
  const spl = name.split(' ');
  return spl.map((spl) => spl[0]).reduce((accumulator, currentValue) => accumulator + currentValue, '');
};

function Account({ name, currency, currentBalance, mask }: Account) {
  const theme = useTheme();
  const amount = new Intl.NumberFormat('en-CA', { style: 'currency', currency: currency }).format(currentBalance);
  let initials = getInitials(name);
  if (initials.length > 3) {
    initials = initials.slice(0, 3);
  }
  return (
    <Card
      variant='outlined'
      sx={{
        width: '100%',
      }}
    >
      <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: theme.roundedCorners(5),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 2,
          }}
        >
          <Avatar sx={{ bgcolor: theme.palette.success.main }}>{initials}</Avatar>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant='body1'>{name}</Typography>
          <Typography variant='caption'>****{mask}</Typography>
        </Box>
        <Box
          sx={{
            color: theme.palette.grey[800],
            fontSize: theme.spacing(4),
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: 'normal',
            marginLeft: 'auto',
          }}
        >
          {amount}
        </Box>
      </CardContent>
    </Card>
  );
}

export function BankAccountSummaryWidget(props: BankAccountSummaryWidgetProps) {
  const theme = useTheme();
  const { addConversationMessage, conversationId } = useConversation();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(props.isSuccess === true || props.isSuccess === false);

  const content = props.accounts?.map((a) => {
    return (
      <div key={a.id}>
        <Account {...a} />
      </div>
    );
  });

  const successfulConnection = async () => {
    setDisabled(true);
    await addConversationMessage({ conversationId: conversationId as string, message: `PLAID_SUCCESS_MESSAGE`, includeMessageInConversation: false });
  };

  const clickContinue = async () => {
    setDisabled(true);
    await addConversationMessage({ conversationId: conversationId as string, message: `Let's continue` });
  };

  return (
    <>
      <Stack>
        <Typography variant='h5'>Currently Linked Bank Accounts</Typography>
        {content}
      </Stack>

      <Typography sx={{ pt: theme.spacing(5) }}>Have any other business accounts to connect? If not, let's continue.</Typography>
      <Box paddingTop={theme.spacing(5)}>
        <PlaidLinkWidget
          buttonColor='neutral'
          buttonVariant='contained'
          buttonShade={400}
          buttonLabel='Connect additional account(s)'
          disable={disabled || loading}
          onSuccess={successfulConnection}
          onLoadingChange={(loading) => setLoading(loading)}
          {...props}
        />
        <Button
          disabled={disabled || loading}
          variant='contained'
          color='primary'
          onClick={() => clickContinue()}
          sx={{ mt: theme.spacing(2), width: '100%' }}
        >
          Let's Continue
        </Button>
      </Box>
    </>
  );
}
