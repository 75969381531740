import { Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField } from '@mui/material';
import { CloseCircle } from 'iconsax-react';
import { Transaction } from '../../../api';
import { ThreeColumn } from '../../../components';

export interface CheckInResultsDialogProps {
  openFor: Transaction | null;
  onClose: () => void;
}

export function CheckInResultsDialog({ openFor, onClose }: CheckInResultsDialogProps) {
  return (
    <Dialog open={!!openFor} onClose={onClose}>
      <DialogTitle>
        <ThreeColumn $mainColumn align='center'>
          <span></span>
          <span>Check-In Results</span>
          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </ThreeColumn>
      </DialogTitle>
      <DialogContent>
        <Stack>
          {openFor && (
            <>
              {openFor.checkInDescription !== null && (
                <TextField
                  multiline
                  InputProps={{ readOnly: true, disableUnderline: true }}
                  fullWidth
                  label='Description Provided'
                  value={openFor.checkInDescription}
                />
              )}

              {openFor.checkInDocuments !== null && (
                <TextField
                  InputProps={{ readOnly: true, disableUnderline: true }}
                  fullWidth
                  label='Documents Provided'
                  value={openFor.checkInDocuments.join(',')}
                />
              )}

              {openFor.checkInNoDocuments && (
                <TextField
                  InputProps={{ readOnly: true, disableUnderline: true }}
                  fullWidth
                  label='User Claimed No Documents Available'
                  value={openFor.checkInNoDocuments}
                />
              )}

              {openFor.checkInAwaitingDocumentEmail && (
                <TextField
                  InputProps={{ readOnly: true, disableUnderline: true }}
                  fullWidth
                  label='User Emailing Document'
                  value={openFor.checkInAwaitingDocumentEmail}
                />
              )}
            </>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
