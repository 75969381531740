import { CircularProgress, FormControl, Stack, useTheme } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useCallback, useEffect, useState } from 'react';
import { Organization, useAdmin } from '../../../api';
import { AdminOrganizationSelect, Button, PageBody, PageContainer, PageHeader, useSelectedOrganization } from '../../../components';

const useAdminData = (selectedOrg: Organization | null) => {
  const [emailData, setEmailData] = useState<unknown[] | null>(null);
  const { organizations, fetchOrganizations, fetchOrganizationDocumentEmails } = useAdmin();

  useEffect(() => {
    fetchOrganizations().catch((e) => {
      throw e;
    });
  }, [fetchOrganizations]);

  const fetchDocumentEmails = useCallback(
    async (emailCursor: Date | null) => {
      if (!selectedOrg) {
        return;
      }

      const data = await fetchOrganizationDocumentEmails(selectedOrg.id!, emailCursor);

      setEmailData(data);
    },
    [selectedOrg, fetchOrganizationDocumentEmails]
  );

  return {
    organizations,
    fetchDocumentEmails,
    emailData,
  };
};

export function AdminDocumentEmailPage({ ...props }) {
  const theme = useTheme();
  const { selectedOrganization: selectedOrg, setSelectedOrganization: setSelectedOrg } = useSelectedOrganization(null);
  const { organizations, fetchDocumentEmails, emailData } = useAdminData(selectedOrg);
  const [emailCursor, setEmailCursor] = useState<Date | null>(null);

  useEffect(() => {
    if (!selectedOrg) {
      return;
    }

    setEmailCursor(selectedOrg.documentEmailCursor);
  }, [selectedOrg]);

  const resetEmailCursor = () => {
    setEmailCursor(selectedOrg?.documentEmailCursor || null);
  };

  if (!organizations) {
    return (
      <PageContainer {...props}>
        <PageHeader title='Admin - Document Emails' />
        <PageBody gutter='thin' style={{ alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </PageBody>
      </PageContainer>
    );
  }

  return (
    <PageContainer {...props}>
      <PageHeader title='Admin - Document Emails' />
      <PageBody gutter='thin'>
        <Stack height='100%'>
          <Stack direction='row' paddingTop={theme.spacing(2)}>
            <AdminOrganizationSelect
              organizations={organizations}
              onOrganizationChange={(org) => {
                setSelectedOrg(org);
              }}
            />
          </Stack>

          <Stack direction='row'>
            <FormControl>
              <DateTimePicker
                label='Email Cursor'
                value={emailCursor}
                onChange={(value) => {
                  setEmailCursor(value);
                }}
              />
            </FormControl>

            <Button variant='contained' color='primary' onClick={resetEmailCursor}>
              Reset
            </Button>

            <Button variant='contained' color='primary' onClick={() => fetchDocumentEmails(emailCursor)}>
              Fetch Emails
            </Button>
          </Stack>

          <pre>{emailData ? JSON.stringify(emailData, null, 2) : null}</pre>
        </Stack>
      </PageBody>
    </PageContainer>
  );
}
