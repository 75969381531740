import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { axios } from './axios-base';
import { useSession } from './session';
import { Account, Journal } from './shared-interfaces';

interface ILedgerContext {
  fetchJournals: () => Promise<void>;
  fetchAccounts: (journalId: string) => Promise<void>;
  downloadEventInvite: (eventId: string) => Promise<void>;
  journals: null | Journal[];
  accounts: { [journalId: string]: Account[] };
}

function useLedgerData() {
  const { session, sessionDiff } = useSession();
  const [journals, setJournals] = useState<null | Journal[]>(null);
  const [accounts, setAccounts] = useState<{ [journalId: string]: Account[] }>({});

  useEffect(() => {
    if (sessionDiff.old && !sessionDiff.new) {
      setJournals(null);
      setAccounts({});
    }
  }, [sessionDiff]);

  const fetchJournals = useCallback(async () => {
    if (!session) {
      return;
    }

    const response = await axios.get(`/journals`);
    const journalData = response.data as { journals: Journal[] };

    setJournals(journalData.journals);
  }, [session]);

  const fetchAccounts = useCallback(
    async (journalId: string) => {
      if (!session) {
        return;
      }

      const response = await axios.get(`/journals/${journalId}/accounts`);
      const accountData = response.data as { accounts: Account[] };

      setAccounts((existing) => ({
        ...existing,
        [journalId]: accountData.accounts,
      }));
    },
    [session]
  );

  const downloadEventInvite = useCallback(
    async (eventId: string) => {
      if (!session) {
        return;
      }

      const response = await axios.get(`/event-invites/${eventId}`, {
        responseType: 'blob',
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'event.ics');

      // Append to html page and click it
      document.body.appendChild(link);
      link.click();

      // Clean up and remove the link
      link.parentNode!.removeChild(link);
      window.URL.revokeObjectURL(url);
    },
    [session]
  );

  const ledgerContext: ILedgerContext = {
    fetchJournals,
    fetchAccounts,
    downloadEventInvite,
    journals,
    accounts,
  };

  return ledgerContext;
}

export const LedgerContext = createContext<ILedgerContext>({} as ILedgerContext);

export const useLedger = () => useContext(LedgerContext);

export const LedgerContextProvider = ({ children }: { children: React.ReactNode }) => {
  const ledger = useLedgerData();

  return <LedgerContext.Provider value={ledger}>{children}</LedgerContext.Provider>;
};
