import { v4 as uuid } from 'uuid';
import { Session } from './session';

export enum AuthorType {
  USER = 'USER',
  SYSTEM = 'SYSTEM',
}

export enum RenderType {
  TEXT = 'TEXT',
  STRUCTURED = 'STRUCTURED',
}

export enum MessageType {
  TEXT = 'TEXT',
  WIDGET = 'WIDGET',
  TITLE = 'TITLE',
}

export interface ConversationMessage {
  id: string;
  author: string;
  authorType: AuthorType;
  renderType: RenderType;
  content: string;
  messageType: MessageType;
  isHidden?: boolean;
  created: Date;
  strategyName?: string;
  strategyState?: string;
}

export interface Conversation<M extends ConversationMessage> {
  id: string;
  messages: M[];
}

function getMessageType({ isTitleResponse, isWidgetResponse }: { isWidgetResponse: boolean; isTitleResponse: boolean }) {
  let messageType: MessageType;
  if (isWidgetResponse) {
    messageType = MessageType.WIDGET;
  } else if (isTitleResponse) {
    messageType = MessageType.TITLE;
  } else {
    messageType = MessageType.TEXT;
  }

  return messageType;
}

export interface AddToConversationParams<C extends Conversation<M>, M extends ConversationMessage, D> {
  url: string;
  session: Session;
  token: string;
  csrf: string;
  conversation: C;
  message?: string;
  includeMessageInConversation?: boolean;
  additionalRequestData?: object;
  onStreamingStart?: () => void;
  onStreamingEnd?: () => void;
  onStreamingSuccess?: () => void;
  interrupt: AbortSignal;
  onConversationUpdated: (updatedConversation: C) => void;
  onConversationDataUpdated: (dataUpdate: D) => void;
  timeout?: number;
}
export async function addToConversation<C extends Conversation<M>, M extends ConversationMessage, D>({
  url,
  session,
  token,
  csrf,
  conversation,
  message,
  includeMessageInConversation = true,
  additionalRequestData,
  onStreamingStart,
  onStreamingEnd,
  onStreamingSuccess,
  onConversationUpdated,
  onConversationDataUpdated,
  interrupt,
  timeout = 30000,
}: AddToConversationParams<C, M, D>) {
  const startTime = Date.now();
  onStreamingStart && onStreamingStart();

  if (message) {
    conversation = {
      ...conversation,
      messages: [
        ...conversation.messages,
        {
          id: uuid(),
          author: session.userId,
          authorType: AuthorType.USER,
          renderType: RenderType.TEXT,
          content: message,
          isHidden: !includeMessageInConversation,
        },
      ],
    };

    onConversationUpdated(conversation);
  }

  const abortController = new AbortController();
  const requestBody = {
    message,
    isHidden: !includeMessageInConversation,
    ...additionalRequestData,
  };

  const fetchTimeout = setTimeout(() => {
    abortController.abort();
  }, timeout);

  // Note: using fetch because axios does not support streaming
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'X-CSRF-TOKEN': csrf,
    },
    credentials: 'include',
    body: JSON.stringify(requestBody),
    signal: abortController.signal || interrupt,
  });

  clearTimeout(fetchTimeout);

  if (!response.ok) {
    onStreamingEnd && onStreamingEnd();
    abortController.abort();
    throw new Error('HTTP error adding to conversation');
  }

  const reader = response.body!.getReader();
  const decoder = new TextDecoder();

  let isDataResponse = false;
  let isTitleResponse = false;
  let isWidgetResponse = false;
  let isStructuredResponse = false;
  let structuredResponseBuffer = '';
  let systemResponseAdded = false;
  const processStream = async (): Promise<void> => {
    if (Date.now() - startTime > timeout) {
      await reader.cancel();
      reader.releaseLock();
      throw new Error('Timeout while processing conversation stream.');
    }

    const { done, value } = await reader.read();
    const lines = decoder
      .decode(value)
      .split('\n')
      .filter((line: string) => line.trim() !== '');

    let messageBuffer = '';
    for (const line of lines) {
      const message = line.replace(/^data: /, '');

      let parsed: {
        isDone?: boolean;
        delta?: string;
        structuredResponse?: boolean;
        widgetResponse?: boolean;
        dataResponse?: boolean;
        titleResponse?: boolean;
        newMessage?: boolean;
      };
      try {
        messageBuffer += message;

        parsed = JSON.parse(messageBuffer) as {
          isDone?: boolean;
          delta?: string;
          structuredResponse?: boolean;
          widgetResponse?: boolean;
          newMessage?: boolean;
        };

        messageBuffer = '';
      } catch (e) {
        continue;
      }

      if (parsed.isDone) {
        if (isStructuredResponse) {
          const messageType = getMessageType({
            isTitleResponse,
            isWidgetResponse,
          });

          onConversationUpdated({
            ...conversation,
            messages: [
              ...conversation.messages,
              {
                id: uuid(),
                author: 'Otter',
                authorType: AuthorType.SYSTEM,
                renderType: RenderType.STRUCTURED,
                messageType,
                content: structuredResponseBuffer,
              },
            ],
          });
        }
      } else if (parsed.dataResponse) {
        isDataResponse = true;
      } else if (parsed.structuredResponse) {
        isStructuredResponse = true;
      } else if (parsed.widgetResponse) {
        isWidgetResponse = true;
      } else if (parsed.titleResponse) {
        isTitleResponse = true;
      } else if (parsed.newMessage) {
        if (isStructuredResponse) {
          const messageType = getMessageType({
            isTitleResponse,
            isWidgetResponse,
          });

          conversation = {
            ...conversation,
            messages: [
              ...conversation.messages,
              {
                id: uuid(),
                author: 'Otter',
                authorType: AuthorType.SYSTEM,
                renderType: RenderType.TEXT,
                messageType,
                content: structuredResponseBuffer,
                lastViewed: new Date(),
              },
            ],
          };

          onConversationUpdated(conversation);
          structuredResponseBuffer = '';
        }

        isDataResponse = false;
        isWidgetResponse = false;
        isStructuredResponse = false;
        isTitleResponse = false;
        systemResponseAdded = false;
      } else {
        if (isDataResponse) {
          onConversationDataUpdated(JSON.parse(parsed.delta!) as D);
        } else if (isStructuredResponse) {
          structuredResponseBuffer += parsed.delta;
        } else if (!systemResponseAdded) {
          const messageType = getMessageType({
            isTitleResponse,
            isWidgetResponse,
          });

          conversation = {
            ...conversation,
            messages: [
              ...conversation.messages,
              {
                id: uuid(),
                author: 'Otter',
                authorType: AuthorType.SYSTEM,
                renderType: RenderType.TEXT,
                messageType,
                content: parsed.delta,
                lastViewed: new Date(),
              },
            ],
          };

          systemResponseAdded = true;
          onConversationUpdated(conversation);
        } else {
          const systemMessage = conversation.messages[conversation.messages.length - 1];
          conversation.messages.splice(conversation.messages.length - 1, 1);
          const content = systemMessage.content + parsed.delta!;

          conversation = {
            ...conversation,
            messages: [
              ...conversation.messages,
              {
                ...systemMessage,
                content: content,
                lastViewed: new Date(),
              },
            ],
          };
          onConversationUpdated(conversation);
        }
      }
    }

    if (done) {
      onStreamingEnd && onStreamingEnd();
      onStreamingSuccess && onStreamingSuccess();
    } else {
      return processStream();
    }
  };

  return processStream();
}
