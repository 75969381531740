import {
  Avatar,
  AvatarGroup,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { format } from 'date-fns';
import { ArrowLeft2, ArrowRight2, CloseCircle, Filter, HierarchySquare2 } from 'iconsax-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getDepth, Organization, OrganizationEvent, ScheduledOrgEventType, useAdmin } from '../../../api';
import { Calendar, PageBody, PageContainer, PageHeader, ThreeColumn } from '../../../components';
import { OutlineContainer, OutlineContainerSection } from '../../../components/outline-container';
import { MED_HORIZONTAL_SPACING, SMALL_VERTICAL_SPACING } from '../../../theme';

export function restrictLength(str: string, length: number, delimiter = ' ') {
  const tokenized = str.split(delimiter);

  let sum = 0;
  const approvedFrontParts = [] as string[];
  const approvedBackParts = [] as string[];

  if (tokenized.length === 1) {
    return str;
  }

  for (let i = 0; i < Math.ceil(tokenized.length / 2); i++) {
    if (i !== tokenized.length - 1 - i) {
      const frontPart = tokenized[i];
      if (sum + frontPart.length + 1 < length) {
        sum += frontPart.length;
        approvedFrontParts.push(frontPart);
      }
    }

    const backPart = tokenized[tokenized.length - 1 - i];
    if (sum + backPart.length + 1 < length) {
      sum += backPart.length;
      approvedBackParts.push(backPart);
    }
  }

  const frontJoined = approvedFrontParts.join(delimiter);
  const backJoined = approvedBackParts.join(delimiter);

  if (frontJoined + delimiter + backJoined === str) {
    return str;
  }

  return frontJoined + ' ... ' + backJoined;
}

const monthMap = {
  1: 'January',
  2: 'February',
  3: 'March',
  4: 'April',
  5: 'May',
  6: 'June',
  7: 'July',
  8: 'August',
  9: 'September',
  10: 'October',
  11: 'November',
  12: 'December',
} as { [key: number]: string };

function dateKey(date: Date) {
  return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}`;
}

const useAdminData = (year: number, month: number) => {
  const { fetchOrganizationEvents, fetchOrganizations, organizationEvents, organizations } = useAdmin();

  useEffect(() => {
    fetchOrganizations().catch((e) => {
      throw e;
    });

    fetchOrganizationEvents(year, month).catch((e) => {
      throw e;
    });
  }, [fetchOrganizationEvents, fetchOrganizations, year, month]);

  const organizationsById = useMemo(() => {
    if (!organizations) {
      return null;
    }

    return organizations.reduce(
      (map, current) => {
        map[current.id!] = current;
        return map;
      },
      {} as { [orgId: string]: Organization }
    );
  }, [organizations]);

  const events = useMemo(() => {
    if (!organizationEvents[year] || !organizationEvents[year][month]) {
      return null;
    }

    const events = organizationEvents[year][month];

    const eventsByDay = {} as { [dateString: string]: OrganizationEvent[] };
    for (const event of events) {
      const dateString = dateKey(event.timestamp);

      if (!eventsByDay[dateString]) {
        eventsByDay[dateString] = [];
      }

      eventsByDay[dateString].push(event);
    }

    return eventsByDay;
  }, [organizationEvents, year, month]);

  return {
    events,
    organizationsById,
  };
};

interface DayEventsDialogProps {
  openForDay: {
    year: number;
    month: number;
    day: number;
  } | null;
  events: {
    [dateString: string]: OrganizationEvent[];
  };
  onClose: () => void;
  organizationsById: { [orgId: string]: Organization };
}

function DayEventsDialog({ openForDay, onClose, events, organizationsById }: DayEventsDialogProps) {
  const theme = useTheme();
  const [showChildren, setShowChildren] = useState(() => new Set<string>());
  const dateString = openForDay ? dateKey(new Date(openForDay.year, openForDay.month - 1, openForDay.day)) : null;

  const toggleChildren = (id: string) => {
    setShowChildren((current) => {
      const newSet = new Set(current);

      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }

      return newSet;
    });
  };

  const eventsByParentId = Object.values(events)
    .flat()
    .reduce(
      (map, current) => {
        if (!map[current.parentId]) {
          map[current.parentId] = [];
        }

        map[current.parentId].push(current);

        return map;
      },
      {} as { [eventId: string]: Array<OrganizationEvent> }
    );

  let content: React.ReactNode;
  if (!dateString || !events[dateString]) {
    content = <Typography padding={theme.spacing(10)}>No events</Typography>;
  } else {
    const dayEvents = [] as OrganizationEvent[];

    const addEventAndChildren = (event: OrganizationEvent) => {
      dayEvents.push(event);

      if (showChildren.has(event.id)) {
        const children = eventsByParentId[event.id] || [];

        for (const child of children) {
          addEventAndChildren(child);
        }
      }
    };

    for (const event of events[dateString]) {
      if (!event.parentId) {
        addEventAndChildren(event);
      }
    }

    content = dayEvents.map((e) => {
      const organization = organizationsById[e.organizationId];

      const depth = getDepth(e);

      let status: string;
      if (e.complete) {
        status = 'Complete';
      } else if (e.started) {
        status = 'Started';
      } else {
        status = 'Not Started';
      }

      return (
        <OutlineContainer style={{ marginLeft: `calc(${theme.spacing(MED_HORIZONTAL_SPACING)} * ${depth})` }}>
          <OutlineContainerSection>
            <Tooltip title='View Child Events'>
              <span>
                <IconButton onClick={() => toggleChildren(e.id)} disabled={!eventsByParentId[e.id]}>
                  <HierarchySquare2
                    size='1rem'
                    variant='Bold'
                    color={eventsByParentId[e.id] ? theme.palette.primary.main : theme.palette.text.disabled}
                  />
                </IconButton>
              </span>
            </Tooltip>
            <Typography>{format(e.timestamp, 'hh:mm a')}</Typography>
            <Typography>{organization.name}</Typography>
            <Tooltip title={e.name}>
              <Typography>{restrictLength(e.name, 20)}</Typography>
            </Tooltip>
            <Typography>{status}</Typography>
          </OutlineContainerSection>
        </OutlineContainer>
      );
    });
  }

  return (
    <Dialog open={!!openForDay} onClose={onClose} maxWidth='md'>
      {openForDay && (
        <>
          <DialogTitle>
            <ThreeColumn $mainColumn align='center'>
              <span></span>
              <span>{format(new Date(openForDay.year, openForDay.month - 1, openForDay.day), 'MMMM d, yyyy')}</span>
              <IconButton onClick={onClose}>
                <CloseCircle />
              </IconButton>
            </ThreeColumn>
          </DialogTitle>
          <DialogContent>
            <Stack overflow='auto' alignItems='stretch'>
              {content}
            </Stack>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}

export function AdminCalendarPage({ ...props }) {
  const now = new Date();

  const theme = useTheme();
  const [year, setYear] = useState(now.getFullYear());
  const [month, setMonth] = useState(now.getMonth() + 1);

  const [selectedDay, setSelectedDay] = useState<null | { day: number; month: number; year: number }>(null);

  const { organizationsById, events } = useAdminData(year, month);

  const [dayOpen, setDayOpen] = useState<{ year: number; month: number; day: number } | null>(null);

  const years = useMemo(() => {
    const y: number[] = [];
    for (let i = 2023; i < new Date().getFullYear() + 1; i++) {
      y.push(i);
    }
    return y;
  }, []);

  const filterConfigButtonRef = useRef<HTMLButtonElement | null>(null);
  const [showFilterConfigMenu, setShowFilterConfigMenu] = useState(false);
  const [filterConfig, setFilterConfig] = useState({
    [ScheduledOrgEventType.CHECK_IN]: true,
    [ScheduledOrgEventType.REIMBURSEMENT_APPROVAL]: true,
  });

  if (!events || !organizationsById) {
    return (
      <PageContainer {...props}>
        <PageHeader title='Admin - Statements' />
        <PageBody gutter='thin'>
          <Stack alignItems='center' justifyContent='center' height='100%'>
            <CircularProgress />
          </Stack>
        </PageBody>
      </PageContainer>
    );
  }

  return (
    <PageContainer {...props}>
      <PageHeader title='Admin - Calendar' />
      <PageBody gutter='thin'>
        <Stack height='100%' paddingTop={theme.spacing(3)}>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Stack direction='row' alignItems='center'>
              <FormControl>
                <InputLabel id='month-label'>Month</InputLabel>
                <Select
                  label='Month'
                  labelId='month-label'
                  value={month}
                  sx={{
                    width: 128,
                  }}
                  onChange={(e) => setMonth(Number(e.target.value))}
                >
                  {Array.from({ length: 12 }, (_, index) => index + 1).map((m) => (
                    <MenuItem key={`month-${m}`} value={m}>
                      {monthMap[m]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl>
                <InputLabel id='year-label'>Year</InputLabel>
                <Select label='Year' labelId='year-label' value={year} onChange={(e) => setYear(Number(e.target.value))}>
                  {years.map((y) => (
                    <MenuItem key={`year-${y}`} value={y}>
                      {y}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <IconButton
                onClick={() => {
                  setMonth((current) => {
                    if (current === 1) {
                      return 12;
                    } else {
                      return current - 1;
                    }
                  });
                }}
              >
                <ArrowLeft2 size='1rem' color={theme.palette.text.primary} />
              </IconButton>

              <IconButton
                onClick={() => {
                  setMonth((current) => {
                    if (current === 12) {
                      return 1;
                    } else {
                      return current + 1;
                    }
                  });
                }}
              >
                <ArrowRight2 size='1rem' color={theme.palette.text.primary} />
              </IconButton>
            </Stack>

            <Stack direction='row' alignItems='center'>
              <IconButton onClick={() => setShowFilterConfigMenu(true)} ref={filterConfigButtonRef}>
                <Filter color={theme.palette.primary.main} />
              </IconButton>

              <Menu
                open={showFilterConfigMenu}
                onClose={() => setShowFilterConfigMenu(false)}
                anchorEl={filterConfigButtonRef.current}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <Stack
                  spacing={0}
                  sx={{
                    margin: theme.spacing(5),
                  }}
                >
                  <Typography variant='h3' sx={{ marginBottom: theme.spacing(SMALL_VERTICAL_SPACING) }}>
                    Event Types
                  </Typography>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterConfig[ScheduledOrgEventType.CHECK_IN]}
                        onChange={(e) => setFilterConfig({ ...filterConfig, [ScheduledOrgEventType.CHECK_IN]: e.target.checked })}
                      />
                    }
                    label='Check-in'
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filterConfig[ScheduledOrgEventType.REIMBURSEMENT_APPROVAL]}
                        onChange={(e) => setFilterConfig({ ...filterConfig, [ScheduledOrgEventType.REIMBURSEMENT_APPROVAL]: e.target.checked })}
                      />
                    }
                    label='Reimbursement Approval'
                  />
                </Stack>
              </Menu>
            </Stack>
          </Stack>

          <Calendar
            year={year}
            month={month}
            selectedDay={selectedDay}
            style={{ flex: 1 }}
            dayChildren={(year, month, day) => {
              const dateString = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
              const filteredEvents = (events[dateString] || []).filter((e) => {
                return !e.parentId && filterConfig[e.type];
              });

              if (!filteredEvents) {
                return null;
              }

              return (
                <Stack width='100%' alignItems='center'>
                  <AvatarGroup
                    max={3}
                    slotProps={{
                      additionalAvatar: {
                        sx: {
                          width: 32,
                          height: 32,
                          fontSize: '1rem',
                        },
                      },
                    }}
                  >
                    {filteredEvents
                      .sort((a, b) => {
                        const aIncomplete = a.started && !a.complete;
                        const bIncomplete = b.started && !b.complete;
                        if (aIncomplete && bIncomplete) {
                          return organizationsById[a.organizationId].name.localeCompare(organizationsById[b.organizationId].name);
                        } else if (aIncomplete) {
                          return -1;
                        } else if (bIncomplete) {
                          return 1;
                        } else if (!a.started && b.started) {
                          return -1;
                        } else if (!b.started && a.started) {
                          return 1;
                        }

                        return organizationsById[a.organizationId].name.localeCompare(organizationsById[b.organizationId].name);
                      })
                      .map((e) => {
                        const organization = organizationsById[e.organizationId];
                        let bgColor: string | undefined;
                        let textColor: string | undefined;
                        if (e.complete) {
                          bgColor = undefined;
                          textColor = undefined;
                        } else if (e.started) {
                          bgColor = theme.palette.error.main;
                          textColor = theme.palette.error.contrastText;
                        } else {
                          bgColor = theme.palette.warning.main;
                          textColor = theme.palette.warning.contrastText;
                        }

                        return (
                          <Avatar
                            key={e.id}
                            alt={organization.name}
                            src={''}
                            sx={{
                              width: 32,
                              height: 32,
                              bgcolor: bgColor,
                              color: textColor,
                            }}
                          >
                            {organization.name.charAt(0)}
                          </Avatar>
                        );
                      })}
                  </AvatarGroup>
                </Stack>
              );
            }}
            onDayClicked={(year, month, day) => {
              setSelectedDay({
                year,
                month,
                day,
              });
              setDayOpen({
                year,
                month,
                day,
              });
            }}
          />

          <DayEventsDialog openForDay={dayOpen} onClose={() => setDayOpen(null)} events={events} organizationsById={organizationsById} />
        </Stack>
      </PageBody>
    </PageContainer>
  );
}
