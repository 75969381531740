import { Box, Stack, Typography, useTheme } from '@mui/material';
import { CSSProperties, forwardRef } from 'react';

export interface ProgressBarProps {
  complete: number;
  total: number;
  style?: CSSProperties;
}

export const ProgressBar = forwardRef<HTMLDivElement, ProgressBarProps>(({ complete, total, style }, ref) => {
  const theme = useTheme();

  return (
    <Stack spacing={1} style={style}>
      <Typography>{`${complete}/${total} reviewed`}</Typography>
      <Box ref={ref} borderRadius='0.25rem' width='100%' height='0.5rem' border={`1px solid ${theme.palette.border.main}`} overflow='hidden'>
        <Box
          width={`${(100 * complete) / total}%`}
          height='100%'
          sx={{
            backgroundColor: theme.palette.primary.main,
            transition: 'all 300ms ease',
          }}
        ></Box>
      </Box>
    </Stack>
  );
});
