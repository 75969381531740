import { Button as MuiButton, ButtonProps as MuiButtonProps, emphasize, useTheme } from '@mui/material';
import { ForwardedRef, forwardRef } from 'react';
import styled from 'styled-components';

export interface ButtonProps {
  variant: 'text' | 'contained' | 'outlined';
  color: 'primary' | 'secondary' | 'neutral' | 'error' | 'warning';
  corners?: 'standard' | 'round';
  shade?: number;
  elevation?: number;
  children?: React.ReactNode;
}

const StyledButton = styled(
  forwardRef(({ color, shade, ...otherProps }: ButtonProps & Omit<MuiButtonProps, 'color'>, ref: ForwardedRef<HTMLButtonElement>) => (
    <MuiButton ref={ref} {...otherProps} />
  ))
)`
  background-color: ${({ theme, variant, color, shade = 500 }) => {
    if (variant === 'contained') {
      return theme.palette[color][shade];
    } else if (variant === 'outlined') {
      return theme.palette.background.default;
    } else {
      return 'none';
    }
  }};
  color: ${({ theme, variant, color, shade = 500 }) => {
    if (variant === 'contained') {
      return theme.palette[color].contrastTextForShade[shade];
    } else if (variant === 'outlined') {
      return theme.palette.text.primary;
    } else {
      return theme.palette[color][shade];
    }
  }};
  border-color: ${({ theme, variant, color, shade = 500 }) => {
    if (variant === 'outlined') {
      return theme.palette[color][shade];
    }
  }};
  box-shadow: ${({ theme, elevation = 0 }) => theme.shadows[elevation]};
  border-radius: ${({ theme, corners = 'standard' }) => {
    return corners === 'standard' ? theme.roundedCorners(4) : theme.roundedCorners(5);
  }};

  &:hover {
    background-color: ${({ theme, variant, color, shade = 500 }) => {
      if (variant === 'contained') {
        return emphasize(theme.palette[color][shade]);
      } else {
        return emphasize(theme.palette.background.default);
      }
    }};
    box-shadow: ${({ theme, elevation = 0 }) => theme.shadows[elevation]};
    border-color: ${({ theme, variant, color, shade = 500 }) => {
      if (variant === 'outlined') {
        return theme.palette[color][shade];
      }
    }};
  }
`;

export const Button = forwardRef((props: ButtonProps & Omit<MuiButtonProps, 'color'>, ref: ForwardedRef<HTMLButtonElement>) => {
  const { color, variant, shade, corners, children, ...rest } = props;
  const theme = useTheme();

  return (
    <StyledButton theme={theme} variant={variant} color={color} shade={shade} corners={corners} ref={ref} {...rest}>
      {children}
    </StyledButton>
  );
});
