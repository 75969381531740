import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, useTheme } from '@mui/material';
import { CloseCircle } from 'iconsax-react';
import { CSSProperties, ImgHTMLAttributes } from 'react';
import { ThreeColumn } from '../../../components';
import { SMALL_HORIZONTAL_SPACING } from '../../../theme';

export interface Td1ThumbnailProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'width' | 'height' | 'style'> {
  containerWidth?: string | number | undefined;
  containerHeight?: string | number | undefined;
  containerBackground?: string | undefined;
  signedUrl: string;
  imgWidth?: string | number | undefined;
  imgHeight?: string | number | undefined;
  imgFillContainer?: boolean;
  containerStyle?: CSSProperties;
  imgStyle?: CSSProperties;
}

export function Td1Thumbnail({
  containerBackground,
  containerWidth,
  containerHeight,
  containerStyle,
  signedUrl,
  imgWidth,
  imgHeight,
  imgFillContainer,
  imgStyle,
  ...props
}: Td1ThumbnailProps) {
  const theme = useTheme();

  const iWidth = 64;
  const iHeight = 64;

  const img = (
    <img
      src={'/pdf-file-icon.svg'}
      alt={'TD1'}
      width={iWidth}
      height={iHeight}
      {...props}
      style={{
        ...imgStyle,
      }}
    />
  );

  if (containerBackground || containerHeight || containerWidth) {
    return (
      <Box
        style={{
          width: containerWidth,
          height: containerHeight,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: `1px solid ${theme.palette.border.main}`,
          borderRadius: theme.roundedCorners(5),
          background: containerBackground,
          overflow: 'hidden',
          ...containerStyle,
        }}
      >
        {img}
      </Box>
    );
  } else {
    return img;
  }
}

export function Td1Dialog({ openFor, onClose, title }: { openFor: string | null; onClose: () => void; title: string }) {
  const theme = useTheme();

  return (
    <Dialog open={!!openFor} onClose={onClose} style={{ margin: theme.spacing(SMALL_HORIZONTAL_SPACING) }} fullScreen>
      <DialogTitle>
        <ThreeColumn $mainColumn align='center'>
          <span></span>
          <span>{title}</span>
          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </ThreeColumn>
      </DialogTitle>
      <DialogContent>
        {openFor && (
          <Stack direction='row' height='100%'>
            <Box overflow='auto' style={{ flex: 1 }}>
              <iframe src={openFor} style={{ width: '100%', height: '100%' }}></iframe>
            </Box>
          </Stack>
        )}
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}
