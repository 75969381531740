import styled from 'styled-components';

export const ThreeColumn = styled.div<{ $mainColumn?: boolean; align: 'start' | 'center' | 'end' }>`
  display: grid;
  grid-template-columns: ${({ $mainColumn }) => ($mainColumn ? `1fr 2fr 1fr` : `1fr 1fr 1fr`)};
  align-items: ${({ align }) => align};

  > *:first-child {
    justify-self: start;
  }

  > *:last-child {
    justify-self: end;
  }
`;
