import { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useConversation } from '../../api';
import { Button } from '../button.tsx';
import { IWidgetProps } from './IWidgetProps.tsx';

interface ButtonWidgetProps extends IWidgetProps {
  title?: string;
  showOnClickMessage?: boolean;
  onClickMessage?: string;
}

export const ButtonWidget = ({ isSuccess, title, onClickMessage, showOnClickMessage }: ButtonWidgetProps) => {
  const [disable, setDisable] = useState(isSuccess === false || isSuccess === true);
  const { addConversationMessage, conversationId } = useConversation();
  const ref = useRef<HTMLDivElement | null>(null);

  const click = useCallback(async () => {
    setDisable(true);
    await addConversationMessage({
      conversationId: conversationId as string,
      message: onClickMessage!,
      includeMessageInConversation: showOnClickMessage!,
    });
  }, [addConversationMessage, conversationId, onClickMessage, showOnClickMessage]);

  useEffect(() => {
    // Note: timeout exists to ensure that this gets focus instead of conversation input
    if (!disable) {
      setTimeout(() => {
        ref.current!.focus();
      }, 100);
    }
  }, [disable]);

  const onKeyDown = useCallback(
    async (e: KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Enter' && !disable) {
        e.preventDefault();
        await click();
      }
    },
    [click, disable]
  );

  return (
    <div ref={ref} onKeyDown={onKeyDown} autoFocus={!disable} tabIndex={0} style={{ outline: 'none' }}>
      <Button variant='contained' color='primary' onClick={click} disabled={disable}>
        {title}
      </Button>
    </div>
  );
};
