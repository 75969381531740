import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import { CloseCircle } from 'iconsax-react';
import { useEffect, useMemo, useState } from 'react';
import { Role } from '../api';
import { useFormState } from '../utils/useFormState';
import { Button } from './button';
import { ThreeColumn } from './three-column';

export function InviteUserDialog({
  open,
  onClose,
  onInvite,
}: {
  open: boolean;
  onClose: () => void;
  onInvite: (email: string, role: Role) => Promise<void>;
}) {
  const [loading, setLoading] = useState(false);
  const [email, setEmail, emailTouched, setEmailTouched] = useFormState('');
  const [emailInUse, setEmailInUse] = useState(false);
  const emailError = useMemo(() => {
    const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}/;

    if (!email) {
      return 'Email is required';
    } else if (!emailRegex.test(email)) {
      return 'Email is invalid';
    } else if (emailInUse) {
      return 'This email is already in use by another user';
    }

    return null;
  }, [email, emailInUse]);

  const [role, setRole] = useState(Role.EMPLOYEE);

  useEffect(() => {
    setLoading(false);
    setEmail('');
    setEmailTouched(false);
    setEmailInUse(false);
    setRole(Role.EMPLOYEE);
  }, [open, setEmail, setEmailTouched]);

  const invite = async () => {
    try {
      setLoading(true);
      await onInvite(email, role);
    } catch (e) {
      if ((e as { response: { data: { reason: string } } }).response.data.reason) {
        const expectedError = e as { response: { data: { reason: string } } };
        const reason = expectedError.response.data.reason;
        if (reason === 'USER_EXISTS') {
          setEmailInUse(true);
        }
      } else {
        throw e;
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <ThreeColumn align='center'>
          <span></span>
          <span>Invite User</span>
          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </ThreeColumn>
      </DialogTitle>
      <DialogContent>
        <Stack>
          <FormControl required disabled={loading} error={emailTouched && !!emailError} fullWidth>
            <TextField
              label='Email'
              placeholder='Email'
              value={email}
              onBlur={() => setEmailTouched(true)}
              onChange={(event) => {
                setEmail(event.target.value);
                setEmailInUse(false);
              }}
            />
            <FormHelperText error={true}>{emailTouched && emailError}</FormHelperText>
          </FormControl>

          <FormControl>
            <InputLabel id='role-label'>Role</InputLabel>
            <Select label='Role' labelId='role-label' autoWidth value={role} onChange={(event) => setRole(event.target.value as Role)}>
              <MenuItem value={Role.CONTRACTOR}>Contractor</MenuItem>
              <MenuItem value={Role.INTERNATIONAL_CONTRACTOR}>International Contractor</MenuItem>
              <MenuItem value={Role.EMPLOYEE}>Employee</MenuItem>
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='neutral' onClick={onClose}>
          Cancel
        </Button>
        <Button variant='contained' color='primary' onClick={invite} disabled={loading || !!emailError}>
          {loading ? <CircularProgress size='1rem' /> : <span>Send Invite</span>}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
