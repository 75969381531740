import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  useTheme,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { format } from 'date-fns';
import { CloseCircle } from 'iconsax-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Account, CreateJournalEntryArgs, Journal, JournalEntry, JournalEntryLine, JournalEntryLineType, useAdmin } from '../../../api';
import { Button, TreeSelectDataGridCellEdit, TreeSelectDataGridCellView } from '../../../components';
import { CURRENCY_SYMBOLS } from '../../../utils/currencies';
import { useOneClickDataGridEditing } from '../../../utils/datagrid';

type UIJournalEntryLine = Omit<JournalEntryLine, 'accountId'> & { currency: string; account?: Account };

export interface JournalEntryDialogProps {
  existingDetails?: JournalEntry;
  preselectAccount?: Account;
  journal: Journal;
  journalAccountsById: { [accountId: string]: Account };
  loading: boolean;
  readOnly?: boolean;
  open: boolean;
  onClose: () => void;
  onCreate: (newEntry: CreateJournalEntryArgs) => void;
}
export function JournalEntryDialog({
  existingDetails,
  preselectAccount,
  journal,
  journalAccountsById,
  readOnly,
  loading,
  open,
  onClose,
  onCreate,
}: JournalEntryDialogProps) {
  const theme = useTheme();
  const { currencyConvert } = useAdmin();

  const [date, setDate] = useState<Date | null>(null);
  const [dateTouched, setDateTouched] = useState(false);

  const [memo, setMemo] = useState('');
  const [memoTouched, setMemoTouched] = useState(false);

  const dateError = useMemo(() => {
    return !date;
  }, [date]);
  const memoError = useMemo(() => {
    return !memo;
  }, [memo]);

  const [lines, setLines] = useState<Array<UIJournalEntryLine>>([]);

  const debitTotal = useMemo(() => {
    const total = lines
      .filter((l) => l.type === JournalEntryLineType.DEBIT)
      .reduce((sum, current) => {
        if (!current.amount) {
          return sum;
        }
        return sum + parseFloat(current.amount);
      }, 0);

    const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: journal.currency });

    return amountFormatter.format(total);
  }, [lines, journal]);

  const creditTotal = useMemo(() => {
    const total = lines
      .filter((l) => l.type === JournalEntryLineType.CREDIT)
      .reduce((sum, current) => {
        if (!current.amount) {
          return sum;
        }
        return sum + parseFloat(current.amount);
      }, 0);

    const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: journal.currency });

    return amountFormatter.format(total);
  }, [lines, journal]);

  const balanced = useMemo(() => {
    return creditTotal === debitTotal;
  }, [creditTotal, debitTotal]);

  const [amountTouched, setAmountTouched] = useState<{ [lineId: string]: boolean }>({});
  const [accountTouched, setAccountTouched] = useState<{ [lineId: string]: boolean }>({});

  const [isOpeningBalance, setIsOpeningBalance] = useState(false);

  const [linesLoading, setLinesLoading] = useState<Set<string>>(new Set());
  const setLineLoading = (lineId: string) => {
    setLinesLoading((existing) => {
      const newSet = new Set(existing);
      newSet.add(lineId);
      return newSet;
    });
  };
  const setLineNotLoading = (lineId: string) => {
    setLinesLoading((existing) => {
      const newSet = new Set(existing);
      newSet.delete(lineId);
      return newSet;
    });
  };

  const error = useMemo(() => {
    if (dateError || memoError || !balanced) {
      return true;
    }

    for (const line of lines) {
      if (!line.amount || !line.account) {
        return true;
      }

      if (line.currency !== journal.currency && !line.foreignCurrencyAmount) {
        return true;
      }
    }

    return false;
  }, [lines, dateError, memoError, balanced, journal]);

  const updateLine = useCallback((id: string, newLine: UIJournalEntryLine) => {
    setLines((existing) => {
      const newLines = [];
      for (const l of existing) {
        if (id === l.id) {
          newLines.push(newLine);
        } else {
          newLines.push(l);
        }
      }
      return newLines;
    });
  }, []);

  const prevDate = useRef<Date | null>();

  useEffect(() => {
    if (prevDate.current === date || !date) {
      return;
    }

    const updateLines = async () => {
      const newLines = [] as UIJournalEntryLine[];
      for (const line of lines) {
        if (line.currency !== journal.currency && line.foreignCurrencyAmount) {
          setLineLoading(line.id);

          try {
            const convertedAmount = await currencyConvert(date, line.foreignCurrencyAmount, line.currency, journal.currency);

            newLines.push({
              ...line,
              amount: convertedAmount,
            });
          } finally {
            setLineNotLoading(line.id);
          }
        } else {
          newLines.push(line);
        }
      }

      setLines(newLines);
    };

    updateLines().catch((e) => {
      throw e;
    });
  }, [date, updateLine, currencyConvert, lines, journal]);

  useEffect(() => {
    prevDate.current = date;
  }, [date]);

  useEffect(() => {
    if (existingDetails) {
      setMemo(existingDetails.memo);
      setDate(existingDetails.date);
      setLines([
        ...existingDetails.credits.map((c, i) => ({
          id: `credit-${i}`,
          account: journalAccountsById[c.accountId],
          amount: c.amount,
          currency: c.foreignCurrency ? c.foreignCurrency : journal.currency,
          foreignCurrencyAmount: c.foreignCurrencyAmount,
          foreignCurrency: c.foreignCurrency,
          type: JournalEntryLineType.CREDIT,
          description: c.description,
        })),
        ...existingDetails.debits.map((c, i) => ({
          id: `debit-${i}`,
          account: journalAccountsById[c.accountId],
          amount: c.amount,
          currency: c.foreignCurrency ? c.foreignCurrency : journal.currency,
          foreignCurrencyAmount: c.foreignCurrencyAmount,
          foreignCurrency: c.foreignCurrency,
          type: JournalEntryLineType.DEBIT,
          description: c.description,
        })),
      ]);
      setIsOpeningBalance(!!existingDetails.tags.find((t) => t.startsWith('opening-balances::')));
    } else if (!open) {
      setLines([]);
    }
  }, [journalAccountsById, existingDetails, open, journal]);

  const create = () => {
    if (!date || dateError || !balanced || memoError || lines.length === 0 || error) {
      return;
    }

    const formattedDate = format(date, 'yyyy-MM-dd');

    const output: CreateJournalEntryArgs = {
      date: formattedDate,
      memo,
      credits: lines
        .filter((l) => l.type === JournalEntryLineType.CREDIT)
        .map((l) => ({
          accountId: l.account!.id,
          amount: l.currency === journal.currency ? l.amount : l.foreignCurrencyAmount!,
          currency: l.currency,
        })),
      debits: lines
        .filter((l) => l.type === JournalEntryLineType.DEBIT)
        .map((l) => ({
          accountId: l.account!.id,
          amount: l.currency === journal.currency ? l.amount : l.foreignCurrencyAmount!,
          currency: l.currency,
        })),
      isOpeningBalance,
    };

    onCreate(output);
  };

  const formatAmount = (amt: string) => {
    const numAmount = parseFloat(amt);

    const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: journal.currency });

    return amountFormatter.format(numAmount);
  };

  const removeLine = (id: string) => {
    setLines((existing) => {
      return existing.filter((r) => r.id !== id);
    });
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: '',
      width: 50,
      renderCell: (params) => {
        const row = params.row as UIJournalEntryLine;

        if (linesLoading.has(row.id)) {
          return <CircularProgress size='1rem' />;
        } else {
          return null;
        }
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      editable: !readOnly && !loading,
      type: 'singleSelect',
      valueOptions: [JournalEntryLineType.CREDIT, JournalEntryLineType.DEBIT],
    },
    {
      field: 'currency',
      headerName: 'Currency',
      editable: !readOnly && !loading,
      type: 'singleSelect',
      valueOptions: CURRENCY_SYMBOLS,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      editable: !readOnly && !loading,
      renderCell: (params) => {
        const line = params.row as UIJournalEntryLine;
        return (
          <Box
            style={{
              width: '100%',
              height: '100%',
              background: !line.amount && amountTouched[line.id] ? theme.palette.error.main : undefined,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {line.amount ? formatAmount(line.amount) : line.amount}
          </Box>
        );
      },
      valueSetter: (params) => {
        const line = params.row as UIJournalEntryLine;
        const value = params.value as string;

        return {
          ...line,
          amount: value.replace(/[$,A-Za-z]/g, ''),
        };
      },
    },
    {
      field: 'foreignCurrencyAmount',
      headerName: 'Foreign Currency Amount',
      editable: !readOnly && !loading,
      width: 200,
      renderCell: (params) => {
        const line = params.row as UIJournalEntryLine;
        const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: line.foreignCurrency || journal.currency });

        return (
          <Box
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {line.foreignCurrencyAmount ? amountFormatter.format(parseFloat(line.foreignCurrencyAmount)) : line.foreignCurrencyAmount}
          </Box>
        );
      },
    },
    {
      field: 'account',
      headerName: 'Account',
      width: 150,
      editable: !readOnly && !loading,
      renderCell: (params) => {
        const line = params.row as UIJournalEntryLine;

        return (
          <TreeSelectDataGridCellView
            params={params}
            items={Object.values(journalAccountsById)}
            valid={!!line.account || !accountTouched[line.id]}
            disabled={readOnly || loading}
          />
        );
      },
      renderEditCell: (params) => {
        const line = params.row as UIJournalEntryLine;

        return (
          <TreeSelectDataGridCellEdit
            params={params}
            items={Object.values(journalAccountsById)}
            itemComparator={(a, b) => a.name.localeCompare(b.name)}
            onItemChange={() =>
              setAccountTouched((existing) => ({
                ...existing,
                [line.id]: true,
              }))
            }
            noSelectionValue='none'
            disabled={readOnly || loading}
          />
        );
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 1,
      editable: !readOnly && !loading,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => {
        const line = params.row as UIJournalEntryLine;

        if (readOnly || loading) {
          return null;
        }

        return (
          <Tooltip title='Remove line'>
            <span>
              <IconButton onClick={() => removeLine(line.id)}>
                <CloseCircle color={theme.palette.primary.main} />
              </IconButton>
            </span>
          </Tooltip>
        );
      },
    },
  ];

  const { handleCellClick, cellModesModel, handleCellModesModelChange, editing } = useOneClickDataGridEditing();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      PaperProps={{
        style: {
          minWidth: 1000,
        },
      }}
    >
      <DialogTitle>Create Journal Entry</DialogTitle>
      <DialogContent>
        <form>
          <Stack>
            <FormControl required error={!!memoError && memoTouched}>
              <TextField
                label='Memo'
                placeholder='Memo...'
                value={memo}
                onBlur={() => setMemoTouched(true)}
                onChange={(e) => setMemo(e.target.value)}
                disabled={loading || readOnly}
              />
              {!!memoError && memoTouched && <FormHelperText>Invalid memo</FormHelperText>}
            </FormControl>

            <FormControl required error={!!dateError && dateTouched}>
              <DatePicker
                label='Date'
                value={date}
                onChange={(value) => {
                  setDateTouched(true);
                  setDate(value);
                }}
                disabled={loading || readOnly}
              />
              {!!dateError && dateTouched && <FormHelperText>Invalid date</FormHelperText>}
            </FormControl>

            <DataGrid
              style={{
                flex: 1,
                flexBasis: theme.spacing(64),
              }}
              rows={lines}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 50,
                  },
                },
              }}
              pageSizeOptions={[50]}
              hideFooter
              onCellClick={handleCellClick}
              cellModesModel={cellModesModel}
              onCellModesModelChange={handleCellModesModelChange}
              onCellEditStop={(params) => {
                const row = params.row as UIJournalEntryLine;
                const property = params.field;

                if (property === 'amount') {
                  setAmountTouched((existing) => ({
                    ...existing,
                    [row.id]: true,
                  }));
                }
              }}
              isCellEditable={(params) => {
                const line = params.row as UIJournalEntryLine;

                return !!params.colDef.editable && !linesLoading.has(line.id);
              }}
              processRowUpdate={async (nRow, oRow) => {
                const newRow = nRow as UIJournalEntryLine;
                const oldRow = oRow as UIJournalEntryLine;

                if (date) {
                  if (newRow.amount && newRow.amount !== oldRow.amount && newRow.currency !== journal.currency) {
                    setLineLoading(newRow.id);
                    try {
                      newRow.foreignCurrencyAmount = await currencyConvert(date, newRow.amount, journal.currency, newRow.currency);
                    } finally {
                      setLineNotLoading(newRow.id);
                    }
                  } else if (
                    newRow.foreignCurrencyAmount &&
                    newRow.foreignCurrencyAmount !== oldRow.foreignCurrencyAmount &&
                    newRow.currency !== journal.currency
                  ) {
                    setLineLoading(newRow.id);
                    try {
                      newRow.amount = await currencyConvert(date, newRow.foreignCurrencyAmount, newRow.currency, journal.currency);
                    } finally {
                      setLineNotLoading(newRow.id);
                    }
                  } else if (newRow.foreignCurrencyAmount && newRow.currency !== journal.currency) {
                    try {
                      newRow.amount = await currencyConvert(date, newRow.foreignCurrencyAmount, newRow.currency, journal.currency);
                    } finally {
                      setLineNotLoading(newRow.id);
                    }
                  } else if (newRow.amount && newRow.currency !== journal.currency) {
                    try {
                      newRow.foreignCurrencyAmount = await currencyConvert(date, newRow.amount, journal.currency, newRow.currency);
                    } finally {
                      setLineNotLoading(newRow.id);
                    }
                  } else if (newRow.currency !== journal.currency) {
                    newRow.amount = '';
                  }
                }

                updateLine(newRow.id, newRow);
                return newRow;
              }}
            />

            <Button
              variant='contained'
              color='primary'
              disabled={readOnly || loading}
              onClick={() => {
                setLines((existing) => [
                  ...existing,
                  {
                    id: uuid(),
                    type: JournalEntryLineType.CREDIT,
                    amount: '',
                    currency: journal.currency,
                    description: '',
                    account: preselectAccount,
                  },
                ]);
              }}
            >
              Add line
            </Button>

            <table style={{ width: 300 }}>
              <tbody>
                <tr>
                  <td style={{ fontWeight: 'bold ' }}>Debit Total</td>
                  <td
                    style={{
                      textAlign: 'right',
                      color: balanced ? theme.palette.text.primary : theme.palette.error.main,
                    }}
                  >
                    {debitTotal}
                  </td>
                </tr>
                <tr>
                  <td style={{ fontWeight: 'bold ' }}>Credit Total</td>
                  <td
                    style={{
                      textAlign: 'right',
                      color: balanced ? theme.palette.text.primary : theme.palette.error.main,
                    }}
                  >
                    {creditTotal}
                  </td>
                </tr>
              </tbody>
            </table>

            <FormControlLabel
              control={<Checkbox checked={isOpeningBalance} onChange={(e) => setIsOpeningBalance(e.target.checked)} />}
              label='Opening Balance'
              disabled={loading || readOnly}
            />
          </Stack>
        </form>
      </DialogContent>
      <DialogActions>
        {loading && (
          <Box display='flex' justifyContent='center' sx={{ width: '100%' }}>
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <>
            <Button variant='contained' color='neutral' onClick={onClose}>
              {readOnly ? 'Close' : 'Cancel'}
            </Button>
            {!readOnly && (
              <Button variant='contained' color='primary' disabled={error || lines.length === 0 || loading || editing} onClick={create}>
                Create
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
