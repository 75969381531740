import i18n, { ResourceKey } from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './translations/en.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en as ResourceKey,
      },
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  })
  .catch(console.error);

export default i18n;
