import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { CloseCircle } from 'iconsax-react';
import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { CategorizationConversation } from '../api';
import {
  LARGE_HORIZONTAL_SPACING,
  LARGE_VERTICAL_SPACING,
  MED_HORIZONTAL_SPACING,
  MED_VERTICAL_SPACING,
  SMALL_HORIZONTAL_SPACING,
  SMALL_VERTICAL_SPACING,
} from '../theme';
import { Button } from './button';

export interface CategorizationDialogProps {
  title: string;
  categorizationConversations: CategorizationConversation[] | null;
  confidenceRating?: number | null;
  open: boolean;
  onClose: () => void;
}

const getConversationLabel = (conversationNumber: number) => {
  if (conversationNumber === 0) {
    return 'Primary';
  } else if (conversationNumber === 1) {
    return 'Secondary';
  } else if (conversationNumber === 2) {
    return 'Tertiary';
  } else {
    return `${conversationNumber}`;
  }
};

const PaddedTabs = styled(Tabs)(({ theme }) => ({
  paddingLeft: theme.spacing(LARGE_HORIZONTAL_SPACING),
  paddingRight: theme.spacing(LARGE_HORIZONTAL_SPACING),
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(SMALL_HORIZONTAL_SPACING),
    paddingRight: theme.spacing(SMALL_HORIZONTAL_SPACING),
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(MED_HORIZONTAL_SPACING),
    paddingRight: theme.spacing(MED_HORIZONTAL_SPACING),
  },
}));

const PaddedStack = styled(Stack)(({ theme }) => ({
  overflow: 'auto',
  flex: 1,
  minHeight: 0,
  paddingLeft: theme.spacing(LARGE_HORIZONTAL_SPACING),
  paddingRight: theme.spacing(LARGE_HORIZONTAL_SPACING),
  paddingBottom: theme.spacing(LARGE_VERTICAL_SPACING),
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(SMALL_HORIZONTAL_SPACING),
    paddingRight: theme.spacing(SMALL_HORIZONTAL_SPACING),
    paddingBottom: theme.spacing(SMALL_VERTICAL_SPACING),
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(MED_HORIZONTAL_SPACING),
    paddingRight: theme.spacing(MED_HORIZONTAL_SPACING),
    paddingBottom: theme.spacing(MED_VERTICAL_SPACING),
  },
}));

const PaddedBox = styled(Box)(({ theme }) => ({
  flex: 1,
  paddingLeft: theme.spacing(LARGE_HORIZONTAL_SPACING),
  paddingRight: theme.spacing(LARGE_HORIZONTAL_SPACING),
  paddingBottom: theme.spacing(LARGE_VERTICAL_SPACING),
  [theme.breakpoints.up('sm')]: {
    paddingLeft: theme.spacing(SMALL_HORIZONTAL_SPACING),
    paddingRight: theme.spacing(SMALL_HORIZONTAL_SPACING),
    paddingBottom: theme.spacing(SMALL_VERTICAL_SPACING),
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(MED_HORIZONTAL_SPACING),
    paddingRight: theme.spacing(MED_HORIZONTAL_SPACING),
    paddingBottom: theme.spacing(MED_VERTICAL_SPACING),
  },
}));

export function CategorizationDialog({ title, categorizationConversations, confidenceRating, open, onClose }: CategorizationDialogProps) {
  const conversationsByNumber = useMemo(() => {
    if (!categorizationConversations) {
      return {};
    }

    return categorizationConversations.reduce(
      (map, current) => {
        map[current.conversationNumber] = current;
        return map;
      },
      {} as { [conversationNumber: number]: CategorizationConversation }
    );
  }, [categorizationConversations]);

  const [tab, setTab] = useState(0);
  useEffect(() => {
    setTab(0);
  }, [categorizationConversations]);

  const messages = conversationsByNumber[tab]?.messages || [];

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <span>{title}</span>
        <IconButton onClick={onClose}>
          <CloseCircle />
        </IconButton>
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          padding: 0,
        }}
      >
        <Stack>
          {confidenceRating !== undefined && confidenceRating !== null && (
            <PaddedBox style={{ flex: 0 }}>
              <span>Confidence Rating: {confidenceRating * 100}%</span>
            </PaddedBox>
          )}
          <PaddedTabs
            value={tab}
            onChange={(_e, newValue: number) => {
              setTab(newValue);
            }}
          >
            {Object.keys(conversationsByNumber).map((n) => (
              <Tab key={`tab-${n}`} label={getConversationLabel(Number(n))} value={Number(n)} />
            ))}
          </PaddedTabs>

          <PaddedStack>
            {messages.map((m) => {
              return (
                <div key={m.id} style={{ whiteSpace: 'pre-line' }}>
                  <Typography variant='h5'>{m.author}</Typography>
                  <Typography>{m.content}</Typography>
                </div>
              );
            })}
          </PaddedStack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='neutral' onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
