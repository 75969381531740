import { LinkSquare } from 'iconsax-react';
import { Button } from '../button.tsx';
import { FailureIcon, IWidgetProps, SuccessIcon } from './IWidgetProps.tsx';

interface LinkWidgetProps extends IWidgetProps {
  link?: string;
  title?: string;
}

export const LinkWidget = (props: LinkWidgetProps) => {
  if (props.isSuccess === true) {
    return <SuccessIcon />;
  }
  if (props.isSuccess === false) {
    return <FailureIcon />;
  }
  return (
    <div>
      <Button variant='contained' color='primary' startIcon={<LinkSquare />} href={props.link}>
        {props.title}
      </Button>
    </div>
  );
};
