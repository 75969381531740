import { useEffect, useState } from 'react';
import { useTransaction } from '../../api';
import { PageBody, PageContainer, PageHeader, Plaid } from '../../components';

const useToken = () => {
  const { createLinkToken, authenticate } = useTransaction();
  const [token, setToken] = useState<string>();

  useEffect(() => {
    createLinkToken()
      .then((token) => setToken(token))
      .catch((e) => {
        throw e;
      });
  }, [createLinkToken]);

  return {
    token,
    authenticate,
  };
};

export function PlaidTestPage({ ...props }) {
  const { token, authenticate } = useToken();

  return (
    <PageContainer {...props}>
      <PageHeader title='Plaid Test' />
      <PageBody>
        {token && (
          <Plaid
            token={token}
            buttonLabel='Connect'
            buttonVariant='contained'
            buttonColor='primary'
            onExit={(err) => console.log('Error on plaid', err)}
            onSuccess={(publicToken) => authenticate(publicToken)}
          />
        )}
      </PageBody>
    </PageContainer>
  );
}
