import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from '@mui/material';
import * as Sentry from '@sentry/browser';
import { Refresh } from 'iconsax-react';
import { Component, ReactNode, createContext, useContext } from 'react';
import { Button } from './button';

const ErrorContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  showError: (_error: Error) => {},
});

export const useError = () => useContext(ErrorContext);

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  error?: Error;
  unexpectedError?: Error;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  private static errorTimers: Map<string, number> = new Map();

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: undefined, unexpectedError: undefined };
  }

  static getDerivedStateFromError(error: Error, errorInfo: React.ErrorInfo) {
    ErrorBoundary.captureError(error, errorInfo);
    return { error: undefined, unexpectedError: error };
  }

  static captureError(error: Error, errorInfo?: React.ErrorInfo) {
    const errorString = `${error.name}: ${error.message}`;

    if (ErrorBoundary.errorTimers.has(errorString)) {
      // If a timer exists, clear it to reset the debounce period
      clearTimeout(ErrorBoundary.errorTimers.get(errorString));
    }

    const timer = setTimeout(() => {
      Sentry.captureException(error, {
        extra: {
          componentStack: errorInfo?.componentStack,
        },
      });

      ErrorBoundary.errorTimers.delete(errorString);
    }, 1000) as unknown as number;

    ErrorBoundary.errorTimers.set(errorString, timer);
  }

  handleClose = () => {
    this.setState({ error: undefined, unexpectedError: undefined });
  };

  showError = (error: Error) => {
    this.setState({ error: error, unexpectedError: undefined });
  };

  render() {
    const message = this.state.error?.message ? this.state.error.message : 'Something went wrong. Please try again later';

    return (
      <>
        <ErrorContext.Provider value={{ showError: this.showError }}>
          {this.state.unexpectedError ? (
            <Dialog open={true}>
              <DialogTitle>Oops!</DialogTitle>
              <DialogContent>An unknown error occurred. Please refresh the page.</DialogContent>
              <DialogActions>
                <Button variant='contained' color='primary' onClick={() => window.location.reload()}>
                  <Refresh size='1.1rem' style={{ marginRight: 8 }} />
                  <span>Refresh</span>
                </Button>
              </DialogActions>
            </Dialog>
          ) : (
            this.props.children
          )}
        </ErrorContext.Provider>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={this.state.error && !this.state.unexpectedError}
          autoHideDuration={5000}
          onClose={this.handleClose}
        >
          <Alert onClose={this.handleClose} severity='error'>
            {message}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

export default ErrorBoundary;
