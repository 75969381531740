import { InputAdornment, MenuItem, Select, TextField, TextFieldProps, styled } from '@mui/material';
import { CURRENCY_SYMBOLS } from '../utils/currencies';

export type CurrencyInputProps = TextFieldProps & {
  onCurrencyChange: (currency: string) => void;
  currency: string;
};

function CurrencyInputComponent({ currency, onCurrencyChange, className, ...rest }: CurrencyInputProps) {
  return (
    <TextField
      className={className}
      type='number'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <Select value={currency} onChange={(event) => onCurrencyChange(event.target.value)}>
              {CURRENCY_SYMBOLS.map((c) => (
                <MenuItem key={c} value={c}>
                  {c}
                </MenuItem>
              ))}
            </Select>
          </InputAdornment>
        ),
      }}
      {...(rest as TextFieldProps)}
    />
  );
}

export const CurrencyInput = styled(CurrencyInputComponent)<CurrencyInputProps>({
  '& .MuiInputBase-root': {
    paddingLeft: 0,
  },
}) as typeof CurrencyInputComponent;
