import { Box, BoxProps, Stack, StackProps, useTheme } from '@mui/material';
import { forwardRef } from 'react';
import {
  LARGE_HORIZONTAL_SPACING,
  LARGE_VERTICAL_SPACING,
  MED_HORIZONTAL_SPACING,
  MED_VERTICAL_SPACING,
  SMALL_HORIZONTAL_SPACING,
  SMALL_VERTICAL_SPACING,
} from '../theme';
import { ScreenSize, useScreenSize } from '../utils/use-screen-size';

export function useOutlineContainerBorder() {
  const theme = useTheme();

  return `1px solid ${theme.palette.border.main}`;
}

export const OutlineContainer = forwardRef<HTMLDivElement, { background?: string } & BoxProps>(({ background, sx, children, ...props }, ref) => {
  const theme = useTheme();
  const outlineContainerBorder = useOutlineContainerBorder();

  return (
    <Box ref={ref} borderRadius={theme.roundedCorners(5)} overflow='hidden' border={outlineContainerBorder} sx={{ background, ...sx }} {...props}>
      {children}
    </Box>
  );
});

export const OutlineContainerSection = forwardRef<HTMLDivElement, { background?: string } & StackProps>(
  ({ children, sx, background, ...props }, ref) => {
    const screenSize = useScreenSize();

    let paddingX: number;
    let paddingY: number;
    if (screenSize === ScreenSize.LARGE) {
      paddingX = LARGE_HORIZONTAL_SPACING;
      paddingY = LARGE_VERTICAL_SPACING;
    } else if (screenSize === ScreenSize.MEDIUM) {
      paddingX = MED_HORIZONTAL_SPACING;
      paddingY = MED_VERTICAL_SPACING;
    } else {
      paddingX = SMALL_HORIZONTAL_SPACING;
      paddingY = SMALL_VERTICAL_SPACING;
    }

    return (
      <Stack ref={ref} direction='row' alignItems='center' paddingX={paddingX} paddingY={paddingY} sx={{ background, ...sx }} {...props}>
        {children}
      </Stack>
    );
  }
);
