import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import { CloseCircle } from 'iconsax-react';
import { useEffect, useState } from 'react';
import { Journal, Organization } from '../../../api';
import { Button } from '../../../components';

function getMonthMenuItems(organization: Organization) {
  const menuItems: JSX.Element[] = [];

  const monthLabels: { [num: number]: string } = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  };

  for (let i = 0; i < 12; i++) {
    const monthNumber = ((i + organization.fyEndMonth) % 12) + 1;

    menuItems.push(<MenuItem value={monthNumber}>{monthLabels[monthNumber]}</MenuItem>);
  }

  return menuItems;
}

export interface DownloadFinancialStatementDialogProps {
  organization: Organization;
  open: boolean;
  onClose: () => void;
  journals: Journal[];
  selectedJournal: Journal;
  onDownloadFinancialStatements: (journalId: string, type: string, period: number) => Promise<void>;
}

export function DownloadFinancialStatementDialog({
  organization,
  open,
  onClose,
  journals,
  selectedJournal,
  onDownloadFinancialStatements,
}: DownloadFinancialStatementDialogProps) {
  const [downloading, setDownloading] = useState(false);
  const [journalId, setJournalId] = useState<string | null>(selectedJournal.id);
  const [type, setType] = useState('annual');
  const [period, setPeriod] = useState(1);

  useEffect(() => {
    if (!open) {
      setDownloading(false);
      setJournalId(selectedJournal.id);
      setType('annual');
      setPeriod(1);
    }
  }, [selectedJournal, open]);

  useEffect(() => {
    setJournalId(selectedJournal.id);
  }, [selectedJournal]);

  useEffect(() => {
    if (type === 'monthly') {
      setPeriod((organization.fyEndMonth % 12) + 1);
    }
  }, [type, organization]);

  const download = async () => {
    if (!journalId) {
      return;
    }

    try {
      setDownloading(true);
      await onDownloadFinancialStatements(journalId, type, period);
      onClose();
    } finally {
      setDownloading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Stack direction='row' justifyContent='space-between'>
          <span>Download Financial Statements</span>
          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack>
          <FormControl>
            <InputLabel id='fy-select-label'>FY</InputLabel>
            <Select label='FY' labelId='fy-select-label' autoWidth value={journalId || ''} onChange={(event) => setJournalId(event.target.value)}>
              <MenuItem value=''>None</MenuItem>
              {journals.map((j) => (
                <MenuItem key={j.id} value={j.id}>
                  {j.fy}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl>
            <InputLabel id='type-select-label'>Type</InputLabel>
            <Select label='Type' labelId='type-select-label' autoWidth value={type} onChange={(event) => setType(event.target.value)}>
              <MenuItem value='annual'>Annual</MenuItem>
              <MenuItem value='quarterly'>Quarterly</MenuItem>
              <MenuItem value='monthly'>Monthly</MenuItem>
            </Select>
          </FormControl>

          {type === 'quarterly' && (
            <FormControl>
              <InputLabel id='period-select-label'>Period</InputLabel>
              <Select
                label='Period'
                labelId='period-select-label'
                autoWidth
                value={period}
                onChange={(event) => setPeriod(Number(event.target.value))}
              >
                <MenuItem value={1}>Q1</MenuItem>
                <MenuItem value={2}>Q2</MenuItem>
                <MenuItem value={3}>Q3</MenuItem>
                <MenuItem value={4}>Q4</MenuItem>
              </Select>
            </FormControl>
          )}

          {type === 'monthly' && (
            <FormControl>
              <InputLabel id='period-select-label'>Period</InputLabel>
              <Select
                label='Period'
                labelId='period-select-label'
                autoWidth
                value={period}
                onChange={(event) => setPeriod(Number(event.target.value))}
              >
                {getMonthMenuItems(organization)}
              </Select>
            </FormControl>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='neutral' onClick={onClose}>
          Close
        </Button>
        <Button variant='contained' color='primary' onClick={download} disabled={downloading}>
          {downloading ? <CircularProgress /> : <span>Download</span>}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
