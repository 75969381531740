import { Avatar, Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { User } from 'iconsax-react';
import React, { forwardRef } from 'react';
import { AuthorType, ConversationMessage as IConversationMessage } from '../api';
import { MessageType } from '../api/conversation-stream.ts';
import { fetchWidget, fetchWidgetProps } from './widgets';
import { IWidgetProps } from './widgets/IWidgetProps.tsx';

const createMessage = <T extends IWidgetProps>(message: IConversationMessage): React.ReactNode => {
  if (message.messageType === MessageType.WIDGET) {
    const content = JSON.parse(message.content) as fetchWidgetProps<T>;
    return fetchWidget(content);
  }
  return <Typography sx={{ whiteSpace: 'pre-line' }}>{message.content}</Typography>;
};
interface OtterConversationMessageProps {
  message: IConversationMessage;
  index: number;
}
const OtterConversationMessage = forwardRef<HTMLDivElement, OtterConversationMessageProps>(
  ({ message, index, ...props }: OtterConversationMessageProps, ref) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    if (message.messageType === MessageType.TITLE) {
      let topSpace: string | undefined = undefined;
      if (index !== 0) {
        if (isSmallScreen) {
          topSpace = theme.spacing(5);
        } else {
          topSpace = theme.spacing(10);
        }
      }

      return (
        <Box
          ref={ref}
          style={{
            marginRight: isSmallScreen ? theme.spacing(10) : theme.spacing(20),
            padding: theme.spacing(5),
          }}
          {...props}
        >
          <Typography
            variant='h3'
            sx={{
              whiteSpace: 'pre-line',
              marginTop: topSpace,
            }}
          >
            {message.content}
          </Typography>
        </Box>
      );
    }

    return (
      <Stack width='100%' spacing={isSmallScreen ? 2 : 5} direction='row' alignItems='end' alignSelf='start' ref={ref} {...props}>
        <Avatar
          alt='Otter'
          sx={{
            width: isSmallScreen ? '2rem' : null,
            height: isSmallScreen ? '2rem' : null,
            background: theme.palette.secondary.main,
            border: theme.palette.mode === 'dark' ? `1px solid ${theme.palette.secondary[400]}` : 'none',
          }}
          src='/otter-o-white.svg'
        />
        <Box
          style={{
            marginRight: isSmallScreen ? theme.spacing(10) : theme.spacing(20),
            borderRadius: theme.roundedCorners(4),
            borderBottomLeftRadius: 0,
            padding: theme.spacing(5),
            background: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.neutral[300],
            minWidth: 0,
          }}
        >
          {createMessage(message)}
        </Box>
      </Stack>
    );
  }
);

interface UserConversationMessageProps {
  message: IConversationMessage;
  index: number;
}
const UserConversationMessage = forwardRef<HTMLDivElement, UserConversationMessageProps>(
  ({ message, ...props }: UserConversationMessageProps, ref) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <Stack width='100%' spacing={isSmallScreen ? 2 : 5} direction='row' alignItems='end' alignSelf='end' justifyContent='end' ref={ref} {...props}>
        <Box
          sx={{
            marginLeft: isSmallScreen ? theme.spacing(10) : theme.spacing(20),
            border: `1px solid ${theme.palette.border.main}`,
            borderRadius: theme.roundedCorners(4),
            borderBottomRightRadius: 0,
            padding: theme.spacing(5),
          }}
        >
          {createMessage(message)}
        </Box>
        <Avatar
          alt='Anonymous User'
          sx={{
            width: isSmallScreen ? '2rem' : null,
            height: isSmallScreen ? '2rem' : null,
            background: theme.palette.primary[200],
          }}
        >
          <User size={isSmallScreen ? '1rem' : '1.5rem'} color={theme.palette.common.black} />
        </Avatar>
      </Stack>
    );
  }
);

export interface ConversationMessageProps {
  message: IConversationMessage;
  index: number;
}
export const ConversationMessage = forwardRef<HTMLDivElement, ConversationMessageProps>(
  ({ message, index, ...props }: ConversationMessageProps, ref) => {
    return (
      <>
        {message.authorType === AuthorType.SYSTEM ? (
          <OtterConversationMessage message={message} index={index} ref={ref} {...props} />
        ) : (
          <UserConversationMessage message={message} index={index} ref={ref} {...props} />
        )}
      </>
    );
  }
);
