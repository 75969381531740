import { Plaid } from '../../components/plaid.tsx';

import { CircularProgress, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { PlaidLinkError, PlaidLinkOnExitMetadata } from 'react-plaid-link';
import { useConversation, useTransaction } from '../../api';
import { FailureIcon, IWidgetProps, SuccessIcon } from './IWidgetProps.tsx';

const useToken = () => {
  const { createLinkToken, authenticate } = useTransaction();
  const [token, setToken] = useState<string>();

  useEffect(() => {
    createLinkToken()
      .then((token) => setToken(token))
      .catch((e) => {
        throw e;
      });
  }, [createLinkToken]);

  return {
    token,
    authenticate,
  };
};

enum PlaidStatusResponse {
  SUCCESS = 'PLAID_SUCCESS_MESSAGE',
  FAILURE = 'PLAID_FAILURE_MESSAGE',
}
const useOnboardingCallbacks = () => {
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const { addConversationMessage, conversationId } = useConversation();

  const choose = async (message: PlaidStatusResponse) => {
    try {
      await addConversationMessage({ conversationId: conversationId as string, message, includeMessageInConversation: false });
      if (message === PlaidStatusResponse.SUCCESS) {
        setFailure(false);
        setSuccess(true);
      } else {
        setFailure(true);
        setSuccess(false);
      }
    } catch (e) {
      setFailure(true);
    }
  };

  return {
    success,
    failure,
    choose,
  };
};

interface PlaidLinkWidgetProps extends IWidgetProps {
  text?: string;
  buttonLabel: string;
  buttonVariant: 'contained' | 'outlined' | 'text';
  buttonColor: 'primary' | 'secondary' | 'neutral';
  buttonShade?: number;
  disable?: boolean;
  onSuccess?: () => void;
  onExit?: (linkSessionId: string) => void;
  onLoadingChange?: (isLoading: boolean) => void;
}
export function PlaidLinkWidget({
  text,
  buttonLabel,
  buttonColor,
  buttonVariant,
  buttonShade,
  isSuccess,
  disable,
  onSuccess: onSuccessCallback,
  onExit: onExitCallback,
  onLoadingChange,
}: PlaidLinkWidgetProps) {
  const [disabled, setDisabled] = useState(isSuccess === true || isSuccess === false || disable);
  const [loading, setLoading] = useState(false);
  const { success, failure, choose } = useOnboardingCallbacks();
  const { token, authenticate } = useToken();

  useEffect(() => {
    setDisabled(disable);
  }, [disable]);

  useEffect(() => {
    if (onLoadingChange) {
      onLoadingChange(loading);
    }
  }, [loading, onLoadingChange]);

  const onSuccess = async (publicToken: string) => {
    setLoading(true);
    setDisabled(true);

    try {
      await authenticate(publicToken);
      await choose(PlaidStatusResponse.SUCCESS);
      if (onSuccessCallback) {
        onSuccessCallback();
      }
    } finally {
      setLoading(false);
    }
  };

  const onExit = async (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
    if (error) {
      throw new Error(`[${error.error_type} (${metadata.link_session_id}) - ${error.error_code}] ${error.display_message}`);
    }

    console.log(metadata);

    if (onExitCallback) {
      onExitCallback(metadata.link_session_id);
    }
    return await choose(PlaidStatusResponse.FAILURE);
  };

  const theme = useTheme();

  let content;
  if (loading) {
    content = (
      <Stack alignItems='center'>
        <CircularProgress />
      </Stack>
    );
  } else if (success || isSuccess === true) {
    content = <SuccessIcon />;
  } else if (failure || isSuccess === false) {
    content = <FailureIcon />;
  } else if (token) {
    content = (
      <Plaid
        disabled={disabled}
        token={token}
        buttonColor={buttonColor}
        buttonVariant={buttonVariant}
        buttonLabel={buttonLabel}
        buttonShade={buttonShade}
        onSuccess={onSuccess}
        onExit={onExit}
        style={{ marginTop: text ? theme.spacing(2) : undefined, width: '100%' }}
      />
    );
  } else {
    content = <Skeleton variant='rounded' width={190} height={50} />;
  }
  return (
    <Stack>
      {text && <Typography sx={{ whiteSpace: 'pre-line' }}>{text}</Typography>}
      {content}
    </Stack>
  );
}
