import { useTheme } from '@mui/material';
import { Message } from 'iconsax-react';
import { CSSProperties, forwardRef } from 'react';
import { ReactComponent as SendIcon } from '../assets/send-icon-filled.svg';
import { Button } from './button';
import { MessageInput } from './message-input';

function ConversationInputIcon({
  disabled,
  conversationStarted,
  conversationEnded,
  conversationStreaming,
}: {
  disabled?: boolean;
  conversationStarted: boolean;
  conversationEnded: boolean;
  conversationStreaming: boolean;
}) {
  const theme = useTheme();

  if (conversationStreaming) {
    return <Message variant='Bold' color={theme.palette.neutral.main} />;
  } else if (conversationStarted) {
    return (
      <SendIcon
        style={{
          fill: !(conversationEnded || disabled) ? theme.palette.primary.main : theme.palette.text.disabled,
          cursor: conversationEnded || disabled ? 'auto' : 'pointer',
        }}
      />
    );
  }

  return (
    <Button variant='contained' color='secondary'>
      Start conversation
    </Button>
  );
}

export interface ConversationInputProps {
  disabled?: boolean;
  conversationStarted: boolean;
  conversationEnded: boolean;
  conversationStreaming: boolean;
  onSubmit: (message: string) => void;
  style?: CSSProperties;
}

const maxMessageLength = 1500;
export const ConversationInput = forwardRef<HTMLDivElement, ConversationInputProps>(
  ({ disabled, conversationStarted, conversationEnded, conversationStreaming, onSubmit, style }, ref) => {
    return (
      <MessageInput
        style={style}
        ref={ref}
        disabled={disabled || conversationEnded}
        placeholder='Type your message...'
        maxMessageLength={maxMessageLength}
        loading={conversationStreaming}
        onSubmit={onSubmit}
        inputIcon={
          <ConversationInputIcon
            disabled={disabled}
            conversationStarted={conversationStarted}
            conversationEnded={conversationEnded}
            conversationStreaming={conversationStreaming}
          />
        }
      />
    );
  }
);
