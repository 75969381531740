import { Button as MuiButton, PaletteMode, alpha, useTheme } from '@mui/material';
import { Moon, Sun1 } from 'iconsax-react';
import { CSSProperties } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background: ${({ theme }) => theme.palette.background2.default};
  border-radius: ${({ theme }) => theme.roundedCorners(5)};
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => theme.spacing(1)};
`;

const Button = styled(MuiButton)`
  border-radius: ${({ theme }) => theme.roundedCorners(5)};
`;

export interface LightDarkToggleProps {
  mode: PaletteMode;
  onModeChange: (mode: PaletteMode) => void;
  style?: CSSProperties;
}

export function LightDarkToggle({ mode, onModeChange, ...props }: LightDarkToggleProps) {
  const theme = useTheme();

  const changeMode = (newMode: PaletteMode) => {
    onModeChange(newMode);
  };

  return (
    <Container {...props}>
      <Button
        onClick={() => changeMode('dark')}
        style={{
          background: mode === 'dark' ? theme.palette.background.default : 'none',
          border: mode === 'dark' ? `1px solid ${theme.palette.border.main}` : 'none',
        }}
      >
        <Moon size='1rem' color={mode === 'dark' ? theme.palette.text.primary : alpha(theme.palette.text.primary, 0.5)} />
      </Button>
      <Button
        onClick={() => changeMode('light')}
        style={{
          background: mode === 'light' ? theme.palette.background.default : 'none',
          border: mode === 'light' ? `1px solid ${theme.palette.border.main}` : 'none',
        }}
      >
        <Sun1 size='1rem' color={mode === 'light' ? theme.palette.text.primary : alpha(theme.palette.text.primary, 0.5)} />
      </Button>
    </Container>
  );
}
