import { addDays, addMinutes, getDaysInMonth, getMonth, getYear, parse, subDays, subMinutes } from 'date-fns';

export function zonedTimeToUtc(date: Date) {
  const timezoneOffset = date.getTimezoneOffset();
  const newDate = new Date(date);
  return addMinutes(newDate, timezoneOffset);
}

export function utcToZonedTime(date: Date) {
  const timezoneOffset = date.getTimezoneOffset();
  const newDate = new Date(date);
  return subMinutes(newDate, timezoneOffset);
}

export function getFiscalYear(date: Date, fyEndMonth: number) {
  const utcDate = zonedTimeToUtc(date);
  const year = getYear(utcDate);
  const month = getMonth(utcDate) + 1; // +1 because getMonth() returns months in the range 0-11.

  if (month <= fyEndMonth) {
    return String(year);
  }

  // If the date's month is after the fiscal year ending month, then return the next year.
  return String(year + 1);
}

export function getFiscalYearStart(date: Date, fyEndMonth: number) {
  const fy = getFiscalYear(date, fyEndMonth);

  return getFiscalYearStartForFY(fy, fyEndMonth);
}

export function getFiscalYearStartForFY(fy: string, fyEndMonth: number) {
  const fyNum = Number(fy);

  const fyStartMonth = fyEndMonth === 12 ? 1 : fyEndMonth + 1;
  const fyStartYear = fyEndMonth < 12 ? fyNum - 1 : fyNum;

  return new Date(Date.UTC(fyStartYear, fyStartMonth - 1, 1));
}

export function getFiscalYearEnd(date: Date, fyEndMonth: number) {
  const fy = getFiscalYear(date, fyEndMonth);

  return getFiscalYearEndForFY(fy, fyEndMonth);
}

export function getFiscalYearEndForFY(fy: string, fyEndMonth: number) {
  const fyNum = Number(fy);

  const midMonth = new Date(Date.UTC(fyNum, fyEndMonth - 1, 10));

  const daysInMonth = getDaysInMonth(midMonth);

  return new Date(Date.UTC(fyNum, fyEndMonth - 1, daysInMonth, 23, 59, 59, 999));
}

export function dateIsWithinFY(date: Date, fy: string, fyEndMonth: number) {
  const start = getFiscalYearStartForFY(fy, fyEndMonth);
  const end = getFiscalYearEndForFY(fy, fyEndMonth);

  return start <= date && date <= end;
}

export function getDateString(date: Date) {
  return date.toISOString().split('T')[0];
}

export function datePlusDays(date: Date, num: number) {
  return addDays(date, num);
}

export function dateMinusDays(date: Date, num: number) {
  return subDays(date, num);
}

export function startOfDay(date: Date) {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);
  return newDate;
}

export function endOfDay(date: Date) {
  const newDate = new Date(date);
  newDate.setHours(23, 59, 59, 59);
  return newDate;
}

export function combineDateAndTime(dateString: string, timeString: string) {
  // YYYY-MM-DD and HH:MM:SS patterns
  const dateFormat = 'yyyy-MM-dd';
  const timeFormat = 'HH:mm:ss';

  // Parse the date and time
  const date = parse(dateString, dateFormat, new Date());
  const time = parse(timeString, timeFormat, new Date());

  // Combine the parsed date and time to create the final Date object
  const combinedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes(), time.getSeconds());

  return combinedDate;
}

export function toUtc(date: Date) {
  return zonedTimeToUtc(date);
}

export function toLocalTime(date: Date) {
  return utcToZonedTime(date);
}
