import { Account } from '../../../api';

export function transactionFilter({
  transaction,
  searchCriterion,
  journalAccountsById,
  accountsByExternalId,
}: {
  transaction: {
    id: string;
    amount: string;
    name: string;
    date: Date;
    isoCurrencyCode: string;
    merchantName?: string | null;
    accountId?: string;
    suggestedCategory?: string | null;
    assignedCategory?: string | null;
  };
  searchCriterion: string;
  journalAccountsById?: { [accountId: string]: Account };
  accountsByExternalId?: { [externalId: string]: Account };
}) {
  const tAmount = parseFloat(transaction.amount);

  if (searchCriterion.startsWith('id::')) {
    const result = searchCriterion.slice('id::'.length) === transaction.id;
    if (result) {
      return true;
    }
  }

  if (transaction.id === searchCriterion) {
    return true;
  }

  if (
    transaction.name.toLowerCase().includes(searchCriterion.toLowerCase()) ||
    searchCriterion.toLowerCase().includes(transaction.name.toLowerCase())
  ) {
    return true;
  }

  if (('expense'.includes(searchCriterion.toLowerCase()) || searchCriterion.toLowerCase().includes('expense')) && tAmount > 0) {
    return true;
  }

  if (('income'.includes(searchCriterion.toLowerCase()) || searchCriterion.toLowerCase().includes('income')) && tAmount < 0) {
    return true;
  }

  if (
    transaction.date.toString().toLowerCase().includes(searchCriterion.toLowerCase()) ||
    searchCriterion.toLowerCase().includes(transaction.date.toString().toLowerCase())
  ) {
    return true;
  }

  if (
    transaction.isoCurrencyCode.toLowerCase().includes(searchCriterion.toLowerCase()) ||
    searchCriterion.toLowerCase().includes(transaction.isoCurrencyCode.toLowerCase())
  ) {
    return true;
  }

  const searchAmount = parseFloat(searchCriterion);
  if (String(tAmount).startsWith(String(searchAmount)) || String(searchAmount).startsWith(String(tAmount))) {
    return true;
  }

  const amountFormatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: transaction.isoCurrencyCode });
  const formattedAmount = amountFormatter.format(tAmount);
  if (formattedAmount.includes(searchCriterion) || searchCriterion.includes(formattedAmount)) {
    return true;
  }

  if (
    transaction.merchantName &&
    (transaction.merchantName.toLowerCase().includes(searchCriterion.toLowerCase()) ||
      searchCriterion.toLowerCase().includes(transaction.merchantName))
  ) {
    return true;
  }

  if (
    accountsByExternalId &&
    accountsByExternalId[transaction.accountId!] &&
    (accountsByExternalId[transaction.accountId!].name.toLowerCase().includes(searchCriterion.toLowerCase()) ||
      searchCriterion.toLowerCase().includes(accountsByExternalId[transaction.accountId!].name.toLowerCase()))
  ) {
    return true;
  }

  if (
    journalAccountsById &&
    transaction.suggestedCategory &&
    journalAccountsById[transaction.suggestedCategory] &&
    (journalAccountsById[transaction.suggestedCategory].name.toLowerCase().includes(searchCriterion.toLowerCase()) ||
      searchCriterion.toLowerCase().includes(journalAccountsById[transaction.suggestedCategory].name.toLowerCase()))
  ) {
    return true;
  }

  if (
    journalAccountsById &&
    transaction.assignedCategory &&
    journalAccountsById[transaction.assignedCategory] &&
    (journalAccountsById[transaction.assignedCategory].name.toLowerCase().includes(searchCriterion.toLowerCase()) ||
      searchCriterion.toLowerCase().includes(journalAccountsById[transaction.assignedCategory].name.toLowerCase()))
  ) {
    return true;
  }

  return false;
}
