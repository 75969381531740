import { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { useParams } from 'react-router-dom';
import { axios } from '../../api/axios-base';
import { PageBody, PageContainer, PageHeader } from '../../components';

// use the component in your app!

export enum DocumentType {
  INVOICE = 'invoice',
  RECEIPT = 'receipt',
}

export enum ProcessingStatus {
  IN_PROCESS = 'InProcessing',
  COMPLETED_SUCCESS = 'CompletedSuccess',
  COMPLETED_FAILED = 'CompletedFailed',
}

interface Document {
  id: string;
  organizationId: string;
  type: DocumentType;
  processingStatus: ProcessingStatus;
  subtotal: string | null;
  GST: string | null;
  HST: string | null;
  PST: string | null;
  total: string | null;
  date: Date;
  time: string;
  currency: string;
  countryCode: string;
  created: number;
  updated: number;
}
export function ReceiptTestViewPage({ ...props }) {
  const { id } = useParams();
  const [doc, setDoc] = useState<Document>();

  useEffect(() => {
    axios
      .get(`/documents/${id as string}`)
      .then((result) => {
        setDoc(result.data as Document);
      })
      .catch((err) => console.log(err));
  }, [id]);

  return (
    <PageContainer {...props}>
      <PageHeader title='View Receipt' />
      <PageBody>{doc && <ReactJson src={doc} />}</PageBody>
    </PageContainer>
  );
}
