import { Box, CircularProgress, ScopedCssBaseline, ThemeProvider, createTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { useMemo } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ApiContextProvider, Role, useSession } from './api';
import { AdminAppShell, PageContainer, UserAppShell } from './components';
import ErrorBoundary from './components/error-boundary';
import './i18n';
import {
  AcceptInvitePage,
  AdminConversationsPage,
  AdminDocumentsPage,
  AdminJobsPage,
  AdminJournalPage,
  AdminMatchesPage,
  AdminOrganizationsPage,
  AdminPayrollPage,
  AdminStatementsPage,
  AdminTransactionsPage,
  ConversationHistoryPage,
  ConversationPage,
  CsvUploadPage,
  HomePage,
  NotFoundPage,
  PayrollDetailsPage,
  PlaidTestPage,
  PlaidUpdatePage,
  ReceiptTestPage,
  ReceiptTestViewPage,
  ResetPasswordPage,
} from './pages';
import { AdminAssetsPage } from './pages/admin/assets/admin-assets-page';
import { AdminCalculationsPage } from './pages/admin/calculations/admin-calculations-page';
import { AdminCalendarPage } from './pages/admin/calendar/admin-calendar-page';
import { AdminDocumentEmailPage } from './pages/admin/document-email/admin-document-email-page';
import { AdminLearningPage } from './pages/admin/learning/learning-page';
import { DownloadEventInvitePage } from './pages/user/download-event-invite-page';
import { DownloadFileArchivePage } from './pages/user/download-file-archive-page';
import { TeamPage } from './pages/user/team-page';
import { themeConfig } from './theme';
import { ThemeModeContextProvider, useThemeMode } from './theme-mode-context';
import { usePushNotifications } from './utils/use-push-notifications';

const PrivateRoute = ({ children }: { children: React.ReactNode }) => {
  const { session, sessionLoaded } = useSession();
  const location = useLocation();

  if (!sessionLoaded) {
    return (
      <PageContainer>
        <Box display='flex' alignItems='center' justifyContent='center' flex='1'>
          <CircularProgress />
        </Box>
      </PageContainer>
    );
  }

  const isAuthenticated = session?.isRegistered && session?.isVerified;

  if (!isAuthenticated) {
    return <NavigateWithPreservedQueryParams to='/sign-in' additionalParams={{ returnTo: location.pathname }} />;
  }

  return children;
};

const AdminRoute = ({ children }: { children: React.ReactNode }) => {
  const { session } = useSession();

  if (session && session.role !== Role.ADMIN) {
    return <Navigate to='/' />;
  }

  return <PrivateRoute>{children}</PrivateRoute>;
};

const NavigateWithPreservedQueryParams = ({ to, additionalParams }: { to: string; additionalParams?: { [key: string]: string } }) => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  if (additionalParams) {
    for (const [key, value] of Object.entries(additionalParams)) {
      searchParams.append(key, value);
    }
  }

  const searchParamsString = searchParams.toString();

  return <Navigate replace to={`${to}${searchParamsString ? `?${searchParamsString}` : ''}`} />;
};

const RootRedirect = () => {
  const { session } = useSession();

  let to: string;
  if (session?.role === Role.ADMIN) {
    to = '/admin';
  } else {
    to = '/home';
  }

  return <NavigateWithPreservedQueryParams to={to} />;
};

const PushNotificationRegistration = ({ children }: { children?: React.ReactNode }) => {
  usePushNotifications();

  return children;
};

function ThemedApp() {
  const { mode } = useThemeMode();

  const theme = useMemo(() => {
    return createTheme(themeConfig(mode));
  }, [mode]);

  return (
    <ThemeProvider theme={theme}>
      <ScopedCssBaseline enableColorScheme>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ErrorBoundary>
            <ApiContextProvider>
              <PushNotificationRegistration>
                <BrowserRouter>
                  <Routes>
                    <Route path='/' element={<RootRedirect />} />
                    <Route path='/reset-password/:token' element={<ResetPasswordPage />} />
                    <Route
                      path='/payroll-details'
                      element={
                        <PrivateRoute>
                          <PayrollDetailsPage />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      path='/admin/*'
                      element={
                        <AdminAppShell>
                          <Routes>
                            <Route path='/' element={<Navigate replace to='/admin/journals' />} />
                            <Route
                              path='/journals'
                              element={
                                <AdminRoute>
                                  <AdminJournalPage />
                                </AdminRoute>
                              }
                            />
                            <Route
                              path='/calculations'
                              element={
                                <AdminRoute>
                                  <AdminCalculationsPage />
                                </AdminRoute>
                              }
                            />
                            <Route
                              path='/transactions'
                              element={
                                <AdminRoute>
                                  <AdminTransactionsPage />
                                </AdminRoute>
                              }
                            />
                            <Route
                              path='/documents'
                              element={
                                <AdminRoute>
                                  <AdminDocumentsPage />
                                </AdminRoute>
                              }
                            />
                            <Route
                              path='/matches'
                              element={
                                <AdminRoute>
                                  <AdminMatchesPage />
                                </AdminRoute>
                              }
                            />
                            <Route
                              path='/assets'
                              element={
                                <AdminRoute>
                                  <AdminAssetsPage />
                                </AdminRoute>
                              }
                            />
                            <Route
                              path='/statements'
                              element={
                                <AdminRoute>
                                  <AdminStatementsPage />
                                </AdminRoute>
                              }
                            />
                            <Route
                              path='/payroll'
                              element={
                                <AdminRoute>
                                  <AdminPayrollPage />
                                </AdminRoute>
                              }
                            />
                            <Route
                              path='/conversations'
                              element={
                                <AdminRoute>
                                  <AdminConversationsPage />
                                </AdminRoute>
                              }
                            />
                            <Route
                              path='/organizations'
                              element={
                                <AdminRoute>
                                  <AdminOrganizationsPage />
                                </AdminRoute>
                              }
                            />
                            <Route
                              path='/calendar'
                              element={
                                <AdminRoute>
                                  <AdminCalendarPage />
                                </AdminRoute>
                              }
                            />
                            <Route
                              path='/jobs'
                              element={
                                <AdminRoute>
                                  <AdminJobsPage />
                                </AdminRoute>
                              }
                            />
                            <Route
                              path='/csv-upload'
                              element={
                                <AdminRoute>
                                  <CsvUploadPage />
                                </AdminRoute>
                              }
                            />
                            <Route
                              path='/document-email'
                              element={
                                <AdminRoute>
                                  <AdminDocumentEmailPage />
                                </AdminRoute>
                              }
                            />
                            <Route
                              path='/learning'
                              element={
                                <AdminRoute>
                                  <AdminLearningPage />
                                </AdminRoute>
                              }
                            />
                          </Routes>
                        </AdminAppShell>
                      }
                    />
                    <Route
                      path='*'
                      element={
                        <UserAppShell>
                          <Routes>
                            <Route path='/home' element={<HomePage />} />
                            <Route path='/conversations' element={<ConversationPage />} />
                            <Route path='/conversations/:id' element={<ConversationPage />} />
                            <Route path='/conversation-history' element={<ConversationHistoryPage />} />
                            <Route path='/sign-in' element={<NavigateWithPreservedQueryParams to='/' additionalParams={{ modal: 'sign-in' }} />} />
                            <Route
                              path='/create-account'
                              element={<NavigateWithPreservedQueryParams to='/' additionalParams={{ modal: 'create-account' }} />}
                            />
                            <Route
                              path='/onboarding'
                              element={
                                <PrivateRoute>
                                  <NavigateWithPreservedQueryParams to='/conversations' additionalParams={{ strategy: 'ONBOARDING' }} />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path='/team'
                              element={
                                <PrivateRoute>
                                  <TeamPage />
                                </PrivateRoute>
                              }
                            />
                            <Route path='/accept-invite' element={<AcceptInvitePage />} />
                            <Route
                              path='/plaid-update'
                              element={
                                <PrivateRoute>
                                  <PlaidUpdatePage />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path='/plaid-test'
                              element={
                                <PrivateRoute>
                                  <PlaidTestPage />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path='/receipt-test'
                              element={
                                <PrivateRoute>
                                  <ReceiptTestPage />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path='/receipt/:id'
                              element={
                                <PrivateRoute>
                                  <ReceiptTestViewPage />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path='/file-archives/:id'
                              element={
                                <PrivateRoute>
                                  <DownloadFileArchivePage />
                                </PrivateRoute>
                              }
                            />
                            <Route
                              path='/event-invites/:id'
                              element={
                                <PrivateRoute>
                                  <DownloadEventInvitePage />
                                </PrivateRoute>
                              }
                            />
                            <Route path='*' element={<Navigate replace to='/not-found' />} />
                          </Routes>
                        </UserAppShell>
                      }
                    />
                    <Route path='/not-found' element={<NotFoundPage />} />
                  </Routes>
                </BrowserRouter>
              </PushNotificationRegistration>
            </ApiContextProvider>
          </ErrorBoundary>
        </LocalizationProvider>
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}

function App() {
  return (
    <ThemeModeContextProvider>
      <ThemedApp />
    </ThemeModeContextProvider>
  );
}

export default App;
