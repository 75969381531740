import { Box, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import React, { CSSProperties, KeyboardEvent, forwardRef, useCallback, useState } from 'react';

function CircularProgressWithLabel(props: CircularProgressProps & { remainingCharacters: number; currentLength: number; maxLength: number }) {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
      <CircularProgress
        variant='determinate'
        value={props.remainingCharacters >= 0 ? Math.round((props.currentLength / props.maxLength) * 100) : 100}
        color={props.remainingCharacters < 0 ? 'error' : 'success'}
        thickness={10}
        {...props}
      />
      {
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant='caption' component='div' color='text.primary'>{`${props.remainingCharacters}`}</Typography>
        </Box>
      }
    </Box>
  );
}

export interface MessageInputProps {
  disabled?: boolean;
  maxMessageLength: number;
  placeholder: string;
  maxRows?: number;
  inputIcon: React.ReactNode;
  loading: boolean;
  onSubmit: (message: string) => void;
  style?: CSSProperties;
  className?: string;
}

export const MessageInput = forwardRef<HTMLDivElement, MessageInputProps>(
  ({ disabled, maxMessageLength, placeholder, maxRows = 2, inputIcon, loading, onSubmit, style, className }, ref) => {
    const [message, setMessage] = useState('');

    const remainingCharacters = maxMessageLength - message.length;

    const submit = useCallback(
      (message: string) => {
        if (loading || !message) {
          return;
        }

        onSubmit(message);
        setMessage('');
      },
      [onSubmit, setMessage, loading]
    );

    const onKeyDown = useCallback(
      (e: KeyboardEvent<HTMLTextAreaElement>) => {
        if (remainingCharacters >= 0 && e.key === 'Enter') {
          e.preventDefault();
          submit(message);
        }
      },
      [remainingCharacters, message, submit]
    );

    return (
      <>
        <OutlinedInput
          className={className}
          style={{
            ...style,
          }}
          error={remainingCharacters < 0}
          inputRef={ref}
          disabled={loading || disabled}
          placeholder={placeholder}
          multiline
          maxRows={maxRows}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onKeyDown}
          onSubmit={() => submit(message)}
          endAdornment={
            <InputAdornment position='end' title='Send Message' onClick={() => submit(message)}>
              {remainingCharacters <= 0.1 * maxMessageLength && (
                <CircularProgressWithLabel remainingCharacters={remainingCharacters} currentLength={message.length} maxLength={maxMessageLength} />
              )}
              {remainingCharacters >= 0 && inputIcon}
            </InputAdornment>
          }
        />
      </>
    );
  }
);
