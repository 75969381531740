import { useMediaQuery, useTheme } from '@mui/material';
import { useMemo } from 'react';

export enum ScreenSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}
export const useScreenSize = () => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedScreen = useMediaQuery(theme.breakpoints.down('md'));

  return useMemo(() => {
    if (isSmallScreen) {
      return ScreenSize.SMALL;
    } else if (isMedScreen) {
      return ScreenSize.MEDIUM;
    } else {
      return ScreenSize.LARGE;
    }
  }, [isSmallScreen, isMedScreen]);
};
