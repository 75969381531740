import { FormControl, Stack, TextField } from '@mui/material';
import { useState } from 'react';
import { useAdmin } from '../../../api';
import { Button, PageBody, PageContainer, PageHeader } from '../../../components';

const useAdminData = () => {
  const { predictLearningAccountMatch } = useAdmin();

  return {
    predictLearningAccountMatch,
  };
};

export function AdminLearningPage({ ...props }) {
  const { predictLearningAccountMatch } = useAdminData();
  const [transactionId, setTransactionId] = useState('');
  const [result, setResult] = useState('');

  const predict = async (transactionId: string) => {
    const result = await predictLearningAccountMatch(transactionId);

    setResult(JSON.stringify(result, null, 2));
  };

  return (
    <PageContainer {...props}>
      <PageHeader title='Admin - Learning' />
      <PageBody gutter='thin'>
        <Stack height='100%'>
          <Stack direction='row'>
            <FormControl>
              <TextField
                fullWidth
                label='Transaction ID'
                placeholder='transactionId...'
                value={transactionId}
                onChange={(e) => setTransactionId(e.target.value)}
              />
            </FormControl>

            <Button variant='contained' color='primary' onClick={() => predict(transactionId)}>
              Predict
            </Button>
          </Stack>

          <pre>{result}</pre>
        </Stack>
      </PageBody>
    </PageContainer>
  );
}
