import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { axios } from './axios-base';
import { Role, useSession } from './session';
import { User } from './shared-interfaces';

interface IOrganizationContext {
  fetchUsers: () => Promise<void>;
  fetchEarliestAvailableCheckInDateCalendar: () => Promise<void>;
  updateUser: (id: string, changes: Partial<User>) => Promise<void>;
  disableUser: (userId: string) => Promise<void>;
  enableUser: (userId: string) => Promise<void>;
  inviteUser: (email: string, role: Role) => Promise<void>;
  resendInvite: (userId: string) => Promise<void>;
  scheduleCheckIn: (date: Date) => Promise<void>;
  users: Array<User>;
}

function useOrganizationData() {
  const { session, sessionDiff } = useSession();
  const [users, setUsers] = useState<User[] | null>(null);

  useEffect(() => {
    if (sessionDiff.old && !sessionDiff.new) {
      setUsers(null);
    }
  }, [sessionDiff]);

  const fetchUsers = useCallback(async () => {
    if (!session) {
      return;
    }

    const response = await axios.get(`/organizations/${session.organizationId}/users`);

    const userData = response.data as { users: Array<User> };

    setUsers(userData.users);
  }, [session]);

  const updateUser = useCallback(
    async (id: string, changes: Partial<User>) => {
      if (!session) {
        return;
      }

      await axios.put(`/users/${id}`, changes);
    },
    [session]
  );

  const disableUser = useCallback(
    async (id: string) => {
      if (!session) {
        return;
      }

      await axios.put(`/users/${id}`, {
        isDisabled: true,
      });
    },
    [session]
  );

  const enableUser = useCallback(
    async (id: string) => {
      if (!session) {
        return;
      }

      await axios.put(`/users/${id}`, {
        isDisabled: false,
      });
    },
    [session]
  );

  const inviteUser = useCallback(
    async (email: string, role: Role) => {
      if (!session) {
        return;
      }

      await axios.post(`/organizations/${session.organizationId}/users`, {
        email,
        role,
      });
    },
    [session]
  );

  const resendInvite = useCallback(
    async (userId: string) => {
      if (!session) {
        return;
      }

      await axios.post(`/organizations/${session.organizationId}/users/${userId}/resend-invite`, {});
    },
    [session]
  );

  const scheduleCheckIn = useCallback(
    async (date: Date) => {
      if (!session) {
        return;
      }

      await axios.post(`/schedule-check-in`, {
        date,
      });
    },
    [session]
  );

  return {
    users,
    fetchUsers,
    updateUser,
    disableUser,
    enableUser,
    inviteUser,
    resendInvite,
    scheduleCheckIn,
  } as IOrganizationContext;
}

export const OrganizationContext = createContext<IOrganizationContext>({} as IOrganizationContext);

export const useOrganization = () => useContext(OrganizationContext);

export const OrganizationContextProvider = ({ children }: { children: React.ReactNode }) => {
  const organization = useOrganizationData();

  return <OrganizationContext.Provider value={organization}>{children}</OrganizationContext.Provider>;
};
