import { CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { TickCircle } from 'iconsax-react';
import { useCallback, useEffect, useState } from 'react';
import { useConversation, useDocument } from '../../api';
import { Button } from '../button';
import { DocumentUploadDialog, FileObject } from '../document-upload-dialog';
import { IWidgetProps } from './IWidgetProps.tsx';

export const UploadReceiptsWidget = ({ isSuccess }: IWidgetProps) => {
  const { addConversationMessage, conversationId } = useConversation();
  const { uploadDocuments } = useDocument();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(isSuccess === true || isSuccess === false);

  const upload = useCallback(
    async (docs: FileObject[]) => {
      setLoading(true);
      try {
        const docIds = await uploadDocuments(docs);
        setLoading(false);
        setDone(true);
        await addConversationMessage({
          conversationId: conversationId as string,
          message: JSON.stringify(docIds),
          includeMessageInConversation: false,
        });
      } finally {
        setLoading(false);
        setDone(true);
      }
    },
    [uploadDocuments, addConversationMessage, conversationId]
  );
  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isSuccess]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const theme = useTheme();
  if (isSuccess === true) {
    return (
      <Stack alignItems='center' justifyContent='center'>
        <TickCircle variant='Bold' color={theme.palette.primary.main} size={theme.spacing(10)} />
        <Typography>Documents Uploaded</Typography>
      </Stack>
    );
  }

  if (loading) {
    return (
      <Stack alignItems='center' justifyContent='center'>
        <CircularProgress />
        <Typography>Uploading Documents</Typography>
      </Stack>
    );
  }

  if (done) {
    return (
      <Stack alignItems='center' justifyContent='center'>
        <TickCircle variant='Bold' color={theme.palette.primary.main} size={theme.spacing(10)} />
        <Typography>Documents Uploaded</Typography>
      </Stack>
    );
  }
  const content = (
    <Stack>
      <Button variant='outlined' onClick={handleClickOpen} color={'primary'}>
        Add Receipt(s)
      </Button>
      <DocumentUploadDialog open={open} onClose={handleClose} onUpload={(docs) => upload(docs)} />
    </Stack>
  );
  return <Stack>{content}</Stack>;
};
