import { Link, Stack, useTheme } from '@mui/material';
import { Calendar } from 'iconsax-react';
import { useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useLedger } from '../../api';

export function DownloadEventInvitePage() {
  const theme = useTheme();
  const { id } = useParams();
  const { downloadEventInvite } = useLedger();

  useEffect(() => {
    if (!id) {
      return;
    }

    downloadEventInvite(id).catch((e) => {
      throw e;
    });
  }, [downloadEventInvite, id]);

  const download = useCallback(async () => {
    if (!id) {
      return;
    }

    await downloadEventInvite(id);
  }, [downloadEventInvite, id]);

  return (
    <Stack width='100%' height='100%' alignItems='center' justifyContent='center'>
      <Stack alignItems='center'>
        <Calendar size={theme.spacing(32)} />
        <span>
          Your download should begin shortly. If not, please <Link onClick={download}>click here</Link> to begin the download.
        </span>
      </Stack>
    </Stack>
  );
}
