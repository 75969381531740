import { useEffect } from 'react';
import { useAdmin } from '../../../api';
import { CategorizationDialog } from '../../../components';

export interface DocumentCategorizationDialogProps {
  documentId: string | null;
  open: boolean;
  onClose: () => void;
}

export function DocumentCategorizationDialog({ documentId, open, onClose }: DocumentCategorizationDialogProps) {
  const { fetchDocumentCategorizations, documentCategorizations } = useAdmin();

  useEffect(() => {
    if (!documentId) {
      return;
    }

    fetchDocumentCategorizations(documentId).catch((e) => {
      throw e;
    });
  }, [fetchDocumentCategorizations, documentId]);

  return (
    <CategorizationDialog
      title='Document Categorization'
      open={open}
      onClose={onClose}
      categorizationConversations={documentId ? documentCategorizations[documentId] || null : null}
    />
  );
}
