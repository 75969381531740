import {
  Alert,
  Avatar,
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { CloseCircle, Eye, EyeSlash } from 'iconsax-react';
import { FormEvent, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { MIN_PASSWORD_LENGTH, passwordContainsNumber, passwordContainsSpecialCharacter } from '../utils/password-requirements';
import { Button } from './button';
import { Link } from './link';
import { VerificationCodeInput } from './verification-code-input';

const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

interface DetailsStepProps {
  loading: boolean;
  signUpError?: string;
  onClose: () => void;
  onSignUp: (signUpData: { firstName: string; lastName: string; email: string; password: string }) => void;
  onOpenSignIn: () => void;
  onOpenVerify: () => void;
}

function DetailsStep({ loading, signUpError, onClose, onSignUp, onOpenSignIn, onOpenVerify }: DetailsStepProps) {
  const theme = useTheme();

  const [firstNameTouched, setFirstNameTouched] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastNameTouched, setLastNameTouched] = useState(false);
  const [lastName, setLastName] = useState('');
  const [emailTouched, setEmailTouched] = useState(false);
  const [email, setEmail] = useState('');
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [password, setPassword] = useState('');
  const [repeatedPasswordTouched, setRepeatedPasswordTouched] = useState(false);
  const [repeatedPassword, setRepeatedPassword] = useState('');

  const firstNameError = useMemo(() => {
    return firstName ? null : 'First name is required.';
  }, [firstName]);

  const lastNameError = useMemo(() => {
    return lastName ? null : 'Last name is required';
  }, [lastName]);

  const emailError = useMemo(() => {
    const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}/;
    if (!email) {
      return 'Email is required.';
    } else if (!emailRegex.test(email)) {
      return 'Email address is not valid.';
    } else {
      return null;
    }
  }, [email]);
  const emailServerError = useMemo(() => {
    if (signUpError && signUpError === 'USER_EXISTS') {
      return 'This email is already in use.';
    }

    return null;
  }, [signUpError]);

  const passwordError = useMemo(() => {
    if (!password) {
      return 'Password is required.';
    } else if (password.length < MIN_PASSWORD_LENGTH) {
      return `Password must have at least ${MIN_PASSWORD_LENGTH} characters.`;
    } else if (!passwordContainsNumber(password)) {
      return 'Password must contain a number.';
    } else if (!passwordContainsSpecialCharacter(password)) {
      return 'Password must contain a special character.';
    } else {
      return null;
    }
  }, [password]);

  const repeatedPasswordError = useMemo(() => {
    if (!repeatedPassword) {
      return 'Please repeat your password.';
    } else if (repeatedPassword !== password) {
      return 'Passwords do not match.';
    } else {
      return null;
    }
  }, [password, repeatedPassword]);

  const [revealPassword, setRevealPassword] = useState(false);
  const [revealRepeatedPassword, setRevealRepeatedPassword] = useState(false);

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!firstName || !lastName || !email || !password) {
      return;
    }

    onSignUp({
      firstName,
      lastName,
      email,
      password,
    });
  };

  return (
    <Stack margin={theme.spacing(5)} spacing={5}>
      <Header>
        <Avatar
          alt='Otter'
          sx={{
            background: theme.palette.secondary.main,
          }}
          src='/otter-o-white.svg'
        />
        <IconButton onClick={onClose}>
          <CloseCircle />
        </IconButton>
      </Header>
      <Stack alignItems='center'>
        <Box textAlign='center'>
          <Typography variant='h2' style={{ fontFamily: 'Larken' }}>
            Get Better
          </Typography>
          <Typography variant='h2' style={{ fontFamily: 'Larken' }}>
            Bookkeeping <span style={{ color: theme.palette.primary.main }}>Today</span>
          </Typography>
        </Box>
        <img src='/sign-up-happy-people.png' alt='Sign Up' width='256px' />
      </Stack>
      <form onSubmit={submit}>
        <Stack>
          <Stack direction='row'>
            <FormControl required error={!!firstNameError && firstNameTouched}>
              <TextField
                label='First Name'
                placeholder='Enter your name'
                value={firstName}
                disabled={loading}
                onBlur={() => setFirstNameTouched(true)}
                onChange={(e) => setFirstName(e.target.value)}
              />
              {firstNameError && firstNameTouched && <FormHelperText>{firstNameError}</FormHelperText>}
            </FormControl>
            <FormControl required error={!!lastNameError && lastNameTouched}>
              <TextField
                required
                label='Last Name'
                placeholder='Enter your name'
                value={lastName}
                disabled={loading}
                onBlur={() => setLastNameTouched(true)}
                onChange={(e) => setLastName(e.target.value)}
              />
              {!!lastNameError && lastNameTouched && <FormHelperText>{lastNameError}</FormHelperText>}
            </FormControl>
          </Stack>
          <FormControl required error={!!(emailError || emailServerError) && emailTouched}>
            <TextField
              label='Email'
              placeholder='Enter your email'
              value={email}
              disabled={loading}
              onBlur={() => setEmailTouched(true)}
              onChange={(e) => setEmail(e.target.value)}
            />
            {!!(emailError || emailServerError) && emailTouched && <FormHelperText>{emailError || emailServerError}</FormHelperText>}
          </FormControl>
          <FormControl required error={!!passwordError && passwordTouched}>
            <TextField
              type={revealPassword ? 'text' : 'password'}
              label='Password'
              placeholder='Enter your password'
              value={password}
              disabled={loading}
              onBlur={() => setPasswordTouched(true)}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton tabIndex={-1} onClick={() => setRevealPassword((revealPassword) => !revealPassword)}>
                      {revealPassword ? <EyeSlash /> : <Eye />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {!!passwordError && passwordTouched && <FormHelperText>{passwordError}</FormHelperText>}
          </FormControl>
          <FormControl required error={!!repeatedPasswordError && repeatedPasswordTouched}>
            <TextField
              type={revealRepeatedPassword ? 'text' : 'password'}
              label='Repeat Password'
              placeholder='Repeat your password'
              value={repeatedPassword}
              disabled={loading}
              onBlur={() => setRepeatedPasswordTouched(true)}
              onChange={(e) => setRepeatedPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton tabIndex={-1} onClick={() => setRevealRepeatedPassword((revealPassword) => !revealPassword)}>
                      {revealRepeatedPassword ? <EyeSlash /> : <Eye />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {!!repeatedPasswordError && repeatedPasswordTouched && <FormHelperText>{repeatedPasswordError}</FormHelperText>}
          </FormControl>
        </Stack>
        <Stack marginTop={theme.spacing(5)}>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            disabled={!!(firstNameError || lastNameError || emailError || passwordError || repeatedPasswordError || loading)}
            onClick={() => {
              onSignUp({
                firstName,
                lastName,
                email,
                password,
              });
            }}
          >
            {loading ? <CircularProgress style={{ alignSelf: 'center' }} /> : 'Sign Up'}
          </Button>
          <Typography alignSelf='center'>
            Already have an account?{' '}
            <Link to={window.location.pathname} onClick={onOpenSignIn}>
              Sign in
            </Link>{' '}
            or{' '}
            <Link to={window.location.pathname} onClick={onOpenVerify}>
              verify
            </Link>
          </Typography>
        </Stack>
      </form>
    </Stack>
  );
}

interface VerificationStepProps {
  loading: boolean;
  verificationError?: string;
  forEmail?: string;
  onClose: () => void;
  onResendVerificationCode: () => void;
  onCreateDifferentAccount: () => void;
  onVerify: (email: string, verificationCode: string) => void;
}
function VerificationStep({
  loading,
  verificationError,
  forEmail,
  onClose,
  onResendVerificationCode,
  onCreateDifferentAccount,
  onVerify,
}: VerificationStepProps) {
  const theme = useTheme();
  const [email, setEmail] = useState(forEmail || '');
  const [code, setCode] = useState('');
  const [emailTouched, setEmailTouched] = useState(false);
  const [open, setOpen] = useState(false);
  const emailError = useMemo(() => {
    const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}/;
    if (!email) {
      return 'Email is required.';
    } else if (!emailRegex.test(email)) {
      return 'Email not valid.';
    } else {
      return null;
    }
  }, [email]);
  const verificationCodeError = useMemo(() => {
    return code.length < 6;
  }, [code]);
  const verificationErrorMessage = useMemo(() => {
    if (verificationError) {
      if (verificationError === 'CODE_NOT_MATCH') {
        return 'Verification code did not match.';
      } else if (verificationError === 'INVALID_USER') {
        return 'User is invalid for verification.';
      } else {
        return 'Unknown error.';
      }
    }

    return null;
  }, [verificationError]);

  const resendDisabledTimeout = useRef<NodeJS.Timeout | null>(null);
  const resend = () => {
    if (resendDisabledTimeout.current) {
      return;
    }
    setOpen(true);

    onResendVerificationCode();

    resendDisabledTimeout.current = setTimeout(() => {
      resendDisabledTimeout.current = null;
    }, 25 * 1000); // 25 seconds
  };
  useEffect(() => {
    return () => {
      if (resendDisabledTimeout.current) {
        clearTimeout(resendDisabledTimeout.current);
      }
    };
  }, []);

  const handleClose = () => setOpen(false);

  return (
    <Stack margin={theme.spacing(5)}>
      <Header>
        <Avatar
          alt='Otter'
          sx={{
            background: theme.palette.secondary.main,
          }}
          src='/otter-o-white.svg'
        />

        <Typography variant='h2'>Verify</Typography>
        <IconButton onClick={onClose}>
          <CloseCircle />
        </IconButton>
      </Header>
      <Stack alignItems='center'>
        <img width={theme.spacing(512)} src='/verification-art.png' alt='Verify your account' />
        <Typography>Verification code has been sent to your email</Typography>
        {!forEmail && !loading && (
          <FormControl required error={!!emailError && emailTouched}>
            <TextField
              type='text'
              label='Email'
              placeholder='Enter your email'
              value={email}
              disabled={loading}
              onBlur={() => setEmailTouched(true)}
              onChange={(e) => setEmail(e.target.value)}
            />
            {!!emailError && emailTouched && <FormHelperText>{emailError}</FormHelperText>}
          </FormControl>
        )}
        <FormControl required error={!!verificationError}>
          <VerificationCodeInput
            length={6}
            loading={loading}
            onChange={(code) => setCode(code)}
            onComplete={(code: string) => {
              if (forEmail) {
                onVerify(forEmail, code);
              }
            }}
          />
          {!!verificationError && <FormHelperText style={{ textAlign: 'center' }}>{verificationErrorMessage}</FormHelperText>}
        </FormControl>
        {!forEmail && (
          <Button variant='contained' color='primary' disabled={!!emailError || verificationCodeError} onClick={() => onVerify(email, code)}>
            Verify
          </Button>
        )}
        <Typography textAlign='center'>
          Didn't receive a verification code?{' '}
          <Link to={window.location.pathname} onClick={resend}>
            Resend
          </Link>
        </Typography>
        <Typography textAlign='center'>
          Want to create a different account?{' '}
          <Link to={window.location.pathname} onClick={onCreateDifferentAccount}>
            Create account
          </Link>
        </Typography>
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>
            Verification email has been sent.
          </Alert>
        </Snackbar>
      </Stack>
    </Stack>
  );
}

export type SignUpProps = DetailsStepProps & VerificationStepProps & { awaitingVerification: boolean };
export function SignUp({ awaitingVerification, ...props }: SignUpProps) {
  const [step, setStep] = useState<'details' | 'verification'>('details');

  useEffect(() => {
    setStep(awaitingVerification ? 'verification' : 'details');
  }, [awaitingVerification]);

  return <>{step === 'details' ? <DetailsStep {...props} /> : <VerificationStep {...props} />}</>;
}
