import { Chip, IconButton, InputAdornment, Stack, TextField, Tooltip, useTheme } from '@mui/material';
import { CloseCircle, SearchNormal1 } from 'iconsax-react';
import { CSSProperties, useEffect, useState } from 'react';

function truncate(str: string, len: number) {
  if (str.length > len) {
    return str.slice(0, len) + '...';
  } else {
    return str;
  }
}

export interface SearchProps {
  searchCriteria: string[];
  onSearchCriteriaUpdate: (searchCriteria: string[]) => void;
  style?: CSSProperties;
}

export function Search({ searchCriteria, onSearchCriteriaUpdate, style }: SearchProps) {
  const theme = useTheme();
  const [searchTokens, setSearchTokens] = useState<string[]>([]);
  const [tempSearchToken, setTempSearchToken] = useState('');

  useEffect(() => {
    setSearchTokens(searchCriteria.filter((c) => c !== tempSearchToken));
  }, [searchCriteria, tempSearchToken]);

  const clearSearch = () => {
    setSearchTokens([]);
    setTempSearchToken('');
    onSearchCriteriaUpdate([]);
  };

  const addChip = () => {
    let newSearchTokens: string[];
    if (searchTokens.includes(tempSearchToken)) {
      newSearchTokens = searchTokens;
    } else {
      newSearchTokens = [...searchTokens, tempSearchToken];
    }

    setSearchTokens(newSearchTokens);

    setTempSearchToken('');

    onSearchCriteriaUpdate([...newSearchTokens]);
  };

  const deleteChip = (token: string) => {
    const newSearchTokens = searchTokens.filter((t) => t !== token);

    setSearchTokens(newSearchTokens);

    if (tempSearchToken) {
      onSearchCriteriaUpdate([...newSearchTokens, tempSearchToken]);
    } else {
      onSearchCriteriaUpdate([...newSearchTokens]);
    }
  };

  const updateTempSearchToken = (token: string) => {
    const currentToken = token.replace(/^\s*/g, '');
    setTempSearchToken(currentToken);

    if (currentToken) {
      onSearchCriteriaUpdate([...searchTokens, currentToken]);
    } else {
      onSearchCriteriaUpdate([...searchTokens]);
    }
  };

  return (
    <TextField
      type='text'
      placeholder='Search...'
      value={tempSearchToken}
      onChange={(e) => updateTempSearchToken(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          addChip();
        }
      }}
      InputProps={{
        startAdornment: (
          <Stack direction='row' spacing={theme.spacing(2)} sx={{ marginRight: theme.spacing(2) }}>
            {searchTokens
              .filter((t) => !!t)
              .map((token) => {
                return (
                  <Tooltip key={token} title={token}>
                    <Chip label={truncate(token, 5)} onDelete={() => deleteChip(token)} />
                  </Tooltip>
                );
              })}
          </Stack>
        ),
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton onClick={clearSearch}>
              <CloseCircle />
            </IconButton>
            <SearchNormal1 size='1rem' />
          </InputAdornment>
        ),
      }}
      style={{
        borderRadius: theme.roundedCorners(5),
        ...style,
      }}
    />
  );
}
