import { Box, CircularProgress, Stack, Typography, useTheme } from '@mui/material';
import { Warning2 } from 'iconsax-react';
import { CSSProperties, ImgHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import { Document, useDocument } from '../api';

export interface DocumentImgProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'src' | 'width' | 'height' | 'style'> {
  containerWidth?: string | number | undefined;
  containerHeight?: string | number | undefined;
  containerBackground?: string | undefined;
  imgWidth?: string | number | undefined;
  imgHeight?: string | number | undefined;
  imgFillContainer?: boolean;
  document: Document;
  thumbnail?: boolean;
  containerStyle?: CSSProperties;
  imgStyle?: CSSProperties;
}

export function DocumentImg({
  containerBackground,
  containerWidth,
  containerHeight,
  containerStyle,
  document,
  thumbnail,
  imgWidth,
  imgHeight,
  imgFillContainer,
  imgStyle,
  ...props
}: DocumentImgProps) {
  const theme = useTheme();
  const [src, setSrc] = useState(thumbnail ? document.signedUrl : document.thumbnailSignedUrl || '/pdf-file-icon.svg');

  const { refreshSignedUrls } = useDocument();

  const attemptedErrorHandling = useRef(false);
  const onError = useCallback(async () => {
    if (attemptedErrorHandling.current) {
      return;
    }

    const { signedUrl, thumbnailSignedUrl } = await refreshSignedUrls(document.id);

    attemptedErrorHandling.current = true;

    setSrc(thumbnail ? thumbnailSignedUrl || '/pdf-file-icon.svg' : signedUrl);
  }, [refreshSignedUrls, document, thumbnail]);

  let iWidth, iHeight;
  if (src === '/pdf-file-icon.svg') {
    iWidth = 64;
    iHeight = 64;
  } else if (imgFillContainer) {
    iWidth = '100%';
    iHeight = '100%';
  }

  const img = (
    <img
      src={src || undefined}
      alt={document.merchantName || 'Unknown Document'}
      width={iWidth}
      height={iHeight}
      {...props}
      onError={onError}
      style={{
        objectFit: imgFillContainer && src !== '/pdf-file-icon.svg' ? 'cover' : undefined,
        ...imgStyle,
      }}
    />
  );

  if (containerBackground || containerHeight || containerWidth) {
    return (
      <Box
        style={{
          width: containerWidth,
          height: containerHeight,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          border: `1px solid ${theme.palette.border.main}`,
          borderRadius: theme.roundedCorners(5),
          background: containerBackground,
          overflow: 'hidden',
          ...containerStyle,
        }}
      >
        {img}
      </Box>
    );
  } else {
    return img;
  }
}

export interface DocumentViewerProps {
  imgWidth?: string | number | undefined;
  imgHeight?: string | number | undefined;
  document: Document;
  style?: CSSProperties;
}

export function DocumentViewer({ document, imgWidth, imgHeight, ...props }: DocumentViewerProps) {
  const theme = useTheme();
  const [src, setSrc] = useState<string | null>(null);
  const [error, setError] = useState(false);

  const { refreshSignedUrls } = useDocument();

  const attemptedValidation = useRef(false);
  useEffect(() => {
    const validate = async () => {
      const response = await fetch(document.signedUrl);

      if (response.status === 400) {
        const { signedUrl } = await refreshSignedUrls(document.id);

        setSrc(signedUrl);
      } else if (response.status >= 400) {
        setError(true);
      } else {
        setSrc(document.signedUrl);
      }
    };

    if (attemptedValidation.current) {
      return;
    }

    validate().catch((e) => {
      throw e;
    });
  }, [refreshSignedUrls, document]);

  if (src && document.fileType.startsWith('image/')) {
    return (
      <Stack alignItems='center' {...props}>
        <img src={src} style={{ objectFit: 'contain' }} />
      </Stack>
    );
  } else if (src) {
    return <iframe src={src} {...props}></iframe>;
  } else if (error) {
    return (
      <Stack justifyContent='center' alignItems='center' {...props}>
        <Warning2 color={theme.palette.error.main} />
        <Typography color='error'>An error occurred</Typography>
      </Stack>
    );
  } else {
    return (
      <Stack justifyContent='center' alignItems='center' {...props}>
        <CircularProgress />
      </Stack>
    );
  }
}
