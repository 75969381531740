import { Box, Fade, LinearProgress, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useConversation } from '../../api';
import { Button } from '../button';
import { Logo } from '../logo';
import { IWidgetProps } from './IWidgetProps';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100vw;
  height: 100dvh;
  background: ${({ theme }) => theme.palette.background.default};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const Otter1 = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      style={{
        position: 'relative',
      }}
    >
      <Typography
        variant={isSmallScreen ? 'h4' : 'h3'}
        style={{
          position: 'absolute',
          top: isSmallScreen ? '24px' : '70px',
          left: isSmallScreen ? '17px' : '32px',
          width: isSmallScreen ? '150px' : '304px',
        }}
      >
        Keep an eye out for the occasional message from Otter asking for more information about your business transactions
      </Typography>
      <img
        style={{
          position: 'relative',
          top: 5,
        }}
        src='/onboarding-bubble.png'
        alt='Onboarding'
        width={isSmallScreen ? '184px' : '368px'}
        height={isSmallScreen ? '217px' : '434px'}
      />
    </Box>
  );
};

const Otter2 = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      style={{
        position: 'relative',
      }}
    >
      <Typography
        variant={isSmallScreen ? 'h4' : 'h3'}
        style={{
          position: 'absolute',
          top: isSmallScreen ? '24px' : '60px',
          left: isSmallScreen ? '17px' : '32px',
          width: isSmallScreen ? '150px' : '304px',
        }}
      >
        Don't get lost under a sea of receipts! Send us your invoices and receipts right away, and we'll take it from there!
      </Typography>
      <img
        style={{
          position: 'relative',
          top: 5,
        }}
        src='/onboarding-bubble-2.png'
        alt='Onboarding'
        width={isSmallScreen ? '184px' : '368px'}
        height={isSmallScreen ? '207px' : '413px'}
      />
    </Box>
  );
};

const Loader = styled(({ show, onComplete, className }: { show: boolean; onComplete: () => void; className?: string }) => {
  const ONE_SECOND = 1000;
  const LOADING_TIME_TARGET = 20 * ONE_SECOND;
  const [percentComplete, setPercentComplete] = useState(0);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedScreen = useMediaQuery(theme.breakpoints.down('md'));

  const start = useRef(Date.now());
  useEffect(() => {
    if (!show) {
      return;
    }

    const INTERVAL = ONE_SECOND;
    const interval = setInterval(() => {
      if (Date.now() - start.current <= 2 * ONE_SECOND) {
        return;
      }

      const increase = Math.round(Math.random() * 2 * 100 * (INTERVAL / LOADING_TIME_TARGET));
      setPercentComplete((existing) => Math.min(existing + increase, 100));
    }, INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [LOADING_TIME_TARGET, show]);

  const isComplete = useRef(false);
  useEffect(() => {
    if (!isComplete.current && percentComplete >= 100) {
      isComplete.current = true;
      setTimeout(() => {
        onComplete();
      }, ONE_SECOND);
    }
  }, [percentComplete, onComplete]);

  return (
    <Box className={className}>
      <Logo width='100px' />
      <Stack alignItems='center'>
        <Typography>Personalizing your Otter experience...</Typography>
        <Box width={isSmallScreen || isMedScreen ? '256px' : '480px'}>
          <LinearProgress variant='determinate' value={percentComplete} color='primary' />
        </Box>
        <Typography>{percentComplete}% complete</Typography>
      </Stack>
      {!isSmallScreen && <Box></Box>}
    </Box>
  );
})`
  color: #fff;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;

  padding-bottom: ${({ theme }) => theme.spacing(10)};
  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-rows: 1fr 1fr;
    padding-bottom: 0;
  }
`;

export function OnboardingSplashWidget({ isSuccess }: IWidgetProps) {
  const { addConversationMessage, conversationId } = useConversation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const [showOverlay, setShowOverlay] = useState(false);

  const okResponse = async () => {
    setShowOverlay(true);
    await addConversationMessage({
      conversationId: conversationId!,
      message: 'Ok',
      includeMessageInConversation: false,
    });
  };

  const complete = () => {
    navigate('/home');
  };

  const [otterSlot, setOtterSlot] = useState(0);

  const started = useRef(Date.now());
  useEffect(() => {
    const ONE_SECOND = 1000;
    const interval = setInterval(() => {
      if (Date.now() - started.current > 10 * ONE_SECOND) {
        setOtterSlot(1);
      }
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Button variant='contained' color='primary' disabled={isSuccess === true || isSuccess === false} onClick={okResponse}>
        Ok!
      </Button>
      {
        <Fade in={showOverlay} timeout={500}>
          <Overlay>
            <Stack
              direction={isSmallScreen ? 'column-reverse' : 'row'}
              alignItems='center'
              spacing={isSmallScreen ? theme.spacing(15) : theme.spacing(20)}
              style={{
                border: `1px solid ${theme.palette.border.main}`,
                borderRadius: theme.roundedCorners(10),
                paddingLeft: isSmallScreen ? theme.spacing(5) : theme.spacing(10),
                paddingRight: isSmallScreen ? theme.spacing(5) : theme.spacing(10),
                paddingTop: isSmallScreen ? theme.spacing(10) : theme.spacing(10),
              }}
            >
              {otterSlot === 0 && <Otter1 />}
              {otterSlot === 1 && <Otter2 />}
              <Loader show={showOverlay} onComplete={complete} />
            </Stack>
          </Overlay>
        </Fade>
      }
    </>
  );
}
