import { Dialog, DialogContent, DialogTitle, IconButton, Stack, TextField } from '@mui/material';
import { CloseCircle } from 'iconsax-react';
import { useMemo } from 'react';
import { Account, Document, Organization } from '../../../api';
import { ThreeColumn } from '../../../components';
import { getFiscalYear } from '../../../utils/date-utils';

export interface CheckInResultsDialogProps {
  organization: Organization | null;
  openFor: Document | null;
  accountsByFy: { [fy: string]: Account[] };
  onClose: () => void;
}

export function CheckInResultsDialog({ openFor, organization, accountsByFy, onClose }: CheckInResultsDialogProps) {
  const paymentAccount = useMemo(() => {
    if (!openFor || !openFor.checkInPaymentAccountSpecified || !organization) {
      return null;
    }

    if (openFor.checkInPaymentAccountSpecified === `I don't know`) {
      return null;
    }

    const fy = getFiscalYear(openFor.date!, organization.fyEndMonth);
    const accounts = accountsByFy[fy];

    return accounts.find((a) => a.id === openFor.checkInPaymentAccountSpecified);
  }, [openFor, accountsByFy, organization]);

  return (
    <Dialog open={!!openFor} onClose={onClose}>
      <DialogTitle>
        <ThreeColumn $mainColumn align='center'>
          <span></span>
          <span>Check-In Results</span>
          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </ThreeColumn>
      </DialogTitle>
      <DialogContent>
        <Stack>
          {openFor && (
            <>
              {openFor.checkInDescription !== null && (
                <TextField multiline InputProps={{ readOnly: true }} fullWidth label='Description Provided' value={openFor.checkInDescription} />
              )}

              {openFor.checkInUnmatchedReason !== null && (
                <TextField InputProps={{ readOnly: true }} fullWidth label='Unmatched Reason Provided' value={openFor.checkInUnmatchedReason} />
              )}

              {!!paymentAccount && (
                <TextField InputProps={{ readOnly: true }} fullWidth label='Payment Account Provided' value={paymentAccount?.name} />
              )}

              {openFor.checkInPaymentAccountSpecified === `I don't know` && (
                <TextField InputProps={{ readOnly: true }} fullWidth label='Payment Account Provided' value='Unknown' />
              )}

              {openFor.checkInPaidForPersonallyHandling && (
                <TextField
                  InputProps={{ readOnly: true }}
                  fullWidth
                  label='Handle "Paid for Personally"'
                  value={openFor.checkInPaidForPersonallyHandling}
                />
              )}

              {openFor.checkInReimburseToUserId && (
                <TextField InputProps={{ readOnly: true }} fullWidth label='Reimburse to User ID' value={openFor.reimburseToUserId} />
              )}
            </>
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
