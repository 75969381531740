import { Box, Chip, InputAdornment, Stack, TextField, Typography, alpha, emphasize, useTheme } from '@mui/material';
import { ArrowRight, Message, SearchNormal1 } from 'iconsax-react';
import { CSSProperties, RefObject, createRef, forwardRef, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled, { keyframes } from 'styled-components';
import { Conversation, useConversation } from '../../api';
import { Button, PageBody, PageContainer, PageHeader } from '../../components';

const HistorySection = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.palette.background.default};
  border-radius: ${({ theme }) => theme.roundedCorners(5)};
  flex: 1;
  box-sizing: border-box;

  padding: ${({ theme }) => theme.spacing(10)};
  padding-left: ${({ theme }) => theme.spacing(25)};
  padding-right: ${({ theme }) => theme.spacing(25)};
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: ${({ theme }) => theme.spacing(5)};
    padding-left: ${({ theme }) => theme.spacing(5)};
    padding-right: ${({ theme }) => theme.spacing(5)};
  }

  overflow: auto;
`;

interface ConversationButtonProps {
  text: string;
  isNew: boolean;
  className?: string;
  onClick: () => void;
  style?: CSSProperties;
}
const ConversationButton = styled(
  forwardRef<HTMLButtonElement, ConversationButtonProps>(({ text, isNew, className, onClick, ...props }, ref) => {
    const theme = useTheme();

    return (
      <Button
        ref={ref}
        variant='outlined'
        color='secondary'
        shade={100}
        disableElevation={false}
        elevation={8}
        className={className}
        onClick={onClick}
        {...props}
      >
        <Box display='flex' width='100%' flexDirection='row' justifyContent='space-between' alignItems='center'>
          <Stack flex={1} direction='row' alignItems='center' marginRight={theme.spacing(3)}>
            <Message variant='Bold' color={theme.palette.primary.main} style={{ flexShrink: 0 }} />
            <Typography textAlign='left'>{text}</Typography>
          </Stack>
          <Stack flex={0} direction='row' alignItems='center'>
            {isNew && <Chip label='New' color='primary' />}
            <ArrowRight />
          </Stack>
        </Box>
      </Button>
    );
  })
)`
  padding-top: ${({ theme }) => theme.spacing(3)};
  padding-bottom: ${({ theme }) => theme.spacing(3)};
  padding-left: ${({ theme }) => theme.spacing(4)};
  padding-right: ${({ theme }) => theme.spacing(4)};
  background-color: ${({ theme }) => theme.palette.background.paper};
  border: 1px solid ${({ theme }) => theme.palette.border.main};
  border-radius: ${({ theme }) => theme.roundedCorners(4)};

  &:hover {
    background-color: ${({ theme }) => emphasize(theme.palette.background.paper)};
    border: 1px solid ${({ theme }) => theme.palette.border.main};
  }
`;

const useConversations = (searchCriteria: string) => {
  const { conversations, hasConversationBeenViewed } = useConversation();

  const conversationsByDay = Object.values(conversations)
    .filter((c) => {
      return (
        !searchCriteria ||
        c.title.toLowerCase().includes(searchCriteria.toLowerCase()) ||
        c.messages.find((m) => m.content.toLowerCase().includes(searchCriteria.toLowerCase()))
      );
    })
    .sort((a, b) => b.updated.getTime() - a.updated.getTime())
    .reduce(
      (map, current) => {
        const day = new Date(current.updated).toLocaleDateString('en-CA', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });

        if (!map[day]) {
          map[day] = [];
        }

        map[day].push(current);

        return map;
      },
      {} as { [day: string]: Conversation[] }
    );

  return {
    conversationsByDay,
    hasConversationBeenViewed,
  };
};

const slideAppearAnimation = keyframes`
  from {
    transform: scaleY(0%);
    max-height: 0px;
  }
  to {
    transform: scaleY(100%);
    max-height: 50px;
  }
`;

const slideExitAnimation = keyframes`
  from {
    transform: scaleY(100%);
    max-height: 50px;
  }
  to {
    transform: scaleY(0%);
    max-height: 0px;
  }
`;

const AnimateSlide = styled.div`
  &.slide-appear,
  &.slide-enter {
    animation: ${slideAppearAnimation} 250ms forwards;
  }

  @.slide-exit {
    animation: ${slideExitAnimation} 250ms forwards;
  }
`;

export function ConversationHistoryPage({ ...props }) {
  const [searchCriteria, setSearchCriteria] = useState('');
  const { conversationsByDay, hasConversationBeenViewed } = useConversations(searchCriteria);
  const transitionRefs = useRef<{ [conversationId: string]: RefObject<HTMLDivElement> }>({});
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <PageContainer {...props}>
      <PageHeader title='Conversation History' />
      <PageBody gutter='thin'>
        <TextField
          type='text'
          placeholder='Search by title or contents'
          value={searchCriteria}
          onChange={(e) => setSearchCriteria(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <SearchNormal1 size='1rem' />
              </InputAdornment>
            ),
          }}
          style={{
            borderRadius: theme.roundedCorners(5),
            marginBottom: theme.spacing(5),
          }}
        />
        <HistorySection>
          <Stack>
            {Object.keys(conversationsByDay).map((day) => {
              return (
                <Stack key={`day-section-${day}`}>
                  <Typography color={alpha(theme.palette.text.primary, 0.5)}>{day}</Typography>
                  <TransitionGroup component={Stack}>
                    {conversationsByDay[day].map((conversation) => {
                      if (!transitionRefs.current[conversation.id]) {
                        transitionRefs.current[conversation.id] = createRef();
                      }

                      return (
                        <CSSTransition
                          nodeRef={transitionRefs.current[conversation.id]}
                          key={conversation.id}
                          unmountOnExit
                          timeout={250}
                          appear={true}
                          enter={true}
                          exit={true}
                          classNames='slide'
                        >
                          <AnimateSlide ref={transitionRefs.current[conversation.id]}>
                            <ConversationButton
                              text={conversation.title}
                              onClick={() => navigate(`/conversations/${conversation.id}`)}
                              isNew={!hasConversationBeenViewed(conversation)}
                              style={{
                                width: '100%',
                              }}
                            />
                          </AnimateSlide>
                        </CSSTransition>
                      );
                    })}
                  </TransitionGroup>
                </Stack>
              );
            })}
          </Stack>
        </HistorySection>
      </PageBody>
    </PageContainer>
  );
}
