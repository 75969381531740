import { Badge, Divider, IconButton, ListItemText, MenuItem, Stack, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Clock, Home3, InfoCircle, Logout, Message, Notification, People } from 'iconsax-react';
import { MouseEvent, RefObject, createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled, { keyframes } from 'styled-components';
import { v4 as uuid } from 'uuid';
import { Role, useConversation, useSession } from '../api';
import { SMALL_HORIZONTAL_SPACING } from '../theme';
import { ScreenSize, useScreenSize } from '../utils/use-screen-size';
import AppShell, { NavLink } from './app-shell';
import { AvatarMenu } from './app-shell-header';

const SECOND_IN_MS = 1000;

function changeFavicon(src: string) {
  const link = document.createElement('link');
  const oldLink = document.getElementById('favicon');

  link.id = 'favicon';
  link.rel = 'icon';
  link.type = 'image/png';
  link.href = src;

  if (oldLink) {
    document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
}

const useConversations = () => {
  const theme = useTheme();
  const { conversations: conversationsMap, fetchConversations, hasConversationBeenViewed, addConversationMessage } = useConversation();
  const { session, createUnregisteredUser, startUnregisteredSession } = useSession();

  const startConversation = useCallback(
    async (message: string, strategy?: string) => {
      const id = uuid();
      if (!session) {
        const user = await createUnregisteredUser();
        await startUnregisteredSession(user.id);
        await addConversationMessage({ conversationId: id, message, strategy, includeMessageInConversation: !strategy });
      } else {
        await addConversationMessage({ conversationId: id, message, strategy, includeMessageInConversation: !strategy });
      }
      return id;
    },
    [createUnregisteredUser, startUnregisteredSession, addConversationMessage, session]
  );

  const initialized = useRef(false);
  useEffect(() => {
    if (initialized.current === true || !session) {
      return;
    }

    fetchConversations()
      .then(() => {
        initialized.current = true;
      })
      .catch((e) => {
        throw e;
      });

    const interval = setInterval(() => {
      fetchConversations()
        .then(() => {
          initialized.current = true;
        })
        .catch((e) => {
          throw e;
        });
    }, 30 * SECOND_IN_MS);

    return () => {
      clearInterval(interval);
    };
  }, [fetchConversations, session]);

  useEffect(() => {
    if (!session) {
      initialized.current = false;
    }
  }, [session]);

  const conversations = useMemo(() => {
    return Object.values(conversationsMap)
      .filter((c) => c.title)
      .sort((a, b) => b.updated.getTime() - a.updated.getTime())
      .slice(0, 10);
  }, [conversationsMap]);

  useEffect(() => {
    const unviewedConversations = Object.values(conversationsMap)
      .filter((c) => !hasConversationBeenViewed(c))
      .reduce((count) => {
        return count + 1;
      }, 0);

    if (unviewedConversations > 0) {
      document.title = `(${unviewedConversations}) Otter`;

      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        changeFavicon('/favicon-badged-dark.png');
      } else if (window.matchMedia('(prefers-color-scheme: light)').matches) {
        changeFavicon('/favicon-badged-light.png');
      } else {
        if (theme.palette.mode === 'dark') {
          changeFavicon('/favicon-badged-dark.png');
        } else {
          changeFavicon('/favicon-badged-light.png');
        }
      }
    } else {
      document.title = 'Otter';
      changeFavicon('/favicon.png');
    }

    return () => {
      document.title = 'Otter';
    };
  }, [conversationsMap, hasConversationBeenViewed, theme]);

  return {
    conversations,
    hasConversationBeenViewed,
    startConversation,
  };
};

const TruncatedText = styled(({ className, text }: { className?: string; text: string }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const resizeObserverRef = useRef<ResizeObserver | null>(null);

  const isOverflowing = useCallback(() => {
    if (!ref.current) {
      return false;
    }

    return ref.current.scrollHeight > ref.current.clientHeight || ref.current.scrollWidth > ref.current.clientWidth;
  }, []);

  const truncateToFit = useCallback(() => {
    let tempText = text;

    while (isOverflowing() && tempText.length > 0) {
      tempText = tempText.slice(0, -1);
      ref.current!.innerText = tempText + '...';
    }
  }, [isOverflowing, text]);

  useEffect(() => {
    ref.current!.innerText = text;
    truncateToFit();
  }, [text, truncateToFit]);

  useEffect(() => {
    if (ref.current) {
      resizeObserverRef.current = new ResizeObserver(() => {
        truncateToFit();
      });
      resizeObserverRef.current.observe(ref.current);
    }
    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
        resizeObserverRef.current = null;
      }
    };
  }, [text, truncateToFit]);

  useEffect(() => {
    function handleWindowResize() {
      ref.current!.innerText = text;
      truncateToFit();
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [text, truncateToFit]);

  return (
    <div
      ref={ref}
      className={className}
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      }}
    ></div>
  );
})`
  flex: 1;
  min-width: 0;
`;

const ConversationStack = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack spacing={0} maxWidth='100%'>
      {children}
    </Stack>
  );
};

const slideNewAnimation = keyframes`
  from {
    transform: scaleY(0%);
    max-height: 0px;
  }
  to {
    transform: scaleY(100%);
    max-height: 1.1rem;
  }
`;

const AnimatedConversation = styled.div`
  &.slide-enter {
    overflow: hidden;
    animation: ${slideNewAnimation} 250ms forwards;
  }
`;

const ConversationsZeroState = ({ ...props }) => {
  const theme = useTheme();

  return (
    <Stack alignItems='center' justifyContent='center' {...props} marginTop={theme.spacing(10)} height={theme.spacing(64)}>
      <Message size='2rem' color={theme.palette.secondary[200]} />
      <Typography textAlign='center' color={theme.palette.secondary[200]}>
        No Conversations
      </Typography>
    </Stack>
  );
};

const useUserDetailsRedirect = () => {
  const { session, userDetailsRequired, checkUserDetailsRequired } = useSession();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!session) {
      return;
    }

    checkUserDetailsRequired().catch((e) => {
      throw e;
    });
  }, [checkUserDetailsRequired, session]);

  useEffect(() => {
    if (userDetailsRequired === 'PAYROLL') {
      const redirect = encodeURIComponent(location.pathname + location.search);

      navigate(`/payroll-details?redirect=${redirect}`);
    }
  }, [userDetailsRequired, navigate, location]);
};

export interface UserAppShellProps {
  children: React.ReactNode;
}

export function UserAppShell({ children }: UserAppShellProps) {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const { conversations, hasConversationBeenViewed } = useConversations();
  const { session, logout } = useSession();
  const transitionRefs = useRef<{ [conversationId: string]: RefObject<HTMLDivElement> }>({});
  const screenSize = useScreenSize();
  const isSmallScreen = screenSize === ScreenSize.SMALL;
  const isMedScreen = screenSize === ScreenSize.MEDIUM;

  const navLinkClose = (e: MouseEvent) => {
    if ((e.target as HTMLDivElement).classList.contains('MuiLink-root')) {
      setFlyoutOpen(false);
    }
  };

  const unreadCount = useMemo(() => {
    return conversations.reduce((unread, current) => {
      if (hasConversationBeenViewed(current)) {
        return unread;
      }

      return unread + 1;
    }, 0);
  }, [conversations, hasConversationBeenViewed]);

  const [flyoutOpen, setFlyoutOpen] = useState(false);

  const openNotifications = useCallback(() => {
    if (isSmallScreen || isMedScreen) {
      setFlyoutOpen(true);
    } else {
      navigate('/conversation-history');
    }
  }, [navigate, isSmallScreen, isMedScreen]);

  useUserDetailsRedirect();

  return (
    <AppShell
      flyoutOpen={flyoutOpen}
      onFlyoutChange={(open) => setFlyoutOpen(open)}
      authenticatedHeaderContent={
        session && (
          <>
            <IconButton style={{ marginRight: theme.spacing(2) }} onClick={openNotifications}>
              <Badge badgeContent={unreadCount} color='primary' max={9}>
                <Notification variant='Outline' size='1.8rem' color={theme.palette.secondary[100]} />
              </Badge>
            </IconButton>

            <AvatarMenu
              session={session}
              menuItems={(closeMenu) => {
                return [
                  session.role === Role.OWNER && session.isRegistered ? (
                    <MenuItem
                      key='team'
                      onClick={() => {
                        closeMenu();
                        navigate('team');
                      }}
                    >
                      <People style={{ marginRight: theme.spacing(5) }} />
                      <ListItemText>Team</ListItemText>
                    </MenuItem>
                  ) : undefined,

                  <MenuItem
                    key='logout'
                    onClick={async () => {
                      closeMenu();
                      await logout();
                    }}
                  >
                    <Logout style={{ marginRight: theme.spacing(5) }} />
                    <ListItemText>Logout</ListItemText>
                  </MenuItem>,
                ];
              }}
            />
          </>
        )
      }
      leftNavContent={
        <Stack
          flex={1}
          spacing={10}
          marginTop={theme.spacing(12)}
          justifyContent='space-between'
          style={{
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          <Stack spacing={0} onClick={navLinkClose} alignItems='stretch'>
            <NavLink to='/home' active={location.pathname.startsWith('/home')}>
              <Home3 size='1.1rem' style={{ flexShrink: 0, marginRight: theme.spacing(SMALL_HORIZONTAL_SPACING) }} />
              Home
            </NavLink>

            <Divider color={theme.palette.border.main} />

            {conversations.length === 0 && <ConversationsZeroState alignSelf='center' />}

            {conversations.length > 0 && (
              <TransitionGroup component={ConversationStack}>
                {conversations.map((conversation) => {
                  if (!transitionRefs.current[conversation.id]) {
                    transitionRefs.current[conversation.id] = createRef();
                  }

                  return (
                    <CSSTransition
                      nodeRef={transitionRefs.current[conversation.id]}
                      key={conversation.id}
                      unmountOnExit
                      timeout={250}
                      classNames='slide'
                    >
                      <AnimatedConversation ref={transitionRefs.current[conversation.id]}>
                        <NavLink to={'/conversations/' + conversation.id} active={location.pathname.startsWith('/conversations/' + conversation.id)}>
                          <Message size='1.1rem' style={{ flexShrink: 0, marginRight: theme.spacing(SMALL_HORIZONTAL_SPACING) }} />
                          <TruncatedText text={conversation.title} />
                          {!hasConversationBeenViewed(conversation) && (
                            <InfoCircle
                              size='1.1rem'
                              variant='Bold'
                              color={location.pathname.startsWith('/conversations/' + conversation.id) ? undefined : theme.palette.primary.main}
                              style={{ flexShrink: 0, marginLeft: theme.spacing(SMALL_HORIZONTAL_SPACING) }}
                            />
                          )}
                        </NavLink>
                      </AnimatedConversation>
                    </CSSTransition>
                  );
                })}
              </TransitionGroup>
            )}
          </Stack>

          <Stack>
            <NavLink
              to='/conversation-history'
              active={location.pathname.startsWith('/conversation-history')}
              style={{
                borderTop: `1px solid ${theme.palette.border.main}`,
              }}
            >
              <Clock size='1.1rem' style={{ flexShrink: 0, marginRight: theme.spacing(3) }} />
              Conversation History
            </NavLink>
          </Stack>
        </Stack>
      }
      mainContent={children}
    />
  );
}
