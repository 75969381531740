import { Box, Fade, Stack, Typography, useTheme } from '@mui/material';
import { CSSProperties, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { TransitionGroup } from 'react-transition-group';
import styled from 'styled-components';
import { useConversation } from '../../api';
import { SMALL_HORIZONTAL_SPACING, SMALL_VERTICAL_SPACING } from '../../theme';
import { ScreenSize, useScreenSize } from '../../utils/use-screen-size';
import { IWidgetProps } from './IWidgetProps';

export interface InsightPanelWidgetProps extends IWidgetProps {
  insights?: {
    [insight: string]: {
      amount: number;
      currency?: string;
      approximate?: boolean;
      description: string;
    };
  };
}

const Grid = styled.div<{ $numInsights: number; $screenSize: ScreenSize }>`
  display: grid;
  grid-template-columns: ${({ $numInsights, $screenSize }) => {
    if ($screenSize === ScreenSize.LARGE && $numInsights > 2) {
      return `1fr 1fr 1fr 1fr;`;
    } else if ($screenSize === ScreenSize.LARGE || $screenSize === ScreenSize.MEDIUM) {
      return `1fr 1fr;`;
    } else {
      return `1fr;`;
    }
  }}
  grid-auto-rows: 1fr;
  column-gap: ${({ theme }) => theme.spacing(SMALL_HORIZONTAL_SPACING)};
  row-gap: ${({ theme }) => theme.spacing(SMALL_VERTICAL_SPACING)};
`;

const useCountUp = (countTo: number, timeoutMs: number, decimalPlaces: number) => {
  const [renderedAmount, setRenderedAmount] = useState(0);

  function createParabolaIntegralFunction(totalTime: number, totalArea: number): (t: number) => number {
    return function (t: number) {
      return -(totalArea / (2 * Math.pow(totalTime, 3))) * Math.pow(t, 3) + ((3 * totalArea) / (2 * totalTime)) * t;
    };
  }

  const cumulativeAtX = useMemo(() => {
    return createParabolaIntegralFunction(timeoutMs, countTo);
  }, [countTo, timeoutMs]);

  const startTime = useRef(Date.now());
  const tempAmount = useRef(0);
  useEffect(() => {
    let timeout: number | null = null;

    const update = () => {
      const t = Date.now() - startTime.current;
      if (t > timeoutMs) {
        setRenderedAmount(countTo);
        return;
      }

      const target = cumulativeAtX(t);

      tempAmount.current = Math.min(target, countTo);

      const factor = Math.pow(10, decimalPlaces);
      const rounded = Math.round(tempAmount.current * factor) / factor;

      setRenderedAmount(rounded);

      timeout = setTimeout(update, 100) as unknown as number;
    };

    update();

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [countTo, cumulativeAtX, timeoutMs, decimalPlaces]);

  return renderedAmount;
};

const Metric = forwardRef<
  HTMLDivElement,
  {
    amount: number;
    description: string;
    currency?: string;
    approximate?: boolean;
    countTimeoutMs: number;
    style?: CSSProperties;
  }
>(({ amount, description, currency, approximate, countTimeoutMs, style }, ref) => {
  const theme = useTheme();

  const numberAmount = useCountUp(amount, countTimeoutMs, currency ? 2 : 0);

  let renderedAmount;
  if (currency) {
    const formatter = new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 2, // You can adjust the fraction digits as needed
    });

    renderedAmount = formatter.format(numberAmount);
  } else {
    renderedAmount = numberAmount;
  }

  return (
    <Stack
      ref={ref}
      direction='column'
      borderRadius={theme.roundedCorners(5)}
      paddingX={theme.spacing(SMALL_HORIZONTAL_SPACING)}
      paddingY={theme.spacing(SMALL_VERTICAL_SPACING)}
      spacing={theme.spacing(SMALL_VERTICAL_SPACING)}
      sx={{
        background: theme.palette.background2.default,
      }}
      style={style}
    >
      <Box>
        <Typography variant='h1'>{renderedAmount}</Typography>
        {approximate && <Typography variant='small'>(approximately)</Typography>}
      </Box>
      <Typography>{description}</Typography>
    </Stack>
  );
});

export function InsightPanelWidget({ insights }: InsightPanelWidgetProps) {
  const { scrollLockMutable } = useConversation();
  const screenSize = useScreenSize();

  return (
    <Grid $numInsights={insights ? Object.keys(insights).length : 0} $screenSize={screenSize}>
      <TransitionGroup component={null}>
        {Object.entries(insights!).map(([insight, info]) => {
          return (
            <Fade
              key={insight}
              timeout={250}
              mountOnEnter
              unmountOnExit
              onEnter={() => (scrollLockMutable.current = false)}
              onEntered={() => (scrollLockMutable.current = true)}
              onExiting={() => (scrollLockMutable.current = false)}
              onExited={() => (scrollLockMutable.current = true)}
            >
              <Metric
                amount={info.amount}
                currency={info.currency}
                approximate={info.approximate}
                description={info.description}
                countTimeoutMs={Math.min(info.amount * 50, 3000)}
                style={{ height: '100%' }}
              />
            </Fade>
          );
        })}
      </TransitionGroup>
    </Grid>
  );
}
