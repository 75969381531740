import { Checkbox, FormControlLabel, Stack, TextField } from '@mui/material';
import { useRef, useState } from 'react';
import { useTransaction } from '../../api';
import { PageBody, PageContainer, PageHeader } from '../../components';

export function CsvUploadPage() {
  const [accountNumber, setAccountNumber] = useState('');
  const [flipAmounts, setFlipAmounts] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { uploadCsv } = useTransaction();

  const parseAndUpload = () => {
    const file = fileInputRef.current!.files![0];
    if (!file) {
      return;
    }

    const fileName = file.name;
    const isValidCsv = fileName.slice(((fileName.lastIndexOf('.') - 1) >>> 0) + 2) === 'csv';
    if (!isValidCsv) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const fileContent = event.target?.result as string;

      uploadCsv(accountNumber, fileContent, flipAmounts).catch((e) => console.log(e));
    };

    reader.onerror = (e) => {
      console.log(e);
    };

    reader.readAsText(file);
  };

  return (
    <PageContainer>
      <PageHeader title='CSV Upload' />
      <PageBody>
        <Stack>
          <TextField label='Account Number' placeholder='#' value={accountNumber} onChange={(e) => setAccountNumber(e.target.value)} />

          <FormControlLabel control={<Checkbox onClick={() => setFlipAmounts((current) => !current)} />} label='Flip Amounts' />

          <input type='file' ref={fileInputRef} />
          <button onClick={parseAndUpload}>Upload</button>
        </Stack>
      </PageBody>
    </PageContainer>
  );
}
