import { SafeArea } from '@capacitor-community/safe-area';
import { PaletteMode } from '@mui/material';
import { createContext, useContext, useEffect, useState } from 'react';
import { storage } from './utils/storage';

export interface IThemeModeContext {
  mode: PaletteMode;
  setMode: (mode: PaletteMode) => void;
}

export const ThemeModeContext = createContext<IThemeModeContext>({} as IThemeModeContext);

export const useThemeMode = () => useContext(ThemeModeContext);

const themeSafeAreas = async (mode: PaletteMode) => {
  await SafeArea.enable({
    config: {
      customColorsForSystemBars: true,
      statusBarColor: '#00000000', // transparent
      statusBarContent: mode === 'dark' ? 'light' : 'dark',
      navigationBarColor: '#00000000', // transparent
      navigationBarContent: mode === 'dark' ? 'light' : 'dark',
    },
  });
};

const THEME = 'theme';

function useThemeModeData() {
  const [mode, setMode] = useState<PaletteMode>('dark');

  const updateMode = async (newMode: PaletteMode) => {
    await storage.setItem(THEME, newMode);
    setMode(newMode);
    await themeSafeAreas(newMode);
  };

  useEffect(() => {
    const getTheme = async () => {
      const theme = await storage.getItem(THEME);
      if (theme) {
        setMode(theme as PaletteMode);
        await themeSafeAreas(theme as PaletteMode);
      } else {
        setMode('dark');
        await themeSafeAreas('dark');
      }
    };

    getTheme().catch((e) => {
      throw e;
    });
  }, []);

  return {
    mode,
    setMode: updateMode,
  } as IThemeModeContext;
}

export const ThemeModeContextProvider = ({ children }: { children: React.ReactNode }) => {
  const themeMode = useThemeModeData();

  return <ThemeModeContext.Provider value={themeMode}>{children}</ThemeModeContext.Provider>;
};
