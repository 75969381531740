import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Button as MuiButton,
  Select,
  Stack,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { ArrowLeft2, Cd, Message } from 'iconsax-react';
import { CSSProperties, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { Conversation, Organization, useAdmin } from '../../../api';
import {
  AdminOrganizationSelect,
  Button,
  ConversationInput,
  ConversationMessageContainer,
  ConversationMessages,
  PageBody,
  PageContainer,
  PageHeader,
} from '../../../components';

const useAdminData = (selectedOrganization: Organization | null) => {
  const {
    fetchOrganizations,
    fetchConversations,
    organizations,
    conversations: apiConversations,
    startImpersonatedConversation,
    addImpersonatedMessageToConversation,
  } = useAdmin();

  useEffect(() => {
    fetchOrganizations().catch((e) => {
      throw e;
    });
  }, [fetchOrganizations]);

  useEffect(() => {
    if (!selectedOrganization) {
      return;
    }

    fetchConversations(selectedOrganization.id!).catch((e) => {
      throw e;
    });
  }, [selectedOrganization, fetchConversations]);

  const conversations = useMemo(() => {
    if (!selectedOrganization) {
      return null;
    }

    const conversations = apiConversations[selectedOrganization.id!];
    if (!conversations) {
      return null;
    }

    return conversations.sort((a, b) => b.updated.getTime() - a.updated.getTime());
  }, [selectedOrganization, apiConversations]);

  const conversationsById = useMemo(() => {
    if (!selectedOrganization || !conversations) {
      return null;
    }

    return conversations.reduce(
      (map, current) => {
        map[current.id] = current;
        return map;
      },
      {} as { [conversationId: string]: Conversation }
    );
  }, [selectedOrganization, conversations]);

  return {
    organizations,
    conversations,
    conversationsById,
    startImpersonatedConversation,
    addImpersonatedMessageToConversation,
  };
};

interface ConversationTableProps {
  conversations: Conversation[];
  onSelectConversation: (conversation: Conversation) => void;
  style?: CSSProperties;
}
function ConversationsTable({ conversations, onSelectConversation, style }: ConversationTableProps) {
  const columns: GridColDef[] = [
    {
      field: 'title',
      headerName: 'Title',
      width: 256,
    },
    {
      field: 'preview',
      headerName: 'Preview',
      flex: 1,
      valueGetter: (params) => {
        const conversation = params.row as Conversation;

        return conversation.latestMessage?.content;
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      filterable: false,
      flex: 0,
      renderCell: (params) => {
        return (
          <Button variant='contained' color='primary' onClick={() => onSelectConversation(params.row as Conversation)}>
            View
          </Button>
        );
      },
    },
  ];

  return (
    <DataGrid
      style={style}
      rows={conversations}
      columns={columns}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 50,
          },
        },
      }}
      pageSizeOptions={[50]}
    />
  );
}

interface ConversationsOverviewProps {
  conversations: Conversation[];
  onSelectConversation: (conversation: Conversation) => void;
}
function ConversationsOverview({ conversations, onSelectConversation }: ConversationsOverviewProps) {
  return <ConversationsTable conversations={conversations} onSelectConversation={onSelectConversation} />;
}

interface StartConversationModalProps {
  loading: boolean;
  open: boolean;
  onClose: () => void;
  onStartConversation: (title: string, message: string) => void;
}
function StartConversationModal({ loading, open, onClose, onStartConversation }: StartConversationModalProps) {
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Create Conversation Entry</DialogTitle>
      <DialogContent>
        <Stack>
          <TextField label='Title' value={title} onChange={(e) => setTitle(e.target.value)} />
          <TextField
            style={{
              flex: 1,
            }}
            multiline
            rows={10}
            label='Message'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        {loading && (
          <Box display='flex' justifyContent='center' sx={{ width: '100%' }}>
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <>
            <Button variant='contained' color='neutral' onClick={onClose}>
              Cancel
            </Button>
            <Button variant='contained' color='primary' disabled={!message} onClick={() => onStartConversation(title, message)}>
              Start conversation
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

interface AddStrategyModalProps {
  loading: boolean;
  open: boolean;
  onClose: () => void;
  onAddStrategy: (strategy: string, title: string) => void;
}

const StrategyMap = [
  { id: 'ONBOARDING', name: 'Onboarding' },
  { id: 'RECEIPT', name: 'Receipt' },
];

function AddStrategyModal({ loading, open, onClose, onAddStrategy }: AddStrategyModalProps) {
  const [title, setTitle] = useState('');
  const [strategy, setStrategy] = useState('');

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>Add Strategy Entry</DialogTitle>
      <DialogContent>
        <Stack>
          <Select label='Strategy' labelId='strategy-select-label' value={strategy || ''} onChange={(event) => setStrategy(event.target.value)}>
            {StrategyMap.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
          <TextField
            style={{
              flex: 1,
            }}
            label='Title'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        {loading && (
          <Box display='flex' justifyContent='center' sx={{ width: '100%' }}>
            <CircularProgress />
          </Box>
        )}
        {!loading && (
          <>
            <Button variant='contained' color='neutral' onClick={onClose}>
              Cancel
            </Button>
            <Button variant='contained' color='primary' disabled={!strategy} onClick={() => onAddStrategy(strategy, title)}>
              Start w/ Strategy
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}

export function AdminConversationsPage() {
  const theme = useTheme();
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
  const [selectedConversation, setSelectedConversation] = useState<Conversation | null>(null);
  const { organizations, conversations, conversationsById, startImpersonatedConversation, addImpersonatedMessageToConversation } =
    useAdminData(selectedOrganization);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [startConversationModalOpen, setStartConversationModalOpen] = useState(false);
  const [addStrategyModalOpen, setAddStrategyModalOpen] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [messageContainerRef, setMessageContainerRef] = useState<HTMLDivElement | null>(null);
  const [messagesWrapperRef, setMessagesWrapperRef] = useState<HTMLDivElement | null>(null);
  const messageRefs = useRef<{ [messageId: string]: RefObject<HTMLDivElement> }>({});
  const scrollLock = useRef(false);
  const scrollLockMutable = useRef(false);

  const addMessage = async (message: string) => {
    if (!selectedOrganization || !selectedConversation) {
      return;
    }

    try {
      setLoading(true);
      await addImpersonatedMessageToConversation(selectedOrganization.id!, selectedConversation.id, message);
    } finally {
      setLoading(false);
    }
  };

  const startConversation = async (title: string, message: string) => {
    if (!selectedOrganization) {
      return;
    }

    try {
      setLoading(true);
      await startImpersonatedConversation({ organizationId: selectedOrganization.id!, conversationId: uuid(), title, message });
      setStartConversationModalOpen(false);
    } finally {
      setLoading(false);
    }
  };

  const addStrategy = async (strategy: string, title: string) => {
    if (!selectedOrganization) {
      return;
    }

    try {
      setLoading(true);
      await startImpersonatedConversation({ organizationId: selectedOrganization.id!, conversationId: uuid(), title, strategy });
      setAddStrategyModalOpen(false);
    } finally {
      setLoading(false);
    }
  };

  if (!organizations) {
    return (
      <PageContainer>
        <PageHeader title='Admin - Journal' />
        <PageBody gutter='thin' style={{ alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </PageBody>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <PageHeader title='Admin - Conversations' />
      <PageBody gutter='thin'>
        <Stack height='100%'>
          <Stack direction='row' justifyContent='space-between' paddingTop={theme.spacing(2)}>
            <AdminOrganizationSelect organizations={organizations} onOrganizationChange={(org) => setSelectedOrganization(org)} />
            <Box>
              <Button variant='contained' color='primary' onClick={() => setStartConversationModalOpen(true)}>
                <Message size='1rem' variant='Bold' style={{ marginRight: theme.spacing(2) }} />
                Start conversation
              </Button>
              <Button sx={{ margin: theme.spacing(2) }} variant='contained' color='primary' onClick={() => setAddStrategyModalOpen(true)}>
                <Cd size='1rem' variant='Bold' style={{ marginRight: theme.spacing(2) }} />
                Start w/ Strategy
              </Button>
            </Box>
          </Stack>

          {conversations && !selectedConversation && (
            <>
              <ConversationsOverview conversations={conversations} onSelectConversation={(conversation) => setSelectedConversation(conversation)} />
              <StartConversationModal
                loading={loading}
                open={startConversationModalOpen}
                onClose={() => setStartConversationModalOpen(false)}
                onStartConversation={(title, message) => startConversation(title, message)}
              />

              <AddStrategyModal
                loading={loading}
                open={addStrategyModalOpen}
                onClose={() => setAddStrategyModalOpen(false)}
                onAddStrategy={(strategy, title) => addStrategy(strategy, title)}
              />
            </>
          )}
          {selectedConversation && conversationsById && (
            <>
              <MuiButton
                sx={{
                  width: 'auto',
                  alignSelf: 'start',
                }}
                variant='text'
                onClick={() => setSelectedConversation(null)}
              >
                <ArrowLeft2 variant='Bold' size='1rem' />
                Back
              </MuiButton>
              <ConversationMessageContainer
                conversation={selectedConversation}
                streaming={loading}
                messages={conversationsById[selectedConversation.id].messages}
                messageRefs={messageRefs.current}
                messagesWrapperRef={messagesWrapperRef}
                messageContainerRef={messageContainerRef}
                scrollLock={scrollLock}
                scrollLockMutable={scrollLockMutable}
                ref={setMessageContainerRef}
              >
                <ConversationMessages
                  conversation={selectedConversation}
                  messages={conversationsById[selectedConversation.id].messages}
                  messageRefs={messageRefs.current}
                  ref={setMessagesWrapperRef}
                />
              </ConversationMessageContainer>
              <ConversationInput
                ref={inputRef}
                style={{
                  borderRadius: theme.roundedCorners(4),
                  marginLeft: isSmallScreen ? theme.spacing(5) : theme.spacing(50),
                  marginRight: isSmallScreen ? theme.spacing(5) : theme.spacing(50),
                  marginBottom: isSmallScreen ? theme.spacing(5) : theme.spacing(20),
                }}
                conversationStarted={!!conversationsById[selectedConversation.id].messages.length}
                conversationStreaming={loading}
                conversationEnded={selectedConversation.ended}
                onSubmit={(message) => addMessage(message)}
              />
            </>
          )}
        </Stack>
      </PageBody>
    </PageContainer>
  );
}
