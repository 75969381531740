import { alpha, InputLabel, MenuItem, Select, SelectChangeEvent, SelectProps, Stack, useTheme } from '@mui/material';
import { GridRenderCellParams, GridRenderEditCellParams, GridValidRowModel, useGridApiContext } from '@mui/x-data-grid';
import { ArrowDown2 } from 'iconsax-react';
import { ForwardedRef, forwardRef, useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

export interface DataGridSelectDataGridCellViewProps<I = { id: string; name: string }> {
  items: I[];
  params: GridRenderCellParams<GridValidRowModel, I | null>;
  valid?: boolean;
  disabled?: boolean;
}

export const DataGridSelectDataGridCellView = forwardRef(
  ({ items, params, valid, disabled, ...rest }: DataGridSelectDataGridCellViewProps, ref: ForwardedRef<HTMLDivElement>) => {
    const value = params.value;
    const theme = useTheme();

    const itemNameMap = useMemo(() => {
      return items.reduce((map, current) => {
        map.set(current.id, current.name);
        return map;
      }, new Map<string, string>());
    }, [items]);

    return (
      <Stack
        ref={ref}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        overflow='hidden'
        width='100%'
        height='100%'
        bgcolor={valid || valid === undefined ? undefined : alpha(theme.palette.error.main, 0.5)}
        padding={theme.spacing(2)}
        style={{
          cursor: disabled ? 'auto' : 'pointer',
          color: disabled ? theme.palette.text.disabled : theme.palette.text.primary,
        }}
        {...rest}
      >
        <span
          style={{
            flex: '1 1 0%',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {value ? itemNameMap.get(value.id) : 'None'}
        </span>
        <ArrowDown2 variant='Bold' size='1rem' />
      </Stack>
    );
  }
);

export type DataGridSelectDataGridCellEditProps<I = { id: string; name: string }> = SelectProps<I> & {
  label?: string;
  noSelectionValue?: string;
  items: I[];
  disabledItems?: Set<string>;
  onItemChange?: (item: I) => void;
  params: GridRenderEditCellParams<GridValidRowModel, I>;
};

export function DataGridSelectDataGridCellEdit<I extends { id: string; name: string }>({
  noSelectionValue,
  label,
  items,
  disabledItems,
  onItemChange,
  params,
}: DataGridSelectDataGridCellEditProps<I>) {
  const { id, value, field } = params;

  const labelId = useMemo(() => {
    return uuid();
  }, []);

  const itemsById = useMemo(() => {
    return items.reduce(
      (map, current) => {
        map[current.id] = current;
        return map;
      },
      {} as { [itemId: string]: I }
    );
  }, [items]);

  const apiRef = useGridApiContext();

  const change = useCallback(
    async (event: SelectChangeEvent) => {
      const newValue = event.target.value;
      const item = itemsById[newValue] || null;
      await apiRef.current.setEditCellValue({ id, field, value: item });
      apiRef.current.stopCellEditMode({ id, field });

      if (onItemChange) {
        onItemChange(item);
      }
    },
    [apiRef, field, id, itemsById, onItemChange]
  );

  return (
    <>
      {label && <InputLabel id={labelId}>{label}</InputLabel>}
      <Select
        open={true}
        value={value?.id || noSelectionValue}
        onChange={change}
        onClick={(e) => {
          const isBackdropClick = (e.target as HTMLElement).classList.contains('MuiModal-backdrop');
          if (isBackdropClick) {
            apiRef.current.stopCellEditMode({ id, field });
          }
        }}
      >
        {noSelectionValue && <MenuItem value={noSelectionValue}>None</MenuItem>}
        {items.map((i) => (
          <MenuItem key={i.id} value={i.id} disabled={disabledItems && disabledItems.has(i.id)}>
            {i.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}
