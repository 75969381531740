import { CheckCircle } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  alpha,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { format, parseISO } from 'date-fns';
import { ArrowRight, Bank, CloseCircle, DocumentUpload, Personalcard, TickCircle } from 'iconsax-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import InputMask from 'react-input-mask';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Country, fileToBase64, Province, Role, UserDetailsUpdates, useSession } from '../../api';
import { Button, PageBody, PageContainer, PageHeader } from '../../components';
import { DelayCollapse } from '../../components/animations';
import { SMALL_HORIZONTAL_SPACING, SMALL_VERTICAL_SPACING } from '../../theme';
import { upperSnakeToLabel } from '../../utils/string';

enum Steps {
  PERSONAL = 'PERSONAL',
  BANKING = 'BANKING',
  CONFIRM = 'CONFIRM',
}

const DetailsStack = styled(Stack)`
  position: relative;

  > * {
    z-index: 1;
  }
`;

function FileUpload({
  label,
  file,
  disabled,
  onFileChange,
}: {
  label: string;
  file: File | null;
  disabled: boolean;
  onFileChange: (file: File | null) => void;
}) {
  const theme = useTheme();
  const [fileRejections, setFileRejections] = useState<string[]>([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': [],
    },
    maxSize: 5242880, // 5MB
    disabled,
    onDrop: (acceptedFiles, fileRejections) => {
      const rejectionData = [];

      for (const fileRejection of fileRejections) {
        for (const error of fileRejection.errors) {
          if (error.code === 'file-too-large') {
            rejectionData.push(`${fileRejection.file.name} is larger than 5 MB.`);
          }
          if (error.code === 'file-invalid-type') {
            rejectionData.push(`File type is not correct for this institution's statements.`);
          }
        }
      }

      setFileRejections(rejectionData);
      onFileChange(acceptedFiles[0]);
    },
  });

  return (
    <Stack spacing={0}>
      <Typography marginLeft={theme.spacing(SMALL_HORIZONTAL_SPACING)} color={alpha(theme.palette.text.primary, 0.54)}>
        {label}
      </Typography>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        style={{
          cursor: file ? 'default' : 'pointer',
          border: `1px solid ${theme.palette.border.main}`,
          borderRadius: theme.roundedCorners(1),
          paddingTop: theme.spacing(SMALL_VERTICAL_SPACING),
          paddingBottom: theme.spacing(SMALL_VERTICAL_SPACING),
          paddingLeft: theme.spacing(SMALL_HORIZONTAL_SPACING),
          paddingRight: theme.spacing(SMALL_HORIZONTAL_SPACING),
          backgroundColor: theme.palette.background.default,
        }}
        {...getRootProps()}
      >
        <input style={{ display: 'none' }} {...getInputProps()} />

        {file ? (
          <>
            <Stack direction='row' justifyContent='start' alignItems='center'>
              <CheckCircle height='2rem' style={{ color: disabled ? theme.palette.text.disabled : theme.palette.primary.main }} />
              <Typography>{file.name}</Typography>
            </Stack>

            <Stack direction='row' justifyContent='end' alignItems='center'>
              <IconButton
                onClick={(e) => {
                  onFileChange(null);
                  e.stopPropagation();
                }}
              >
                <CloseCircle height='2rem' style={{ color: disabled ? theme.palette.text.disabled : theme.palette.neutral.main }} />
              </IconButton>
            </Stack>
          </>
        ) : (
          <>
            <Stack direction='row' justifyContent='start' alignItems='center'>
              <DocumentUpload size='2rem' color={disabled ? theme.palette.text.disabled : theme.palette.primary.main} />
              <Typography>
                Drag and drop your file(s) here or <Link>Browse...</Link>
              </Typography>
            </Stack>
          </>
        )}
      </Stack>

      {fileRejections.map((r) => (
        <Alert key={r} severity='error' style={{ margin: theme.spacing(SMALL_HORIZONTAL_SPACING) }}>
          <AlertTitle>Error</AlertTitle>
          {r}
        </Alert>
      ))}
    </Stack>
  );
}

export function PayrollDetailsPage({ ...props }) {
  const theme = useTheme();
  const { session, userDetailsRequired, checkUserDetailsRequired, getUserPrivateDetails, getUserBankingDetails, updateUserDetails, getTd1Files } =
    useSession();
  const location = useLocation();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine1Touched, setAddressLine1Touched] = useState(false);

  const [addressLine2, setAddressLine2] = useState('');
  const [addressLine2Touched, setAddressLine2Touched] = useState(false);

  const [city, setCity] = useState('');
  const [cityTouched, setCityTouched] = useState(false);

  const [province, setProvince] = useState('');
  const [provinceTouched, setProvinceTouched] = useState(false);

  const [postalCode, setPostalCode] = useState('');
  const [postalCodeTouched, setPostalCodeTouched] = useState(false);
  const postalCodeError = useMemo(() => {
    return !/^[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d$/.test(postalCode);
  }, [postalCode]);

  const [dateOfBirth, setDateOfBirth] = useState('');
  const [dateOfBirthTouched, setDateOfBirthTouched] = useState(false);

  const [personalEmail, setPersonalEmail] = useState('');
  const [personalEmailTouched, setPersonalEmailTouched] = useState(false);
  const personalEmailError = useMemo(() => {
    return !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/.test(personalEmail);
  }, [personalEmail]);

  const [sin, setSin] = useState('');
  const [sinTouched, setSinTouched] = useState(false);
  const sinError = useMemo(() => {
    return !/^\d{3}-\d{3}-\d{3}$/.test(sin);
  }, [sin]);

  const [contractorBusinessName, setContractorBusinessName] = useState('');
  const [contractorBusinessNameTouched, setContractorBusinessNameTouched] = useState(false);

  const personalDetailsError = useMemo(() => {
    if (session?.role === Role.CONTRACTOR) {
      return !contractorBusinessName;
    } else {
      return !addressLine1 || !city || !province || postalCodeError || !dateOfBirth || personalEmailError || sinError;
    }
  }, [addressLine1, city, province, postalCodeError, dateOfBirth, personalEmailError, sinError, session, contractorBusinessName]);

  const [bankingTransitNumber, setBankingTransitNumber] = useState('');
  const [bankingTransitNumberTouched, setBankingTransitNumberTouched] = useState(false);
  const [bankingInstitutionNumber, setBankingInstitutionNumber] = useState('');
  const [bankingInstitutionNumberTouched, setBankingInstitutionNumberTouched] = useState(false);
  const [bankingAccountNumber, setBankingAccountNumber] = useState('');
  const [bankingAccountNumberTouched, setBankingAccountNumberTouched] = useState(false);

  const [existingTd1Federal, setExistingTd1Federal] = useState<string | null>(null);
  const [td1Federal, setTd1Federal] = useState<File | null>(null);
  const [td1FederalTouched, setTd1FederalTouched] = useState(false);
  const [existingTd1Provincial, setExistingTd1Provincial] = useState<string | null>(null);
  const [td1Provincial, setTd1Provincial] = useState<File | null>(null);
  const [td1ProvincialTouched, setTd1ProvincialTouched] = useState(false);

  const bankingDetailsError = useMemo(() => {
    if (session?.role === Role.CONTRACTOR) {
      return !bankingTransitNumber || !bankingInstitutionNumber || !bankingAccountNumber;
    } else {
      return (
        !bankingTransitNumber ||
        !bankingInstitutionNumber ||
        !bankingAccountNumber ||
        (!td1Federal && !existingTd1Federal) ||
        (!td1Provincial && !existingTd1Provincial)
      );
    }
  }, [
    bankingTransitNumber,
    bankingInstitutionNumber,
    bankingAccountNumber,
    td1Federal,
    td1Provincial,
    session,
    existingTd1Federal,
    existingTd1Provincial,
  ]);

  const redirect = useCallback(() => {
    const searchParams = new URLSearchParams(location.search);

    const redirectTo = searchParams.get('redirect');

    if (redirectTo) {
      navigate(redirectTo);
    }
  }, [navigate, location]);

  useEffect(() => {
    checkUserDetailsRequired().catch((e) => {
      throw e;
    });
  }, [checkUserDetailsRequired]);

  useEffect(() => {
    if (userDetailsRequired !== 'PAYROLL') {
      redirect();
    }
  }, [userDetailsRequired, redirect]);

  useEffect(() => {
    const check = async () => {
      if (!session || userDetailsRequired !== 'PAYROLL') {
        return;
      }

      const privateDetailsPromise = getUserPrivateDetails();
      const bankingDetailsPromise = getUserBankingDetails();
      const td1FilesPromise = getTd1Files();

      const [privateDetails, bankingDetails, td1Files] = await Promise.all([privateDetailsPromise, bankingDetailsPromise, td1FilesPromise]);

      setAddressLine1(session.addressLine1 || '');
      setAddressLine1Touched(false);
      setAddressLine2(session.addressLine2 || '');
      setAddressLine2Touched(false);
      setCity(session.city || '');
      setCityTouched(false);
      setProvince(session.province || '');
      setProvinceTouched(false);
      setPostalCode(session.postalCode || '');
      setPostalCodeTouched(false);
      setDateOfBirth(session.dateOfBirth || '');
      setDateOfBirthTouched(false);
      setPersonalEmail(session.personalEmail || '');
      setPersonalEmailTouched(false);
      setSin(privateDetails.sin || '');
      setSinTouched(false);
      setBankingTransitNumber(bankingDetails.bankingTransitNumber || '');
      setBankingTransitNumberTouched(false);
      setBankingInstitutionNumber(bankingDetails.bankingInstitutionNumber || '');
      setBankingInstitutionNumberTouched(false);
      setBankingAccountNumber(bankingDetails.bankingAccountNumber || '');
      setBankingAccountNumberTouched(false);
      setContractorBusinessName(session.contractorBusinessName || '');
      setContractorBusinessNameTouched(false);
      setExistingTd1Federal(td1Files.federal);
      setExistingTd1Provincial(td1Files.provincial);
    };

    check().catch((e) => {
      throw e;
    });
  }, [redirect, userDetailsRequired, session, getUserBankingDetails, getUserPrivateDetails, getTd1Files]);

  const submit = useCallback(async () => {
    try {
      setLoading(true);

      const updates = {} as UserDetailsUpdates;

      if (addressLine1Touched) {
        updates.addressLine1 = addressLine1;
      }

      if (addressLine2Touched) {
        updates.addressLine2 = addressLine2;
      }

      if (cityTouched) {
        updates.city = city;
      }

      if (provinceTouched) {
        updates.province = province as Province;
        updates.country = Country.CANADA;
      }

      if (postalCodeTouched) {
        updates.postalCode = postalCode;
      }

      if (dateOfBirthTouched) {
        updates.dateOfBirth = dateOfBirth;
      }

      if (personalEmailTouched) {
        updates.personalEmail = personalEmail;
      }

      if (sinTouched) {
        updates.sin = sin;
      }

      if (bankingTransitNumberTouched) {
        updates.bankingTransitNumber = bankingTransitNumber;
      }

      if (bankingInstitutionNumberTouched) {
        updates.bankingInstitutionNumber = bankingInstitutionNumber;
      }

      if (bankingAccountNumberTouched) {
        updates.bankingAccountNumber = bankingAccountNumber;
      }

      if (td1FederalTouched && td1Federal) {
        updates.td1FederalBase64 = await fileToBase64(td1Federal);
      }

      if (td1ProvincialTouched && td1Provincial) {
        updates.td1ProvincialBase64 = await fileToBase64(td1Provincial);
      }

      if (contractorBusinessNameTouched) {
        updates.contractorBusinessName = contractorBusinessName;
      }

      await updateUserDetails(updates);

      setStep(Steps.CONFIRM);
    } finally {
      setLoading(false);
    }
  }, [
    updateUserDetails,
    addressLine1,
    addressLine1Touched,
    addressLine2,
    addressLine2Touched,
    city,
    cityTouched,
    province,
    provinceTouched,
    postalCode,
    postalCodeTouched,
    dateOfBirth,
    dateOfBirthTouched,
    personalEmail,
    personalEmailTouched,
    sin,
    sinTouched,
    bankingTransitNumber,
    bankingTransitNumberTouched,
    bankingInstitutionNumber,
    bankingInstitutionNumberTouched,
    bankingAccountNumber,
    bankingAccountNumberTouched,
    td1Federal,
    td1FederalTouched,
    td1Provincial,
    td1ProvincialTouched,
    contractorBusinessName,
    contractorBusinessNameTouched,
  ]);

  const confirm = async () => {
    if (step === Steps.CONFIRM) {
      await checkUserDetailsRequired();
    }
  };

  const [step, setStep] = useState(Steps.BANKING);

  return (
    <PageContainer {...props} style={{ width: '100vw', height: '100dvh' }}>
      <PageHeader title='Complete Payroll Details' />
      <PageBody
        style={{
          paddingTop: '1rem',
          alignItems: 'center',
        }}
      >
        <DelayCollapse in={step === Steps.BANKING} timeout={250} unmountOnExit>
          <DetailsStack maxWidth={theme.spacing(128)} minWidth='32rem'>
            <Bank
              size='24rem'
              style={{
                position: 'absolute',
                opacity: 0.1,
                right: -128,
                top: -32,
                zIndex: 0,
              }}
            />

            <Typography>Otter Payroll has been enabled for your organization - let's get you paid!</Typography>

            <Typography>
              Please give us information about the bank account you'd like your pay deposited into. You can find your institution, transit and account
              numbers on a void cheque or by contacting your bank.
            </Typography>

            <FormControl required error={!bankingTransitNumber && bankingTransitNumberTouched} disabled={loading} fullWidth>
              <InputMask
                mask='99999'
                maskChar={''}
                value={bankingTransitNumber}
                onBlur={() => setBankingTransitNumberTouched(true)}
                onChange={(e) => setBankingTransitNumber(e.target.value)}
              >
                {(inputProps) => <TextField {...inputProps} label='Transit Number' placeholder='#...' helperText='Format: 99999' />}
              </InputMask>
              {!bankingTransitNumber && bankingTransitNumberTouched && <FormHelperText>Must be in "99999" format</FormHelperText>}
            </FormControl>

            <FormControl required error={!bankingInstitutionNumber && bankingInstitutionNumberTouched} disabled={loading} fullWidth>
              <InputMask
                mask='999'
                maskChar={''}
                value={bankingInstitutionNumber}
                onBlur={() => setBankingInstitutionNumberTouched(true)}
                onChange={(e) => setBankingInstitutionNumber(e.target.value)}
              >
                {(inputProps) => <TextField {...inputProps} label='Institution Number' placeholder='#...' helperText='Format: 999' />}
              </InputMask>
              {!bankingInstitutionNumber && bankingInstitutionNumberTouched && <FormHelperText>Must be in "999" format</FormHelperText>}
            </FormControl>

            <FormControl required error={!bankingAccountNumber && bankingAccountNumberTouched} disabled={loading} fullWidth>
              <InputMask
                mask='999999999999'
                maskChar={''}
                value={bankingAccountNumber}
                onBlur={() => setBankingAccountNumberTouched(true)}
                onChange={(e) => setBankingAccountNumber(e.target.value)}
              >
                {(inputProps) => <TextField {...inputProps} label='Account Number' placeholder='#...' helperText='7-12 digits' />}
              </InputMask>
              {!bankingAccountNumber && bankingAccountNumberTouched && <FormHelperText>Must be 7-12 digits</FormHelperText>}
            </FormControl>

            {(session?.role === Role.EMPLOYEE || session?.role === Role.OWNER) && !existingTd1Federal && !existingTd1Provincial && (
              <>
                <Typography>
                  Please complete and upload the CRA's{' '}
                  <Link
                    target='_blank'
                    href='https://www.canada.ca/en/revenue-agency/services/forms-publications/td1-personal-tax-credits-returns/td1-forms-pay-received-on-january-1-later/td1.html'
                  >
                    federal
                  </Link>{' '}
                  and{' '}
                  <Link
                    target='_blank'
                    href='https://www.canada.ca/en/revenue-agency/services/forms-publications/td1-personal-tax-credits-returns/td1-forms-pay-received-on-january-1-later.html'
                  >
                    provincial
                  </Link>{' '}
                  tax forms for your province
                </Typography>

                <FormControl required error={!td1Federal && td1FederalTouched}>
                  <FileUpload
                    label='Federal TD1'
                    file={td1Federal}
                    disabled={loading}
                    onFileChange={(file) => {
                      setTd1Federal(file);
                      setTd1FederalTouched(true);
                    }}
                  />
                  {!td1Federal && td1FederalTouched && <FormHelperText>Required</FormHelperText>}
                </FormControl>

                <FormControl required error={!td1Provincial && td1ProvincialTouched}>
                  <FileUpload
                    label='Provincial TD1'
                    file={td1Provincial}
                    disabled={loading}
                    onFileChange={(file) => {
                      setTd1Provincial(file);
                      setTd1ProvincialTouched(true);
                    }}
                  />
                  {!td1Provincial && td1ProvincialTouched && <FormHelperText>Required</FormHelperText>}
                </FormControl>
              </>
            )}

            <Stack direction='row' justifyContent='end'>
              <Button variant='contained' color='primary' disabled={bankingDetailsError} onClick={() => setStep(Steps.PERSONAL)}>
                <Stack direction='row' alignItems='center' spacing={1}>
                  <Typography variant='button' color={theme.palette.primary.contrastText}>
                    Next
                  </Typography>
                  <ArrowRight size='1rem' color={theme.palette.primary.contrastText} />
                </Stack>
              </Button>
            </Stack>
          </DetailsStack>
        </DelayCollapse>

        <DelayCollapse in={step === Steps.PERSONAL} timeout={250} delayMs={{ enter: 250, exit: 0 }} unmountOnExit>
          <DetailsStack maxWidth={theme.spacing(128)}>
            <Personalcard
              size='32rem'
              style={{
                position: 'absolute',
                opacity: 0.1,
                left: -128,
                top: -64,
                zIndex: 0,
              }}
            />

            <Typography>
              Please fill in your details below to ensure your pay is set up correctly and meets all employment and tax requirements.
            </Typography>

            {(session?.role === Role.EMPLOYEE || session?.role === Role.OWNER) && (
              <>
                <FormControl required error={!addressLine1 && addressLine1Touched} disabled={loading}>
                  <TextField
                    label='Address Line 1 - Street Address'
                    placeholder='Street address...'
                    value={addressLine1}
                    onBlur={() => setAddressLine1Touched(true)}
                    onChange={(e) => setAddressLine1(e.target.value)}
                  />
                  {!addressLine1 && addressLine1Touched && <FormHelperText>Required</FormHelperText>}
                </FormControl>

                <FormControl disabled={loading}>
                  <TextField
                    label='Address Line 2 - Unit/Apt #'
                    placeholder='Unit/Apt #...'
                    value={addressLine2}
                    onChange={(e) => setAddressLine2(e.target.value)}
                  />
                </FormControl>

                <Stack direction='row'>
                  <FormControl required error={!city && cityTouched} disabled={loading}>
                    <TextField
                      label='City'
                      placeholder='City...'
                      value={city}
                      onBlur={() => setCityTouched(true)}
                      onChange={(e) => setCity(e.target.value)}
                    />
                    {!city && cityTouched && <FormHelperText>Required</FormHelperText>}
                  </FormControl>

                  <FormControl required error={!province && provinceTouched} disabled={loading}>
                    <InputLabel id='user-province-select-label'>Province</InputLabel>
                    <Select
                      label='Province'
                      labelId='user-province-select-label'
                      value={province || 'none'}
                      onChange={(event) => setProvince(event.target.value as Province)}
                      onBlur={() => setProvinceTouched(true)}
                    >
                      <MenuItem value={'none'}>None</MenuItem>
                      {Object.values(Province).map((p) => {
                        return (
                          <MenuItem key={p} value={p}>
                            {upperSnakeToLabel(p)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {!province && provinceTouched && <FormHelperText>Required</FormHelperText>}
                  </FormControl>

                  <FormControl required error={postalCodeError && postalCodeTouched} disabled={loading}>
                    <InputMask
                      mask='a9a 9a9'
                      maskChar={null}
                      value={postalCode}
                      onBlur={() => setPostalCodeTouched(true)}
                      onChange={(e) => setPostalCode(e.target.value)}
                    >
                      {(inputProps) => <TextField {...inputProps} label='Postal Code' placeholder='Postal code...' helperText='Format: A1A 1A1' />}
                    </InputMask>
                    {postalCodeError && postalCodeTouched && <FormHelperText>Must be in "A1A 1A1" format</FormHelperText>}
                  </FormControl>
                </Stack>

                <FormControl required error={!dateOfBirth && dateOfBirthTouched} disabled={loading}>
                  <DatePicker
                    label='Date of Birth'
                    format='yyyy-MM-dd'
                    value={dateOfBirth ? parseISO(dateOfBirth) : null}
                    onChange={(date) => {
                      setDateOfBirth(date ? format(date, 'yyyy-MM-dd') : '');
                      setDateOfBirthTouched(true);
                    }}
                  />
                  {!dateOfBirth && dateOfBirthTouched && <FormHelperText>Required</FormHelperText>}
                </FormControl>

                <FormControl required error={personalEmailError && personalEmailTouched} disabled={loading}>
                  <TextField
                    label='Personal Email Address'
                    placeholder='Email...'
                    value={personalEmail}
                    onBlur={() => setPersonalEmailTouched(true)}
                    onChange={(e) => setPersonalEmail(e.target.value)}
                  />
                  {personalEmailError && personalEmailTouched && <FormHelperText>Required</FormHelperText>}
                </FormControl>

                <FormControl required error={sinError && sinTouched} disabled={loading}>
                  <InputMask mask='999-999-999' maskChar='' value={sin} onBlur={() => setSinTouched(true)} onChange={(e) => setSin(e.target.value)}>
                    {(inputProps) => <TextField {...inputProps} label='SIN' variant='outlined' helperText='Format: 123-456-789' />}
                  </InputMask>
                  {sinError && sinTouched && <FormHelperText>Required</FormHelperText>}
                </FormControl>
              </>
            )}

            {session?.role === Role.CONTRACTOR && (
              <FormControl required error={!contractorBusinessName && contractorBusinessNameTouched} disabled={loading}>
                <TextField
                  label='Contractor Business Name'
                  placeholder='Business Name...'
                  value={contractorBusinessName}
                  onBlur={() => setContractorBusinessNameTouched(true)}
                  onChange={(e) => setContractorBusinessName(e.target.value)}
                />
                {!contractorBusinessName && contractorBusinessNameTouched && <FormHelperText>Required</FormHelperText>}
              </FormControl>
            )}

            <Stack direction='row' justifyContent='end' spacing={SMALL_HORIZONTAL_SPACING}>
              <Button variant='outlined' color='neutral' disabled={personalDetailsError || loading} onClick={() => setStep(Steps.BANKING)}>
                <Stack direction='row' alignItems='center' spacing={1}>
                  <Typography variant='button' color={theme.palette.text.primary}>
                    Back
                  </Typography>
                </Stack>
              </Button>

              <Button variant='contained' color='primary' disabled={personalDetailsError || loading} onClick={() => submit()}>
                <Stack direction='row' alignItems='center' spacing={1}>
                  <Typography variant='button' color={theme.palette.primary.contrastText}>
                    Submit
                  </Typography>
                  <ArrowRight size='1rem' color={theme.palette.primary.contrastText} />
                </Stack>
              </Button>
            </Stack>
          </DetailsStack>
        </DelayCollapse>

        <DelayCollapse in={step === Steps.CONFIRM} timeout={250} delayMs={{ enter: 250, exit: 0 }} unmountOnExit>
          <DetailsStack maxWidth={theme.spacing(128)} justifyContent='center' minHeight={theme.spacing(72)}>
            <TickCircle
              size='32rem'
              style={{
                position: 'absolute',
                opacity: 0.1,
                left: '50%',
                marginLeft: '-16rem',
                top: -32,
                zIndex: 0,
              }}
            />

            <Typography>You're all set! Your details have been submitted and are being processed. We're setting up your payroll now!</Typography>

            <Stack direction='row' justifyContent='center' spacing={SMALL_HORIZONTAL_SPACING}>
              <Button variant='contained' color='primary' disabled={personalDetailsError || loading} onClick={() => confirm()}>
                <Stack direction='row' alignItems='center' spacing={1}>
                  <Typography variant='button' color={theme.palette.primary.contrastText}>
                    Ok!
                  </Typography>
                  <ArrowRight size='1rem' color={theme.palette.primary.contrastText} />
                </Stack>
              </Button>
            </Stack>
          </DetailsStack>
        </DelayCollapse>
      </PageBody>
    </PageContainer>
  );
}
