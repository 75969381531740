export interface TreeInput {
  id: string;
  parentId?: string | null;
  path: string;
}

export interface TreeEntity extends TreeInput {
  depth: number;
  index: number;
  hasChildren: boolean;
}

function addBranch<T extends TreeInput>(tree: TreeEntity[], root: T, depth: number, entitiesByParentId: Map<string | null, T[]>) {
  tree.push({
    ...root,
    depth,
  } as unknown as TreeEntity);

  const children = entitiesByParentId.get(root.id);
  if (children) {
    for (const child of children) {
      addBranch(tree, child, depth + 1, entitiesByParentId);
    }
  }
}

export function SortIntoTreeOrder<T extends TreeInput>(entities: T[], subComparator?: (v1: T, v2: T) => number) {
  const sorted = subComparator ? entities.sort(subComparator) : entities;

  const hasChildrenMap = new Map<string, boolean>();
  const entitiesByParentId = new Map<string | null, T[]>();
  for (const entity of sorted) {
    const parentId = entity.parentId || null;
    let existing = entitiesByParentId.get(parentId);
    if (!existing) {
      existing = [];
      entitiesByParentId.set(parentId, existing);
    }

    entitiesByParentId.set(parentId, [...existing, entity]);

    if (parentId) {
      hasChildrenMap.set(parentId, true);
    }
  }

  const roots = sorted.filter((e) => !e.parentId);
  const tree = [] as (TreeEntity & T)[];
  for (const root of roots) {
    addBranch(tree, root, 0, entitiesByParentId);
  }

  for (let i = 0; i < tree.length; i++) {
    tree[i].index = i;
    tree[i].hasChildren = hasChildrenMap.get(tree[i].id) || false;
  }

  return tree;
}
