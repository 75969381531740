import { AxiosHeaders, InternalAxiosRequestConfig } from 'axios';

export const TimeZoneInterceptor = (config: InternalAxiosRequestConfig) => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (!config.headers) {
    config.headers = new AxiosHeaders();
  }

  config.headers.set('x-user-time-zone', timeZone);

  return config;
};
