import { Collapse, CollapseProps, Fade, FadeProps } from '@mui/material';
import { useEffect, useState } from 'react';

export interface DelayFadeProps extends FadeProps {
  delayMs?:
    | number
    | {
        enter?: number;
        exit?: number;
      };
}

export const DelayFade = ({ in: isIn, delayMs, children, ...props }: DelayFadeProps) => {
  const [isInDelayed, setIsInDelayed] = useState(false);

  useEffect(() => {
    let delay: number;
    if (typeof delayMs === 'number') {
      delay = delayMs;
    } else if (isIn && delayMs?.enter !== undefined) {
      delay = delayMs.enter;
    } else if (!isIn && delayMs?.exit !== undefined) {
      delay = delayMs.exit;
    } else {
      delay = 0;
    }

    const timeout = setTimeout(() => {
      setIsInDelayed(!!isIn);
    }, delay) as unknown as number;

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isIn, delayMs]);

  return (
    <Fade in={isInDelayed} {...props}>
      {children}
    </Fade>
  );
};

export interface DelayCollapseProps extends CollapseProps {
  delayMs?:
    | number
    | {
        enter?: number;
        exit?: number;
      };
}

export const DelayCollapse = ({ in: isIn, delayMs, children, ...props }: DelayCollapseProps) => {
  const [isInDelayed, setIsInDelayed] = useState(false);

  useEffect(() => {
    let delay: number;
    if (typeof delayMs === 'number') {
      delay = delayMs;
    } else if (isIn && delayMs?.enter !== undefined) {
      delay = delayMs.enter;
    } else if (!isIn && delayMs?.exit !== undefined) {
      delay = delayMs.exit;
    } else {
      delay = 0;
    }

    const timeout = setTimeout(() => {
      setIsInDelayed(!!isIn);
    }, delay) as unknown as number;

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [isIn, delayMs]);

  return (
    <Collapse in={isInDelayed} {...props}>
      {children}
    </Collapse>
  );
};
